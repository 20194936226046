<div fxLayout="column" class="dialog-wrapper">
    <div fxFlex="100" fxLayoutAlign="start start" fxLayout="row">
        <h4 mat-dialog-title class="no-margins dialog-title">
            {{ 'Leave chat' | transloco }}
        </h4>
        <vsc-close-button (click)="cancel()"> </vsc-close-button>
    </div>
    <h2 class="no-margins">{{ data.title }}</h2>
    <div
        mat-dialog-content
        fxLayout="column"
        fxLayoutGap="24px"
        class="display-content"
    >
        <h2>{{ 'Are you sure you want to leave chat?' | transloco }}</h2>
    </div>

    <div fxLayoutAlign="end center" fxLayoutGap="16px" class="buttons-wrapper">
        <button
            type="button"
            mat-stroked-button
            (click)="cancel()"
            color="primary"
            class="mr-8 dialog-cancel-button border"
        >
            {{ 'Cancel' | transloco }}
        </button>
        <button
            (click)="leaveChat()"
            mat-flat-button
            color="primary"
            class="dialog-submit-button"
        >
            {{ 'Yes' | transloco }}
        </button>
    </div>
</div>
