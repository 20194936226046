<div class="action" fxLayout="row" fxLayoutAlign="end center">
    <div
        *ngIf="
            ((notification?.actionType === NotificationAction?.ChatCreated ||
                notification?.actionType === NotificationAction?.ChatUpdated ||
                notification?.actionType === NotificationAction?.NotRSVPdChat ||
                notification?.actionType === NotificationAction?.ChatPending) &&
                (isChatReady$ | async) === false) ||
            notification?.actionType ===
                NotificationAction?.ProgramSessionWatched
        "
        fxLayoutAlign="center center"
        fxLayoutGap="13px"
    >
        <button
            mat-stroked-button
            color="primary"
            class="border"
            (click)="rejectAction($event)"
        >
            {{ 'No' | transloco }}
        </button>
        <button mat-flat-button color="primary" (click)="invokeAction($event)">
            {{ 'Yes' | transloco }}
        </button>
    </div>
    <ng-container *ngIf="notificationMetadata?.classId">
        <vsc-join-class
            (mouseup)="markAsRead()"
            [text]="'Join program' | transloco"
            [classSessionId]="notification.entityId"
            [classId]="notificationMetadata?.classId"
            [isLiveStream]="notificationMetadata?.isLiveStream"
            *ngIf="
                (notification?.actionType ===
                    NotificationAction.ProgramStarted ||
                    notification?.actionType ===
                        NotificationAction.ProgramAboutToStart) &&
                (isProgramReady$ | async) === true
            "
        ></vsc-join-class>
    </ng-container>
    <vsc-join-chat
        (mouseup)="markAsRead()"
        *ngIf="
            (notification?.actionType === NotificationAction.ChatStarted ||
                notification?.actionType ===
                    NotificationAction.ChatAboutToStart) &&
            (isChatReady$ | async) === true
        "
        [chatId]="notification.entityId"
    ></vsc-join-chat>
</div>
