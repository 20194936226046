import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';

import { TranslocoModule } from '@ngneat/transloco';

import { IconModule } from '@shared/icon/icon.module';

import { MatSectionHeaderComponent } from './mat-section-header.component';

@NgModule({
    imports: [CommonModule, MatTooltipModule, TranslocoModule, IconModule],
    declarations: [MatSectionHeaderComponent],
    exports: [MatSectionHeaderComponent],
})
export class MatSectionHeaderModule {}
