import { Injectable } from '@angular/core';

import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

import { Weather } from './weather.model';

export type WeatherState = EntityState<Weather>;

export const initialState: WeatherState = {};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'weather' })
export class WeatherStore extends EntityStore<WeatherState, Weather> {
    constructor() {
        super(initialState);
    }
}
