import { defer, finalize, Observable, Subscriber } from 'rxjs';

/**
 *
 * Returns a result {@link Observable} that emits all values pushed by the source observable if they
 * are distinct  in comparison to the empty array.
 */
export function distinctEmptyArray() {
    return <T>(source: Observable<T>) => {
        return new Observable((subscriber: Subscriber<T>) =>
            source.subscribe({
                next(value: T) {
                    if (!Array.isArray(value) || value?.length) {
                        return subscriber.next(value);
                    }
                },
            })
        );
    };
}

/**
 *
 * @param callback Callback to execute once someone subscribes to the source {@link Observable}, as well as when the source {@link Observable} completes.
 * @returns A result {@link Observable} that emits all values pushed by the source observable.
 */
export function setLoadingCallback(callback: (isLoading: boolean) => void) {
    return <T>(source: Observable<T>) => {
        return defer(() => {
            callback(true);
            return source.pipe(finalize(() => callback(false)));
        });
    };
}
