import { ComponentPortal } from '@angular/cdk/portal';
import {
    ChangeDetectionStrategy,
    Component,
    HostListener,
    Inject,
    Injector,
    Input,
    ViewContainerRef,
} from '@angular/core';

import { Subject } from 'rxjs';

import { ToastService } from '@shared/toast/services/toast.service';

import { Toast, ToastOptions, TOAST_CONFIG, TOAST_DATA } from '../toast';

@Component({
    selector: 'vsc-toast-item',
    templateUrl: './toast-item.component.html',
    styleUrls: ['./toast-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToastItemComponent {
    private _toast: Toast;
    @Input() set toast(value: Toast) {
        this._toast = value;
        const opts = value?.options;

        this.options = {
            ...this.defaultToastConfig,
            ...{
                ...opts,
                onClick:
                    opts?.onClick ??
                    // If no onClick handler is provided and the toast
                    // has custom component, then do nothing on click from
                    (value.toastComponent
                        ? null
                        : (id: string) => {
                              this.toastService.remove(id);
                          }),
            },
        };

        this.toastClass = this.formatToastClasses(this.options.toastClass);

        // **********************************
        // **** Custom component setup. *****
        // **********************************
        if (this.toast?.toastComponent) {
            this.createToastComponentPortal(this.toast);
        }
    }

    get toast(): Toast {
        return this._toast;
    }

    toastComponentPortal: ComponentPortal<any>;
    options: ToastOptions;
    toastClass: string;
    toastClicked$: Subject<void>;

    constructor(
        @Inject(TOAST_CONFIG) private defaultToastConfig: ToastOptions,
        private toastService: ToastService,
        private injector: Injector,
        private viewContainerRef: ViewContainerRef
    ) {
        this.toastClicked$ = new Subject();
    }

    @HostListener('click', ['$event'])
    onClick(event: MouseEvent) {
        event.preventDefault();
        event.stopPropagation();

        this.options.onClick && this.options.onClick!(this.toast?.id);
        this.toastClicked$.next();
    }

    private createToastComponentPortal(toast: Toast): void {
        const injector = Injector.create({
            parent: this.injector,
            providers: [
                {
                    provide: TOAST_DATA,
                    useValue: {
                        ...toast.data,
                        toastClicked$: this.toastClicked$,
                    },
                },
            ],
        });

        this.toastComponentPortal = new ComponentPortal(
            toast.toastComponent,
            this.viewContainerRef,
            injector
        );
    }

    private formatToastClasses(classes: string | string[]): string {
        if (!classes) {
            return '';
        }

        if (typeof classes === 'string') {
            return classes;
        }

        return classes.join(' ');
    }
}
