import * as moment from 'moment';

export abstract class QueryParamsUtils {
    /**
     * @description Converts query parameters to string.
     * @param obj Resource parameters object to convert.
     * @returns Object with string query parameters.
     */
    static convertParametersToString(obj: any): any {
        if (!obj) {
            return {};
        }
        const clonedObj = { ...obj };

        Object.keys(clonedObj).forEach((key: string) => {
            const value = clonedObj[key];
            if (value === undefined || value === '' || value === null) {
                delete clonedObj[key];
            } else if (moment.isMoment(value)) {
                clonedObj[key] = moment(value).toApiDateTimeStringFormat();
            } else if (typeof value !== 'string') {
                clonedObj[key] = value + '';
            }
        });
        return clonedObj;
    }
}
