<ol *ngIf="crumbs$ | async as crumbs" class="breadcrumb">
    <li
        *ngFor="let crumb of crumbs; let last = last"
        [ngClass]="{ active: last }"
        class="breadcrumb-item"
    >
        <a
            *ngIf="crumb.path && !last; else crumbWithoutPath"
            [routerLink]="crumb.path"
        >
            {{
                crumb.isTranslatable === undefined || crumb.isTranslatable
                    ? (crumb.text | transloco)
                    : crumb.text
            }}
        </a>
        <ng-template #crumbWithoutPath>
            <span>
                {{
                    crumb.isTranslatable === undefined || crumb.isTranslatable
                        ? (crumb.text | transloco)
                        : crumb.text
                }}
            </span>
        </ng-template>

        <vsc-icon *ngIf="!last" iconStyle="solid">chevron-right</vsc-icon>
    </li>
</ol>
