import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import * as moment from 'moment';
import { take } from 'rxjs/operators';

@Injectable()
export class VersionCheckService {
    // this will be replaced by actual hash post-build.js
    private currentHash = '{{BUILD_HASH}}';
    constructor(private http: HttpClient) {}
    /**
     *
     *
     * Checks in every set frequency the version of frontend application
     *
     *
     *
     * @param url
     * @param frequency - in milliseconds, defaults to 30 minutes
     */
    public initVersionCheck(
        url: string,
        frequency: number = 10000 * 60 * 3
    ): void {
        setInterval(() => {
            this.checkVersion(url);
        }, frequency);
        this.checkVersion(url);
    }
    /**
     * Will do the call and check if the hash has changed or not
     *
     * @param url
     */
    private checkVersion(url: string): void {
        // timestamp these requests to invalidate caches
        this.http
            .get(url + '?t=' + moment().valueOf())
            .pipe(take(1))
            .subscribe(
                (response: any) => {
                    const hash = response.hash;
                    const hashChanged = this.hasHashChanged(
                        this.currentHash,
                        hash
                    );
                    // If new version, do something
                    if (hashChanged) {
                        window.location.reload();
                    }
                    // store the new hash so we wouldn't trigger versionChange again
                    // only necessary in case you did not force refresh
                    this.currentHash = hash;
                },
                (err) => {
                    console.error(err, 'Could not get version');
                }
            );
    }
    /**
     *
     *
     * Checks if hash has changed.
     *
     * This file has the JS hash, if it is a different one than in the version.json
     *
     * we are dealing with version change
     *
     *
     *
     * @param currentHash
     * @param newHash
     * @returns
     */
    private hasHashChanged(currentHash: string, newHash: string): boolean {
        if (!currentHash || currentHash === '{{BUILD_HASH}}') {
            return false;
        }
        return currentHash !== newHash;
    }
}
