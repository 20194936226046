import { DataSource } from '@angular/cdk/collections';

import {
    Observable,
    Subject,
    BehaviorSubject,
    combineLatest,
    Subscription,
} from 'rxjs';
import { switchMap, startWith, pluck, share } from 'rxjs/operators';

import { Constants } from '../constants';
import { indicate } from './operators';
import { Page, Sort, PaginatedEndpoint } from './page';

export interface SimpleDataSource<T> extends DataSource<T> {
    connect(): Observable<T[]>;
    disconnect(): void;
}

export class PaginatedDataSource<T, Q> implements SimpleDataSource<T> {
    private pageNumber: Subject<{
        page: number;
        pageSize: number;
    }> = new Subject<{
        page: number;
        pageSize: number;
    }>();
    private sort: Subject<Sort> = new Subject<Sort>();
    private query: BehaviorSubject<Q>;
    private loading: Subject<boolean> = new Subject<boolean>();

    public loading$: Observable<boolean> = this.loading.asObservable();
    public page$: Observable<Page<T>>;
    public content: T[];
    private currentPage: number = 0;
    private subscriptions: Array<Subscription> = [];

    constructor(
        private endpoint: PaginatedEndpoint<T, Q>,
        initialSort: Sort,
        initialQuery: Q,
        public pageSize: number = Constants.INITIAL_PAGE_SIZE,
        private reload$: Observable<Q>
    ) {
        this.query = new BehaviorSubject<Q>(initialQuery);

        const param$ = combineLatest([
            this.query,
            this.sort.pipe(startWith(initialSort)),
            this.reload$,
        ]);

        this.page$ = param$.pipe(
            switchMap(([query, sort]) =>
                this.pageNumber.pipe(
                    startWith({
                        page: this.currentPage,
                        pageSize: this.pageSize,
                    }),
                    switchMap((pager) => {
                        this.pageSize = pager.pageSize;
                        this.currentPage = pager.page;
                        return this.endpoint(
                            { page: pager.page, sort, size: pager.pageSize },
                            query
                        ).pipe(indicate(this.loading));
                    })
                )
            ),
            share()
        );
        this.subscriptions.push(
            this.page$.subscribe((page) => {
                this.content = page.content;
            })
        );
    }

    sortBy(sort: Sort): void {
        this.sort.next(sort);
    }

    queryBy(query: Partial<Q>): void {
        const lastQuery = this.query.getValue();
        const nextQuery = { ...lastQuery, ...query };
        this.currentPage = 0;
        this.query.next(nextQuery);
    }

    fetch(page: number, pageSize: number): void {
        this.pageNumber.next({ page, pageSize });
    }

    connect(): Observable<T[]> {
        return this.page$.pipe(pluck('content'));
    }

    disconnect(): void {
        this.subscriptions.forEach((subscription) =>
            subscription.unsubscribe()
        );
    }

    removeItem(predicate: (value: T) => boolean): void {
        if (!predicate) {
            return;
        }
        const item = this.content.find(predicate);
        const index = this.content.indexOf(item);
        if (index !== -1) {
            this.content.splice(index, 1);
        }
    }

    updateItem(predicate: (value: T) => boolean, item: T): void {
        const itemToUpdate = this.content.find(predicate);
        const index = this.content.indexOf(itemToUpdate);
        if (index !== -1) {
            this.content[index] = item;
        }
    }
}
