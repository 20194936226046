<div fxLayout="column leave-class-wrapper">
    <div fxFlex="100" fxLayoutAlign="space-between center" fxLayout="row">
        <h4 mat-dialog-title class="no-margins">
            {{ 'Leave Program' | transloco | uppercase }}
        </h4>
        <vsc-close-button (click)="cancel()"> </vsc-close-button>
    </div>
    <div mat-dialog-content fxLayout="column" fxLayoutGap="24px">
        <h2>{{ 'Are you sure you want to leave program?' | transloco }}</h2>
    </div>

    <div fxLayoutAlign="end center" fxLayoutGap="16px">
        <button
            type="button"
            mat-stroked-button
            (click)="cancel()"
            color="primary"
            class="mr-16 dialog-cancel-button border"
        >
            {{ 'Cancel' | transloco }}
        </button>
        <button (click)="leaveClass()" mat-flat-button color="primary">
            {{ 'Yes' | transloco }}
        </button>
    </div>
</div>
