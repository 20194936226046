import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { TranslocoService } from '@ngneat/transloco';

import * as moment from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@environment/environment';

import {
    DailyForecast,
    Forecast,
    Weather,
} from '../../state/weather-state/weather.model';
import { WeatherStore } from '../../state/weather-state/weather.store';

@Injectable({ providedIn: 'root' })
export class WeatherService {
    constructor(
        private weatherStore: WeatherStore,
        private http: HttpClient,
        private translocoService: TranslocoService
    ) {}
    private baseUrl: string = environment.wetherApi.baseUrl;
    private apiKey: string = environment.wetherApi.apiKey;
    private defaultParams: string = `&appid=${this.apiKey}&units=imperial`;

    getWeatherByCoordinates(
        latitude: number,
        longitude: number
    ): Observable<Weather[]> {
        let lang = this.translocoService.getActiveLang();
        switch (lang) {
            case 'zh':
                lang = 'zh_tw';
                break;
            case 'ko':
                lang = 'kr';
                break;
        }

        return this.http
            .get<DailyForecast>(
                `${this.baseUrl}/onecall?lat=${latitude}&lon=${longitude}${this.defaultParams}&lang=${lang}`
            )
            .pipe(
                map((dailyForecast: DailyForecast) => {
                    const weather: Weather[] = [];

                    // * save current day as a reference day
                    const today = moment();

                    for (let i = 0; i < 8; i++) {
                        const weatherData = dailyForecast.daily[i];

                        const dailyForecastAsAny = dailyForecast.current as any;

                        weatherData.current = {
                            humidity: dailyForecast.current.humidity,
                            temperature: dailyForecastAsAny.temp,
                            windSpeed: dailyForecastAsAny.wind_speed,
                            pressure: dailyForecastAsAny.pressure,
                            icon: dailyForecastAsAny.weather[0].icon,
                        };

                        // * assign the id for the weather data
                        weatherData.id = i;

                        // * assign the day for the weather data
                        weatherData.day = moment(today).addDays(i);

                        weather.push(weatherData);
                    }

                    this.weatherStore.set(weather);

                    return weather;
                })
            );
    }

    getWeatherByCityName(
        cityName: string,
        countryCode?: string
    ): Observable<Weather> {
        if (countryCode && countryCode.length > 0) {
            return this.http.get<Weather>(
                `${this.baseUrl}/weather?q=${cityName},${countryCode}${this.defaultParams}`
            );
        }

        return this.http.get<Weather>(
            `${this.baseUrl}/weather?q=${cityName}${this.defaultParams}`
        );
    }

    getForecastByCityId(cityId: number): Observable<Forecast> {
        return this.http.get<Forecast>(
            `${this.baseUrl}/forecast?id=${cityId}${this.defaultParams}`
        );
    }

    getForecastByCoordinates(
        latitude: number,
        longitude: number
    ): Observable<Forecast> {
        return this.http.get<Forecast>(
            `${this.baseUrl}/forecast?lat=${latitude}&lon=${longitude}${this.defaultParams}`
        );
    }

    getForecastByCityName(
        cityName: string,
        countryCode?: string
    ): Observable<Forecast> {
        if (countryCode && countryCode.length > 0) {
            return this.http.get<Forecast>(
                `${this.baseUrl}/forecast?q=${cityName},${countryCode}${this.defaultParams}`
            );
        }

        return this.http.get<Forecast>(
            `${this.baseUrl}/forecast?q=${cityName}${this.defaultParams}`
        );
    }
}
