import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { TranslocoService } from '@ngneat/transloco';

import { iif, Observable, of } from 'rxjs';
import { delay, filter, map, mergeMap, take } from 'rxjs/operators';

import { Country } from '@app/admin/admin/users/user-details-personal-info/country';
import { VideoProviderStatus } from '@app/admin/admin/videos/video-provider-manage/video-provider.enum';

import { BuiltInRoleType } from '@shared/built-in-role-type';
import { Constants } from '@shared/constants';
import * as countriesData from '@shared/countries.json';
import { Month } from '@shared/interfaces/months.model';
import * as monthsData from '@shared/months.json';
import { NotificationsDataService } from '@shared/notifications/services/notifications.dataservice';
import { NotificationCaseCategory } from '@shared/notifications/state/notification-case-category';
import { Ethnicity } from '@shared/user-sections/ethnicity';
import { Gender } from '@shared/user-sections/gender';
import { ILiveAlone } from '@shared/user-sections/i-live-alone';
import { ILiveWithMySpouse } from '@shared/user-sections/i-live-with-my-spouse';
import { LivingArrangements } from '@shared/user-sections/living-arrangements';
import { PrimarySpokenLanguage } from '@shared/user-sections/primary-spoken-language';
import { Race } from '@shared/user-sections/race';
import { CommonUtilities } from '@shared/utils/common-utilities';

import { BookmarkType } from '@admin/bookmarks/bookmark-state/bookmark-type.enum';
import { ClassDataService } from '@admin/classes/services/classes.dataservice';
import { GroupStatus, GroupType } from '@admin/groups/group.enum';
import { GroupDataservice } from '@admin/groups/services/group.dataservice';
import { GroupsQueryParams } from '@admin/groups/services/query-params/groups.queryparams';
import { LanguageDataService } from '@admin/languages/services/language.dataservice';
import { Language } from '@admin/languages/state/languages';
import { OrganizationDataService } from '@admin/organizations/services/organization.dataservice';
import { Organization } from '@admin/organizations/state/organization';
import { RoleDataService } from '@admin/roles/services/roles.dataservice';
import { UserDataservice } from '@admin/users/services/user.dataservice';

import { Lookup } from './lookup';
import { LookupQuery } from './lookup.query';
import { LookupStore } from './lookup.store';
import { Lookups } from './lookups';

@Injectable({ providedIn: 'root' })
export class LookupService {
    constructor(
        private lookupQuery: LookupQuery,
        private groupDataService: GroupDataservice,
        private roleDataService: RoleDataService,
        private lookupStore: LookupStore,
        private userDataService: UserDataservice,
        private translocoService: TranslocoService,
        private classDataService: ClassDataService,
        private languageDataService: LanguageDataService,
        private organizationDataService: OrganizationDataService,
        private notificationsDataService: NotificationsDataService,
        private route: ActivatedRoute
    ) {}

    selectLookup(
        type: string,
        searchTerm: string = ''
    ): Observable<Array<Lookup>> {
        if (!type || type.length === 0) {
            throw new Error('Type cannot be null');
        }
        return this.lookupQuery.selectLookup(type).pipe(
            mergeMap((lookups: Lookup[]) =>
                iif(
                    () => !lookups,
                    this.loadLookup(type, searchTerm)
                        .pipe(
                            map((loadedLookup) => {
                                this.lookupStore.setLookup(loadedLookup, type);
                                return loadedLookup;
                            })
                        )
                        .pipe(take(1)),
                    of(lookups)
                )
            )
        );
    }

    selectLookupNames(type: string, lookupIds: string[]): Observable<string[]> {
        return this.lookupQuery.selectLookup(type).pipe(
            filter(Boolean),
            map((lookups: Lookup[]) =>
                lookups
                    .filter((lookup: Lookup) => lookupIds.includes(lookup.id))
                    .map((lookup: Lookup) => lookup.name)
            )
        );
    }

    removeLookup(type: string): void {
        if (!type || type.length === 0) {
            throw new Error('Type cannot be null');
        }
        this.lookupStore.setLookup(null, type);
    }

    private loadLookup(
        type: string,
        searchTerm: string,
        queryParams?: GroupsQueryParams
    ): Observable<Array<Lookup>> {
        switch (type) {
            case Lookups.Groups: {
                queryParams = {
                    pageSize: 1000,
                    sortBy: 'name',
                };
                return this.groupDataService.getGroups(queryParams).pipe(
                    map((response) =>
                        response.entities.map(
                            (group) =>
                                ({
                                    id: group.id,
                                    name: group.name,
                                    languages: group.languages,
                                } as Lookup)
                        )
                    )
                );
            }
            case Lookups.ActiveGroups: {
                queryParams = {
                    pageSize: 1000,
                    sortBy: 'name',
                    groupStatus: GroupStatus.Active,
                };
                return this.groupDataService.getGroups(queryParams).pipe(
                    map((response) =>
                        response.entities.map(
                            (group) =>
                                ({
                                    id: group.id,
                                    name: group.name,
                                } as Lookup)
                        )
                    )
                );
            }
            case Lookups.BrandedGroups: {
                queryParams = {
                    page: 0,
                    pageSize: 1000,
                    search: '',
                    hasBranding: true,
                    sortBy: 'name',
                };
                return this.groupDataService.getGroups(queryParams).pipe(
                    map((response) =>
                        response.entities.map(
                            (group) =>
                                ({
                                    id: group.id,
                                    name: group.name,
                                } as Lookup)
                        )
                    )
                );
            }
            case Lookups.RestrictedRoles: {
                return this.roleDataService.getRestrictedRoles().pipe(
                    map((response) =>
                        response.map(
                            (group) =>
                                ({
                                    id: group.id,
                                    name: group.name,
                                } as Lookup)
                        )
                    )
                );
            }
            case Lookups.GroupAdmins: {
                return this.userDataService
                    .getUsersBasicInfo({
                        name: searchTerm,
                        roleEnum: BuiltInRoleType.GroupAdmin,
                    })
                    .pipe(
                        map((response) =>
                            response.map(
                                (user) =>
                                    ({
                                        id: user.id,
                                        name: `${user.firstName} ${user.lastName}`,
                                    } as Lookup)
                            )
                        )
                    );
            }
            case Lookups.ClassCreators: {
                return this.classDataService.getClassCreators().pipe(
                    map((response) =>
                        response.map(
                            (classCreator) =>
                                ({
                                    id: classCreator.id,
                                    name: classCreator.name,
                                } as Lookup)
                        )
                    )
                );
            }
            case Lookups.Facilitators: {
                return this.userDataService
                    .getUsersBasicInfo({
                        name: searchTerm,
                        roleEnum: BuiltInRoleType.Facilitator,
                    })
                    .pipe(
                        map((response) =>
                            response.map(
                                (user) =>
                                    ({
                                        id: user.id,
                                        name: `${user.firstName} ${user.lastName}`,
                                    } as Lookup)
                            )
                        )
                    );
            }
            case Lookups.BookmarkTypes: {
                return of([
                    {
                        id: BookmarkType.Games,
                        name: this.translocoService.translate('Games'),
                    },
                    {
                        id: BookmarkType.Internet,
                        name: this.translocoService.translate('Internet'),
                    },
                ]).pipe(delay(100));
            }
            case Lookups.ClassCategories: {
                return this.classDataService.getClassCategories().pipe(
                    map((response) =>
                        response.map(
                            (classCategory) =>
                                ({
                                    id: classCategory.id,
                                    name: classCategory.name,
                                } as Lookup)
                        )
                    )
                );
            }
            case Lookups.Languages: {
                return this.languageDataService.getLanguages().pipe(
                    map((response: Array<Language>) =>
                        response.map(
                            (language: Language) =>
                                ({
                                    id: language.id,
                                    name: language.description,
                                } as Lookup)
                        )
                    )
                );
            }
            case Lookups.ConsumerLanguages: {
                return this.languageDataService.getConsumerLanguages().pipe(
                    map((response: Array<Language>) =>
                        response.map(
                            (language: Language) =>
                                ({
                                    id: language.id,
                                    name: Constants.LANGUAGES_NATIVE_NAMES[
                                        language.description
                                    ],
                                } as Lookup)
                        )
                    )
                );
            }
            case Lookups.GroupLanguages: {
                return this.groupDataService.getGroupLanguages().pipe(
                    map((response: Array<Language>) =>
                        response.map(
                            (language: Language) =>
                                ({
                                    id: language.id,
                                    name: language.description,
                                } as Lookup)
                        )
                    )
                );
            }
            case Lookups.GroupStatus: {
                return of([
                    {
                        id: GroupStatus.Active.toString(),
                        name: 'Active',
                    },
                    {
                        id: GroupStatus.Inactive.toString(),
                        name: 'Inactive',
                    },
                ]).pipe(delay(100));
            }
            case Lookups.GroupType: {
                return of([
                    {
                        id: GroupType.Internal.toString(),
                        name: 'Internal',
                    },
                    {
                        id: GroupType.Customer.toString(),
                        name: 'Customer',
                    },
                ]).pipe(delay(100));
            }
            case Lookups.ContentExchangeEnabledOrganizations: {
                return this.organizationDataService
                    .getOrganizationsWithContentExchangeEnabled()
                    .pipe(
                        map((response: Organization[]) =>
                            response.map(
                                (organization) =>
                                    ({
                                        id: organization.id,
                                        name: organization.name,
                                    } as Lookup)
                            )
                        )
                    );
            }
            case Lookups.ProfilePersonalizationAllowedOrganizations: {
                return this.organizationDataService
                    .getOrganizationsWithProfilePersonalizationAllowed()
                    .pipe(
                        map((response: Organization[]) =>
                            response.map(
                                (organization) =>
                                    ({
                                        id: organization.id,
                                        name: organization.name,
                                    } as Lookup)
                            )
                        )
                    );
            }
            case Lookups.Countries: {
                return of(
                    (countriesData as any).default.map(
                        (country: Country) =>
                            ({
                                id: country.fullName,
                                name: country.fullName,
                            } as Lookup)
                    )
                );
            }
            case Lookups.Months: {
                return of(
                    (monthsData as any).default.map(
                        (month: Month) =>
                            ({
                                id: month.id,
                                name: month.name,
                            } as Lookup)
                    )
                );
            }
            case Lookups.OrganizationLanguages: {
                return this.languageDataService.getLanguages(false).pipe(
                    map((response: Array<Language>) =>
                        response.map(
                            (language: Language) =>
                                ({
                                    id: language.id,
                                    name: language.description,
                                } as Lookup)
                        )
                    )
                );
            }
            case Lookups.VideoProviderStatus: {
                return of([
                    {
                        id: VideoProviderStatus.Active.toString(),
                        name: 'Active',
                    },
                    {
                        id: VideoProviderStatus.Inactive.toString(),
                        name: 'Inactive',
                    },
                ]).pipe(delay(100));
            }
            case Lookups.NotificationCaseCategories: {
                return this.notificationsDataService
                    .getNotificationCaseCategories()
                    .pipe(
                        map(
                            (
                                notifCaseCategories: Array<NotificationCaseCategory>
                            ) =>
                                notifCaseCategories.map(
                                    (
                                        notifCaseCategory: NotificationCaseCategory
                                    ) =>
                                        ({
                                            id: notifCaseCategory.id,
                                            name: notifCaseCategory.name,
                                        } as Lookup)
                                )
                        )
                    );
            }
            case Lookups.Gender: {
                return of(CommonUtilities.enumToList(Gender)).pipe(delay(100));
            }
            case Lookups.Race: {
                return of(CommonUtilities.enumToList(Race)).pipe(delay(100));
            }
            case Lookups.Ethnicity: {
                return of(CommonUtilities.enumToList(Ethnicity)).pipe(
                    delay(100)
                );
            }
            case Lookups.PrimarySpokenLanguage: {
                return of(
                    CommonUtilities.enumToList(PrimarySpokenLanguage)
                ).pipe(delay(100));
            }
            case Lookups.LivingArrangements: {
                return of(CommonUtilities.enumToList(LivingArrangements)).pipe(
                    delay(100)
                );
            }
            case Lookups.ILiveAlone: {
                return of(CommonUtilities.enumToList(ILiveAlone)).pipe(
                    delay(100)
                );
            }
            case Lookups.ILiveWithMySpouse: {
                return of(CommonUtilities.enumToList(ILiveWithMySpouse)).pipe(
                    delay(100)
                );
            }
        }
    }
}
