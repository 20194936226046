<mat-card class="cookies-mat-card">
    <mat-card-content>
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <h2>{{ 'What are Cookies?' | transloco }}</h2>
                    <p>
                        {{
                            'Cookies are simple text files that are stored on your computer or mobile device by a website’s server. Each cookie is unique to your web browser. It will contain some anonymous information such as a unique identifier, the website’s domain name, and some digits and numbers.'
                                | transloco
                        }}
                    </p>
                </div>
            </div>
        </div>
        <h2>{{ 'What types of cookies do we use?' | transloco }}</h2>

        <p>
            {{
                'You can manage which cookies are set on your device, but if you disable cookies, some parts of our website may not work properly. We use cookies to optimize our website and services. For more information, please visit our'
                    | transloco
            }}
            {{ 'Cookie Policy' | transloco }}
            {{ 'and' | transloco }}
            {{ 'Privacy Policy' | transloco }}.
        </p>

        <span>{{ 'Essential Cookies' | transloco }}</span>
        <p>
            {{
                'Essential cookies enable core functionality such as security, network management and accessibility.'
                    | transloco
            }}
        </p>

        <span>{{ 'Google Analytics' | transloco }}</span>
        <p>
            {{
                'We would like to set Google Analytics cookies to help us improve our website by collecting and reporting information on how you use it. The cookies collect information in a way that does not directly identify anyone.'
                    | transloco
            }}
        </p>
        <h2>{{ 'How to manage cookie settings?' | transloco }}</h2>
        <p>
            {{
                'You can manage and update your cookie preferences at any time by clicking'
                    | transloco
            }}
            <a (click)="changePreferences()">{{ 'here' | transloco }}.</a>
            {{
                'If you have any questions about this policy or our use of cookies, please reach out.'
                    | transloco
            }}
        </p>
        <p></p>
    </mat-card-content>
</mat-card>
