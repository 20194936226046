import { InjectionToken } from '@angular/core';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';

import { Notification } from '@shared/notifications/models/notification';
import { NotificationAction } from '@shared/notifications/models/notification-action.enum';

import { ClassService } from '@public/classes/services/classes.service';

import {
    formatNotificationDelegateInjectionToken,
    INotificationDelegate,
} from './notification-action';

export const ProgramStartedNotificationDelegateInjectionToken =
    new InjectionToken<ProgramStartedNotificationDelegate>(
        formatNotificationDelegateInjectionToken(
            NotificationAction.ProgramStarted
        )
    );

export const ProgramAboutToStartNotificationDelegateInjectionToken =
    new InjectionToken<ProgramStartedNotificationDelegate>(
        formatNotificationDelegateInjectionToken(
            NotificationAction.ProgramAboutToStart
        )
    );

export class ProgramStartedNotificationDelegate
    implements INotificationDelegate
{
    constructor(private router: Router, private classService: ClassService) {}

    invokeAction(_: Notification): void {}

    rejectAction(_: Notification): void {}

    navigateFromNotification(
        notification: Notification
    ): void | Observable<any> {
        if (!notification?.metadata) {
            return;
        }

        const classId = notification.metadata['classId'];
        const classSessionId = notification.entityId;

        if (!classId || !classSessionId) {
            return;
        }

        this.classService.updateState({ backUrl: this.router.url });
        this.router.navigate([
            `/classes/${classId}/sessions/${notification.entityId}`,
        ]);
    }

    navigateFromNotificationBody(
        notification: Notification
    ): void | Observable<any> {
        if (!notification?.metadata) {
            return;
        }

        const classId = notification.metadata['classId'];
        const classSessionId = notification.entityId;

        if (!classId || !classSessionId) {
            return;
        }

        this.classService.updateState({ backUrl: this.router.url });
        this.router.navigate([
            `/classes/${classId}/sessions/${notification.entityId}`,
        ]);
    }
}
