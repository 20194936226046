import { Injectable } from '@angular/core';

import { setLoading } from '@datorama/akita';

import * as moment from 'moment';
import {
    map,
    Observable,
    of,
    combineLatest,
    EMPTY,
    timer,
    Subscription,
} from 'rxjs';
import { catchError, switchMap, take, tap } from 'rxjs/operators';

import { AuthService } from '@app/core/auth/auth.service';
import { Permissions } from '@core/auth/permissions';

import { PermissionAuthorize } from '@shared/decorators/method/method-decorators';
import { setLoadingCallback } from '@shared/operators/rxjs-operators';
import { SnackBarService } from '@shared/snack-bar/snack-bar.service';

import { ReportsQuery } from '@admin/reports/state/reports.query';
import { ReportsStore } from '@admin/reports/state/reports.store';

import { PBIDatasetRefreshSchedule } from '../report/models/pbi-dataset-refresh-schedule';
import { PBIEmbedToken } from '../report/models/pbi-embed-token';
import { EmbedTokenQuery } from '../state/embed-token.query';
import { EmbedTokenStore } from '../state/embed-token.store';
import { IReport } from '../state/report';
import { ReportDataService } from './report.dataservice';

@Injectable({
    providedIn: 'root',
})
export class ReportService {
    private readonly REFRESH_TOKEN_EXPIRATION_TOLERANCE_MS = 60 * 1000; // 60 seconds

    private scheduledTokenRefreshSubscription: Subscription;

    constructor(
        private reportDataService: ReportDataService,
        private reportsQuery: ReportsQuery,
        private reportsStore: ReportsStore,
        private snackBarService: SnackBarService,
        private authService: AuthService,
        private embedTokenStore: EmbedTokenStore,
        private embedTokenQuery: EmbedTokenQuery
    ) {}

    @PermissionAuthorize(Permissions.Reports.View)
    getAvailableReports(): Observable<IReport[]> {
        return this.reportDataService.getAvailableReports().pipe(
            tap((reports: IReport[]) => this.reportsStore.set(reports)),
            setLoading(this.reportsStore),
            catchError(() => {
                this.snackBarService.open(
                    'The reports are currently unavailable. Please try again later.'
                );
                return EMPTY;
            })
        );
    }

    getReport(reportId: string): Observable<IReport> {
        return this.reportDataService.getReport(reportId).pipe(
            tap(
                (report: IReport) =>
                    report && this.reportsStore.upsert(report.id, report)
            ),
            take(1)
        );
    }

    getDatasetRefreshSchedule(
        datasetId: string
    ): Observable<PBIDatasetRefreshSchedule> {
        return this.reportDataService.getDatasetRefreshSchedule(datasetId).pipe(
            tap((datasetRefresh: PBIDatasetRefreshSchedule) =>
                this.reportsStore.setDatasetRefreshSchedule(
                    datasetId,
                    datasetRefresh
                )
            ),
            setLoadingCallback((isLoading: boolean) =>
                this.reportsStore.update({
                    isLoadingDatasetLastRefresh: isLoading,
                })
            ),
            catchError(() => {
                this.snackBarService.open(
                    "Couldn't load data source refresh schedule. Please try again later."
                );
                return EMPTY;
            })
        );
    }

    /**
     * Fetches all available reports if the store is empty.
     * @returns `Observable<IReport[]>`
     */
    @PermissionAuthorize(Permissions.Reports.View)
    fetchAllIfEmpty(): Observable<IReport[]> {
        return !this.reportsQuery.getCount()
            ? this.getAvailableReports()
            : this.reportsQuery.selectAll();
    }

    /**
     * Fetches all available reports if there is no report we are looking for in the store.
     * @param reportId The report Id to fetch.
     * @returns `Observable<IReport>`
     */
    fetchAllIfNotPresent(reportId: string): Observable<IReport> {
        const report = this.reportsQuery.getEntity(reportId);

        return report
            ? of(report)
            : this.getAvailableReports()?.pipe(
                  map((reports: IReport[]) =>
                      reports.find((report: IReport) => report.id === reportId)
                  ),
                  take(1)
              );
    }

    /**
     * Fetches dataset refresh schedule if it does not exist in the store.
     * @param datasetId The dataset Id to fetch.
     * @returns `Observable<PBIDatasetRefreshSchedule>`
     */
    fetchDatasetRefreshScheduleIfNotPresent(
        datasetId: string
    ): Observable<PBIDatasetRefreshSchedule> {
        const datasetRefreshSchedule =
            this.reportsQuery.getDatasetRefreshSchedule(datasetId);

        return datasetRefreshSchedule
            ? of(datasetRefreshSchedule)
            : this.getDatasetRefreshSchedule(datasetId);
    }

    /**
     * Returns a token from storage, if there is a valid one that was previously generated.
     * If a token does not exist or expired, acquires a new one.
     * @returns Power BI access token.
     */
    getPowerBIAccessToken(): Observable<string> {
        const tenantId = this.authService.tenantId;
        return combineLatest([
            this.embedTokenQuery.selectPbiAccessTokenByTenant(tenantId),
            this.embedTokenQuery.selectPbiAccessTokenExpirationByTenant(
                tenantId
            ),
        ]).pipe(
            switchMap(([accessToken, expiresAt]: [string, number]) => {
                if (accessToken && expiresAt) {
                    const utcNowTimestamp = moment.utc().valueOf();
                    if (
                        utcNowTimestamp < +expiresAt &&
                        this.isTokenValidForAvailableReports()
                    ) {
                        return of(accessToken);
                    }
                }

                return this.acquirePowerBIEmbedToken();
            }),
            take(1)
        );
    }

    acquirePowerBIEmbedToken(): Observable<string> {
        return this.reportDataService.getEmbedToken().pipe(
            map((pbiEmbedToken: PBIEmbedToken) => {
                if (pbiEmbedToken) {
                    pbiEmbedToken.tenantId = this.authService.tenantId;
                    this.embedTokenStore.upsert(
                        pbiEmbedToken.tenantId,
                        pbiEmbedToken
                    );
                    return pbiEmbedToken.token;
                }
            }),
            setLoading(this.embedTokenStore),
            catchError(() => {
                this.snackBarService.open(
                    "Couldn't load data from the data source. Please try again later."
                );
                return EMPTY;
            }),
            take(1)
        );
    }

    /**
     * Schedules the acquiring of a new Power BI embed token.
     * The token will be renewed 1 minute before the old token expires.
     */
    scheduleEmbedTokenRefresh(): void {
        const tokenExpiresAt: number =
            this.embedTokenQuery.getPbiAccessTokenExpirationByTenant(
                this.authService.tenantId
            );
        const utcNow: number = moment.utc().valueOf();

        let refreshTokenInMs = tokenExpiresAt - utcNow;

        if (refreshTokenInMs > this.REFRESH_TOKEN_EXPIRATION_TOLERANCE_MS) {
            // If the token expiration time span is greater then the tolerance
            // time span, schedule a refresh for expiration time subtracted by the tolerance time.
            refreshTokenInMs -= this.REFRESH_TOKEN_EXPIRATION_TOLERANCE_MS;
        } else {
            // Otherwise, schedule a refresh immediately.
            refreshTokenInMs = 0;
        }

        if (this.scheduledTokenRefreshSubscription) {
            this.cancelScheduledTokenRefresh();
        }

        this.scheduledTokenRefreshSubscription = timer(refreshTokenInMs)
            .pipe(switchMap(() => this.acquirePowerBIEmbedToken()))
            .subscribe();
    }

    cancelScheduledTokenRefresh(): void {
        this.scheduledTokenRefreshSubscription?.unsubscribe();
        this.scheduledTokenRefreshSubscription = null;
    }

    /**
     * Compares the reports for which the token is generated with the available reports in the store.
     * @returns True if the token is valid for all available reports, otherwise False.
     */
    private isTokenValidForAvailableReports(): boolean {
        const tokenReports = this.embedTokenQuery.getPbiAccessTokenByTenant(
            this.authService.tenantId
        )?.reports;

        if (!tokenReports) {
            return false;
        }

        const availableReports = this.reportsQuery.getAll();

        return availableReports.every((report: IReport) =>
            tokenReports.includes(report?.id)
        );
    }
}
