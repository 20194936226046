export enum LivingArrangements {
    Alone,
    'With Spouse',
    'With Unmarried Partner',
    'With Spouse/Partner and Child/ren',
    'With Child/ren Only, No Spouse/Partner',
    'With Grandchild/ren',
    'With Other Relative',
    'With Others',
}
