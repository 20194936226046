import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { TranslocoModule } from '@ngneat/transloco';

import { AsyncIfObservablePipe } from './async-if-observable.pipe';
import { FormatTextPipe } from './format-text.pipe';
import { ImageUrlSizePipe } from './image-url-size.pipe';
import { LocalizedDatePipe } from './localized-date.pipe';
import { LocalizedMomentPipe } from './localized-moment.pipe';
import { LocalizedTimePipe } from './localized-time.pipe';
import { SafePipe } from './safe-url.pipe';
import { TooltipListPipe } from './tooltip-list.pipe';

@NgModule({
    declarations: [
        LocalizedDatePipe,
        TooltipListPipe,
        SafePipe,
        AsyncIfObservablePipe,
        ImageUrlSizePipe,
        LocalizedMomentPipe,
        LocalizedTimePipe,
        FormatTextPipe,
    ],
    imports: [CommonModule, FormsModule, TranslocoModule],

    exports: [
        LocalizedDatePipe,
        TooltipListPipe,
        SafePipe,
        AsyncIfObservablePipe,
        ImageUrlSizePipe,
        LocalizedMomentPipe,
        LocalizedTimePipe,
        FormatTextPipe,
    ],
})
export class PipesModule {}
