import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import * as moment from 'moment';
import { Observable } from 'rxjs';

import { UserInterest } from '@app/consumer-information/user-interest';
import { environment } from '@environment/environment';

import { BuiltInRoleType } from '@shared/built-in-role-type';
import { ValidationMessage } from '@shared/forms/validation-message/validation-message';
import { BaseUserInfo } from '@shared/models/base-user-info';
import { PagedEntities } from '@shared/models/paged-entities';
import { UserAdditionalInformation } from '@shared/models/user-additional-info';
import { UserNotificationSettings } from '@shared/models/user-notification-settings';
import { UserProfileInfo } from '@shared/models/user-profile-info';
import { PageRequest } from '@shared/table/page';

import { User } from '../state/user';
import { UserSubscription } from '../state/user-subscription';
import { UserSearchQuery } from './user.service';

@Injectable({ providedIn: 'root' })
export class UserDataservice {
    constructor(private httpClient: HttpClient) {}

    getUsers(
        request: PageRequest<User>,
        query: UserSearchQuery,
        isAscDefaultSortDir?: boolean
    ): Observable<PagedEntities<User>> {
        request.sort.property =
            request.sort?.property.trim().length !== 0 ||
            query.sortBy === null ||
            query.sortBy === undefined
                ? request.sort?.property
                : query.sortBy;
        request.sort.order =
            request.sort?.order.trim().length !== 0 ||
            isAscDefaultSortDir !== true
                ? request.sort?.order
                : 'asc';
        const params = new HttpParams({
            fromObject: {
                searchTerm: query.searchTerm ?? '',
                name: query.name ? query.name.trim() : '',
                email: query.email ? query.email?.trim() : '',
                status: query.status ? query.status?.trim() : '',
                languages: query.languages ? query.languages?.join(',') : '',
                zipCode: query.zipCode ? query.zipCode?.trim() : '',
                page: request.page.toString(),
                pageSize: request.size.toString(),
                sortBy: request.sort.property,
                sortDirection: request.sort.order,
                statuses: query.statuses,
                createdBy: query.createdBy ? query.createdBy.trim() : '',
            },
        });

        return this.httpClient.get<PagedEntities<User>>(
            environment.apiUrl + '/vsc/users',
            {
                params,
            }
        );
    }

    getAllUsers(): Observable<PagedEntities<User>> {
        const params = new HttpParams({
            fromObject: {
                name: '',
                page: '0',
                pageSize: '1000',
                sortBy: '',
                sortDirection: '',
            },
        });

        return this.httpClient.get<PagedEntities<User>>(
            environment.apiUrl + '/vsc/users',
            {
                params,
            }
        );
    }

    getUsersBasicInfo(query: UserSearchQuery): Observable<Array<User>> {
        const params = new HttpParams({
            fromObject: {
                roleEnum: query.roleEnum
                    ? query.roleEnum.toString()
                    : BuiltInRoleType.All.toString(),
            },
        });
        return this.httpClient.get<Array<User>>(
            environment.apiUrl + '/vsc/users/me',
            {
                params,
            }
        );
    }

    getById(id: string): Observable<User> {
        return this.httpClient.get<User>(
            environment.apiUrl + `/vsc/users/${id}`
        );
    }

    getMyUserInterests(): Observable<UserInterest> {
        return this.httpClient.get<UserInterest>(
            environment.apiUrl + `/vsc/users/me/interest`
        );
    }

    getUserProfile(): Observable<BaseUserInfo> {
        return this.httpClient.get<User>(
            environment.apiUrl + `/vsc/users/me/profile`
        );
    }

    getMyInfo(): Observable<UserProfileInfo> {
        return this.httpClient.get<UserProfileInfo>(
            environment.apiUrl + `/vsc/users/profile`
        );
    }

    exists(email: string): Observable<ValidationMessage> {
        return this.httpClient.get<ValidationMessage>(
            environment.apiUrl + `/vsc/users/exists/${email}`
        );
    }

    post(user: User): Observable<User> {
        return this.httpClient.post<User>(
            environment.apiUrl + `/ag/users`,
            user
        );
    }

    put(user: User): Observable<User> {
        return this.httpClient.put<User>(
            environment.apiUrl + '/ag/users',
            user
        );
    }
    updateProfile(user: UserProfileInfo, response: any) {
        return this.httpClient.patch<UserProfileInfo>(
            environment.apiUrl + '/ag/users/profile',
            user,
            response
        );
    }

    saveUserInterest(userInterests: UserInterest, response: any) {
        return this.httpClient.patch<UserInterest>(
            environment.apiUrl + '/vsc/users/interest',
            userInterests,
            response
        );
    }

    activateUser(id: string): Observable<User> {
        return this.httpClient.patch<User>(
            `${environment.apiUrl}/ag/users/${id}/activate`,
            {}
        );
    }

    enableChatVisibility(): Observable<User> {
        return this.httpClient.patch<User>(
            `${environment.apiUrl}/vsc/users/chat-visibility/enable`,
            {}
        );
    }

    disableChatVisibility(): Observable<User> {
        return this.httpClient.patch<User>(
            `${environment.apiUrl}/vsc/users/chat-visibility/disable`,
            {}
        );
    }

    upsertUserAdditionalInfo(
        body: UserAdditionalInformation
    ): Observable<UserAdditionalInformation> {
        return this.httpClient.patch<UserAdditionalInformation>(
            `${environment.apiUrl}/vsc/users/additional-info`,
            body
        );
    }

    deactivateUser(id: string): Observable<User> {
        return this.httpClient.patch<User>(
            `${environment.apiUrl}/ag/users/${id}/deactivate`,
            {}
        );
    }

    admitUser(id: string): Observable<User> {
        return this.httpClient.patch<User>(
            `${environment.apiUrl}/vsc/users/${id}/admit`,
            {}
        );
    }

    canDeactivateUser(userId: string): Observable<boolean> {
        return this.httpClient.get<boolean>(
            `${environment.apiUrl}/vsc/users/${userId}/class-host?dateTimeNow=` +
                moment().toApiDateTimeStringFormat()
        );
    }

    createHostAccount(id: string): Observable<User> {
        return this.httpClient.post<User>(
            `${environment.apiUrl}/ag/users/${id}/create-host-account`,
            {}
        );
    }
    getUserActiveSubscription(id: string): Observable<UserSubscription> {
        return this.httpClient.get<UserSubscription>(
            environment.apiUrl +
                `/identity/subscriptions/users/${id}/subscription`
        );
    }
    cancelSubscription(id: string) {
        return this.httpClient.patch<any>(
            environment.apiUrl + `/identity/subscription/${id}/cancel`,
            {}
        );
    }

    resendActivationEmail(userId: string): Observable<User> {
        return this.httpClient.patch<User>(
            `${environment.apiUrl}/vsc/users/${userId}/resend-activation-mail`,
            {}
        );
    }

    UpdateConsumerInformationPageVisibility(id: string): Observable<number> {
        return this.httpClient.patch<number>(
            `${environment.apiUrl}/vsc/users/${id}/update-consumer-info-page-visibility`,
            {}
        );
    }

    GetConsumerInfoPageVisibility(id: string): Observable<boolean> {
        return this.httpClient.get<boolean>(
            `${environment.apiUrl}/vsc/users/${id}/get-consumer-info-page-visibility`
        );
    }

    updateNotificationSettings(
        userNotifSettings: UserNotificationSettings
    ): Observable<User> {
        return this.httpClient.patch<User>(
            environment.apiUrl + '/vsc/users/update-user-notification-settings',
            userNotifSettings
        );
    }
}
