import { Injectable } from '@angular/core';

import { Subject, BehaviorSubject } from 'rxjs';

import { Toast, DEFAULT_TOAST_CONFIG } from '../toast';

type ToastItem = Toast & { removeTimeout: ReturnType<typeof setTimeout> };

@Injectable({ providedIn: 'root' })
export class ToastService {
    public readonly items: ToastItem[] = [];

    public readonly itemAdded$ = new Subject<Toast>();
    public readonly itemRemoved$ = new Subject<Toast>();

    public readonly hasAnyToast$: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(true);

    constructor() {}

    /**
     * Shows a toast.
     * @param toast The toast to show.
     */
    show(toast: Toast): void {
        if (!toast) {
            return;
        }

        const item = new Toast(toast);

        if (!item.id) {
            // Incrementally generate a unique toast item id if not provided.
            item.id = this.items.length + '';
        }

        this.hasAnyToast$.next(true);
        const removeTimeout = setTimeout(() => {
            this.remove(item.id);
        }, toast?.options?.duration ?? DEFAULT_TOAST_CONFIG.duration);

        this.items.push({
            ...item,
            removeTimeout,
        });

        this.itemAdded$.next(item);
    }

    /**
     * Shows custom component as a toast.
     * @param toast The toast to show.
     */
    showComponent(
        toast: Pick<Toast, 'id' | 'toastComponent' | 'data' | 'options'>
    ): void {
        const item = new Toast(toast);
        this.show(item);
    }

    /**
     * @description Removes a toast item with the given id.
     * @param id The id of the toast item to remove.
     */
    remove(id: string): void {
        const itemIndex = this.items.findIndex(
            (item: ToastItem) => item?.id === id
        );
        if (itemIndex !== -1) {
            this.items
                .filter((item: ToastItem) => item?.id === id)
                .forEach((item: ToastItem) => {
                    item.active = false;
                    item.removeTimeout && clearTimeout(item.removeTimeout);
                });
            this.itemRemoved$.next(this.items[itemIndex]);

            // Clear the toast container if there are no more toast items.
            // Timeout is used to allow the last toast to animate out.
            setTimeout(() => {
                if (this.items.every((item: ToastItem) => !item.active)) {
                    this.items.length = 0;
                    this.hasAnyToast$.next(false);
                }
            }, 500);
        }
    }
}
