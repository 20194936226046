import { Observable, Subject, defer } from 'rxjs';
import { finalize } from 'rxjs/operators';

export function prepare<T>(
    callback: () => void
): (source: Observable<T>) => Observable<T> {
    return (source: Observable<T>): Observable<T> =>
        defer(() => {
            callback();
            return source;
        });
}

export function indicate<T>(
    indicator: Subject<boolean>
): (source: Observable<T>) => Observable<T> {
    return (source: Observable<T>): Observable<T> =>
        source.pipe(
            prepare(() => indicator.next(true)),
            finalize(() => indicator.next(false))
        );
}
