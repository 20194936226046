import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    TemplateRef,
} from '@angular/core';

import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';

import { BasePaginatorComponent } from '@shared/paginator/base-paginator.component';
import { Page } from '@shared/table/page';

import { CollectionViewPaginationSettingsModel } from './collection-view-settings.model';
import { CollectionViewDelegate } from './collection-view.delegate';
import { CollectionViewQuery, PaginatedEndpoint } from './page';
import { PaginatedDataSource } from './paginated-datasource';

@Component({
    selector: 'vsc-collection-view',
    templateUrl: './collection-view.component.html',
    styleUrls: ['./collection-view.component.scss'],
})
export class CollectionViewComponent implements OnInit, OnDestroy {
    /**
     * @description enable selection of rows
     */
    @Input() noRecordsFoundText: Observable<string> | any = 'No Records Found';
    /**
     * @description `sqPaginationConfig` is additional configuration settings provided to `sq-table`.Refer [SqTablePaginationSettingsModel].
     */
    sqPaginationConfig?: CollectionViewPaginationSettingsModel;

    /**
     * @description Paginated endpoint configuration
     */
    paginatedEndpoint: PaginatedEndpoint<{}, {}>;

    /**
     * @description Table delegate for configuration
     */
    @Input() delegate: CollectionViewDelegate<{}, {}>;

    /**
     * @description Inserting template from parent element
     */
    @Input() collectionItemTemplateRef: TemplateRef<any>;

    /**
     * @description Custom paginator
     */
    @Input() collectionPaginator: BasePaginatorComponent;

    data: Observable<any>;

    /**
     * @description Local variable to keep datasource
     */
    dataSource: PaginatedDataSource<{}, {}>;

    @Input() initialQuery: CollectionViewQuery = {};

    @Input() defaultPage: number = 0;

    @Output() pageLoaded: EventEmitter<any> = new EventEmitter();

    @Output() pageChanged: EventEmitter<number> = new EventEmitter<number>();

    totalCount$: Subject<number> = new BehaviorSubject(0);

    private subscriptions: Subscription[] = [];

    @Input() isCarouselPaginator: boolean = false;

    /**
     * @hidden
     */
    /**
     * Initialize the directive/component after Angular first displays the data-bound properties
     * and sets the directive/component's input properties
     */
    ngOnInit(): void {
        if (this.delegate == null) {
            throw new Error('You must set the table delegate.');
        }

        this.delegate.collectionComponent = this;
        this.sqPaginationConfig = this.delegate.getPaginationSetings();
        this.paginatedEndpoint = this.delegate.getPaginatedEndpoint();

        this.dataSource = new PaginatedDataSource(
            this.paginatedEndpoint,
            // tslint:disable-next-line: no-any
            { property: '', order: '' } as any,
            this.initialQuery,
            this.sqPaginationConfig ? this.sqPaginationConfig.pageSize : null
        );

        this.data = this.dataSource.connect(this.defaultPage);

        this.subscriptions.push(
            this.data.subscribe((data) => {
                this.pageLoaded.emit(data);
                this.totalCount$.next(this.dataSource.totalCount);
            })
        );

        this.collectionPaginator && this.setCustomPaginator();
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((sub: Subscription) => sub?.unsubscribe());
        this.dataSource.disconnect();
        this.delegate.destroy();
    }
    isObservable(value: any): boolean {
        return value instanceof Observable;
    }

    setDefaultPage(page: number): void {
        this.defaultPage = page;
        this.dataSource.setDefaultPage(page);
    }

    fetchData(pageIndex: number, pageSize: number): void {
        this.dataSource.fetch(pageIndex, pageSize);
        this.pageChanged.emit(pageIndex);
    }

    private setCustomPaginator(): void {
        if (this.sqPaginationConfig) {
            this.collectionPaginator.showFirstLastButtons =
                this.sqPaginationConfig.showFirstLastButtons;
        }

        this.collectionPaginator.pageSize =
            this.sqPaginationConfig?.pageSize ?? 10;
        this.collectionPaginator.hidden =
            !this.sqPaginationConfig ||
            !this.sqPaginationConfig.enablePagination;

        this.subscriptions = this.subscriptions.concat([
            this.collectionPaginator.pageChange$
                .pipe(debounceTime(300))
                .subscribe((page: { pageIndex: number; pageSize: number }) =>
                    this.fetchData(page.pageIndex, page.pageSize)
                ),
            this.dataSource.page$
                .pipe(
                    tap((page: Page<any>) => {
                        this.collectionPaginator.hidden =
                            !page.content?.length ||
                            (page.totalElements < page.size &&
                                this.sqPaginationConfig &&
                                this.sqPaginationConfig.hidePagedIfOnlyOnePage);
                        this.collectionPaginator.page = page.number;
                        this.collectionPaginator.totalCount =
                            page.totalElements;
                    })
                )
                .subscribe(),
        ]);
    }
}
