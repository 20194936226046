import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnInit,
} from '@angular/core';
import { Router } from '@angular/router';

import { ID } from '@datorama/akita';
import { TranslocoService } from '@ngneat/transloco';

import { Observable } from 'rxjs';
import { switchMap, take, map } from 'rxjs/operators';

import { AuthService } from '@app/core/auth/auth.service';

import { Constants } from '@shared/constants';
import { FormatParticipantsService } from '@shared/format-participants';
import { PublicEvent } from '@shared/public-cards/public-event-card/state/public-event';

import { Chat } from '@public/chats/chat-state/chat';
import { ChatsQuery } from '@public/chats/chat-state/chats.query';
import { ChatService } from '@public/chats/services/chat.service';

@Component({
    selector: 'vsc-public-chat-card',
    templateUrl: './public-chat-card.component.html',
    styleUrls: ['./public-chat-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PublicChatCardComponent implements OnInit {
    @Input() orientation: 'horizontal' | 'vertical' = 'vertical';

    @Input() chat$: Observable<Chat>;

    publicEvent$: Observable<PublicEvent>;
    isChatReady$: Observable<boolean>;
    memberId: string;

    constructor(
        private authService: AuthService,
        private chatService: ChatService,
        private chatsQuery: ChatsQuery,
        private formatParticipantsService: FormatParticipantsService,
        private router: Router,
        private translocoService: TranslocoService
    ) {}

    ngOnInit() {
        this.memberId = this.authService.userId;

        this.isChatReady$ = this.chat$.pipe(
            take(1),
            switchMap((chat: Chat) =>
                this.chatsQuery.selectChatReady(chat?.id, true)
            )
        );
        const isOrganizerFirst = true;

        this.publicEvent$ = this.chat$.pipe(
            map(
                (chat: Chat) =>
                    chat &&
                    ({
                        title: chat.title,
                        chipTitle: this.translocoService.translate('Chat'),
                        startDateTime: chat.startDateTime,
                        duration: Constants.CHAT_DURATION_MIN,
                        organizer:
                            this.formatParticipantsService.generateFormattedChatParticipants(
                                chat,
                                isOrganizerFirst
                            ),
                        imageUrl: Constants.CHAT_DEFAULT_IMAGE_URL,
                        isReady$: this.isChatReady$,
                    } as PublicEvent)
            )
        );
    }

    navigateToChatManage(chatId: ID): void {
        this.chatService.setBackUrl(this.router.url);
        this.router.navigate([`/chats/${chatId}/details`]);
    }
}
