import { Injectable, Injector, OnDestroy } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Event,
    NavigationEnd,
    Router,
} from '@angular/router';

import { BehaviorSubject, Observable, of, Subject, Subscription } from 'rxjs';
import { filter, first, mergeMap } from 'rxjs/operators';

import { Breadcrumb, wrapIntoObservable } from '../vsc-breadcrumbs.shared';
import { VscBreadcrumbsConfig } from './vsc-breadcrumbs.config';
import { VscBreadcrumbsResolver } from './vsc-breadcrumbs.resolver';

@Injectable()
export class VscBreadcrumbsService implements OnDestroy {
    private breadcrumbs: BehaviorSubject<Breadcrumb[]> = new BehaviorSubject<
        Breadcrumb[]
    >([]);
    get crumbs$(): Observable<Breadcrumb[]> {
        return this.breadcrumbs;
    }

    private defaultResolver: VscBreadcrumbsResolver =
        new VscBreadcrumbsResolver();
    private crumbValues: Breadcrumb[] = [];

    private crumbSubject$: Subject<Breadcrumb[]> = new Subject<Breadcrumb[]>();
    private crumbUpdate$: Observable<Breadcrumb[]> = this.crumbSubject$.pipe(
        mergeMap((crumbs: Breadcrumb[]) => {
            if (this.config.postProcess) {
                const y = this.config.postProcess(crumbs);
                return wrapIntoObservable<Breadcrumb[]>(y).pipe(first());
            } else {
                return of(crumbs);
            }
        })
    );

    private subscriptions: Subscription[] = [];

    constructor(
        private router: Router,
        private config: VscBreadcrumbsConfig,
        private injector: Injector
    ) {
        this.subscriptions.concat([
            this.crumbUpdate$.subscribe((crumbs: Breadcrumb[]) => {
                this.breadcrumbs.next(crumbs);
            }),
            this.router.events
                .pipe(filter((event: Event) => event instanceof NavigationEnd))
                .subscribe(() => {
                    this.crumbValues = [];

                    const route = router.routerState.snapshot.root;
                    this._resolveCrumbs(route);
                }),
        ]);
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((sub: Subscription) => sub.unsubscribe());
    }

    private _resolveCrumbs(route: ActivatedRouteSnapshot): void {
        const data = route.routeConfig && route.routeConfig.data;

        if (data && data.breadcrumbs) {
            let resolver: VscBreadcrumbsResolver;

            if (data.breadcrumbs.prototype instanceof VscBreadcrumbsResolver) {
                resolver = this.injector.get<VscBreadcrumbsResolver>(
                    data.breadcrumbs
                );
            } else {
                resolver = this.defaultResolver;
            }

            const result = resolver.resolve(
                route,
                this.router.routerState.snapshot
            );

            const index = this.crumbValues.length;
            this.subscriptions.push(
                wrapIntoObservable<Breadcrumb[]>(result).subscribe(
                    (items: Breadcrumb[]) => {
                        if (this.crumbValues.length > index) {
                            this.crumbValues[index] = items[0];
                        } else {
                            this.crumbValues.push(items[0]);
                        }
                        this.crumbSubject$.next(this.crumbValues);
                    }
                )
            );
        }

        if (route.firstChild) {
            this._resolveCrumbs(route.firstChild);
        }
    }
}
