import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';

import { TranslocoModule } from '@ngneat/transloco';

import { NgxPermissionsModule } from 'ngx-permissions';

import { DirectiveModule } from '@shared/directives/directive.module';
import { IconModule } from '@shared/icon/icon.module';

import { UserMenuComponent } from './user-menu.component';

@NgModule({
    imports: [
        CommonModule,
        MatMenuModule,
        MatButtonModule,
        IconModule,
        TranslocoModule,
        RouterModule,
        NgxPermissionsModule,
        DirectiveModule,
    ],
    declarations: [UserMenuComponent],
    exports: [UserMenuComponent],
})
export class UserMenuModule {}
