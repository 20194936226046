<div class="cookies-manage-wrapper">
    <a class="close-icon-button" (click)="close()">
        <vsc-icon>x</vsc-icon>
    </a>
    <h2 class="mt-0 pt-0 header-text">{{ 'Manage cookies' | transloco }}</h2>
    <div class="message-body">
        <p>
            {{
                'You can manage which cookies are set on your device, but if you disable cookies, some parts of our website may not work properly. We use cookies to optimize our website and services. For more information, please visit our'
                    | transloco
            }}
            <a (click)="navigateToCookiesPage()">{{
                'Cookie Policy' | transloco
            }}</a>
            {{ 'and' | transloco }}
            <a (click)="navigateToPrivacy()"
                >{{ 'Privacy Policy' | transloco }}
            </a>
        </p>

        <vsc-cookie-settings
            (cookieStatusChanged)="onToggle($event)"
        ></vsc-cookie-settings>
    </div>

    <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="end">
        <button
            class="cookies-manage-save"
            mat-flat-button
            color="primary"
            (click)="savePreferences()"
        >
            {{ 'Save preferences' | transloco }}
        </button>
    </div>
</div>
