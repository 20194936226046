import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';

import { TranslocoService } from '@ngneat/transloco';

import { NgcCookieConsentService } from 'ngx-cookieconsent';
import {
    BehaviorSubject,
    combineLatest,
    Observable,
    Subscription,
    timer,
} from 'rxjs';
import {
    exhaustMap,
    map,
    mergeMap,
    startWith,
    take,
    tap,
} from 'rxjs/operators';

import { UserService } from '@app/admin/admin/users/services/user.service';
import { OrganizationSettings } from '@app/core/auth/organization-settings';
import { OrganizationSettingsService } from '@app/core/auth/organization-settings.service';
import { AuthService } from '@core/auth/auth.service';
import { Permissions } from '@core/auth/permissions';
import { PermissionService } from '@core/auth/permissions.service';

import { Constants } from '@shared/constants';
import { CookieHelperService } from '@shared/cookies/services/cookie-helper.service';
import { PermissionAuthorize } from '@shared/decorators/method/method-decorators';
import { ClassAssessmentSurvey } from '@shared/survey/models/class-assessment-survey.model';
import { SurveyService } from '@shared/survey/services/survey.service';

import { ChatInviteStatus } from './chats/chat-state/chat-invite-status.enum';
import { ChatsQuery } from './chats/chat-state/chats.query';
import { ChatService } from './chats/services/chat.service';

@Component({
    templateUrl: './public.component.html',
    styleUrls: ['./public.component.scss'],
    selector: 'vsc-public',
    // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PublicComponent implements OnInit, OnDestroy {
    @ViewChild('nav') menu: ElementRef;
    isMenuVisible: boolean = false;
    readonly cookieConsentStatus: string = 'cookieconsent_status';
    readonly gaCookieName: string = '_ga';

    pendingChatsCountNote$: Observable<string>;
    private subscriptions: Subscription[] = [];
    private pendingChatsFetchingSub: Subscription;

    hasViewHelpPagePermission: boolean;

    menuItemClickAction$: BehaviorSubject<any> = new BehaviorSubject<any>([]);

    headerBackgroundColor$ = this.organizationSettingsService.selectSetting(
        OrganizationSettings.HeaderBackgroundColor
    );
    headerColor$ = this.organizationSettingsService.selectSetting(
        OrganizationSettings.HeaderTextColor
    );

    Permissions: typeof Permissions = Permissions;

    constructor(
        private authService: AuthService,
        private chatService: ChatService,
        private chatsQuery: ChatsQuery,
        private changeDetectorRef: ChangeDetectorRef,
        private router: Router,
        private surveyService: SurveyService,
        private translocoService: TranslocoService,
        private cookieConsentService: NgcCookieConsentService,
        private cookieHelperService: CookieHelperService,
        private permissionService: PermissionService,
        private organizationSettingsService: OrganizationSettingsService,
        private usersService: UserService
    ) {}

    ngOnInit(): void {
        const isChatsScheduleRoute = this.router.url?.includes(
            Constants.CHATS_SCHEDULE_PATH
        );

        if (!isChatsScheduleRoute) {
            this.schedulePendingChatInvitesFetching();
        }

        this.subscriptions = this.subscriptions.concat([
            this.surveyService
                .showSelfAssessmentSurveyIfAvailable()
                .subscribe(),
            this.chatService.subscribeToPendingChatsFetchingTrigger(
                (isSchedulingAction: boolean) =>
                    isSchedulingAction
                        ? this.schedulePendingChatInvitesFetching()
                        : this.rejectPendingChatInvitesFetching()
            ),
        ]);

        this.pendingChatsCountNote$ = combineLatest([
            this.chatsQuery.selectPendingChatsCountConditionally$,
            this.translocoService.langChanges$.pipe(startWith('')),
        ]).pipe(
            map(([count]: [number, string]) =>
                this.formatChatInvitesCountNote(count)
            ),
            tap(() => this.changeDetectorRef.detectChanges())
        );

        this.subscriptions.push(
            this.translocoService.langChanges$.subscribe(() => {
                this.changeDetectorRef.detectChanges();
                const config =
                    this.cookieHelperService.configureCookieConsent();
                this.cookieConsentService.destroy();
                this.cookieConsentService.init(config);
            })
        );
        this.cookieHelperService.initializeCookies();
        this.hasViewHelpPagePermission = this.permissionService.hasPermission(
            Permissions.HelpPage.View
        );

        this.subscriptions.push(
            this.menuItemClickAction$
                .pipe(exhaustMap(() => this.onMenuItemClick()))
                .subscribe()
        );

        this.usersService
            .UpdateConsumerInformationPageVisibilityIfUserSubmittedInterests()
            .subscribe();
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((sub: Subscription) => sub?.unsubscribe());
        this.pendingChatsFetchingSub?.unsubscribe();
    }

    toggleMenu(): void {
        this.isMenuVisible = !this.isMenuVisible;
    }

    onMenuItemClick(): Observable<ClassAssessmentSurvey> {
        this.toggleMenu();
        return this.surveyService
            .showClassAssessmentSurveyIfAvailable()
            .pipe(take(1));
    }

    @PermissionAuthorize(Permissions.Chat.View)
    private schedulePendingChatInvitesFetching(): void {
        this.rejectPendingChatInvitesFetching();
        this.pendingChatsFetchingSub = timer(
            0,
            Constants.CHATS_REFRESH_INTERVAL_MS
        )
            .pipe(
                mergeMap(() =>
                    this.chatService
                        .getUserInvitedChats({
                            inviteStatus: ChatInviteStatus.Pending,
                        })
                        .pipe(take(1))
                )
            )
            .subscribe();
    }

    private rejectPendingChatInvitesFetching(): void {
        this.pendingChatsFetchingSub?.unsubscribe();
        this.pendingChatsFetchingSub = null;
    }

    private formatChatInvitesCountNote(count: number): string {
        return !count
            ? ''
            : `(${count} ${this.translocoService.translate(
                  count === 1 ? 'chat invite' : 'chat invites'
              )})`;
    }
}
