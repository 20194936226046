import {
    animate,
    state,
    style,
    transition,
    trigger,
} from '@angular/animations';
import {
    Component,
    HostBinding,
    Input,
    OnChanges,
    SimpleChanges,
} from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { IconState } from './icon-state';
import { SideMenuItem } from './side-menu-item.model';
import { SideMenuState } from './side-menu-state';

@Component({
    selector: 'vsc-side-menu',
    templateUrl: './side-menu.component.html',
    styleUrls: ['./side-menu.component.scss'],
    animations: [
        trigger('slideInOut', [
            state(
                'out',
                style({
                    width: '250px',
                })
            ),
            state(
                'in',
                style({
                    width: '64px',
                })
            ),
            transition('in => out', [animate('250ms ease-in-out')]),
            transition('out => in', [animate('250ms ease-in-out')]),
        ]),
        trigger('indicatorRotate', [
            state('collapsed', style({ transform: 'rotate(0deg)' })),
            state('expanded', style({ transform: 'rotate(180deg)' })),
            transition(
                'expanded <=> collapsed',
                animate('225ms cubic-bezier(0.4,0.0,0.2,1)')
            ),
        ]),
    ],
})
export class SideMenuComponent implements OnChanges {
    private menuItems: Array<SideMenuItem> = [];
    iconState: string = 'default';

    get items(): Array<SideMenuItem> {
        return this.menuItems;
    }

    @Input() set items(items: Array<SideMenuItem>) {
        this.menuItems = items;
        this.configureMenuItems(this.menuItems);
    }

    @Input() menuState: SideMenuState = SideMenuState.Out;
    @Input() configureSideMenuItemFn: (sideMenuItem: SideMenuItem) => void;
    @Input() expanderStyle: any;

    @HostBinding('@slideInOut') get slideInOut(): SideMenuState {
        return this.menuState;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.items) {
            this.configureMenuItems(changes.items?.currentValue);
        }
    }

    isObservable(value: any): boolean {
        return value instanceof Observable;
    }

    public toggleMenu(): void {
        this.menuState =
            this.menuState === SideMenuState.Out
                ? SideMenuState.In
                : SideMenuState.Out;
        this.iconState =
            this.iconState === IconState.Default
                ? IconState.Rotated
                : IconState.Default;
    }

    private configureMenuItems(
        items: Array<SideMenuItem> | Observable<SideMenuItem[]>
    ): void {
        if (items != null) {
            if (this.isObservable(items)) {
                items = items as Observable<SideMenuItem[]>;
                items = items.pipe(
                    map((sideMenuItems: SideMenuItem[]) => ({
                        ...sideMenuItems,
                        items: this.configureMenuItems(sideMenuItems),
                    }))
                );
            } else {
                items = items as SideMenuItem[];
                items?.forEach((item: SideMenuItem) => {
                    if (this.isObservable(item.children)) {
                        item.children = item.children as Observable<
                            SideMenuItem[]
                        >;
                        item.hasVisibleChildren = item.children?.pipe(
                            map(
                                (childrenItems: SideMenuItem[]) =>
                                    childrenItems &&
                                    childrenItems.length &&
                                    childrenItems.filter(
                                        (childItem: SideMenuItem) =>
                                            childItem.visible
                                    ).length > 0
                            )
                        );
                    } else {
                        item.children = item.children as SideMenuItem[];
                        item.hasVisibleChildren =
                            item.children &&
                            item.children.length > 0 &&
                            item.children.filter((x) => x.visible).length > 0;
                    }

                    if (this.configureSideMenuItemFn != null) {
                        this.configureSideMenuItemFn(item);
                    }
                    if (item.children) {
                        this.configureMenuItems(item.children);
                    }
                });
            }
        }
    }
}
