import {
    AbstractControl,
    FormControl,
    ValidationErrors,
    Validator,
} from '@angular/forms';

import { DynamicValidator, Group, Rule } from './dynamic';

export class AllowedCharactersValidator
    extends DynamicValidator
    implements Validator
{
    constructor(
        private readonly allowedCharacters: Array<string>,
        rules?: Array<Rule | Group>
    ) {
        super(rules);
    }

    validate(control: AbstractControl): ValidationErrors | null {
        if (control instanceof FormControl) {
            if (!control.value || typeof control.value !== 'string') {
                return null;
            }

            let notAllowedCharacter: string;
            for (const i of control.value) {
                if (this.allowedCharacters.indexOf(i) === -1) {
                    notAllowedCharacter = i;
                    break;
                }
            }
            if (!notAllowedCharacter) {
                return null;
            }

            return {
                allowedCharacters: {
                    current: control.value,
                    notAllowedCharacter,
                },
            };
        }

        return null;
    }
}
