import {
    Component,
    DoCheck,
    Input,
    OnInit,
    ViewChild,
    ElementRef,
} from '@angular/core';
import { FormControl, FormGroupDirective } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatInput } from '@angular/material/input';

import * as moment from 'moment';

import {
    ControlValueAccessor,
    controlValueAccessorProvider,
} from '../control-value-accessor';
import { InputAutoComplete } from './input-autocomplete.enum';
import { InputType } from './input-type.enum';

@Component({
    selector: 'vsc-input',
    templateUrl: './input.component.html',
    styleUrls: ['./input.component.scss'],
    providers: [controlValueAccessorProvider(VSCInputComponent as Component)],
})
export class VSCInputComponent
    extends ControlValueAccessor<string>
    implements OnInit, DoCheck
{
    @Input() formControlName: string;
    @Input() placeholder: string;
    @Input() type: string = InputType.Text;
    @Input() autocomplete: string = InputAutoComplete.Off;
    @Input() mask: string;
    @Input() id: string;
    @Input() showLabel: boolean = true;
    @Input() label: string;
    @Input() showIcon: boolean = false;
    @Input() iconName: string = '';
    @Input() readonly: boolean = false;
    @Input() hint: string = '';
    @Input() hintPosition: 'between' | 'end' = 'end';
    @Input() showRequiredStar: boolean = true;
    @Input() textAreaRows: number;
    @Input() ariaLabel: string;
    @Input() leadZeroDateTime: boolean;
    @Input() cssClass: string;

    /**
     * @description Show copy icon on text input and allow copying of text.
     */
    @Input() showCopyIcon: boolean;

    formControl: FormControl;
    hidePassword: boolean = true;
    @ViewChild(MatInput, { static: false }) private readonly matInput: MatInput;

    constructor(
        private readonly formGroupDirective: FormGroupDirective,
        private readonly errorStateMatcher: ErrorStateMatcher,
        public readonly elementRef: ElementRef
    ) {
        super();
    }

    ngOnInit(): void {
        this.formControl = this.formGroupDirective.control.get(
            this.formControlName
        ) as FormControl;
        if (this.matInput) {
            this.matInput.ngControl = null;
        }
    }

    ngDoCheck(): void {
        if (this.matInput) {
            this.matInput.errorState = this.errorStateMatcher.isErrorState(
                this.formControl,
                this.formGroupDirective
            );
            this.matInput.stateChanges.next();
        }
    }

    __valueChanged(val: string): void {
        this.formGroupDirective.control
            .get(this.formControlName)
            .patchValue(val);
        this.valueChanged.emit(val);
    }

    onChange(value) {
        if (value !== this.value) {
            this.__valueChanged(value);
        }
    }

    formatValue(value: string): string {
        if (this.type === InputType.Date) {
            const date = moment(value);
            if (date.isValid() && date.year().toString().length >= 4) {
                const nowDate = date;
                const month = `0${nowDate.month() + 1}`.slice(-2);
                const day = `0${nowDate.date()}`.slice(-2);
                return `${nowDate.year()}-${month}-${day}`;
            }
        }

        return value;
    }
}
