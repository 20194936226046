import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    Router,
} from '@angular/router';

import { RouterQuery } from '@datorama/akita-ng-router-store';

import { LeaveClassConfirmationStatus } from './leave-class-confirmation-status.enum';
import { LeaveClassComponent } from './leave-class/leave-class.component';
import { ClassroomService } from './services/classroom.service';
import { ClassroomQuery } from './state/classroom.query';

@Injectable({
    providedIn: 'root',
})
export class LeaveClassConfirmationGuard implements CanActivate {
    constructor(
        private router: Router,
        private routerQuery: RouterQuery,
        private dialog: MatDialog,
        private classroomQuery: ClassroomQuery,
        private classroomService: ClassroomService
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<boolean> {
        const navigation = this.router.getCurrentNavigation();
        const leaveIndicator = this.classroomQuery.getLeaveIndicator();
        if (
            leaveIndicator &&
            leaveIndicator === LeaveClassConfirmationStatus.Confirmed
        ) {
            this.classroomService.setLeaveIndicator(
                LeaveClassConfirmationStatus.Skipped
            );
            return Promise.resolve(true);
        }
        if (navigation.extras.state) {
            const navigationExtras = navigation.extras.state as {
                byPassLeaveGuard: number;
            };
            if (navigationExtras.byPassLeaveGuard === 1) {
                return Promise.resolve(true);
            }
        } else {
            const originUrl = this.routerQuery.getValue().state
                ? this.routerQuery.getValue().state.urlAfterRedirects
                : null;

            if (originUrl && originUrl.includes('classroom')) {
                const redirectUrl = state?.url?.toString();
                const classSessionId =
                    this.routerQuery.getValue().state.params.id;
                this.dialog.open(LeaveClassComponent, {
                    width: '480px',
                    data: { classSessionId, redirectUrl },
                });
                return Promise.resolve(false);
            }
        }
        return Promise.resolve(true);
    }
}
