import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';

import { TranslocoModule } from '@ngneat/transloco';

import { FilterModule } from '@shared/forms/filter/filter.module';
import { IconModule } from '@shared/icon/icon.module';
import { MaterialModule } from '@shared/material.module';
import { PipesModule } from '@shared/pipes/pipes.module';

import { TableComponent } from './table.component';

export { TableComponent } from './table.component';
export {
    TablePaginationSettingsModel,
    ColumnSettingsModel,
} from './table-settings.model';

@NgModule({
    declarations: [TableComponent],
    imports: [
        CommonModule,
        MatTableModule,
        MatCheckboxModule,
        MatSortModule,
        MatPaginatorModule,
        MaterialModule,
        TranslocoModule,
        MatSidenavModule,
        FilterModule,
        PipesModule,
        FlexLayoutModule,
        IconModule,
    ],

    exports: [TableComponent],
})
export class TableModule {}
