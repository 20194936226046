import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'imageUrlSize' })
export class ImageUrlSizePipe implements PipeTransform {
    constructor() {}

    /**
     * @description Inserts the size at the end of the image name in the URL, and before the extension (JPG or PNG).
     * The size can be small, medium and large.
     * @param url URL to transform.
     * @param size Image size.
     * @returns
     */
    transform(
        url: string,
        size: 'small' | 'medium' | 'large' = 'small'
    ): string {
        if (!url || (!url.includes('.jpg') && !url.includes('.png'))) {
            return url;
        }

        const lastDotIndex = url.lastIndexOf('.');
        if (lastDotIndex === -1) {
            return url;
        }

        return (
            url.substring(0, lastDotIndex) +
            `_${size}` +
            url.substring(lastDotIndex)
        );
    }
}
