<div class="widget-title">
    <h4>{{ 'Announcements' | transloco }}</h4>
</div>
<div class="vsc-card widget announcement-container" fxLayout="column">
    <ng-container
        *ngIf="
            (isLoading$ | async) === false && announcements$
                | async as announcements
        "
    >
        <div
            *ngFor="let announcement of announcements; let i = index"
            fxLayout="row"
            class="announcement item"
            [class.link]="!!announcement?.link"
            (click)="openPage(announcement)"
        >
            <div class="date" fxLayout="column" fxLayoutAlign="start center">
                <div class="day">
                    {{ announcement.startDate | localizedDate: 'D' }}
                </div>
                <div class="month">
                    {{ announcement.startDate | localizedDate: 'MMM' }}
                </div>
            </div>
            <div
                fxFlex
                fxLayout="column"
                fxLayoutAlign="center start"
                [class.link]="!!announcement.link"
            >
                <div
                    class="announcement-name"
                    fxLayout="column"
                    fxLayoutAlign="center space-between"
                    fxLayoutGap="10px"
                >
                    <span>{{ announcement.text }}</span>
                    <div>
                        <a
                            *ngIf="announcement.link"
                            class="read-more"
                            (click)="openPage(announcement)"
                            fxLayout="row"
                            fxLayoutAlign="start center"
                        >
                            {{ 'Learn more' | transloco }}

                            <vsc-icon iconStyle="solid" color="accent"
                                >caret-right</vsc-icon
                            >
                        </a>
                    </div>
                </div>
            </div>
            <div class="line" *ngIf="i < announcements.length - 1"></div>
        </div>
        <div *ngIf="announcements.length === 0" class="no-announcements">
            {{ 'There are no announcements' | transloco }}
        </div>
    </ng-container>
    <div class="loading" *ngIf="isLoading$ | async">
        {{ 'Loading announcements ...' | transloco }}
    </div>
</div>
