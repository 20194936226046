import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { NgxPermissionsGuard } from 'ngx-permissions';

import { AuthGuard } from '@core/auth/auth-guard.service';
import { Permissions } from '@core/auth/permissions';

import { AppUnit } from '@shared/analytics/app-unit';
import { VisitPageGuard } from '@shared/analytics/visit-page.guard';
import { BadRequestComponent } from '@shared/bad-request/bad-request.component';
import { CookiesComponent } from '@shared/cookies/cookies.component';
import { NotAuthorizedComponent } from '@shared/not-authorized/not-authorized.component';
import { NotFoundComponent } from '@shared/not-found/not-found.component';
import { AllNotificationsComponent } from '@shared/notifications/all-notifications.component';
import { PrivacyPolicyComponent } from '@shared/privacy-policy/privacy-policy.component';
import { TosComponent } from '@shared/tos/tos.component';

import { LeaveChatConfirmationGuard } from './chats/chat-room/leave-chat-confirmation.guard';
import { LeaveClassConfirmationGuard } from './classroom/leave-class-confirmation.guard';
import { routes as homeRoutes } from './home/home-routing.module';
import { WeatherComponent } from './home/weather/weather.component';

export const routes: Routes = [
    { path: '', pathMatch: 'full', redirectTo: 'home' },
    {
        path: 'classes',
        canActivate: [
            AuthGuard,
            LeaveChatConfirmationGuard,
            LeaveClassConfirmationGuard,
        ],
        loadChildren: () =>
            import('./classes/classes.module').then((m) => m.ClassesModule),
    },
    {
        path: 'home',
        canActivate: [
            AuthGuard,
            NgxPermissionsGuard,
            VisitPageGuard,
            LeaveChatConfirmationGuard,
            LeaveClassConfirmationGuard,
        ],
        data: {
            appRoute: AppUnit.Home,
            permissions: {
                only: Permissions.Homepage.View,
                redirectTo: 'public/unauthorized',
            },
        },
        children: [...homeRoutes],
    },
    {
        path: 'calendar',
        canActivate: [
            AuthGuard,
            VisitPageGuard,
            LeaveChatConfirmationGuard,
            NgxPermissionsGuard,
            LeaveClassConfirmationGuard,
        ],
        data: {
            appRoute: AppUnit.MyCalendar,
            permissions: {
                only: Permissions.Calendar.View,
                redirectTo: 'public/unauthorized',
            },
        },
        loadChildren: () =>
            import('./calendar/calendar.module').then((m) => m.CalendarModule),
    },
    {
        path: 'videos',
        canActivate: [
            AuthGuard,
            VisitPageGuard,
            LeaveChatConfirmationGuard,
            NgxPermissionsGuard,
            LeaveClassConfirmationGuard,
        ],
        data: {
            appRoute: AppUnit.VideoLibrary,
            permissions: {
                only: Permissions.Videos.View,
                redirectTo: 'public/unauthorized',
            },
        },
        loadChildren: () =>
            import('./videos/videos.module').then((m) => m.VideosModule),
    },

    {
        path: 'classroom',
        canActivate: [AuthGuard, VisitPageGuard],
        data: {
            appRoute: AppUnit.Classroom,
        },
        loadChildren: () =>
            import('./classroom/classroom.module').then(
                (m) => m.ClassroomModule
            ),
    },
    {
        path: 'games',
        canActivate: [
            AuthGuard,
            VisitPageGuard,
            LeaveChatConfirmationGuard,
            LeaveClassConfirmationGuard,
        ],
        data: {
            display: 'Games',
            appRoute: AppUnit.Games,
            permissions: {
                only: Permissions.Internet.View,
                redirectTo: 'public/unauthorized',
            },
        },
        loadChildren: () =>
            import('./bookmarks/bookmarks.module').then(
                (m) => m.BookmarksModule
            ),
    },
    {
        path: 'internet',
        canActivate: [
            AuthGuard,
            VisitPageGuard,
            LeaveChatConfirmationGuard,
            LeaveClassConfirmationGuard,
        ],
        data: {
            display: 'Internet',
            appRoute: AppUnit.Internet,
            permissions: {
                only: Permissions.Internet.View,
                redirectTo: 'public/unauthorized',
            },
        },
        loadChildren: () =>
            import('./bookmarks/bookmarks.module').then(
                (m) => m.BookmarksModule
            ),
    },
    {
        path: 'support-request',
        canActivate: [
            AuthGuard,
            VisitPageGuard,
            LeaveChatConfirmationGuard,
            LeaveClassConfirmationGuard,
        ],
        data: {
            appRoute: AppUnit.SupportRequest,
        },
        loadChildren: () =>
            import('./support-request/support-request.module').then(
                (m) => m.SupportRequestModule
            ),
    },
    {
        path: 'profile',
        canActivate: [
            AuthGuard,
            VisitPageGuard,
            LeaveChatConfirmationGuard,
            LeaveClassConfirmationGuard,
        ],
        data: {
            appRoute: AppUnit.UserSettings,
        },
        loadChildren: () =>
            import('./user-profile/user-profile.module').then(
                (m) => m.UserProfileModule
            ),
    },
    {
        path: 'chats',
        canActivate: [AuthGuard],
        data: {
            appRoute: AppUnit.Chat,
            permissions: {
                only: Permissions.Chat.View,
                redirectTo: 'public/unauthorized',
            },
        },
        loadChildren: () =>
            import('./chats/chats.module').then((m) => m.ChatsModule),
    },
    {
        path: 'news',
        canActivate: [
            AuthGuard,
            VisitPageGuard,
            LeaveChatConfirmationGuard,
            LeaveClassConfirmationGuard,
        ],
        data: {
            appRoute: AppUnit.News,
        },
        loadChildren: () =>
            import('./news/news.module').then((m) => m.NewsModule),
    },
    {
        path: 'weather',
        component: WeatherComponent,
        canActivate: [
            NgxPermissionsGuard,
            VisitPageGuard,
            LeaveChatConfirmationGuard,
            LeaveClassConfirmationGuard,
        ],
        data: {
            appRoute: AppUnit.Weather,
            permissions: {
                only: Permissions.Weather.View,
                redirectTo: 'public/unauthorized',
            },
        },
    },
    {
        path: 'unauthorized',
        component: NotAuthorizedComponent,
    },
    {
        path: 'bad-request',
        component: BadRequestComponent,
    },
    {
        path: 'not-found',
        component: NotFoundComponent,
    },
    {
        path: 'tos',
        canActivate: [
            VisitPageGuard,
            LeaveChatConfirmationGuard,
            LeaveClassConfirmationGuard,
        ],
        data: {
            appRoute: AppUnit.TOS,
        },
        component: TosComponent,
    },
    {
        path: 'privacy-policy',
        canActivate: [
            VisitPageGuard,
            LeaveChatConfirmationGuard,
            LeaveClassConfirmationGuard,
        ],
        data: {
            appRoute: AppUnit.Privacy,
        },
        component: PrivacyPolicyComponent,
    },
    {
        path: 'cookies',
        canActivate: [
            VisitPageGuard,
            LeaveChatConfirmationGuard,
            LeaveClassConfirmationGuard,
        ],
        data: {
            appRoute: AppUnit.Cookies,
        },
        component: CookiesComponent,
    },
    {
        path: 'notifications',
        canActivate: [VisitPageGuard],
        data: {
            appRoute: AppUnit.Notifications,
        },
        component: AllNotificationsComponent,
    },
];

@NgModule({
    imports: [RouterModule],
    exports: [RouterModule],
})
export class PublicRoutingModule {}
