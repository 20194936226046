import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';

import { TranslocoModule } from '@ngneat/transloco';

import { PipesModule } from '@shared/pipes/pipes.module';

import { ValidationMessageComponent } from './validation-message.component';

@NgModule({
    declarations: [ValidationMessageComponent],
    imports: [CommonModule, TranslocoModule, PipesModule, FlexLayoutModule],

    exports: [ValidationMessageComponent],
})
export class ValidationMessageModule {}
