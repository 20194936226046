import { OAuthModuleConfig } from 'angular-oauth2-oidc';

import { environment } from '@environment/environment';

export const authModuleConfig: OAuthModuleConfig = {
    resourceServer: {
        allowedUrls: [environment.apiUrl, environment.identityUrl],
        sendAccessToken: true,
    },
};
