import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { TranslocoService } from '@ngneat/transloco';

import { NgcCookieConsentService } from 'ngx-cookieconsent';

import { PagePaths } from '@shared/constants/page-paths';
import { SnackBarService } from '@shared/snack-bar/snack-bar.service';

import { CookiesManageComponent } from './cookies-manage/cookies-manage.component';
import { CookieHelperService } from './services/cookie-helper.service';

@Component({
    selector: 'vsc-cookies',
    templateUrl: './cookies.component.html',
    styleUrls: ['./cookies.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CookiesComponent implements OnInit {
    isGaEnabled: boolean = true;

    constructor(
        private router: Router,
        private snackBar: SnackBarService,
        private cookieConsentService: NgcCookieConsentService,
        private cookieHelperService: CookieHelperService,
        private translocoService: TranslocoService,
        private dialog: MatDialog
    ) {}

    ngOnInit(): void {
        if (!this.cookieConsentService.hasAnswered()) {
            this.cookieConsentService.open();
        }
        if (this.cookieConsentService.hasAnswered()) {
            this.isGaEnabled = this.cookieHelperService.isCookieSet();
        }
    }

    navigateToPrivacy(): void {
        if (this.router.url?.startsWith('/admin')) {
            this.router.navigate(['admin' + PagePaths.Public.Privacy]);
        } else {
            this.router.navigate([PagePaths.Public.Privacy]);
        }
    }

    onToggle(isChecked: boolean): void {
        this.isGaEnabled = isChecked;
    }

    savePreferences(): void {
        this.cookieHelperService.setGACookiesTrackingStatus(this.isGaEnabled);
        this.cookieHelperService.setCookieConsentStatus(this.isGaEnabled);
        this.cookieConsentService.close(false);
        this.snackBar.open(
            this.translocoService.translate('Cookie settings are updated')
        );
    }

    changePreferences(): void {
        this.dialog.open(CookiesManageComponent, {
            width: '560px',
            disableClose: true,
            data: { isGaEnabled: this.isGaEnabled },
        });
    }
}
