import {
    AbstractControl,
    FormControl,
    ValidationErrors,
    Validator,
} from '@angular/forms';

import { DynamicValidator, Group, Rule } from './dynamic';

export class SameAsPatternValidator
    extends DynamicValidator
    implements Validator
{
    constructor(private readonly pattern: string, rules?: Array<Rule | Group>) {
        super(rules);
    }

    validate(control: AbstractControl): ValidationErrors | null {
        if (control instanceof FormControl) {
            if (!control.value || typeof control.value !== 'string') {
                return null;
            }

            let sameAsPattern = control.value.length === this.pattern.length;
            if (sameAsPattern) {
                for (let i = 0; i < control.value.length; i++) {
                    const valueChar: any = control.value[i];
                    const patternChar = this.pattern[i];
                    if (
                        (patternChar === '0' && isNaN(valueChar)) ||
                        (patternChar !== '0' && valueChar !== patternChar)
                    ) {
                        sameAsPattern = false;
                        break;
                    }
                }
            }

            if (sameAsPattern) {
                return null;
            }

            return {
                sameAsPattern: {
                    current: control.value,
                    pattern: this.pattern,
                },
            };
        }

        return null;
    }
}
