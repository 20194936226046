import { Injectable } from '@angular/core';

import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

import { Announcement } from './announcement';

export type AnnouncementState = EntityState<Announcement>;

export const initialState: AnnouncementState = {
    loading: false,
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'public-announcements' })
export class AnnouncementStore extends EntityStore<
    AnnouncementState,
    Announcement
> {
    constructor() {
        super(initialState);
    }
}
