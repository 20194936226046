import {
    AbstractControl,
    FormControl,
    ValidationErrors,
    Validator,
} from '@angular/forms';

import * as moment from 'moment';

import { DynamicValidator, Group, Rule } from './dynamic';

export class MaxDateValidator extends DynamicValidator implements Validator {
    constructor(
        private readonly maxDate: moment.Moment,
        rules?: Array<Rule | Group>
    ) {
        super(rules);
    }

    validate(control: AbstractControl): ValidationErrors | null {
        if (control instanceof FormControl && control?.value) {
            const dateToValidate = moment(control.value);
            if (
                !dateToValidate.isValid() ||
                dateToValidate.isSameOrBefore(this.maxDate)
            ) {
                return null;
            }

            return {
                maxDate: {
                    currentDate: control.value,
                    maxDate: this.maxDate,
                },
            };
        }

        return null;
    }
}
