import { ValueProvider } from '@angular/core';

import { PersistState, persistState } from '@datorama/akita';

import { debounceTime } from 'rxjs/operators';

const embedTokenPersistsStorage: PersistState = persistState({
    include: ['embedtoken'],
    key: 'embedtoken',
    preStorageUpdateOperator: () => debounceTime(3000),
});

export const EMBED_TOKEN_PERSIST_STATE_PROVIDER: ValueProvider = {
    provide: 'persistStorage',
    useValue: embedTokenPersistsStorage,
    multi: true,
};

export const clearEmbedTokenPersistState: () => void = () => {
    localStorage.removeItem('embedtoken');
};
