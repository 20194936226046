import { PermissionService } from '@core/auth/permissions.service';

import { DecoratorService } from '../decorator.service';

export function permissionAuthorize(
    target: any,
    name: string,
    descriptor: PropertyDescriptor,
    permissions: string | string[]
) {
    const method = descriptor.value; // references the method being decorated

    descriptor.value = function (...args: any) {
        const permissionService =
            DecoratorService.getServiceInstance(PermissionService);

        if (!permissionService.hasPermission(permissions)) {
            return;
        } // exit the function

        // This part will run when Meteor.isClient == false
        return method.apply(this, args);
    };
}
