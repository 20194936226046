import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';

import { TranslocoModule } from '@ngneat/transloco';

import { FormsModule } from '@shared/forms/forms.module';
import { MaterialModule } from '@shared/material.module';
import { SharedModule } from '@shared/shared.module';

import { PasswordManageComponent } from './password-manage.component';

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        FormsModule,
        FlexLayoutModule,
        TranslocoModule,
        SharedModule,
    ],
    declarations: [PasswordManageComponent],
    exports: [PasswordManageComponent],
})
export class PasswordManageModule {}
