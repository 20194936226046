import { Injectable } from '@angular/core';

import { QueryEntity, combineQueries, ID } from '@datorama/akita';
import { RouterQuery, RouterState } from '@datorama/akita-ng-router-store';

import * as moment from 'moment';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { AuthService } from '@app/core/auth/auth.service';

import { Constants } from '@shared/constants';
import { Lookup } from '@shared/state/lookup/lookup';
import { LookupQuery } from '@shared/state/lookup/lookup.query';
import { Lookups } from '@shared/state/lookup/lookups';

import { UserSearchQuery } from '../services/user.service';
import { HostAccountStatus } from '../user-details-personal-info/host-account-status.enum';
import { User } from './user';
import { UserSubscription } from './user-subscription';
import { UsersState, UsersStore } from './users.store';

@Injectable({
    providedIn: 'root',
})
export class UsersQuery extends QueryEntity<UsersState, User> {
    hostAccountStatus: any = HostAccountStatus;
    constructor(
        protected store: UsersStore,
        private routerQuery: RouterQuery,
        private lookupsQuery: LookupQuery,
        private authService: AuthService
    ) {
        super(store);
    }

    selectActiveUser$: Observable<User> = combineQueries([
        this.routerQuery.selectParams(),
        this.selectAll(),
    ]).pipe(
        map((combine) => {
            const userItem = combine[1].find((x) => x.id === combine[0]['id']);
            if (userItem) {
                return {
                    ...userItem,
                    dateOfBirth: userItem.dateOfBirth
                        ? moment(userItem.dateOfBirth)
                        : null,
                };
            }
            return userItem;
        })
    );

    isSelectedRoleFacilitator$: Observable<boolean> = combineQueries([
        this.select((state: UsersState) => state.selectedRoleIds),
        this.lookupsQuery.selectLookup(Lookups.RestrictedRoles),
    ]).pipe(
        map((combine: [string[], Lookup[]]) => {
            if (
                combine[1]?.find(
                    (x) =>
                        x.name === 'Facilitator' && combine[0]?.includes(x.id)
                )
            ) {
                return true;
            } else {
                return false;
            }
        })
    );

    selectIsEditMode$: Observable<boolean> = this.routerQuery.select().pipe(
        map((route: RouterState) => {
            const userId = route.state?.params['id'];
            const isUserProfile = route.state?.url?.includes('profile');
            return userId !== undefined || isUserProfile;
        })
    );

    getIsEditMode(): boolean {
        const userId = this.routerQuery.getParams('id');
        const isUserProfile = this.routerQuery
            .getValue()
            .state?.url?.includes('profile');
        return userId !== undefined || isUserProfile;
    }

    getEditingId(): string {
        return this.routerQuery.getParams('id') ?? this.authService.userId;
    }

    getActiveUser(): User {
        return this.getEntity(this.routerQuery.getParams('id'));
    }

    getSearch(): UserSearchQuery {
        return this.getValue().search;
    }

    selectActiveHostAccountStatus$: Observable<HostAccountStatus> =
        combineQueries([
            this.routerQuery.selectParams(),
            this.selectAll(),
        ]).pipe(
            map((combine) => {
                return combine[1].find((x) => x.id === combine[0]['id'])
                    .hostAccountStatus;
            })
        );

    getHasHostAccount(id: string): boolean {
        const user = this.getEntity(id);
        if (user) {
            return (
                user.hostAccountStatus === this.hostAccountStatus.Verified ||
                user.hostAccountStatus ===
                    this.hostAccountStatus.PendingVerification
            );
        } else {
            return false;
        }
    }

    selectUserActiveSubscription(
        userId: string | ID
    ): Observable<UserSubscription> {
        return this.selectEntity(userId).pipe(
            filter((user: User) => user && !!user.activeSubscription),
            map((user) => {
                const activeSubscription = user.activeSubscription;

                return {
                    ...activeSubscription,
                    activationDateTime: moment(
                        activeSubscription.activationDateTime
                    ).format(Constants.DATE_FORMAT_US),
                    expirationDateTime: moment(
                        activeSubscription.expirationDateTime
                    ).format(Constants.DATE_FORMAT_US),
                };
            })
        );
    }
}
