import {
    trigger,
    state,
    style,
    transition,
    animate,
} from '@angular/animations';
import { Component } from '@angular/core';

import { ToastService } from './services/toast.service';
import { Toast } from './toast';

@Component({
    selector: 'vsc-toast',
    templateUrl: './toast.component.html',
    styleUrls: ['./toast.component.scss'],
    animations: [
        trigger('slideIn', [
            state(
                '*',
                style({
                    transform: 'translateX(0)',
                    opacity: 1,
                    filter: 'blur(0) saturate(100%)',
                })
            ),
            state(
                'void',
                style({
                    transform: 'translateX(100%)',
                    opacity: 0,
                    filter: 'blur(2px) saturate(50%)',
                })
            ),
            transition('void => *', animate('.3s ease-out')),
        ]),
    ],
})
export class ToastComponent {
    constructor(public toastService: ToastService) {}

    trackItemsFn(item: Toast): any {
        item?.id || item;
    }
}
