import {
    AbstractControl,
    FormControl,
    ValidationErrors,
    Validator,
} from '@angular/forms';

import { DynamicValidator, Group, Rule } from './dynamic';

export class PatternValidator extends DynamicValidator implements Validator {
    constructor(
        private readonly pattern: string,
        private readonly message?: string,
        rules?: Array<Rule | Group>
    ) {
        super(rules);
    }

    validate(control: AbstractControl): ValidationErrors | null {
        if (control instanceof FormControl) {
            const value: string = control.value;
            const regex = new RegExp(this.pattern);
            if (
                value != null &&
                value.toString().trim() &&
                !regex.test(value)
            ) {
                return {
                    pattern: {
                        pattern: value,
                        message: this.message,
                    },
                };
            }
        }

        return null;
    }
}
