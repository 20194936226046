import { Component, AfterViewInit } from '@angular/core';

import { Observable } from 'rxjs';

import { AuthService } from '@app/core/auth/auth.service';

@Component({
    selector: 'vsc-tenant-switcher',
    templateUrl: './tenant-switcher.component.html',
    styleUrls: ['./tenant-switcher.component.scss'],
})
export class TenantSwitcherComponent implements AfterViewInit {
    availableTenants$: Observable<Array<any>>;
    activeTenant: string;
    activeTenantName$: Observable<string>;

    constructor(private authService: AuthService) {}
    ngAfterViewInit(): void {
        this.activeTenant = this.authService.tenantId;
        this.activeTenantName$ = this.authService.tenantName$;
        this.availableTenants$ = this.authService.availableTenants$;
    }

    change(tenant: string): void {
        this.authService.switchTenant(tenant).then(() => location.reload());
    }
}
