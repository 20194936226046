import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';

import { TranslocoModule } from '@ngneat/transloco';

import { DirectiveModule } from '@shared/directives/directive.module';
import { MaterialModule } from '@shared/material.module';

import { ValidationMessageModule } from '../validation-message/validation-message.module';
import { DateCalendarComponent } from './date-calendar.component';

@NgModule({
    declarations: [DateCalendarComponent],
    imports: [
        CommonModule,
        TranslocoModule,
        FormsModule,
        ValidationMessageModule,
        MaterialModule,
        FlexLayoutModule,
        DirectiveModule,
    ],

    exports: [DateCalendarComponent],
})
export class VscDateCalendarModule {}
