<button
    aria-label="Options Menu"
    mat-button
    [matMenuTriggerFor]="menu"
    class="user-menu"
>
    <vsc-icon color="accent" class="mr-8">circle-user</vsc-icon>
    <span class="user-name" *ngLet="firstName$ | async as firstName">
        {{ firstName || ('Welcome!' | transloco) }}</span
    >
    <vsc-icon color="accent" class="chevron">chevron-down</vsc-icon>
</button>

<mat-menu #menu="matMenu" class="user-menu-content">
    <ng-container *ngIf="(isAdminRoute$ | async) === true; else homeButton">
        <a
            mat-menu-item
            [href]="PagePaths.Public.Home"
            [routerLink]="PagePaths.Public.Home"
        >
            {{ 'Home' | transloco }}
        </a>
    </ng-container>
    <ng-template #homeButton>
        <a
            *ngxPermissionsOnly="Permissions.Administration.View"
            mat-menu-item
            [href]="PagePaths.Admin.Root"
            [routerLink]="PagePaths.Admin.Root"
        >
            {{ 'Administration' | transloco }}
        </a>
    </ng-template>
    <a
        mat-menu-item
        [href]="PagePaths.Public.Profile"
        [routerLink]="PagePaths.Public.Profile"
    >
        {{ 'My Profile' | transloco }}
    </a>
    <button mat-menu-item (click)="logout()">
        {{ 'Logout' | transloco }}
    </button>
</mat-menu>
