import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TranslocoModule } from '@ngneat/transloco';

import { MaterialModule } from '@shared/material.module';

import { VscDateRangeModule } from '../date-range/date-range.module';
import { VscDateModule } from '../date/date.module';
import { VscInputModule } from '../input/input.module';
import { SelectSearchModule } from '../select-search/select-search.module';
import { VscSelectModule } from '../select/select.module';
import { DynamicFieldComponent } from './dynamic-field/dynamic-field.component';
import { DynamicFormComponent } from './dynamic-form/dynamic-form.component';
import { SentenceCasePipe } from './sentence-case.pipe';

@NgModule({
    declarations: [
        DynamicFormComponent,
        DynamicFieldComponent,
        SentenceCasePipe,
    ],
    imports: [
        CommonModule,
        TranslocoModule,
        FormsModule,
        ReactiveFormsModule,
        VscInputModule,
        SelectSearchModule,
        MaterialModule,
        VscSelectModule,
        VscDateModule,
        VscDateRangeModule,
        FlexLayoutModule,
    ],

    exports: [DynamicFormComponent],
})
export class DynamicFormModule {}
