<div class="collection-view-container">
    <div
        class="collection-view-content"
        fxLayout="row wrap"
        fxLayoutGap="16px grid"
    >
        <ng-container *ngFor="let item of data | async">
            <ng-container
                *ngTemplateOutlet="
                    collectionItemTemplateRef;
                    context: { item: item }
                "
            ></ng-container>
        </ng-container>
    </div>
    <div
        class="no-record-found"
        *ngIf="
            dataSource.content == undefined || dataSource.content.length == 0
        "
    >
        <h3>
            {{
                isObservable(noRecordsFoundText)
                    ? (noRecordsFoundText | async)
                    : noRecordsFoundText
            }}
        </h3>
    </div>
    <ng-container *ngIf="dataSource.page$ | async as page">
        <ng-container
            *ngIf="
                !collectionPaginator &&
                !isCarouselPaginator &&
                sqPaginationConfig &&
                sqPaginationConfig.enablePagination
            "
        >
            <mat-paginator
                *ngIf="
                    (page.totalElements < page.size &&
                        sqPaginationConfig.hidePagedIfOnlyOnePage) ||
                    page.totalElements > page.size
                "
                vsc-style-paginator
                [showTotalPages]="10"
                [pageSize]="sqPaginationConfig.pageSize"
                [pageSizeOptions]="sqPaginationConfig.pageSizeOptions"
                [showFirstLastButtons]="sqPaginationConfig.showFirstLastButtons"
                [length]="page.totalElements"
                [pageIndex]="page.number"
                (page)="fetchData($event.pageIndex, $event.pageSize)"
            >
            </mat-paginator>
        </ng-container>

        <ng-container
            *ngIf="
                !collectionPaginator &&
                isCarouselPaginator &&
                sqPaginationConfig &&
                sqPaginationConfig.enablePagination
            "
        >
            <div fxLayout="row" fxLayoutAlign="space-between center">
                <div
                    fxFlex="100"
                    fxLayoutAlign="space-between center"
                    *ngIf="isCarouselPaginator"
                    class="carousel-paginator-container"
                >
                    <mat-paginator
                        *ngIf="page.totalElements > 12"
                        #matPaginator
                        [pageIndex]="page.number"
                        [pageSize]="sqPaginationConfig.pageSize"
                        [length]="page.totalElements"
                        (page)="fetchData($event.pageIndex, $event.pageSize)"
                    >
                    </mat-paginator>
                </div>
            </div>
        </ng-container>
    </ng-container>
</div>
