import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    HostBinding,
    Input,
    OnInit,
    Renderer2,
} from '@angular/core';
import { CanColor, mixinColor, ThemePalette } from '@angular/material/core';

const _IconBase = mixinColor(
    class {
        constructor(public _elementRef: ElementRef) {}
    }
);

@Component({
    selector: 'vsc-icon',
    template: `<ng-content></ng-content>`,
    styleUrls: ['./icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconComponent extends _IconBase implements OnInit, CanColor {
    private _icon: string;
    @Input() set icon(value: string) {
        const hasIconSet = !!this._icon;
        if (value) {
            this._icon = `fa-${value}`;
        }

        if (hasIconSet) {
            // Process and create icon only if icon is rendered already.
            // Case -> Icon change.
            this.processAndCreateIcon();
        }
    }

    private _style: IconStyle = 'fa-regular';
    @Input() set iconStyle(value: JustStyle) {
        const hasStyleSet = !!this._style;
        if (value) {
            this._style = `fa-${value}`;
        }

        if (hasStyleSet && this._icon) {
            // Process and create icon only if icon is rendered already
            // Case -> Icon change.
            this.processAndCreateIcon();
        }
    }

    @Input() color: ThemePalette;

    classes: string;

    @HostBinding('role') role = 'img';
    @HostBinding('attr.aria-hidden') ariaHidden = 'true';
    @HostBinding('class') class = `vsc-icon mat-icon${
        !this.color && ' default-color'
    }`;

    constructor(private elementRef: ElementRef, private renderer: Renderer2) {
        super(elementRef);
    }

    ngOnInit(): void {
        const element = this.elementRef.nativeElement as HTMLElement;
        if (!this._icon) {
            this.icon = element.innerHTML?.trim();
        }

        this.processAndCreateIcon();
    }

    private processAndCreateIcon(): void {
        const element = this.elementRef.nativeElement as HTMLElement;
        const content = element.innerHTML;

        const contentSpan = this.renderer.createElement('span');
        contentSpan.innerHTML = content;

        this.renderer.setProperty(element, 'innerHTML', '');
        this.renderer.appendChild(element, contentSpan);

        if (this._icon) {
            const iconElement = this.renderer.createElement('i');

            this.renderer.addClass(iconElement, this._style);
            this.renderer.addClass(iconElement, this._icon);
            this.renderer.appendChild(element, iconElement);
        }
    }
}

type IconStyle = `fa-${JustStyle}`;

type JustStyle = 'regular' | 'solid' | 'light' | 'duotone' | 'thin';
