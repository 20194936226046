import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';

import { Observable, Subscription } from 'rxjs';

import { Alert } from '../state/alert-state/alert';
import { AlertQuery } from '../state/alert-state/alert.query';

@Component({
    selector: 'vsc-alerts-widget',
    templateUrl: './alerts.component.html',
    styleUrls: ['./alerts.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertsComponent implements OnInit, OnDestroy {
    alert$: Observable<Alert> = this.alertQuery.select('activeAlert');
    isLoading$: Observable<boolean> = this.alertQuery.selectLoading();
    isVisible: boolean = false;
    @Output() alertVisibilityChangeEvent = new EventEmitter<boolean>();

    private subscription: Subscription;

    constructor(private alertQuery: AlertQuery) {}

    ngOnInit(): void {
        this.subscription = this.alert$.subscribe((alert: Alert) => {
            if (alert !== null) {
                this.manageAlertWidgetVisibility(alert.id);
            }
        });
    }

    openPage(link: string): void {
        window.open(link);
    }

    manageAlertWidgetVisibility(id: string): void {
        if (!localStorage.active_alert) {
            this.isVisible = true;
        } else if (
            localStorage.active_alert &&
            localStorage.active_alert !== id
        ) {
            this.isVisible = true;
            localStorage.removeItem('active_alert');
        }

        this.alertVisibilityChangeEvent.emit(this.isVisible);
    }

    closeWidget(id: string): void {
        localStorage.setItem('active_alert', id);
        this.isVisible = false;
        this.alertVisibilityChangeEvent.emit(this.isVisible);
    }

    ngOnDestroy(): void {
        this.subscription?.unsubscribe();
    }
}
