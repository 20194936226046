import { Injectable } from '@angular/core';

import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

import * as moment from 'moment';
import { Moment } from 'moment';

import { PagePaths } from '@shared/constants/page-paths';

import { UserClass } from './class';
import { FilterType } from './filter-type.enum';

export interface ClassesState extends EntityState<UserClass> {
    backUrl: string;
    defaultPage: number;
    dateFilter: Moment;
    filterType: FilterType;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'public-classes', idKey: 'classSessionId' })
export class ClassesStore extends EntityStore<ClassesState, UserClass> {
    static readonly INITIAL_STATE: ClassesState = {
        backUrl: PagePaths.Public.Classes,
        defaultPage: 0,
        dateFilter: moment(),
        search: '',
        filterType: null,
    };

    constructor() {
        super(ClassesStore.INITIAL_STATE);
    }
}
