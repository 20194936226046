import { Injectable } from '@angular/core';

import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

import { HelpPage } from './help-page';

export type HelpPageState = EntityState<HelpPage>;

export const initialState: HelpPageState = {
    loading: false,
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'help-page' })
export class HelpPageStore extends EntityStore<HelpPageState, HelpPage> {
    constructor() {
        super(initialState);
    }
}
