/**
 * Checks if the given value is `undefined` or `null`.
 * @param val The value to check.
 * @returns `true` if the value is `undefined` or `null`, `false` otherwise.
 */
export function isNullOrUndefined(val: any): boolean {
    return val == null && val == undefined;
}

/**
 * Parses dictionary of strings to object.
 * Boolean values are parsed as booleans, otherwise they are parsed as strings.
 * @param dictionary The dictionary to parse.
 * @returns The parsed object.
 */
export function parseDictionaryToObject(dictionary: any): any {
    if (!dictionary) {
        return null;
    }

    const obj = {};
    Object.keys(dictionary).forEach((key: string) => {
        switch (dictionary[key]) {
            case 'true':
                obj[key] = true;
                break;
            case 'false':
                obj[key] = false;
                break;
            default:
                obj[key] = dictionary[key];
        }
    });
    return obj;
}
