import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { MaterialModule } from '@shared/material.module';
import { SharedModule } from '@shared/shared.module';

import { MenuItemComponent } from './menu-item.component';
import { MenuComponent } from './menu.component';

@NgModule({
    declarations: [MenuComponent, MenuItemComponent],
    exports: [MenuComponent, MenuItemComponent],
    imports: [CommonModule, RouterModule, SharedModule, MaterialModule],
})
export class MenuModule {}
