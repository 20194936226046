import { InjectionToken } from '@angular/core';
import { Router } from '@angular/router';

import { take, Observable } from 'rxjs';

import { ChatInviteStatus } from '@app/public/public/chats/chat-state/chat-invite-status.enum';
import { ChatService } from '@app/public/public/chats/services/chat.service';

import { Notification } from '@shared/notifications/models/notification';
import { NotificationAction } from '@shared/notifications/models/notification-action.enum';

import {
    formatNotificationDelegateInjectionToken,
    INotificationDelegate,
} from './notification-action';

export const ChatCreatedNotificationDelegateInjectionToken =
    new InjectionToken<ChatInvitedNotificationDelegate>(
        formatNotificationDelegateInjectionToken(NotificationAction.ChatCreated)
    );

export const ChatUpdatedNotificationDelegateInjectionToken =
    new InjectionToken<ChatInvitedNotificationDelegate>(
        formatNotificationDelegateInjectionToken(NotificationAction.ChatUpdated)
    );

export const NotRSVPdChatNotificationDelegateInjectionToken =
    new InjectionToken<ChatInvitedNotificationDelegate>(
        formatNotificationDelegateInjectionToken(
            NotificationAction.NotRSVPdChat
        )
    );

export const ChatPendingNotificationDelegateInjectionToken =
    new InjectionToken<ChatInvitedNotificationDelegate>(
        formatNotificationDelegateInjectionToken(NotificationAction.ChatPending)
    );

export class ChatInvitedNotificationDelegate implements INotificationDelegate {
    constructor(private router: Router, private chatService: ChatService) {}

    invokeAction(notification: Notification): Observable<any> {
        const entityId = notification?.entityId;
        if (entityId) {
            return this.chatService
                .updateChatInviteStatus(entityId, ChatInviteStatus.Accepted)
                .pipe(take(1));
        }
    }

    rejectAction(notification: Notification): Observable<any> {
        const entityId = notification?.entityId;
        if (entityId) {
            return this.chatService
                .updateChatInviteStatus(entityId, ChatInviteStatus.Declined)
                .pipe(take(1));
        }
    }

    navigateFromNotification(_: Notification): void {}

    navigateFromNotificationBody(
        notification: Notification
    ): void | Observable<any> {
        if (!notification?.metadata) {
            return;
        }

        const chatId: string =
            notification.metadata['chatId'] ||
            notification.metadata['chatEntityId'];

        if (!chatId) {
            return;
        }

        this.chatService.setBackUrl(this.router.url);
        this.router.navigate([`/chats/schedule`]);
    }
}
