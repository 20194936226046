import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatListModule } from '@angular/material/list';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';

import { SharedModule } from '@shared/shared.module';

import { SideMenuItemComponent } from './side-menu-item/side-menu-item.component';
import { SideMenuComponent } from './side-menu.component';

const components = [SideMenuComponent, SideMenuItemComponent];

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        MatListModule,
        MatTooltipModule,
        SharedModule,
    ],
    declarations: [...components],
    exports: [...components],
    entryComponents: [...components],
})
export class SideMenuModule {}
