import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    templateUrl: './privacy-policy.component.html',
    styleUrls: ['./privacy-policy.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrivacyPolicyComponent {
    constructor() {}
}
