<div fxLayout="column" class="dialog-wrapper">
    <div fxFlex="100" fxLayoutAlign="start start" fxLayout="row">
        <h4 mat-dialog-title class="no-margins dialog-title">
            {{
                (isObservable(data.title) ? (data.title | async) : data.title)
                    | transloco
            }}
        </h4>
        <vsc-close-button (click)="onDismiss()"> </vsc-close-button>
    </div>

    <div
        mat-dialog-content
        fxLayout="column"
        fxLayoutGap="24px"
        class="display-content"
    >
        <h2>
            {{
                (isObservable(data.message)
                    ? (data.message | async)
                    : data.message
                ) | transloco
            }}
        </h2>
    </div>
    <div
        fxFlex
        fxLayoutAlign="end center"
        fxLayoutGap="16px"
        class="buttons-wrapper"
    >
        <button
            *ngIf="data.noButtonVisible"
            type="button"
            mat-stroked-button
            (click)="onDismiss()"
            color="primary"
            class="mr-8 dialog-cancel-button border"
        >
            {{
                isObservable(data.no)
                    ? (data.no | async)
                    : (data.no | transloco)
            }}
        </button>
        <button
            (click)="onConfirm()"
            mat-flat-button
            color="primary"
            class="dialog-submit-button"
        >
            {{
                isObservable(data.yes)
                    ? (data.yes | async)
                    : (data.yes | transloco)
            }}
        </button>
    </div>
</div>
