import {
    Component,
    Inject,
    ChangeDetectionStrategy,
    OnDestroy,
    OnInit,
} from '@angular/core';

import * as moment from 'moment';
import {
    Subject,
    Subscription,
    take,
    switchMap,
    of,
    isObservable,
    Observable,
} from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { Constants } from '@shared/constants';
import { Notification } from '@shared/notifications/models/notification';
import { LocalizedDatePipe } from '@shared/pipes/localized-date.pipe';
import { ToastService } from '@shared/toast/services/toast.service';
import { TOAST_DATA } from '@shared/toast/toast';
import { isNullOrUndefined } from '@shared/utils/helpers';

import { INotificationDelegate } from '../delegates/notification-action';
import { NotificationAction } from '../models/notification-action.enum';
import { NotificationDelegateResolver } from '../services/notification-delegate.resolver';
import { NotificationsService } from './../services/notifications.service';

@Component({
    selector: 'vsc-notification-toast',
    templateUrl: './notification-toast.component.html',
    styleUrls: ['./notification-toast.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationToastComponent implements OnDestroy, OnInit {
    NotificationAction: typeof NotificationAction = NotificationAction;
    notification: Notification;
    notificationIds: string[] = [];
    createdAt: string;
    newDateTime: string;
    templateParams: string[];

    notificationDelegate: INotificationDelegate;
    private subscriptions: Subscription[] = [];

    constructor(
        private notificationDelegateResolver: NotificationDelegateResolver,
        private notificationsService: NotificationsService,
        private localizedDatePipe: LocalizedDatePipe,
        private toastService: ToastService,
        @Inject(TOAST_DATA)
        public data: Notification & { toastClicked$: Subject<void> }
    ) {}

    ngOnInit(): void {
        this.notification = this.data as Notification;
        if (this.notification) {
            const notificationActionValue: NotificationAction =
                this.notification.actionType;
            if (!isNullOrUndefined(notificationActionValue)) {
                // Get the notification delegate for the specified action
                this.notificationDelegate =
                    this.notificationDelegateResolver.resolve(
                        notificationActionValue
                    );

                this.createdAt = this.localizedDatePipe.transform(
                    this.notification.createdAt,
                    Constants.FULL_DATE_TIME_FORMAT_US
                );

                this.templateParams = [...this.notification.templateParameters];
                if (this.notification.metadata['newDateTime'] !== undefined) {
                    const constantForTransform: string =
                        this.notification.actionType !==
                        NotificationAction.ProgramStarted
                            ? Constants.FULL_DATE_TIME_FORMAT_US
                            : Constants.TIME_FORMAT_US;
                    this.newDateTime = this.localizedDatePipe.transform(
                        moment(this.notification.metadata['newDateTime']),
                        constantForTransform
                    );

                    this.templateParams.splice(
                        this.templateParams.length - 1,
                        1,
                        this.newDateTime
                    );
                }
            }

            this.subscriptions.push(this.subscribeToToastClick());
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((sub: Subscription) => sub?.unsubscribe());
    }

    markAsRead(): void {
        if (
            this.notification.isRead === false &&
            this.notification?.id !== null
        ) {
            this.notificationIds.push(this.notification?.id);
            this.subscriptions.push(
                this.notificationsService
                    .markNotificationsAsRead(this.notificationIds, 1)
                    .pipe(take(1))
                    .subscribe(() => (this.notificationIds = []))
            );
        }
    }

    onActionInvoked(): void {
        const notificationId: string = this.notification?.id;

        const method: void | Observable<any> =
            this.notificationDelegate?.invokeAction(this.notification);

        if (isObservable(method)) {
            method?.pipe(take(1)).subscribe(() => {
                this.toastService.remove(notificationId);
            });
        } else {
            this.toastService.remove(notificationId);
        }

        this.notificationsService
            .deleteNotification(notificationId)
            .subscribe();

        this.markAsRead();
    }

    onActionRejected(): void {
        const notificationId: string = this.notification?.id;
        const method: void | Observable<any> =
            this.notificationDelegate?.rejectAction(this.notification);

        if (isObservable(method)) {
            method?.pipe(take(1)).subscribe(() => {
                this.toastService.remove(notificationId);
            });
        } else {
            this.toastService.remove(notificationId);
        }

        this.notificationsService
            .deleteNotification(notificationId)
            .subscribe();
        this.markAsRead();
    }

    private subscribeToToastClick(): Subscription {
        if (this.notification && this.notification?.id !== null) {
            this.notificationIds.push(this.notification.id);
        }
        return this.data?.toastClicked$
            ?.pipe(
                tap(() => {
                    this.notificationDelegate?.navigateFromNotification(
                        this.notification
                    );
                    this.toastService.remove(this.notification?.id);
                }),
                switchMap(() =>
                    this.notificationsService
                        .markNotificationsAsRead(this.notificationIds, 1)
                        .pipe(
                            take(1),
                            // Catch error to ensure toast will be closed.
                            catchError(() => of(null))
                        )
                )
            )
            .subscribe();
    }
}
