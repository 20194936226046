<ng-container *ngIf="formReady">
    <form [formGroup]="form" fxLayout="column stretch" fxLayoutAlign="start">
        <ng-container *ngFor="let field of fieldset">
            <vsc-dynamic-field
                [formGroup]="form"
                [field]="field"
            ></vsc-dynamic-field>
        </ng-container>
    </form>
</ng-container>
