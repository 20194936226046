import { Injectable } from '@angular/core';

import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

import { NotificationDeliveryMethod } from './notification-delivery-method';

export type NotificationDeliveryMethodsState =
    EntityState<NotificationDeliveryMethod>;

export const initialState: NotificationDeliveryMethodsState = {
    loading: false,
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'notificationDeliveryMethods' })
export class NotificationDeliveryMethodsStore extends EntityStore<
    NotificationDeliveryMethodsState,
    NotificationDeliveryMethod
> {
    constructor() {
        super(initialState);
    }
}
