import {
    AbstractControl,
    AsyncValidator as AngularAsyncValidator,
    ValidationErrors,
} from '@angular/forms';

import { Observable, Observer, timer } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

import { Group, Rule } from './dynamic';

export class DynamicAsyncValidator implements AngularAsyncValidator {
    constructor(
        private readonly func: (value: string) => Observable<any>,
        rules?: Array<Rule | Group>
    ) {}

    validate(c: AbstractControl): Observable<ValidationErrors | null> {
        if (c.value) {
            return timer(500).pipe(
                switchMap(() => {
                    return this.func
                        .bind(this)(c.value)
                        .pipe(
                            map((result: boolean) => {
                                // error is set in validateControlValue activity
                            })
                        );
                }),
                take(1)
            );
        }

        return new Observable((observer: Observer<any>) => {
            observer.next(undefined);
            observer.complete();
        });
    }
}
