import { InjectionToken, Provider } from '@angular/core';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';

import { ChatService } from '@public/chats/services/chat.service';
import { ClassService } from '@public/classes/services/classes.service';

import { Notification } from '../models/notification';
import { NotificationAction } from '../models/notification-action.enum';
import {
    ChatInvitedNotificationDelegate,
    ChatCreatedNotificationDelegateInjectionToken,
    ChatUpdatedNotificationDelegateInjectionToken,
    NotRSVPdChatNotificationDelegateInjectionToken,
    ChatPendingNotificationDelegateInjectionToken,
} from './chat-invited-notification.delegate';
import {
    ChatAboutToStartNotificationDelegateInjectionToken,
    ChatStartedNotificationDelegate,
    ChatStartedNotificationDelegateInjectionToken,
} from './chat-started-notification.delegate';
import {
    ProgramSessionWatchedNotificationDelegate,
    ProgramSessionWatchedNotificationDelegateInjectionToken,
} from './program-session-watched-notification.delegate';
import {
    ProgramAboutToStartNotificationDelegateInjectionToken,
    ProgramStartedNotificationDelegate,
    ProgramStartedNotificationDelegateInjectionToken,
} from './program-started-notification.delegate';

/**
 * Represents a notification action.
 */
export interface INotificationDelegate {
    invokeAction(notification: Notification): void | Observable<any>;
    rejectAction(notification: Notification): void | Observable<any>;

    /**
     * Navigates to the notification's URL.
     * @param notification The notification to navigate from.
     */
    navigateFromNotification(
        notification: Notification
    ): void | Observable<any>;

    navigateFromNotificationBody(
        notification: Notification
    ): void | Observable<any>;
}

export const NotificationDelegateInjectionTokens: {
    [key: string]: InjectionToken<any>;
} = {
    ProgramSessionWatchedNotificationDelegate:
        ProgramSessionWatchedNotificationDelegateInjectionToken,
    ProgramStartedNotificationDelegate:
        ProgramStartedNotificationDelegateInjectionToken,
    ProgramAboutToStartNotificationDelegate:
        ProgramAboutToStartNotificationDelegateInjectionToken,
    ChatStartedNotificationDelegate:
        ChatStartedNotificationDelegateInjectionToken,
    ChatAboutToStartNotificationDelegate:
        ChatAboutToStartNotificationDelegateInjectionToken,
    ChatCreatedNotificationDelegate:
        ChatCreatedNotificationDelegateInjectionToken,
    ChatUpdatedNotificationDelegate:
        ChatUpdatedNotificationDelegateInjectionToken,
    NotRSVPdChatNotificationDelegate:
        NotRSVPdChatNotificationDelegateInjectionToken,
    ChatPendingNotificationDelegate:
        ChatPendingNotificationDelegateInjectionToken,
};

/**
 * Collection of notification action providers.
 */
export const NOTIFICATION_DELEGATE_PROVIDERS: Provider[] = [
    {
        provide: ProgramSessionWatchedNotificationDelegateInjectionToken,
        useFactory: (classService: ClassService, router: Router) =>
            new ProgramSessionWatchedNotificationDelegate(classService, router),
        deps: [ClassService, Router],
    },
    {
        provide: ProgramStartedNotificationDelegateInjectionToken,
        useFactory: (router: Router, classService: ClassService) =>
            new ProgramStartedNotificationDelegate(router, classService),
        deps: [Router, ClassService],
    },
    {
        provide: ProgramAboutToStartNotificationDelegateInjectionToken,
        useFactory: (router: Router, classService: ClassService) =>
            new ProgramStartedNotificationDelegate(router, classService),
        deps: [Router, ClassService],
    },
    {
        provide: ChatStartedNotificationDelegateInjectionToken,
        useFactory: (router: Router, chatService: ChatService) =>
            new ChatStartedNotificationDelegate(router, chatService),
        deps: [Router],
    },
    {
        provide: ChatAboutToStartNotificationDelegateInjectionToken,
        useFactory: (router: Router, chatService: ChatService) =>
            new ChatStartedNotificationDelegate(router, chatService),
        deps: [Router],
    },
    {
        provide: ChatCreatedNotificationDelegateInjectionToken,
        useFactory: (router: Router, chatService: ChatService) =>
            new ChatInvitedNotificationDelegate(router, chatService),
        deps: [Router, ChatService],
    },
    {
        provide: ChatUpdatedNotificationDelegateInjectionToken,
        useFactory: (router: Router, chatService: ChatService) =>
            new ChatInvitedNotificationDelegate(router, chatService),
        deps: [Router, ChatService],
    },
    {
        provide: NotRSVPdChatNotificationDelegateInjectionToken,
        useFactory: (router: Router, chatService: ChatService) =>
            new ChatInvitedNotificationDelegate(router, chatService),
        deps: [Router, ChatService],
    },
    {
        provide: ChatPendingNotificationDelegateInjectionToken,
        useFactory: (router: Router, chatService: ChatService) =>
            new ChatInvitedNotificationDelegate(router, chatService),
        deps: [Router, ChatService],
    },
];

/**
 * Format `NotificationAction` injection token name.
 * @param action Notification action.
 * @returns Formatted injection token name.
 */
export function formatNotificationDelegateInjectionToken(
    action: NotificationAction
): string {
    return `${NotificationAction[action]}NotificationDelegate`;
}
