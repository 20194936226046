<div fxFlex fxLayout="column">
    <label
        >{{ label | transloco }}
        {{ isRequired(formControl) ? '*' : '' }}
    </label>
    <mat-hint *ngIf="hintPosition === 'between' && hint !== ''">{{
        hint | transloco
    }}</mat-hint>
    <mat-form-field
        appearance="outline"
        floatLabel="never"
        (click)="!inputEnabled && openCalendar()"
    >
        <!-- <div class="input-container"> -->
        <!--           
            <input
                #txtDate
                matInput
                [readonly]="!inputEnabled"
                type="text"
                spellcheck="false"
                [placeholder]="InputMask.Date"
                [value]="dateValue"
                [disabled]="readonly"
                [attr.aria-label]="'Date' | transloco"
                (blur)="onBlur(formControl)"
                (change)="__valueChanged(txtDate.value)"
                color="accent"
                vscDateMask
            />
            <div *ngIf="!inputEnabled" class="overlay"></div>
        </div> -->
        <!-- <input
            #matDateInput
            aria-label="Date picker"
            [value]="pickerDateValue"
            [matDatepicker]="picker"
            [max]="this.maxDate"
            [min]="this.minDate"
            [hidden]="true"
            (dateChange)="__valueChanged(matDateInput.value)"
        />
        <mat-datepicker-toggle
            matSuffix
            [for]="picker"
            [hidden]="!this.showDatepicker || readonly"
        ></mat-datepicker-toggle>
        <mat-datepicker
            #picker
            [startView]="this.datePickerStartView"
            [startAt]="this.startAtDate"
            
        ></mat-datepicker> -->

        <mat-date-range-input [disabled]="readonly" [rangePicker]="picker">
            <input
                matStartDate
                vscDateMask
                [max]="this.maxDate"
                [min]="this.minDate"
                [value]="dateValue?.start"
                (dateChange)="__valueChanged($event)"
                placeholder="Start date"
            />
            <input
                matEndDate
                vscDateMask
                [max]="this.maxDate"
                [min]="this.minDate"
                [value]="dateValue?.end"
                (dateChange)="__valueChanged($event)"
                placeholder="End date"
            />
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker
            [startAt]="this.startAtDate"
            #picker
        ></mat-date-range-picker>
    </mat-form-field>
    <mat-hint *ngIf="hintPosition === 'end' && hint !== ''">{{
        hint | transloco
    }}</mat-hint>
</div>
