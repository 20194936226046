<ng-container
    [formGroup]="formGroup"
    *ngIf="field.visible !== false"
    [ngSwitch]="field.type"
>
    <!-- CHECKBOX -->
    <div *ngSwitchCase="0" class="checkbox-view">
        <mat-checkbox [formControl]="control" color="primary">
            {{ field.label | transloco }}
        </mat-checkbox>
    </div>

    <!-- DATEPICKER -->
    <mat-list-item *ngSwitchCase="1">
        <vsc-date
            [formControlName]="field.name"
            [label]="field.label"
            hintPosition="between"
        >
        </vsc-date>
    </mat-list-item>

    <!-- SINGLE RADIO -->
    <mat-radio-group *ngSwitchCase="2" [formControl]="control">
        <mat-radio-button
            *ngFor="
                let option of isObservable(field.options)
                    ? (field.options | async)
                    : field.options
            "
            [value]="option.id"
            color="primary"
        >
            {{ option.name | transloco }}
        </mat-radio-button>
    </mat-radio-group>

    <!-- SELECT DROPDOWN -->
    <mat-list-item *ngSwitchCase="3">
        <vsc-select
            [label]="field.labelVisible === false ? '' : field.label"
            [options]="
                isObservable(field.options)
                    ? (field.options | async)
                    : field.options
            "
            [placeholder]="field.name | sentenceCase"
            [formControlName]="field.name"
            [isTranslatable]="field.isTranslatable"
        >
        </vsc-select>
    </mat-list-item>

    <!-- SELECT LIST -->
    <mat-list-item *ngSwitchCase="4">
        <vsc-select-search
            [label]="field.labelVisible === false ? '' : field.label"
            [searchable]="true"
            [multiple]="field.config?.multiple ?? true"
            [options]="
                isObservable(field.options)
                    ? (field.options | async)
                    : field.options
            "
            [placeholder]="field.name | sentenceCase"
            [formControlName]="field.name"
            [isTranslatable]="field.isTranslatable"
        ></vsc-select-search>
    </mat-list-item>

    <!-- SLIDE TOGGLE -->
    <ng-container *ngSwitchCase="5">
        <!-- <mat-list-item > -->
        <mat-slide-toggle
            matLine
            [formControl]="control"
            color="primary"
            class="checkbox-view"
        >
            {{ field.name | transloco }}
        </mat-slide-toggle>
        <!-- </mat-list-item> -->

        <div matLine *ngIf="field.children" style="padding-top: 7px">
            <ng-container *ngFor="let child of field.children">
                <vsc-dynamic-field
                    [field]="child"
                    class="toggleChild"
                ></vsc-dynamic-field>
            </ng-container>
        </div>
    </ng-container>

    <!-- TEXTAREA -->
    <mat-list-item *ngSwitchCase="6">
        <mat-form-field appearance="outline" floatLabel="never">
            <textarea
                matInput
                type="text"
                [formControl]="control"
                [placeholder]="field.label | transloco"
            ></textarea>
        </mat-form-field>
    </mat-list-item>

    <!-- TEXTFIELD -->
    <mat-list-item *ngSwitchCase="7">
        <vsc-input
            [label]="field.labelVisible === false ? '' : field.label"
            [formControlName]="field.name"
            [placeholder]="field.label"
        ></vsc-input>
    </mat-list-item>

    <!-- SUBHEADER -->
    <div *ngSwitchCase="8" class="subheader">
        {{ field.name | transloco | sentenceCase }}
    </div>

    <!-- DIVIDER -->
    <ng-container *ngSwitchCase="9">
        <hr />
    </ng-container>

    <!-- DATE RANGE PICKER -->
    <mat-list-item *ngSwitchCase="10">
        <vsc-date-range
            [label]="field.labelVisible === false ? '' : field.label"
            [formControlName]="field.name"
        >
        </vsc-date-range>
    </mat-list-item>

    <mat-list-item *ngSwitchDefault>no field found</mat-list-item>
</ng-container>
