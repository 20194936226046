import {
    ChangeDetectionStrategy,
    Component,
    OnDestroy,
    OnInit,
} from '@angular/core';

import { ID } from '@datorama/akita';

import * as moment from 'moment';
import {
    BehaviorSubject,
    combineLatest,
    Observable,
    of,
    Subscription,
    timer,
} from 'rxjs';
import { map, take } from 'rxjs/operators';

import { Permissions } from '@core/auth/permissions';

import { PageRequest } from '@shared/collection-view/page';
import { Constants } from '@shared/constants';
import { PagePaths } from '@shared/constants/page-paths';
import { PermissionAuthorize } from '@shared/decorators/method/method-decorators';

import { Chat } from '@public/chats/chat-state/chat';
import { ChatInviteStatus } from '@public/chats/chat-state/chat-invite-status.enum';
import { ChatsQuery } from '@public/chats/chat-state/chats.query';
import { ChatsState, ChatsStore } from '@public/chats/chat-state/chats.store';
import { ChatService } from '@public/chats/services/chat.service';
import { UserClass } from '@public/classes/class-state/class';
import { TodaysClassesQuery } from '@public/classes/class-state/todays-classes.query';
import { ClassService } from '@public/classes/services/classes.service';

import { TodaysEvent } from './state/todays-event';

@Component({
    selector: 'vsc-todays-schedule',
    templateUrl: './todays-schedule.component.html',
    styleUrls: ['./todays-schedule.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TodaysScheduleComponent implements OnInit, OnDestroy {
    UserClass: any = UserClass;
    Chat: any = Chat;

    classesPath: string = PagePaths.Public.Classes;

    todaysEvents$: Observable<TodaysEvent[]>;
    isLoading$: Observable<boolean> = combineLatest([
        this.chatsQuery.selectLoading(),
        this.todaysClassesQuery.selectLoading(),
    ]).pipe(
        map(
            ([isLoadingClasses, isLoadingChats]: [boolean, boolean]) =>
                isLoadingChats || isLoadingClasses
        )
    );

    private pageNumber = 0;

    private readonly pageSize: number = 6;

    // * store if there are more chats to fetch
    private moreChatsToFetchSource = new BehaviorSubject<boolean>(true);

    // * store if there are more classes to fetch
    private moreTodayClassesToFetchSource = new BehaviorSubject<boolean>(true);

    moreToFetch: Observable<boolean> = combineLatest([
        this.moreChatsToFetchSource.asObservable(),
        this.moreTodayClassesToFetchSource.asObservable(),
    ]).pipe(
        map(
            ([moreChatsToFetch, moreTodayClassesToFetch]: [boolean, boolean]) =>
                moreChatsToFetch || moreTodayClassesToFetch
        )
    );

    private subscriptions: Subscription[] = [];

    constructor(
        private todaysClassesQuery: TodaysClassesQuery,
        private chatsQuery: ChatsQuery,
        private classService: ClassService,
        private chatService: ChatService
    ) {}

    ngOnInit(): void {
        this.subscriptions.push(
            timer(0, Constants.TODAYS_SCHEDULE_REFRESH_INTERVAL_MS)
                .pipe(
                    map(() => {
                        this.pageNumber = 0;
                        this.todaysEvents$ = this.getTodaysEvents();
                        this.fetchTodaysClasses();
                        this.fetchTodaysChats();
                    })
                )
                .subscribe()
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((sub: Subscription) => sub?.unsubscribe());
    }

    asObservable(userClass: UserClass): Observable<UserClass> {
        return of(userClass);
    }

    isChat(todaysEvent: TodaysEvent): boolean {
        return todaysEvent.event instanceof Chat;
    }

    isUserClass(todaysEvent: TodaysEvent): boolean {
        return todaysEvent.event instanceof UserClass;
    }

    __navigatedFromCard(backUrl: string): void {
        this.classService.setBackUrl(backUrl);
    }

    __addedClassToFavorite(classId: ID): void {
        this.classService
            .addToFavorite(classId?.toString())
            .pipe(take(1))
            .subscribe();
    }

    __removedClassFromFavorite(classId: ID): void {
        this.classService
            .removeFromFavorite(classId?.toString())
            .pipe(take(1))
            .subscribe();
    }

    private getTodaysEvents(): Observable<TodaysEvent[]> {
        return combineLatest([
            this.todaysClassesQuery.todaysClasses$,
            this.chatsQuery.todaysChats$,
        ]).pipe(
            map(([userClasses, chats]: [UserClass[], Chat[]]) => {
                const classesEvents = userClasses.map(
                    (userClass: UserClass) =>
                        ({
                            id: userClass.id,
                            event: Object.assign(new UserClass(), userClass),
                            startDateTime:
                                userClass?.classSession?.startDateTime,
                        } as TodaysEvent)
                );

                const chatsEvents = chats.map(
                    (chat: Chat) =>
                        ({
                            id: chat.id,
                            event: Object.assign(new Chat(), chat),
                            startDateTime: chat?.startDateTime,
                        } as TodaysEvent)
                );
                const events = [...classesEvents, ...chatsEvents].filter(
                    (element, index, array) => {
                        return (
                            array.findIndex(
                                (item) => item.id === element.id
                            ) === index
                        );
                    }
                );

                events.sort(
                    (a: TodaysEvent, b: TodaysEvent) =>
                        a.startDateTime.valueOf() - b.startDateTime.valueOf()
                );

                return events;
            })
        );
    }

    @PermissionAuthorize(Permissions.Classes.View)
    private fetchTodaysClasses(): void {
        const request: PageRequest<UserClass> = {
            page: this.pageNumber,
            size: this.pageSize,
        } as PageRequest<UserClass>;

        this.subscriptions.push(
            this.classService.getTodaysClasses(request).subscribe({
                next: (todayClasses) => {
                    // * increment the page number only in one place / could have chosen to do in on the chats method
                    this.pageNumber++;

                    // * if the number of classes returned is different than the page size, then there are no more classes to fetch
                    this.moreTodayClassesToFetchSource.next(
                        todayClasses.length === this.pageSize
                    );
                },
            })
        );
    }

    @PermissionAuthorize(Permissions.Chat.View)
    private fetchTodaysChats(): void {
        const request: PageRequest<Chat> = {
            page: this.pageNumber,
            size: this.pageSize,
        } as PageRequest<Chat>;

        this.subscriptions.push(
            this.chatService
                .getUserChats(
                    {
                        fromDate: moment(),
                        toDate: moment().addDays(1).setMidnightTime(),
                        inviteStatus: ChatInviteStatus.Accepted,
                        sortBy: Constants.START_DATE_TIME_PROPERTY,
                    },
                    (chats: Chat[], store: ChatsStore) => {
                        store.update((state: ChatsState) => ({
                            ...state,
                            todaysChats: [
                                ...state.todaysChats,
                                ...chats,
                            ].filter((element, index, array) => {
                                return (
                                    array.findIndex(
                                        (chat) => chat.id === element.id
                                    ) === index
                                );
                            }),
                        }));
                    },
                    request
                )
                .subscribe((chats) => {
                    // * if the number of chats returned is different than the page size, then there are no more classes to fetch
                    this.moreChatsToFetchSource.next(
                        chats.totalCount === this.pageSize
                    );
                })
        );
    }

    /**
     *
     * @param index Index of item, used for trackingBy signature
     * @param userClass UserClass object
     * @returns ID of the UserClass
     */
    userClassTrackBy(index: number, userClass: UserClass): ID {
        return userClass?.id;
    }

    onScrollDown(): void {
        this.fetchTodaysChats();
        this.fetchTodaysClasses();
    }
}
