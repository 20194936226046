import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';

import { TranslocoModule } from '@ngneat/transloco';

import { DirectiveModule } from '@shared/directives/directive.module';
import { MaterialModule } from '@shared/material.module';

import { FooterComponent } from './footer/footer.component';
import { LogoComponent } from './logo/logo.component';

@NgModule({
    imports: [
        TranslocoModule,
        MaterialModule,
        FlexLayoutModule,
        CommonModule,
        RouterModule,
        DirectiveModule,
    ],
    declarations: [FooterComponent, LogoComponent],
    exports: [FooterComponent, LogoComponent],
})
export class LayoutModule {}
