import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { environment } from '@environment/environment';

import { PagedEntities } from '@shared/models/paged-entities';
import { PageRequest } from '@shared/table/page';

import { Role } from '../state/role';
import { RoleQuery } from './roles.service';

@Injectable({ providedIn: 'root' })
export class RoleDataService {
    constructor(private httpClient: HttpClient) {}

    getRoles(
        request: PageRequest<Role>,
        query: RoleQuery
    ): Observable<PagedEntities<Role>> {
        const params = new HttpParams({
            fromObject: {
                search: query.search ?? '',
                page: request.page.toString(),
                pageSize: request.size.toString(),
                sortBy: request.sort.property,
                sortDirection: request.sort.order,
            },
        });

        return this.httpClient.get<PagedEntities<Role>>(
            environment.apiUrl + '/vsc/roles',
            {
                params,
            }
        );
    }

    getAllRoles(): Observable<PagedEntities<Role>> {
        const params = new HttpParams({
            fromObject: {
                name: '',
                page: '0',
                pageSize: '1000',
                sortBy: '',
                sortDirection: '',
            },
        });

        return this.httpClient.get<PagedEntities<Role>>(
            environment.apiUrl + '/vsc/roles',
            {
                params,
            }
        );
    }

    getRestrictedRoles(): Observable<Array<Role>> {
        return this.httpClient.get<Array<Role>>(
            environment.apiUrl + '/vsc/roles/me'
        );
    }
}
