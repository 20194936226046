import { AbstractControl, ValidationErrors, Validator } from '@angular/forms';

import * as moment from 'moment';

import { DynamicValidator, Group, Rule } from './dynamic';

export class MinDateValidator extends DynamicValidator implements Validator {
    constructor(
        private readonly minDate: moment.Moment,
        rules?: Array<Rule | Group>
    ) {
        super(rules);
    }

    validate(control: AbstractControl): ValidationErrors | null {
        return super.validateControl(control, () => {
            const dateToValidate = moment(control.value);
            if (
                !dateToValidate.isValid() ||
                dateToValidate.isSameOrAfter(this.minDate)
            ) {
                return null;
            }

            return {
                minDate: {
                    currentDate: control.value,
                    minDate: this.minDate,
                },
            };
        });
    }
}
