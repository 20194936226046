import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';

import { TranslocoModule } from '@ngneat/transloco';

import { IconModule } from '@shared/icon/icon.module';

import { BackButtonComponent } from './back-button.component';

@NgModule({
    imports: [
        CommonModule,
        IconModule,
        TranslocoModule,
        RouterModule,
        FlexLayoutModule,
        MatButtonModule,
    ],
    declarations: [BackButtonComponent],
    exports: [BackButtonComponent],
})
export class BackButtonModule {}
