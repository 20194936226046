<div
    class="notifications-panel-wrapper fade-in"
    (click)="$event.stopPropagation()"
>
    <div class="notifications-panel-title">
        {{ 'Notifications' | transloco }}
        <a (click)="close()">
            <vsc-icon class="close-panel-icon x-mark-icon"
                >circle-xmark</vsc-icon
            >
        </a>
    </div>
    <div
        class="vsc-card vsc-scrollbar notifications-panel-container"
        fxLayout="column"
    >
        <ng-container *ngIf="notifications$ | async as notifications">
            <ng-container *ngIf="notifications?.length; else noNotifications">
                <ng-container
                    *ngFor="
                        let notification of notifications;
                        trackBy: trackByFn
                    "
                >
                    <vsc-notification-item
                        [item]="notification"
                    ></vsc-notification-item>
                </ng-container>
                <div class="panel-links">
                    <a (click)="markAllAsRead()" class="mark-all-link">
                        <vsc-icon
                            [iconStyle]="'solid'"
                            [color]="'accent'"
                            class="vsc-icon flag-icon"
                            >flag</vsc-icon
                        >
                        {{ 'Mark All As Read' | transloco }}
                    </a>
                    <a (click)="redirectAndClose()"
                        >{{ 'View All Notifications' | transloco }}
                        <vsc-icon
                            [iconStyle]="'solid'"
                            [color]="'accent'"
                            class="vsc-icon"
                            >caret-right</vsc-icon
                        >
                    </a>
                </div>
            </ng-container>
            <ng-template #noNotifications>
                <div
                    class="no-items"
                    fxLayoutAlign="center center"
                    fxLayout="column"
                >
                    <ng-container>
                        <h3>
                            {{ 'There are no notifications' | transloco }}
                        </h3>
                    </ng-container>
                </div>
            </ng-template>
        </ng-container>
    </div>
</div>
