import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

import { Observable } from 'rxjs';

import { OrganizationSettings } from '@app/core/auth/organization-settings';
import { OrganizationSettingsService } from '@app/core/auth/organization-settings.service';
import { AuthService } from '@core/auth/auth.service';

import { OrganizationService } from '@admin/organizations/services/organization.service';

@Component({
    selector: 'vsc-logo',
    templateUrl: './logo.component.html',
    styleUrls: ['./logo.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogoComponent {
    organizationLogoUrl$: Observable<string>;
    tenant$: Observable<string>;
    logoHeight$ = this.organizationSettingsService.selectSetting(
        OrganizationSettings.LogoHeight
    );

    @Input() link: string;

    constructor(
        authService: AuthService,
        organizationService: OrganizationService,
        private organizationSettingsService: OrganizationSettingsService
    ) {
        this.tenant$ = authService.tenantName$;
        this.organizationLogoUrl$ = organizationService.organizationLogoUrl$;
    }
}
