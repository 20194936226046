import {
    Component,
    EventEmitter,
    forwardRef,
    Output,
    Injectable,
} from '@angular/core';
import {
    ControlValueAccessor as AngularControlValueAccessor,
    FormControl,
    NG_VALUE_ACCESSOR,
} from '@angular/forms';

@Injectable()
export class ControlValueAccessor<T> implements AngularControlValueAccessor {
    get value(): T {
        return this.valueData;
    }

    set value(val: T) {
        if (val !== this.valueData) {
            this.valueData = val;
            this.onChangeCallback(val);
        }
    }
    private valueData: T;
    @Output() readonly valueChanged: EventEmitter<T> = new EventEmitter<T>();
    onChangeCallback: (value: T) => void = () => {};
    onTouchedCallback: () => void = () => {};

    /**
     * Write a new value to the element.
     */
    writeValue(obj: T): void {
        if (obj !== this.valueData) {
            this.valueData = obj;
            this.onChangeCallback(this.valueData);
        }
    }
    /**
     * Set the function to be called when the control receives a change event.
     */
    registerOnChange(fn: any): void {
        this.onChangeCallback = fn;
    }
    /**
     * Set the function to be called when the control receives a touch event.
     */
    registerOnTouched(fn: any): void {
        this.onTouchedCallback = fn;
    }

    onBlur(formControl?: FormControl): void {
        if (formControl) {
            formControl.markAsTouched();
            if (typeof this.valueData === 'string') {
                let valueDataTrimmed = this.valueData.trim() as any;
                valueDataTrimmed = !valueDataTrimmed
                    ? null
                    : (valueDataTrimmed as T);
                this.writeValue(valueDataTrimmed);
            }
        }

        this.onTouchedCallback();
    }

    isRequired(formControl: any): boolean {
        if (formControl) {
            if (formControl.required) {
                return true;
            }
            return false;
        }
    }
}

export function controlValueAccessorProvider(component: Component): any {
    return {
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => component),
        multi: true,
    };
}
