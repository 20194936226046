import {
    Component,
    DoCheck,
    ElementRef,
    Input,
    OnDestroy,
    OnInit,
    Renderer2,
    ViewChild,
} from '@angular/core';
import { FormControl, FormGroupDirective, ValidatorFn } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatInput } from '@angular/material/input';

import * as moment from 'moment';
import { Subscription } from 'rxjs';

import { Constants } from '@shared/constants';

import {
    ControlValueAccessor,
    controlValueAccessorProvider,
} from '../control-value-accessor';
import { InputMask } from '../input';
import { Validators } from '../validators/validators';

type NewType = MatInput;
type Moment = moment.Moment;

@Component({
    selector: 'vsc-date',
    templateUrl: './date.component.html',
    styleUrls: ['./date.component.scss'],
    providers: [controlValueAccessorProvider(DateComponent as Component)],
})
export class DateComponent
    extends ControlValueAccessor<Moment>
    implements OnInit, OnDestroy, DoCheck
{
    @Input() formControlName: string;
    @Input() label: string;
    @Input() readonly: boolean = false;
    @Input() minDate: Moment;
    @Input() maxDate: Moment;
    @Input() datePickerStartView: 'month' | 'year' | 'multi-year' = 'month';
    @Input() startAtDate: Moment;
    @Input() showDatepicker: boolean = true;
    @Input() hint: string = '';
    @Input() hintPosition: 'between' | 'end' = 'end';
    @Input() inputEnabled: boolean = true;
    @Input() isUtc: boolean = false;

    dateValue: string;
    formControl: FormControl;
    pickerDateValue: Moment;
    isRequiredFormControl: boolean = false;
    isDirty: boolean = false;
    subscriptions: Subscription[] = [];
    @ViewChild(MatInput, { static: false }) private readonly matInput: NewType;
    @ViewChild('picker') private readonly matDatepicker: MatDatepicker<any>;
    @ViewChild('txtDate') private readonly inputElementRef: ElementRef;

    InputMask: any = InputMask;

    constructor(
        private formGroupDirective: FormGroupDirective,
        private errorStateMatcher: ErrorStateMatcher,
        private renderer: Renderer2
    ) {
        super();
    }

    ngOnInit(): void {
        this.formControl = this.formGroupDirective.control.get(
            this.formControlName
        ) as FormControl;
        if (this.matInput) {
            this.matInput.ngControl = null;
        }

        const validatorFns: ValidatorFn[] = [];

        if (this.formControl.validator) {
            const validator: any =
                this.formControl.validator &&
                this.formControl.validator(this.formControl);
            this.isRequiredFormControl =
                validator && validator.required && validator.required.required;
            validatorFns.push(this.formControl.validator);
        }

        validatorFns.push(Validators.validDate());

        if (this.maxDate) {
            validatorFns.push(Validators.maxDate(this.maxDate));
        }

        if (this.minDate) {
            validatorFns.push(Validators.minDate(this.minDate));
        }

        this.formControl.setValidators(validatorFns);
        this.formControl.updateValueAndValidity();

        this.subscriptions.concat(
            this.formControl.valueChanges.subscribe(() => {
                this.inputElementRef?.nativeElement?.dispatchEvent(
                    new CustomEvent('patchValue')
                );
            }),
            this.formControl.statusChanges.subscribe(() => {
                if (this.isDirty && this.formGroupDirective.pristine) {
                    this.resetDateInput();
                }
            })
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((sub) => sub.unsubscribe());
    }

    ngDoCheck(): void {
        if (this.matInput) {
            this.matInput.errorState = this.errorStateMatcher.isErrorState(
                this.formControl,
                this.formGroupDirective
            );
            this.matInput.stateChanges.next();
        }
    }

    writeValue(value: Moment): void {
        if (this.value !== value) {
            if (value) {
                this.value = value;
                this.pickerDateValue = value.isValid() ? value : null;
                this.dateValue = value.toDateString();
            } else {
                this.value = null;
                this.pickerDateValue = null;
                this.dateValue = InputMask.Date;
            }
        }
    }

    __valueChanged(date: string): void {
        this.isDirty = true;
        let parsedDate: Moment = null;
        const invalidFormat = this.inputElementRef.nativeElement
            .invalidFormat as boolean;

        if (date === InputMask.Date) {
            date = null;
        } else if (date.match(/[mdy]+/g) || invalidFormat) {
            date = Constants.INVALID_DATE;
        }
        if (date?.length) {
            parsedDate = this.isUtc
                ? moment.utc(date, Constants.DATE_FORMAT_US)
                : moment(date, Constants.DATE_FORMAT_US);
        }

        this.formControl.markAsTouched();

        if (date !== this.dateValue || !date) {
            this.formGroupDirective.control
                .get(this.formControlName)
                .patchValue(parsedDate);

            this.valueChanged.emit(parsedDate);
        }
    }

    resetDateInput() {
        this.isDirty = false;
        this.renderer.setProperty(
            this.inputElementRef.nativeElement,
            'value',
            null
        );
        this.renderer.setProperty(
            this.inputElementRef.nativeElement,
            Constants.IS_RESET_FORM_ATTR,
            true
        );
        this.inputElementRef.nativeElement?.dispatchEvent(
            new CustomEvent('blur')
        );
    }

    openCalendar(): void {
        !this.readonly && this.matDatepicker.open();
    }
}
