import { Injectable, Injector } from '@angular/core';

import { isNullOrUndefined } from '@shared/utils/helpers';

import {
    INotificationDelegate,
    NotificationDelegateInjectionTokens,
    formatNotificationDelegateInjectionToken,
} from '../delegates/notification-action';
import { NotificationAction } from '../models/notification-action.enum';

@Injectable({
    providedIn: 'root',
})
export class NotificationDelegateResolver {
    private readonly NOT_FOUND_VALUE: any = {};

    constructor(private injector: Injector) {}

    resolve(action: NotificationAction): INotificationDelegate {
        if (!isNullOrUndefined(action)) {
            return this.injector.get<INotificationDelegate>(
                NotificationDelegateInjectionTokens[
                    formatNotificationDelegateInjectionToken(action)
                ],
                this.NOT_FOUND_VALUE
            );
        }
    }
}
