import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { ID, setLoading } from '@datorama/akita';
import { TranslocoService } from '@ngneat/transloco';

import * as moment from 'moment';
import { EMPTY, Observable, of, Subject, Subscription } from 'rxjs';
import { catchError, filter, map, tap } from 'rxjs/operators';

import { AuthService } from '@core/auth/auth.service';
import { Permissions } from '@core/auth/permissions';

import { PageRequest } from '@shared/collection-view/page';
import { Constants } from '@shared/constants';
import { PermissionAuthorize } from '@shared/decorators/method/method-decorators';
import { SingleItem } from '@shared/forms/items/item-state/single-item';
import { PagedEntities } from '@shared/models/paged-entities';
import { SnackBarService } from '@shared/snack-bar/snack-bar.service';

import { Chat, ParticipantInfo } from '../chat-state/chat';
import { UserChatVisibilityStatusModel } from '../chat-state/user-chat-visibility-status';
import { ChatInviteStatus } from './../chat-state/chat-invite-status.enum';
import { ChatsQuery } from './../chat-state/chats.query';
import { ChatsStore } from './../chat-state/chats.store';
import { ChatDataService } from './chat.dataservice';
import { UserInvitedChatsQueryParams } from './query-params/user-chat-invited.queryparams';
import { UserChatsQueryParams } from './query-params/user-chats.queryparams';

type Moment = moment.Moment;
@Injectable({
    providedIn: 'root',
})
export class ChatService {
    /**
     * Subject used to inform whether the scheduled fetching of pending user chats should be started or stopped.
       It is currently used in the public component, in case the user navigates from and to the chats schedule
       page, in order to start / stop the fetching of pending chats to display the number of chat invites.
     */
    private readonly pendingChatsFetchingTrigger$: Subject<boolean> =
        new Subject();

    constructor(
        private chatsStore: ChatsStore,
        private chatDataService: ChatDataService,
        private snackBar: SnackBarService,
        private chatsQuery: ChatsQuery,
        private router: Router,
        private translocoService: TranslocoService,
        private authService: AuthService
    ) {
        this.pendingChatsFetchingTrigger$ = new Subject();
    }

    getById(id: string): Observable<Chat> {
        return this.chatDataService.getById(id).pipe(
            map((chatEntity: Chat) => {
                this.chatsStore.upsert(chatEntity.id, chatEntity);

                return {
                    ...chatEntity,
                    startDateTime: chatEntity?.startDateTime
                        ? moment(chatEntity.startDateTime)
                        : chatEntity.startDateTime,
                };
            }),
            catchError(() => {
                this.snackBar.open('Failed to load Chat.');
                return EMPTY;
            })
        );
    }

    getAllUserChats(
        queryParams: UserChatsQueryParams,
        isStoreUpdateEnabled: boolean = true
    ): Observable<PagedEntities<Chat>> {
        return this.chatDataService.getUserChats(queryParams).pipe(
            filter(Boolean),
            map((pagedEntities: PagedEntities<Chat>) => ({
                ...pagedEntities,
                entities: pagedEntities.entities.map((chat: Chat) => ({
                    ...chat,
                    startDateTime: chat?.startDateTime
                        ? moment(chat.startDateTime)
                        : null,
                })),
            })),
            tap(
                (pagedEntities: PagedEntities<Chat>) =>
                    isStoreUpdateEnabled &&
                    this.chatsStore.upsertMany(pagedEntities?.entities)
            ),
            setLoading(this.chatsStore),
            catchError(() => {
                this.snackBar.open('Failed to load Chats');
                return EMPTY;
            })
        );
    }

    getUserChats(
        queryParams: UserChatsQueryParams,
        updateStoreFn?: (chats: Chat[], store: ChatsStore) => void,
        pageRequest?: PageRequest<Chat>
    ): Observable<PagedEntities<Chat>> {
        return this.chatDataService.getUserChats(queryParams, pageRequest).pipe(
            filter(Boolean),
            map((pagedEntities: PagedEntities<Chat>) => ({
                ...pagedEntities,
                entities: pagedEntities.entities.map((chat: Chat) => ({
                    ...chat,
                    startDateTime: chat?.startDateTime
                        ? moment(chat.startDateTime)
                        : null,
                    isAccepted: false,
                })),
            })),
            tap((pagedEntities: PagedEntities<Chat>) => {
                if (updateStoreFn) {
                    updateStoreFn(pagedEntities.entities, this.chatsStore);
                } else {
                    this.chatsStore.set(pagedEntities.entities);
                }
            }),
            setLoading(this.chatsStore),
            catchError(() => {
                this.snackBar.open('Failed to load Chats');
                return EMPTY;
            })
        );
    }

    getUsersVisibleForChat(
        search: string,
        exceptUsers: string[]
    ): Observable<SingleItem[]> {
        return this.chatDataService
            .getUsersVisibleForChat(search, exceptUsers)
            .pipe(
                map((pagedEntities: PagedEntities<ParticipantInfo>) =>
                    pagedEntities?.entities?.map(
                        (item: ParticipantInfo) => new SingleItem(item)
                    )
                ),
                catchError(() => {
                    this.snackBar.open('Failed to load Users.');
                    return EMPTY;
                })
            );
    }

    getUserChatVisibilityStatus(
        userIds: string[],
        chatId: ID
    ): Observable<UserChatVisibilityStatusModel[]> {
        return this.chatDataService
            .getUserChatVisibilityStatus(userIds, chatId)
            .pipe(
                catchError(() => {
                    this.snackBar.open(
                        'There was an error while adding participants. Please try again.'
                    );

                    return EMPTY;
                })
            );
    }

    @PermissionAuthorize(Permissions.Chat.View)
    getUserInvitedChats(
        queryParams: UserInvitedChatsQueryParams
    ): Observable<PagedEntities<Chat>> {
        return this.chatDataService.getUserInvitedChats(queryParams).pipe(
            tap((pagedEntities: PagedEntities<Chat>) => {
                const invitedChats = pagedEntities?.entities?.map(
                    (chat: Chat) => ({
                        ...chat,
                        startDateTime: chat?.startDateTime
                            ? moment(chat.startDateTime)
                            : null,
                    })
                );

                this.chatsStore.upsertMany(invitedChats);
            }),
            catchError(() => {
                this.snackBar.open('Failed to load chats.');
                return EMPTY;
            })
        );
    }

    post(chat: Chat): Observable<Chat> {
        return this.chatDataService.post(chat).pipe(
            map((response: Chat) => {
                this.chatsStore.add(response);
                this.snackBar.open('Chat created.');
                this.router.navigate(['/chats']);
                return response;
            }),
            setLoading(this.chatsStore),
            catchError(() => {
                this.snackBar.open('Failed to create Chat.');
                return EMPTY;
            })
        );
    }

    put(chat: Chat): Observable<Chat> {
        return this.chatDataService.put(chat).pipe(
            map((chat: Chat) => {
                this.chatsStore.update(chat.id, chat);
                this.snackBar.open('Chat updated.');
                this.router.navigate(['/chats']);
                return chat;
            }),
            setLoading(this.chatsStore),
            catchError(() => {
                this.snackBar.open('Failed to update Chat.');
                return EMPTY;
            })
        );
    }

    upsert(chat: Chat): Observable<Chat> {
        chat.startDateTime = moment(chat.startDateTime).format(
            Constants.DATE_TIME_FORMAT_ISO
        ) as any;

        return chat.id ? this.put(chat) : this.post(chat);
    }

    updateChatInviteStatus(
        chatId: ID,
        inviteStatus: ChatInviteStatus
    ): Observable<Chat> {
        const isAccepted = inviteStatus === ChatInviteStatus.Accepted;

        return this.chatDataService
            .updateChatInviteStatus(chatId, inviteStatus)
            .pipe(
                tap((chat: Chat) => {
                    this.chatsStore.update(chatId, (chat) => {
                        const participantsInfo = [...chat.participantsInfo];
                        const memberParticipantIndex =
                            participantsInfo.findIndex(
                                (participantsInfo: ParticipantInfo) =>
                                    participantsInfo.id ===
                                    this.authService.userId
                            );
                        participantsInfo[memberParticipantIndex] = {
                            ...participantsInfo[memberParticipantIndex],
                            inviteStatus,
                        };
                        return {
                            ...chat,
                            participantsInfo,
                        };
                    });

                    this.snackBar.openWithIcon(
                        this.formatChatAcceptedMessage(
                            chat.title,
                            chat.startDateTime,
                            inviteStatus
                        ),
                        isAccepted ? 'message-check' : 'message-xmark',
                        isAccepted ? 'success' : 'info'
                    );
                }),
                catchError((e) => {
                    this.snackBar.open(
                        `Failed to ${isAccepted ? 'accept' : 'decline'} chat.`
                    );
                    return EMPTY;
                })
            );
    }

    fetchIfNotPresent(): Observable<Chat> {
        const chatEntity = this.chatsQuery.getActiveChat();
        const isEditMode = this.chatsQuery.getIsEditMode();
        if (!chatEntity && isEditMode) {
            return this.getById(this.chatsQuery.getEditingId());
        }

        return of(chatEntity);
    }

    startPendingChatsFetching(): void {
        this.pendingChatsFetchingTrigger$.next(true);
    }

    stopPendingChatsFetching(): void {
        this.pendingChatsFetchingTrigger$.next(false);
    }

    @PermissionAuthorize(Permissions.Chat.View)
    subscribeToPendingChatsFetchingTrigger(
        nextFn?: (value: boolean) => void
    ): Subscription {
        return this.pendingChatsFetchingTrigger$.subscribe(nextFn);
    }

    setBackUrl(backUrl: string): void {
        this.chatsStore.update({ backUrl });
    }

    private formatChatAcceptedMessage(
        title: string,
        startDateTime: Moment,
        inviteStatus: ChatInviteStatus
    ): string {
        const atTranslated = this.translocoService.translate('at');

        return this.translocoService
            .translate(
                `You ${
                    inviteStatus === ChatInviteStatus.Accepted
                        ? 'accepted'
                        : 'declined'
                } invite to $1 on $2`
            )
            .replace('$1', `${title}`)
            .replace(
                '$2',
                `${moment(startDateTime)
                    .setLocale(this.translocoService.getActiveLang())
                    .format(`MMM Do [${atTranslated}] hh:mm A`)}`
            );
    }
}
