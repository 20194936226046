import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
    UrlSegment,
} from '@angular/router';

import { Observable, of } from 'rxjs';

import { Breadcrumb, stringFormat } from '../vsc-breadcrumbs.shared';

export class VscBreadcrumbsResolver implements Resolve<Breadcrumb[]> {
    public resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<Breadcrumb[]> | Promise<Breadcrumb[]> | Breadcrumb[] {
        const data = route.routeConfig.data;

        if (!data?.breadcrumbs) {
            return [];
        }

        const breadcrumbs = data?.breadcrumbs;
        const isBreadcrumbsStringType = typeof breadcrumbs === 'string';

        const path: string =
            breadcrumbs.path || breadcrumbs.path === null
                ? breadcrumbs.path
                : this.getFullPath(route);

        let text = isBreadcrumbsStringType
            ? breadcrumbs
            : breadcrumbs.text || data.text || path;
        text = stringFormat(text, route.data);

        const isTranslatable: boolean =
            isBreadcrumbsStringType ||
            breadcrumbs.isTranslatable === undefined ||
            breadcrumbs.isTranslatable;

        const crumbs: Breadcrumb[] = [
            {
                text,
                path: data?.breadcrumbs?.path || path,
                isTranslatable,
            },
        ];

        return of(crumbs);
    }

    public getFullPath(route: ActivatedRouteSnapshot): string {
        const relativePath = (segments: UrlSegment[]) =>
            segments.reduce((a, v) => (a += '/' + v.path), '');
        const fullPath = (routes: ActivatedRouteSnapshot[]) =>
            routes.reduce((a, v) => (a += relativePath(v.url)), '');

        return fullPath(route.pathFromRoot);
    }
}
