import { Injectable } from '@angular/core';

import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

import { Constants } from '@shared/constants';

import { Chat } from './chat';

export interface ChatsState extends EntityState<Chat> {
    backUrl: string;
    todaysChats: Chat[];
}

export const initialState: ChatsState = {
    loading: false,
    backUrl: Constants.CHAT_DEFAULT_BACK_URL,
    todaysChats: [],
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'chats' })
export class ChatsStore extends EntityStore<ChatsState, Chat> {
    constructor() {
        super(initialState);
    }
}
