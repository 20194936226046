import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TranslocoModule } from '@ngneat/transloco';

import { IconModule } from '@shared/icon/icon.module';
import { MaterialModule } from '@shared/material.module';

import { TenantSwitcherComponent } from './tenant-switcher.component';

@NgModule({
    imports: [TranslocoModule, MaterialModule, CommonModule, IconModule],
    declarations: [TenantSwitcherComponent],
    exports: [TenantSwitcherComponent],
})
export class TenantSwitcherModule {}
