<span *ngIf="formControl.hasError('required')">
    {{
        (formControl.errors['required'].message != null
            ? formControl.errors['required'].message
            : 'Required'
        ) | transloco
    }}
</span>
<span *ngIf="formControl.hasError('minlength')">
    {{
        formControl.errors['minlength'].message != null
            ? (formControl.errors['minlength'].message | transloco)
            : ('Minimum length is' | transloco) +
              ': ' +
              __getParams(formControl.errors['minlength'].requiredLength).value
    }}
</span>
<span *ngIf="formControl.hasError('maxlength')">
    {{
        formControl.errors['maxlength'].message != null
            ? (formControl.errors['maxlength'].message | transloco)
            : ('Max length is' | transloco) +
              ': ' +
              __getParams(formControl.errors['maxlength'].requiredLength).value
    }}
</span>
<span *ngIf="formControl.hasError('selectDate')">
    {{
        formControl.errors['selectDate'].message != null
            ? formControl.errors['selectDate'].message
            : 'Select date'
    }}
</span>
<span *ngIf="formControl.hasError('validDate')">
    {{
        formControl.errors['validDate'].message != null
            ? formControl.errors['validDate'].message
            : ('Please populate valid date' | transloco)
    }}
</span>
<span *ngIf="formControl.hasError('maxDate')">
    {{
        formControl.errors['maxDate'].message != null
            ? formControl.errors['maxDate'].message
            : ('Future dates are not allowed' | transloco)
    }}
</span>
<span *ngIf="formControl.hasError('minDate')">
    {{
        formControl.errors['minDate'].message != null
            ? formControl.errors['minDate'].message
            : ('Minimum date is' | transloco) +
              ': ' +
              (__getParams(formControl.errors['minDate'].minDate).value
                  | localizedDate)
    }}
</span>
<span *ngIf="formControl.hasError('futureDateTime')">
    {{
        formControl.errors['futureDateTime'].message != null
            ? formControl.errors['futureDateTime'].message
            : formControl.errors['futureDateTime'].messageResolver != null
            ? formControl.errors['futureDateTime'].messageResolver()
            : ('Cannot be a future date.' | transloco)
    }}
</span>
<span *ngIf="formControl.hasError('min')">
    {{
        formControl.errors['min'].message != null
            ? formControl.errors['min'].message
            : ('Minimum value is' | transloco) +
              ': ' +
              __getParams(formControl.errors['min'].min).value
    }}
</span>
<span *ngIf="formControl.hasError('max')">
    {{
        formControl.errors['max'].messageResolver != null
            ? formControl.errors['max'].messageResolver()
            : ('Maximum value is' | transloco) +
              ': ' +
              __getParams(formControl.errors['max'].max).value
    }}
</span>
<span *ngIf="formControl.hasError('email')">
    {{
        formControl.errors['email'].message != null
            ? formControl.errors['email'].message
            : ('Email format is not valid' | transloco)
    }}
</span>
<span *ngIf="formControl.hasError('number')">
    {{
        formControl.errors['number'].message != null
            ? formControl.errors['number'].message
            : ('Not a valid number' | transloco)
    }}
</span>
<span *ngIf="formControl.hasError('pattern')">
    {{
        (formControl.errors['pattern'].message != null
            ? formControl.errors['pattern'].message
            : 'Not valid'
        ) | transloco
    }}
</span>
<span *ngIf="formControl.hasError('regularExpression')">
    {{
        formControl.errors['regularExpression'].message != null
            ? formControl.errors['regularExpression'].message
            : ('Not valid' | transloco)
    }}
</span>
<span *ngIf="formControl.hasError('integer')">
    {{
        formControl.errors['integer'].message != null
            ? formControl.errors['integer'].message
            : ('Not a valid integer' | transloco)
    }}
</span>
<span *ngIf="formControl.hasError('greaterThan')">
    {{
        (formControl.errors['greaterThan'].message != null
            ? formControl.errors['greaterThan'].message
            : formControl.errors['greaterThan'].controlToValidateName +
              ' cannot be smaller than ' +
              formControl.errors['greaterThan'].controlToCompareName
        ) | transloco
    }}
</span>
<span *ngIf="formControl.hasError('greaterThanDateTime')">
    {{ formControl.errors['greaterThanDateTime'].message | transloco }}
</span>
<span *ngIf="formControl.hasError('lessThan')">
    {{
        formControl.errors['lessThan'].message != null
            ? formControl.errors['lessThan'].message
            : formControl.errors['lessThan'].controlToValidateName +
              ' cannot be bigger than ' +
              formControl.errors['lessThan'].controlToCompareName
    }}
</span>
<span *ngIf="formControl.hasError('async')">
    {{
        isObservable(formControl.errors['async'].message)
            ? (formControl.errors['async'].message | async)
            : (
                  formControl.errors['async'].message?.toString() | transloco
              )?.replace('$value', formControl.value)
    }}
</span>
<span *ngIf="formControl.hasError('wrongFileType')">
    {{
        formControl.errors['wrongFileType'].message != null
            ? formControl.errors['wrongFileType'].message
            : ("You can't upload files of this type" | transloco)
    }}
</span>
<span *ngIf="formControl.hasError('custom')">
    {{
        formControl.errors['custom'].messageResolver
            ? formControl.errors['custom'].messageResolver()
            : (formControl.errors['custom'].message | transloco)
    }}
</span>
<span *ngIf="formControl.hasError('allowedCharacters')">
    {{
        'Not Allowed'
            | transloco
                : __getParams(
                      formControl.errors['allowedCharacters']
                          .notAllowedCharacter
                  )
    }}
</span>
<span *ngIf="formControl.hasError('sameAsPattern')">
    {{
        'Not valid. Valid format example'
            | transloco
                : __getParams(formControl.errors['sameAsPattern'].pattern)
    }}
</span>
<span *ngIf="formControl.hasError('fileType')">
    {{
        formControl.errors['fileType'].message != null
            ? formControl.errors['fileType'].message
            : ('File type not allowed' | transloco)
    }}
</span>
<span *ngIf="formControl.hasError('dynamicAsyncMessage')">
    {{ formControl.errors['dynamicAsyncMessage'].message }}
</span>
<span *ngIf="formControl.hasError('sameAs')">
    {{
        (formControl.errors['sameAs'].message != null
            ? formControl.errors['sameAs'].message
            : 'Please enter the same value again.'
        ) | transloco
    }}
</span>
<span *ngIf="formControl.hasError('url')">
    {{
        formControl.errors['url'].messageResolver != null
            ? formControl.errors['url'].messageResolver()
            : (formControl.errors['url'].message | transloco)
    }}
</span>

<span *ngIf="formControl.hasError('validatePhoneNumber')">
    {{
        (formControl.errors['validatePhoneNumber'].message != null
            ? formControl.errors['validatePhoneNumber'].message
            : 'Invalid phone number'
        ) | transloco
    }}
</span>
