import { Pipe, PipeTransform, Injectable } from '@angular/core';

import { TranslocoService } from '@ngneat/transloco';

import * as moment from 'moment';

import { Constants } from '@shared/constants';

@Pipe({ name: 'localizedDate', pure: false })
@Injectable({ providedIn: 'root' })
export class LocalizedDatePipe implements PipeTransform {
    constructor(private translocoService: TranslocoService) {}
    transform(
        value: any,
        pattern?: string | moment.CalendarSpec,
        toDateValue?: any
    ): string | null {
        if (moment.isMoment(value)) {
            const _SHORT_DATE_FORMAT_ = 'MMM D';
            const locale = this.translocoService.getActiveLang();

            if (pattern && typeof pattern !== 'string') {
                return moment(value)
                    .setLocale(locale)
                    .calendar(null, pattern)
                    .replace('Today', this.translocoService.translate('Today'))
                    .replace(
                        'Tomorrow',
                        this.translocoService.translate('Tomorrow')
                    )
                    .replace(
                        'Yesterday',
                        this.translocoService.translate('Yesterday')
                    );
            }

            pattern = pattern as string;

            if (toDateValue) {
                const fromDateMoment = moment(value);
                const fromDate = fromDateMoment
                    .setLocale(locale)
                    .format(pattern ?? _SHORT_DATE_FORMAT_);

                const toDate = moment(toDateValue)
                    .setLocale(locale)
                    .format(pattern ?? _SHORT_DATE_FORMAT_);

                return `${fromDate} - ${toDate}, ${fromDateMoment.year()}`;
            }

            return moment(value)
                .setLocale(locale)
                .format(pattern ?? Constants.DATE_FORMAT_US);
        }
    }
}
