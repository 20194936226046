import { ValueProvider } from '@angular/core';

import {
    PersistState,
    persistState,
    PersistStateSelectFn,
} from '@datorama/akita';

import { debounceTime } from 'rxjs/operators';

import { StoreNames } from '@shared/constants/store-names';

import { ReportsState } from '@admin/reports/state/reports.store';

const selectReportsStatePersistProperties: () => PersistStateSelectFn<ReportsState> =
    () => {
        const selectToken: PersistStateSelectFn<ReportsState> = (
            state: ReportsState
        ) => ({
            pbiEmbedToken: state.pbiEmbedToken,
        });
        selectToken.storeName = StoreNames.Admin.Reports;

        return selectToken;
    };

const reportsPersistsStorage: PersistState = persistState({
    include: [StoreNames.Admin.Reports],
    select: [selectReportsStatePersistProperties()],
    key: StoreNames.Admin.Reports,
    /*
     * By default, the plugin will update the storage upon each store's change. Some applications perform
     * multiple updates in a second, and update the storage on each change can be costly.
     * For such cases, it's recommended to use the preStorageUpdateOperator option and add a debounce.
     */
    preStorageUpdateOperator: () => debounceTime(3000),
});

/**
 * Provides instructions to the Dependency Injection system on how to obtain a persistStorage dependency value.
   The value will be the Akita `PersistState` returned by the `persistState` function, which allows the state to be stored in LocalStorage.
 */
export const REPORTS_PERSIST_STATE_PROVIDER: ValueProvider = {
    provide: 'persistStorage',
    useValue: reportsPersistsStorage,
    multi: true,
};

export const clearReportsPersistState: () => void = () => {
    localStorage.removeItem(StoreNames.Admin.Reports);
};
