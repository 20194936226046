import { Injectable } from '@angular/core';

import { ID } from '@datorama/akita';

import { EMPTY, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { SnackBarService } from '@shared/snack-bar/snack-bar.service';

import { ClassSession } from '@public/classes/class-session-state/class-session';

import { ClassAttendance } from '../state/class-attendance';
import { Classroom } from '../state/classroom';
import { ClassroomQuery } from '../state/classroom.query';
import { ClassroomStore } from '../state/classroom.store';
import { ClassroomDataService } from './classroom.dataservice';

@Injectable({ providedIn: 'root' })
export class ClassroomService {
    constructor(
        private classroomDataService: ClassroomDataService,
        private snackBar: SnackBarService,
        private classroomQuery: ClassroomQuery,
        private classroomStore: ClassroomStore
    ) {}

    getClassroomSignature(classSessionId: string): Observable<Classroom> {
        return this.classroomDataService
            .getClassroomSignature(classSessionId)
            .pipe(
                map((response: Classroom) => {
                    return response;
                }),
                catchError(() => {
                    this.snackBar.open('Failed to join virtual room');
                    return EMPTY;
                })
            );
    }

    getUserInfo(classSessionId: string): Observable<Classroom> {
        return this.classroomDataService.getUserInfo(classSessionId).pipe(
            map((response: Classroom) => {
                return response;
            }),
            catchError(() => {
                this.snackBar.open('Failed to join virtual room');
                return EMPTY;
            })
        );
    }

    fetchClassSessionIfNotPresent(id: ID): Observable<ClassSession> {
        const classSession = this.classroomQuery.getActiveSession(id);

        if (!classSession) {
            return this.getById(id);
        }
        return EMPTY;
    }

    getById(id: ID): Observable<ClassSession> {
        return this.classroomDataService.getById(id).pipe(
            map((classSession: ClassSession) => {
                this.classroomStore.add(classSession);
                return classSession;
            }),
            catchError(() => {
                this.snackBar.open('Failed to load Program Session.');
                return EMPTY;
            })
        );
    }

    recordClassAttendance(classSessionId: ID): Observable<ClassAttendance> {
        const classAttendance =
            this.classroomQuery.selectClassAttendanceRecord(classSessionId);
        return this.classroomDataService
            .recordClassAttendance(classAttendance)
            .pipe(
                map((response) => {
                    return response;
                }),
                catchError(() => {
                    return EMPTY;
                })
            );
    }

    setBackUrl(backUrl: string): void {
        this.classroomStore.update({ backUrl });
    }

    setLeaveIndicator(leaveIndicator: number): void {
        this.classroomStore.update({ leaveIndicator });
    }
}
