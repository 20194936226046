import { Injectable } from '@angular/core';

import { QueryEntity } from '@datorama/akita';

import { Announcement } from './announcement';
import { AnnouncementState, AnnouncementStore } from './announcement.store';

@Injectable({
    providedIn: 'root',
})
export class AnnouncementQuery extends QueryEntity<
    AnnouncementState,
    Announcement
> {
    constructor(protected store: AnnouncementStore) {
        super(store);
    }
}
