import { Injectable } from '@angular/core';
import { NavigationStart, Router, RouterEvent } from '@angular/router';

import { filter, take, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class RouterService {
    constructor(private router: Router) {}

    /**
     * Checks if the URL of the next navigation does not match the specified URL or RegExp.
     * There is no need to unsubscribe from this observable since only the first emitted NavigationStart event is taken.
     * @param urlToMatch The URL of next navigation.
     * @param callback Callback function to execute if URL does not match passed one.
     * @param urlToIgnore The URL of the next navigation to ignore.
     * @returns Observable of boolean that is true if the next URL does not match the passed one, otherwise false.
     */
    checkIfNextUrlDoesNotMatch(
        urlToMatch: string | RegExp,
        callback?: (navigationStart: NavigationStart) => void,
        urlToIgnore?: string | RegExp
    ): Observable<boolean> {
        return this.router.events.pipe(
            filter(
                (event: RouterEvent) =>
                    event instanceof NavigationStart &&
                    event?.url &&
                    (!urlToIgnore ||
                        (typeof urlToIgnore === 'string'
                            ? event.url !== urlToIgnore
                            : !urlToIgnore.test(event.url)))
            ),
            map((event: NavigationStart) => {
                if (
                    typeof urlToMatch === 'string'
                        ? urlToMatch !== event.url
                        : !urlToMatch.test(event.url)
                ) {
                    callback && callback(event);
                    return true;
                }
                return false;
            }),
            take(1)
        );
    }
}
