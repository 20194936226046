import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';

import * as moment from 'moment';
import { map, Observable, of, take, timer } from 'rxjs';

import { Constants } from '@shared/constants';
import { LocalizedDatePipe } from '@shared/pipes/localized-date.pipe';
import { parseDictionaryToObject } from '@shared/utils/helpers';

import { Notification } from '../models/notification';
import { NotificationAction } from '../models/notification-action.enum';
import { NotificationsService } from '../services/notifications.service';

@Component({
    selector: 'vsc-notification-action',
    templateUrl: './notification-action.component.html',
    styleUrls: ['./notification-action.component.scss'],
})
export class NotificationActionComponent implements OnInit {
    @Input() notification: Notification;
    @Output() actionInvoked: EventEmitter<void> = new EventEmitter<void>();
    @Output() actionRejected: EventEmitter<void> = new EventEmitter<void>();

    NotificationAction: typeof NotificationAction = NotificationAction;
    notificationMetadata: any;
    notificationIds: string[] = [];
    isChatReady$: Observable<boolean> = of(false);
    isProgramReady$: Observable<boolean> = of(false);
    templateParams: string[];
    newDateTime: string;

    constructor(
        private notificationsService: NotificationsService,
        private localizedDatePipe: LocalizedDatePipe
    ) {}

    ngOnInit(): void {
        this.notificationMetadata = parseDictionaryToObject(
            this.notification?.metadata
        );

        this.isChatReady$ = this.selectChatReady();
        this.isProgramReady$ = this.selectProgramReady();
    }

    selectChatReady(): Observable<boolean> {
        return timer(0, 1000).pipe(
            map(() => {
                this.setTemplateParameters();

                const now = moment(
                    this.localizedDatePipe.transform(
                        moment(),
                        Constants.FULL_DATE_TIME_FORMAT_US
                    )
                );
                let minutes = -5;
                const chatStartDateTime = this.templateParams.pop();
                const startDateTime = moment(chatStartDateTime);
                const twoHoursInMinutes = 120;

                if (
                    this.notification?.actionType ===
                        NotificationAction?.ChatCreated ||
                    this.notification?.actionType ===
                        NotificationAction?.ChatUpdated ||
                    this.notification?.actionType ===
                        NotificationAction?.NotRSVPdChat ||
                    this.notification?.actionType ===
                        NotificationAction?.ChatPending ||
                    this.notification?.actionType ===
                        NotificationAction?.ChatAccepted
                ) {
                    return moment(startDateTime)
                        .addMinutes(minutes)
                        .isSameOrBefore(now);
                } else if (
                    this.notification?.actionType ===
                        NotificationAction?.ChatStarted ||
                    this.notification?.actionType ===
                        NotificationAction?.ChatAboutToStart
                ) {
                    minutes = -6;
                    return (
                        moment(startDateTime)
                            .addMinutes(minutes)
                            .isSameOrBefore(now) &&
                        moment(startDateTime)
                            .addMinutes(twoHoursInMinutes)
                            .isSameOrAfter(now)
                    );
                }
                return false;
            })
        );
    }

    selectProgramReady(): Observable<boolean> {
        return timer(0, 1000).pipe(
            map(() => {
                if (
                    this.notification?.actionType ===
                        NotificationAction?.ProgramAboutToStart ||
                    this.notification?.actionType ===
                        NotificationAction?.ProgramStarted
                ) {
                    this.setTemplateParameters();
                    const now = moment(
                        this.localizedDatePipe.transform(
                            moment(),
                            Constants.FULL_DATE_TIME_FORMAT_US
                        )
                    );
                    const minutes = -11;
                    const sessionStartDateTime = moment(
                        this.templateParams.pop()
                    );
                    const sessionDuration = parseInt(this.templateParams.pop());
                    return (
                        moment(sessionStartDateTime)
                            .addMinutes(minutes)
                            .isSameOrBefore(now) &&
                        moment(sessionStartDateTime)
                            .addMinutes(sessionDuration)
                            .isSameOrAfter(now)
                    );
                }
                return false;
            })
        );
    }

    invokeAction(event: MouseEvent): void {
        event.preventDefault();
        event.stopPropagation();

        this.actionInvoked.emit();
    }

    rejectAction(event: MouseEvent): void {
        event.preventDefault();
        event.stopPropagation();

        this.actionRejected.emit();
    }

    markAsRead(): void {
        if (
            this.notification?.isRead === false &&
            this.notification?.id !== null
        ) {
            this.notificationIds.push(this.notification?.id);

            this.notificationsService
                .markNotificationsAsRead(this.notificationIds, 1)
                .pipe(take(1))
                .subscribe();
        }
    }

    private setTemplateParameters(): void {
        this.templateParams = [...this.notification.templateParameters];
        if (this.notification.metadata['newDateTime'] !== undefined) {
            this.newDateTime = this.localizedDatePipe.transform(
                moment(this.notification.metadata['newDateTime']),
                Constants.FULL_DATE_TIME_FORMAT_US
            );

            this.templateParams.splice(
                this.templateParams.length - 1,
                1,
                this.newDateTime
            );
        }
    }
}
