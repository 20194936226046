import { Injectable } from '@angular/core';

import { TranslocoService } from '@ngneat/transloco';

import { AuthService } from '@core/auth/auth.service';

import { Chat, ParticipantInfo } from '@public/chats/chat-state/chat';

@Injectable({
    providedIn: 'root',
})
export class FormatParticipantsService {
    constructor(
        private translocoService: TranslocoService,
        private authService: AuthService
    ) {}

    generateFormattedChatParticipants(
        chat: Chat,
        isOrganizerFirst?: boolean
    ): string {
        if (!chat) {
            return '';
        }
        const isMemberOrganizer = this.authService.userId === chat.organizerId;

        const organizerTitleTranslated =
            this.translocoService.translate('Organizer');
        const organizer =
            (isMemberOrganizer
                ? this.translocoService.translate('You')
                : `${chat.organizer}`) + ` (${organizerTitleTranslated})`;

        const participants = chat.participantsInfo
            .filter(
                (participantInfo: ParticipantInfo) =>
                    participantInfo.id !== this.authService.userId
            )
            .map(
                (participant: ParticipantInfo) =>
                    `${participant.firstName} ${participant.lastName}`
            );

        const addOrganizerAtStart = () => participants.unshift(organizer);
        const addMemberParticipantAtStart = () =>
            !isMemberOrganizer &&
            participants.unshift(`${this.translocoService.translate('You')}`);

        if (isOrganizerFirst) {
            addMemberParticipantAtStart();
            addOrganizerAtStart();
        } else {
            addOrganizerAtStart();
            addMemberParticipantAtStart();
        }

        const participantsFormatted = participants.join(', ');

        return participantsFormatted.replaceLastSeparator(
            ',',
            ` ${this.translocoService.translate('and')}`
        );
    }
}
