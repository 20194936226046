import { Injectable } from '@angular/core';

import * as moment from 'moment';

import {
    NotificationsSettings,
    NotificationsSettingsService,
} from '@core/services/notifications-settings.service';
import { environment } from '@environment/environment';

import { Notification } from '@shared/notifications/models/notification';
import { NotificationsService } from '@shared/notifications/services/notifications.service';
import { NotificationsQuery } from '@shared/notifications/state/notifications.query';
import { NotificationsStore } from '@shared/notifications/state/notifications.store';
import { SignalRService } from '@shared/services/signalr.service';

/**
 * Notifications Hub methods.
 */
abstract class NotificationsHubMethods {
    public static readonly Send: string = 'send';
    public static readonly Remove: string = 'remove';
}

/**
 * Notifications hub provides real-time notifications management.
 */
@Injectable()
export class NotificationsHub {
    private hubConnection: signalR.HubConnection;
    private readonly CONNECTION_URL: string = `${environment.apiUrl}/notis/hubs/notifications`;

    constructor(
        private notificationsService: NotificationsService,
        private notificationsStore: NotificationsStore,
        private notificationsSettingsService: NotificationsSettingsService,
        private signalRService: SignalRService,
        private notificationsQuery: NotificationsQuery
    ) {}

    /**
     * Starts connection to the notifications hub that provides real-time notifications management.
     * @remarks
     * `NOTE: This method should be called only once.`
     */
    startConnection(): void {
        if (this.hubConnection) {
            throw new Error(
                'Connection to the notifications hub already started. '
            );
        }

        this.hubConnection = this.signalRService.startConnection(
            this.CONNECTION_URL
        );

        this.setSendCallback();
        this.setRemoveCallback();
    }

    /**
     * Stops connection with the notifications hub.
     */
    stopConnection(): void {
        this.hubConnection?.stop();
    }

    private setSendCallback(): void {
        this.hubConnection.on(
            NotificationsHubMethods.Send,
            (notification: Notification) => {
                if (notification) {
                    notification = {
                        ...notification,
                        createdAt: moment(notification.createdAt),
                        isRead: false,
                    };

                    this.notificationsStore.upsert(
                        notification.id,
                        notification
                    );

                    this.notificationsStore.updateUnreadCount(1);

                    const notificationsSettings: NotificationsSettings =
                        this.notificationsSettingsService.getNotificationsSettings();

                    // Show notification only if the user has enabled in-app notifications.
                    if (notificationsSettings?.isInAppEnabled) {
                        this.notificationsService.showNotification(
                            notification
                        );
                    }
                }
            }
        );
    }

    private setRemoveCallback(): void {
        this.hubConnection.on(
            NotificationsHubMethods.Remove,
            (id: string) => id && this.notificationsStore.remove(id)
        );
    }
}
