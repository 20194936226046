import {
    AbstractControl,
    FormControl,
    ValidationErrors,
    Validator,
} from '@angular/forms';

import { DynamicValidator, Group, Rule } from './dynamic';

export class NumberValidator extends DynamicValidator implements Validator {
    constructor(rules?: Array<Rule | Group>) {
        super(rules);
    }

    validate(control: AbstractControl): ValidationErrors | null {
        if (control instanceof FormControl) {
            if (
                control.value !== null &&
                control.value !== undefined &&
                !isNaN(control.value)
            ) {
                return null;
            }

            return {
                number: {
                    current: control.value,
                },
            };
        }

        return null;
    }
}
