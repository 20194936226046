export enum UserStatus {
    Active,
    Inactive,
    Pending,
    InReview,
}

/**
 * Converts UserStatus enum to string.
 * @param type UserStatus type.
 * @returns USerStatus type as string.
 */
export function convertUserStatus(type: UserStatus): string {
    switch (type) {
        case UserStatus.Active:
            return 'Active';
        case UserStatus.Inactive:
            return 'Inactive';
        case UserStatus.Pending:
            return 'Pending';
        case UserStatus.InReview:
            return 'Waiting for approval';
    }
}
