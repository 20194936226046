export enum SurveyType {
    SelfAssessment = '8A7AC3FA-54AC-4AAE-872E-B553672E63B6',
    ClassAssessment = '49B35B78-A7C5-4A5B-BAA5-4DD6D93607CF',
    IsolationSurvey = 2,
}

export enum SurveyTypeName {
    SelfAssessment = 'Self Assessment',
    ClassAssessment = 'Program Assessment',
    IsolationSurvey = 'Isolation Survey',
}
