import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
    ReactiveFormsModule,
    FormsModule as AngularFormsModule,
} from '@angular/forms';

import { TranslocoModule } from '@ngneat/transloco';

import {
    DropzoneModule,
    DROPZONE_CONFIG,
    DropzoneConfigInterface,
} from 'ngx-dropzone-wrapper';
import { NgxMaskModule } from 'ngx-mask';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

import { FileUploadComponent } from '@shared/forms/file-upload';
import { FileUploadMaterialComponent } from '@shared/forms/file-upload/file-upload-material/file-upload-material.component';
import { IconModule } from '@shared/icon/icon.module';
import { MaterialModule } from '@shared/material.module';
import { SharedModule } from '@shared/shared.module';

import { CheckboxMultiSelectModule } from './checbox-multi-select/checkbox-multi-select.component.module';
import { ChipsMultiSelectModule } from './chips-multi-select/chips-multi-select.component.module';
import { VscDateCalendarModule } from './date-calendar/date-calendar.module';
import { VscDateRangeModule } from './date-range/date-range.module';
import { DateTimeComponent } from './date-time/date-time.component';
import { VscDateModule } from './date/date.module';
import { VscInputModule } from './input/input.module';
import { ItemsComponent } from './items/items.component';
import { SingleItemComponent } from './items/single-item/single-item.component';
import { SelectSearchModule } from './select-search/select-search.module';
import { VscSelectModule } from './select/select.module';
import { VscTelInput } from './tel-input/tel-input.module';
import { ValidationMessageModule } from './validation-message/validation-message.module';

const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {};

@NgModule({
    declarations: [
        DateTimeComponent,
        FileUploadComponent,
        FileUploadMaterialComponent,
        ItemsComponent,
        SingleItemComponent,
    ],
    imports: [
        CommonModule,
        AngularFormsModule,
        ReactiveFormsModule,
        TranslocoModule,
        NgxMaskModule,
        MaterialModule,
        NgxMatSelectSearchModule,
        SharedModule,
        DropzoneModule,
        VscInputModule,
        SelectSearchModule,
        ValidationMessageModule,
        VscSelectModule,
        VscDateModule,
        VscDateRangeModule,
        ChipsMultiSelectModule,
        CheckboxMultiSelectModule,
        VscDateCalendarModule,
        IconModule,
        VscTelInput,
    ],
    exports: [
        AngularFormsModule,
        ReactiveFormsModule,
        NgxMaskModule,
        DateTimeComponent,
        FileUploadComponent,
        FileUploadMaterialComponent,
        ItemsComponent,
        SingleItemComponent,
        VscInputModule,
        ValidationMessageModule,
        SelectSearchModule,
        VscSelectModule,
        VscDateModule,
        VscDateRangeModule,
        ChipsMultiSelectModule,
        CheckboxMultiSelectModule,
        VscDateCalendarModule,
        VscTelInput,
    ],
    providers: [
        {
            provide: DROPZONE_CONFIG,
            useValue: DEFAULT_DROPZONE_CONFIG,
        },
    ],
})
export class FormsModule {}
