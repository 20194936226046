import { Injectable } from '@angular/core';

import { ID, QueryEntity } from '@datorama/akita';

import { Observable } from 'rxjs';

import { PBIEmbedToken } from '../report/models/pbi-embed-token';
import { EmbedTokenState, EmbedTokenStore } from './embed-token.store';

@Injectable({
    providedIn: 'root',
})
export class EmbedTokenQuery extends QueryEntity<
    EmbedTokenState,
    PBIEmbedToken
> {
    constructor(protected store: EmbedTokenStore) {
        super(store);
    }

    getPbiAccessTokenByTenant(tenantId: ID): PBIEmbedToken {
        return this.getEntity(tenantId);
    }

    selectPbiAccessTokenByTenant(tenantId: ID): Observable<string> {
        return this.selectEntity(tenantId, (e) => e.token);
    }

    selectPbiAccessTokenExpirationByTenant(tenantId: ID): Observable<number> {
        return this.selectEntity(tenantId, (e) => e.expiresAt);
    }

    getPbiAccessTokenExpirationByTenant(tenantId: ID): number {
        return this.getEntity(tenantId)?.expiresAt;
    }
}
