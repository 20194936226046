import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective } from '@angular/forms';

import { Observable } from 'rxjs';

import { Field, FieldType } from '../dynamic-reactive-form.model';

@Component({
    selector: 'vsc-dynamic-field',
    templateUrl: './dynamic-field.component.html',
    styleUrls: ['./dynamic-field.component.scss'],
})
export class DynamicFieldComponent implements OnInit {
    @Input() field: Field;
    @Input() formGroup: FormGroup;

    public control: FormControl;
    public FieldType = FieldType;

    constructor(private formGroupDir: FormGroupDirective) {}

    ngOnInit(): void {
        /**
         * @angular/forms -> FormGroupDirective! 🎉
         *
         * https://angular.io/api/forms/FormGroupDirective
         * "Binds an existing FormGroup to a DOM element."
         *
         * We can easily access Reactive Forms functionality from this component in our
         * parent component without the need to pass our own inputs or event emitters.
         */
        this.control = this.formGroupDir.control.get(
            this.field.name
        ) as FormControl;
    }

    isObservable(value: any): boolean {
        return value instanceof Observable;
    }
}
