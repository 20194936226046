import { Injectable } from '@angular/core';

import { QueryEntity } from '@datorama/akita';

import { NotificationDeliveryMethod } from './notification-delivery-method';
import {
    NotificationDeliveryMethodsStore,
    NotificationDeliveryMethodsState,
} from './notification-delivery-methods.store';

@Injectable({
    providedIn: 'root',
})
export class NotificationDeliveryMethodsQuery extends QueryEntity<
    NotificationDeliveryMethodsState,
    NotificationDeliveryMethod
> {
    constructor(protected store: NotificationDeliveryMethodsStore) {
        super(store);
    }
}
