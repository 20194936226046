abstract class AdminStoreNames {
    static readonly Reports = 'admin-reports';
}

/**
 * This class contains all the store names for both admin and public module.
 */
export abstract class StoreNames {
    /**
     * Store names for admin module.
     */
    static readonly Admin: typeof AdminStoreNames = AdminStoreNames;
}
