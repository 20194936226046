import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'vsc-close-button',
    templateUrl: './close-button.component.html',
    styleUrls: ['./close-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CloseButtonComponent {
    constructor() {}
}
