import { Injectable } from '@angular/core';

import { BehaviorSubject, map } from 'rxjs';

export interface OrganizationSetting {
    name: string;
    value: string;
}
@Injectable({ providedIn: 'root' })
export class OrganizationSettingsService {
    private settings$: BehaviorSubject<Array<OrganizationSetting>>;
    constructor() {}

    public loadSettings(settings: Array<OrganizationSetting>) {
        this.settings$ = new BehaviorSubject<Array<OrganizationSetting>>(
            settings
        );
    }

    public getSetting(type: string): OrganizationSetting {
        return this.settings$
            ?.getValue()
            .find((x) => x.name.toLowerCase() === type.toLowerCase());
    }

    public selectSetting(type: string) {
        return this.settings$?.pipe(
            map(
                (value) =>
                    value.find(
                        (x) => x.name.toLowerCase() === type.toLowerCase()
                    )?.value
            )
        );
    }
}
