import {
    ChangeDetectionStrategy,
    Component,
    OnDestroy,
    OnInit,
} from '@angular/core';

import { TranslocoService } from '@ngneat/transloco';

import { Observable, skip, Subscription } from 'rxjs';

import { WeatherService } from '../services/weather-services/weather.service';
import { Weather } from '../state/weather-state/weather.model';
import { WeatherQuery } from '../state/weather-state/weather.query';

@Component({
    selector: 'vsc-weather-widget',
    templateUrl: './weather.component.html',
    styleUrls: ['./weather.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WeatherComponent implements OnInit, OnDestroy {
    constructor(
        private weatherQuery: WeatherQuery,
        private weatherService: WeatherService,
        private translocoService: TranslocoService
    ) {}
    Math: Math = Math;
    weather$: Observable<Array<Weather>> = this.weatherQuery.selectAll();
    subscriptions: Array<Subscription> = [];

    ngOnInit(): void {
        // * get the weather data
        this.updateWeather();

        // * every time the language changes, update the weather data but skip the first load since the language is already set
        this.subscriptions.push(
            this.translocoService.langChanges$.pipe(skip(1)).subscribe(() => {
                this.updateWeather();
            })
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription) =>
            subscription?.unsubscribe()
        );
    }

    updateWeather(): void {
        // * default New York Lat Long Coordinates
        const nyLong = -73.935242;
        const nyLat = 40.73061;

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    this.getWeatherByCoordinates(
                        position.coords.latitude,
                        position.coords.longitude
                    );
                },
                () => {
                    this.getWeatherByCoordinates(nyLat, nyLong);
                }
            );
        } else {
            this.getWeatherByCoordinates(nyLat, nyLong);
        }
    }

    private getWeatherByCoordinates(lat: number, long: number) {
        this.subscriptions.push(
            this.weatherService.getWeatherByCoordinates(lat, long).subscribe()
        );
    }
}
