import { Injectable } from '@angular/core';

import { combineQueries, QueryEntity } from '@datorama/akita';
import { RouterQuery } from '@datorama/akita-ng-router-store';

import { map, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { PBIDatasetRefreshSchedule } from '@admin/reports/report/models/pbi-dataset-refresh-schedule';

import { IReport } from './report';
import { ReportsState, ReportsStore } from './reports.store';

@Injectable({
    providedIn: 'root',
})
export class ReportsQuery extends QueryEntity<ReportsState, IReport> {
    constructor(
        protected store: ReportsStore,
        private routerQuery: RouterQuery
    ) {
        super(store);
    }

    selectActiveReport$: Observable<IReport> = combineQueries([
        this.routerQuery.selectParams(),
        this.selectAll(),
    ]).pipe(
        map((combine: [any, IReport[]]) => {
            const classItem = combine[1]?.find(
                (report: IReport) => report.id === combine[0]['id']
            );
            return classItem;
        })
    );

    selectDatasetRefreshSchedule(
        reportId: string
    ): Observable<PBIDatasetRefreshSchedule> {
        return this.selectEntity(reportId).pipe(
            filter(Boolean),
            map((report) => report.datasetRefresh)
        );
    }

    selectIsLoadingDatasetLastRefresh(): Observable<boolean> {
        return this.select(
            (state: ReportsState) => state.isLoadingDatasetLastRefresh
        );
    }

    getReport(reportId: string): IReport {
        return this.getEntity(reportId);
    }

    getDatasetRefreshSchedule(datasetId: string): PBIDatasetRefreshSchedule {
        return this.getEntity(
            (report: IReport) => report.datasetId === datasetId
        )?.datasetRefresh;
    }
}
