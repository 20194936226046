import { Injectable } from '@angular/core';

import { QueryEntity } from '@datorama/akita';

import { Observable } from 'rxjs';

import { Notification } from '../models/notification';
import { NotificationsState, NotificationsStore } from './notifications.store';

@Injectable({
    providedIn: 'root',
})
export class NotificationsQuery extends QueryEntity<
    NotificationsState,
    Notification
> {
    selectUnreadCount$: Observable<number> = this.select(
        (state: NotificationsState) => {
            return state.unreadCount;
        }
    );

    constructor(protected store: NotificationsStore) {
        super(store);
    }
}
