import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TranslocoModule } from '@ngneat/transloco';

import { IconModule } from '@shared/icon/icon.module';
import { MaterialModule } from '@shared/material.module';

import { ScrollToTopComponent } from './scroll-to-top.component';

@NgModule({
    imports: [CommonModule, MaterialModule, TranslocoModule, IconModule],
    declarations: [ScrollToTopComponent],
    exports: [ScrollToTopComponent],
})
export class ScrollToTopModule {}
