import { Observable } from 'rxjs';

export class SideMenuItem {
    constructor(initData: Partial<SideMenuItem>) {
        Object.assign(this, initData);
    }
    displayName: Observable<string> | any;
    iconName?: string;
    disabled?: boolean;
    route?: string;
    children?: Observable<SideMenuItem[]> | SideMenuItem[];
    data?: any;
    visible: boolean = true;
    iconStyle?: any;
    hasVisibleChildren?: boolean | Observable<boolean> = true;
}
