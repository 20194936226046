import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';

import { TranslocoModule } from '@ngneat/transloco';

import { IconModule } from '@shared/icon/icon.module';

import { MaterialModule } from '../material.module';
import { VideoModule } from '../video/video.module';
import { VideoSwitcherComponent } from './switchers/video-switcher/video-switcher.component';
import { VideoSliderComponent } from './video-slider.component';

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        TranslocoModule,
        VideoModule,
        FlexLayoutModule,
        IconModule,
    ],
    declarations: [VideoSliderComponent, VideoSwitcherComponent],
    exports: [VideoSliderComponent, VideoSwitcherComponent],
})
export class VideoSliderModule {}
