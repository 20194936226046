import {
    ChangeDetectionStrategy,
    Component,
    OnDestroy,
    OnInit,
} from '@angular/core';

import { Observable, Subscription } from 'rxjs';

import { NotificationsService } from '../services/notifications.service';
import { NotificationsQuery } from '../state/notifications.query';

@Component({
    selector: 'vsc-notifications-panel-trigger',
    templateUrl: './notifications-panel-trigger.component.html',
    styleUrls: ['./notifications-panel-trigger.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationsPanelTriggerComponent implements OnInit, OnDestroy {
    notificationsCount$: Observable<number> =
        this.notificationsQuery.selectUnreadCount$;
    panelVisible: boolean = false;
    private subscriptions: Subscription[] = [];

    constructor(
        private notificationsQuery: NotificationsQuery,
        private notificationsService: NotificationsService
    ) {}

    ngOnInit(): void {
        this.subscriptions.push(
            this.notificationsService
                .getUnreadUserNotificationsCount()
                .subscribe()
        );
    }

    toggleNotificationsPanel(e): void {
        this.panelVisible = !this.panelVisible;
        e.stopPropagation();
    }

    closePanel(): void {
        this.panelVisible = false;
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((sub: Subscription) => sub?.unsubscribe());
    }
}
