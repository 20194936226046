import { Injectable } from '@angular/core';

import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

import { Lookup } from './lookup';

export interface LookupState extends EntityState<Lookup> {
    [k: string]: any;
}
export function createInitialState(): LookupState {
    const initialState = {};
    return initialState;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'lookup' })
export class LookupStore extends EntityStore<LookupState, Lookup> {
    constructor() {
        super(createInitialState());
    }

    setLookup(lookups: Array<Lookup>, type: string): void {
        const state = {};
        state[type] = lookups;
        this.update(state);
    }
}
