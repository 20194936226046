import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    Router,
} from '@angular/router';

import { RouterQuery } from '@datorama/akita-ng-router-store';

import { LeaveChatComponent } from './leave-chat/leave-chat.component';

@Injectable({
    providedIn: 'root',
})
export class LeaveChatConfirmationGuard implements CanActivate {
    constructor(
        private router: Router,
        private routerQuery: RouterQuery,
        private dialog: MatDialog
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<boolean> {
        const navigation = this.router.getCurrentNavigation();
        if (navigation?.extras?.state) {
            const navigationExtras = navigation.extras.state as {
                byPassLeaveGuard: number;
            };

            if (navigationExtras.byPassLeaveGuard === 1) {
                return Promise.resolve(true);
            }
        } else {
            const originUrl = this.routerQuery.getValue().state
                ? this.routerQuery.getValue().state.urlAfterRedirects
                : null;

            if (
                originUrl &&
                originUrl.includes('chats') &&
                originUrl.endsWith('join')
            ) {
                const redirectUrl = state?.url?.toString();
                const chatId = this.routerQuery.getValue().state.params.id;

                this.dialog.open(LeaveChatComponent, {
                    width: '500px',
                    data: { chatId, redirectUrl },
                });
                return Promise.resolve(false);
            }
        }

        return Promise.resolve(true);
    }
}
