import { Injectable } from '@angular/core';

import { StoreConfig, EntityStore } from '@datorama/akita';

import { Moment } from 'moment';

import { StoreNames } from '@shared/constants/store-names';
import { MomentUtils } from '@shared/moment-utilities';

import { PBIDatasetRefreshSchedule } from '../report/models/pbi-dataset-refresh-schedule';
import { PBIEmbedToken } from './../report/models/pbi-embed-token';
import { IReport } from './report';

export interface ReportsState {
    pbiEmbedToken: PBIEmbedToken;
    isLoadingDatasetLastRefresh: boolean;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: StoreNames.Admin.Reports })
export class ReportsStore extends EntityStore<ReportsState, IReport> {
    static readonly INITIAL_STATE: ReportsState = {
        pbiEmbedToken: null,
        isLoadingDatasetLastRefresh: false,
    };
    constructor() {
        super(ReportsStore.INITIAL_STATE);
    }

    setPbiEmbedToken(pbiEmbedToken: PBIEmbedToken): void {
        this.update({
            pbiEmbedToken,
        });
    }

    setDatasetRefreshSchedule(
        datasetId: string,
        datasetRefresh: PBIDatasetRefreshSchedule
    ): void {
        this.update((report: IReport) => report?.datasetId === datasetId, {
            datasetRefresh: {
                lastRefreshAt: MomentUtils.parseIfTruthy(
                    datasetRefresh.lastRefreshAt
                ),
                nextRefreshAt: MomentUtils.parseIfTruthy(
                    datasetRefresh.nextRefreshAt
                ),
                scheduledRefreshes: datasetRefresh.scheduledRefreshes.map(
                    (refreshSchedule: Moment | string) =>
                        MomentUtils.parseIfTruthy(refreshSchedule)
                ),
            },
        });
    }
}
