import { AbstractControl, ValidationErrors, Validator } from '@angular/forms';

import { DynamicValidator, Group, Rule } from './dynamic';

export class CustomValidator extends DynamicValidator implements Validator {
    constructor(
        private readonly validatorFn: (control: AbstractControl) => boolean,
        private readonly message: string,
        private readonly messageResolver: () => string,
        rules?: Array<Rule | Group>
    ) {
        super(rules);
    }

    validate(control: AbstractControl): ValidationErrors | null {
        if (!this.validatorFn(control)) {
            return {
                custom: {
                    message: this.message,
                    messageResolver: this.messageResolver,
                },
            };
        }

        return null;
    }
}
