import {
    Directive,
    ElementRef,
    Input,
    Renderer2,
    OnDestroy,
    OnInit,
} from '@angular/core';

import { Observable, Subscription } from 'rxjs';
import { tap, debounceTime } from 'rxjs/operators';

@Directive({
    selector: '[vscMatSpinner]',
})
export class MatSpinnerDirective implements OnDestroy, OnInit {
    @Input() fixedPosition: boolean = false;

    private subscription: Subscription;

    @Input()
    set vscMatSpinner(value: Observable<boolean> | boolean) {
        if (value instanceof Observable) {
            const loading$ = value as Observable<boolean>;

            this.subscription = loading$
                .pipe(
                    debounceTime(500),
                    tap((shouldBlock) => {
                        if (shouldBlock) {
                            this.blockUI();
                        } else {
                            this.unblockUI();
                        }
                    })
                )
                .subscribe();
        } else {
            if (value) {
                this.blockUI();
            } else {
                this.unblockUI();
            }
        }
    }
    private loaderElement: HTMLElement;
    private circularElement: HTMLElement;

    constructor(private elementRef: ElementRef, private renderer: Renderer2) {
        const loaderHtml = `<div class="showbox">
                              <div class='loader'>
                                <svg class='circular' xmlns="http://www.w3.org/2000/svg" width="50.385" height="58.682" viewBox="0 0 50.385 58.682">
                                    <g id="enliveo-trademark" transform="translate(-2098.8 -5946.318)">
                                        <path id="Path_2068" data-name="Path 2068" d="M30.578,38.195c-3.916-4.995-11.138-1.477-11.531,4.388a7.825,7.825,0,0,0,1.672,5.639,2.93,2.93,0,0,1-.376,3.983l-.177.156.258-4.125C31.277,61.538,50.76,52.8,51.946,36.675A20.359,20.359,0,0,0,47.738,22.4a1.286,1.286,0,0,1,.287-1.91l-.109,1.745C37.567,8.988,18.391,7.782,5.954,18.943,14.626,10.167,28.834,7.486,40,13.075a31.4,31.4,0,0,1,9.889,7.53,1.293,1.293,0,0,1-.277,1.91l.109-1.753c7.968,8.91,7.416,23.589-1.036,32.125a22.249,22.249,0,0,1-25.492,4.847,23.1,23.1,0,0,1-7.429-5.646,2.924,2.924,0,0,1,.142-4.011c.07-.067.214-.212.284-.28l-.258,4.133C8.206,43.071,14.491,28.319,26.6,28.5a13.61,13.61,0,0,1,10.05,4.8c3.344,4.131-2.7,9.007-6.073,4.9" transform="translate(2093.813 5936.166)" fill="#00aabc"/>
                                        <path id="Path_2069" data-name="Path 2069" d="M29.52,43.363c3.916,5.021,11.139,1.485,11.531-4.411a7.9,7.9,0,0,0-1.672-5.669,2.957,2.957,0,0,1,.376-4l.177-.157-.258,4.146C28.82,19.9,9.337,28.683,8.152,44.891a20.541,20.541,0,0,0,4.208,14.35,1.3,1.3,0,0,1-.287,1.92l.109-1.754C22.53,72.722,41.706,73.935,54.143,62.716c-8.672,8.822-22.88,11.516-34.044,5.9a31.413,31.413,0,0,1-9.889-7.569,1.3,1.3,0,0,1,.277-1.92l-.109,1.762C2.41,51.93,2.962,37.174,11.414,28.593a22.165,22.165,0,0,1,25.492-4.872A23.116,23.116,0,0,1,44.335,29.4a2.951,2.951,0,0,1-.141,4.032c-.071.068-.214.213-.284.281l.258-4.155a13.934,13.934,0,0,1,3.212,11.415c-1.02,6.5-6.313,12.254-13.883,12.144a13.582,13.582,0,0,1-10.05-4.827,3.91,3.91,0,1,1,6.073-4.923" transform="translate(2094.076 5933.448)" fill="#f05d43"/>
                                    </g>
                                </svg>
                              </div>
                            </div>`;
        this.loaderElement = this.renderer.createElement('div');
        this.loaderElement.classList.add('spinner-container');
        this.loaderElement.innerHTML = loaderHtml;
        this.circularElement = this.loaderElement.querySelector('svg.circular');
    }

    ngOnInit(): void {
        this.fixedPosition && this.setSpinnerPosition('fixed');
    }

    ngOnDestroy(): void {
        this.subscription?.unsubscribe();
    }

    private blockUI(): void {
        this.renderer.appendChild(
            this.elementRef.nativeElement,
            this.loaderElement
        );
    }

    private unblockUI(): void {
        this.renderer.removeChild(
            this.elementRef.nativeElement,
            this.loaderElement
        );
    }

    private setSpinnerPosition(position: 'fixed' | 'relative'): void {
        this.circularElement &&
            this.renderer.setStyle(this.circularElement, 'position', position);
    }
}
