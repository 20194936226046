import {
    AbstractControl,
    FormArray,
    FormControl,
    ValidationErrors,
    Validator,
} from '@angular/forms';

import { DynamicValidator, Group, Rule } from './dynamic';

export class MinLengthValidator extends DynamicValidator implements Validator {
    constructor(
        private readonly minLength: number,
        private readonly message?: string,
        rules?: Array<Rule | Group>
    ) {
        super(rules);
    }

    validate(control: AbstractControl): ValidationErrors | null {
        if (control instanceof FormControl || control instanceof FormArray) {
            if (
                control.value === null ||
                control.value === undefined ||
                (typeof control.value === 'string' &&
                    control.value.length >= this.minLength) ||
                (control.value instanceof Array &&
                    control.value.length >= this.minLength)
            ) {
                return null;
            }

            return {
                minlength: {
                    current: control.value,
                    requiredLength: this.minLength,
                    message: this.message,
                },
            };
        }

        return null;
    }
}
