import {
    AbstractControl,
    FormControl,
    ValidationErrors,
    Validator,
} from '@angular/forms';

import * as moment from 'moment';

import { Constants } from '@shared/constants';

import { DynamicValidator, Group, Rule } from './dynamic';

export class GreaterThanDateTimeValidator
    extends DynamicValidator
    implements Validator
{
    constructor(
        private readonly controlToCompareName: string,
        private readonly invalidDateMessage: string,
        private readonly invalidTimeMessage: string,
        rules?: Array<Rule | Group>
    ) {
        super(rules);
    }

    validate(control: AbstractControl): ValidationErrors | null {
        return super.validateControl(control, () => {
            if (control instanceof FormControl) {
                if (
                    !control.parent ||
                    !control.value ||
                    control.value.toString() === Constants.INVALID_DATE
                ) {
                    return null;
                }

                const dateTimeToValidate = moment(control.value);
                const controlToCompare: FormControl =
                    control.parent.controls[this.controlToCompareName];
                const dateTimeToCompare = moment(controlToCompare.value);
                let message = '';

                if (
                    dateTimeToValidate.isSameDate(dateTimeToCompare) &&
                    dateTimeToValidate.valueOf() <= dateTimeToCompare.valueOf()
                ) {
                    message = this.invalidTimeMessage;
                } else if (
                    dateTimeToValidate.diff(dateTimeToCompare, 'm') <= 0
                ) {
                    message = this.invalidDateMessage;
                }

                if (message.length) {
                    return {
                        greaterThanDateTime: {
                            message,
                        },
                    };
                }
            }

            return null;
        });
    }
}
