import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TranslocoModule } from '@ngneat/transloco';

import { IconModule } from '@shared/icon/icon.module';
import { MaterialModule } from '@shared/material.module';

import { ValidationMessageModule } from '../validation-message/validation-message.module';
import { SelectSearchComponent } from './select-search.component';

@NgModule({
    declarations: [SelectSearchComponent],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslocoModule,
        ValidationMessageModule,
        MaterialModule,
        FlexLayoutModule,
        IconModule,
    ],

    exports: [SelectSearchComponent],
})
export class SelectSearchModule {}
