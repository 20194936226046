import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    DoCheck,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { FormControl, FormGroupDirective } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';

import {
    ControlValueAccessor,
    controlValueAccessorProvider,
} from '../control-value-accessor';
import { SelectItem, SelectItemSingle } from './select-item.model';

@Component({
    selector: 'vsc-select',
    templateUrl: './select.component.html',
    styleUrls: ['./select.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [controlValueAccessorProvider(VSCSelectComponent as Component)],
})
export class VSCSelectComponent
    extends ControlValueAccessor<string | string[]>
    implements AfterViewInit, OnInit, DoCheck
{
    @Input() formControlName: string;
    @Input() placeholder: string;
    @Input() sourceProcId: string;
    @Input() multiple: boolean;
    @Input() label: string;
    @Input() readonly: boolean;
    @Input() hint: string = '';
    @Input() infoTooltipHint: string;
    @Input() valueRef: any;
    @Input() isTranslatable: boolean = false;
    @Output() readonly selectionChanged: EventEmitter<SelectItem> =
        new EventEmitter<SelectItem>();
    formControl: FormControl;
    @ViewChild(MatSelect, { static: false }) matSelect: MatSelect;
    private items: Array<SelectItemSingle>;
    get options(): Array<SelectItemSingle> {
        // transform value for display
        return this.items;
    }

    @Input() set options(items: Array<SelectItemSingle>) {
        const itemsData = items ? [...items] : [];
        this.items = itemsData;
    }

    @Input() textMapper = (option: any) => {
        return option['name'];
    };
    @Input() valueMapper = (option: any) => {
        return option['id'];
    };
    @Input() shouldDisplayOption = (option: any) => {
        return true;
    };

    constructor(
        private readonly formGroupDirective: FormGroupDirective,
        private readonly errorStateMatcher: ErrorStateMatcher
    ) {
        super();
    }

    ngOnInit(): void {
        this.formControl = this.formGroupDirective.control.get(
            this.formControlName
        ) as FormControl;
    }

    ngAfterViewInit(): void {
        this.matSelect.ngControl = null;
    }

    ngDoCheck(): void {
        if (this.matSelect) {
            this.matSelect.errorState = this.errorStateMatcher.isErrorState(
                this.formControl,
                this.formGroupDirective
            );
            this.matSelect.stateChanges.next();
        }
    }

    __onSelectionChange(value: string): void {
        this.value = value === '' ? null : value;
        this.selectionChanged.emit(
            this.options && this.options.find((x) => x.id === value)
        );
    }

    __onMultiSelectionChange(value: string[]): void {
        this.value = value.length === 0 ? null : value;

        this.selectionChanged.emit(
            this.options &&
                this.options.filter((x) => value.includes(x.id.toString()))
        );
    }

    compareFn(o1: any, o2: any): boolean {
        return o1 == o2;
    }
}
