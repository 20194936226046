import { Injectable } from '@angular/core';

import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

import { UserSearchQuery } from '../services/user.service';
import { User } from './user';

export interface UsersState extends EntityState<User> {
    selectedRoleIds: string[];
    search: UserSearchQuery;
}

export const initialState: UsersState = {
    loading: false,
    selectedRoleIds: [],
    search: {} as UserSearchQuery,
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'users' })
export class UsersStore extends EntityStore<UsersState, User> {
    constructor() {
        super(initialState);
    }
}
