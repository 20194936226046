import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';

import { TranslocoModule } from '@ngneat/transloco';

import { IconModule } from '@shared/icon/icon.module';

import { CopyToClipboardComponent } from './copy-to-clipboard.component';

@NgModule({
    declarations: [CopyToClipboardComponent],
    imports: [CommonModule, MatTooltipModule, IconModule, TranslocoModule],
    exports: [CopyToClipboardComponent],
    providers: [],
})
export class CopyToClipboardModule {}
