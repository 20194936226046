import {
    AbstractControl,
    ValidationErrors,
    Validator,
    FormControl,
} from '@angular/forms';

import * as moment from 'moment';

import { DynamicValidator, Group, Rule } from './dynamic';

export class GreaterThanValidator
    extends DynamicValidator
    implements Validator
{
    constructor(
        private readonly controlToValidateName: string,
        private readonly controlToCompareName: string,
        private readonly message?: string,
        rules?: Array<Rule | Group>
    ) {
        super(rules);
    }

    validate(controlToValidate: AbstractControl): ValidationErrors | null {
        const start = controlToValidate.value;
        if (
            controlToValidate.parent !== undefined &&
            start !== undefined &&
            controlToValidate.parent !== null
        ) {
            const controlToCompare: FormControl =
                controlToValidate.parent.controls[this.controlToCompareName];
            const end = controlToCompare.value;

            if (
                start === undefined ||
                start === null ||
                end === undefined ||
                end === null ||
                (!isNaN(start) && +start > +end) ||
                (isNaN(start) && moment(start).isAfter(end))
            ) {
                if (controlToCompare.invalid) {
                    controlToCompare.updateValueAndValidity({
                        onlySelf: true,
                        emitEvent: false,
                    });
                }

                return undefined;
            }

            return {
                greaterThan: {
                    greaterThan: true,
                    controlToValidateName: this.controlToValidateName,
                    controlToCompareName: this.controlToCompareName,
                    message: this.message,
                },
            };
        }

        return null;
    }
}
