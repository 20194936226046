import { Injectable } from '@angular/core';

import { QueryEntity } from '@datorama/akita';

import { Observable } from 'rxjs';

import { NotificationEventReminder } from './notification-event-reminder';
import {
    NotificationEventRemindersState,
    NotificationEventRemindersStore,
} from './notification-event-reminders.store';

@Injectable({
    providedIn: 'root',
})
export class NotificationEventRemindersQuery extends QueryEntity<
    NotificationEventRemindersState,
    NotificationEventReminder
> {
    constructor(protected store: NotificationEventRemindersStore) {
        super(store);
    }

    selectUserNotificationEventReminders(
        userId: string
    ): Observable<Array<NotificationEventReminder>> {
        if (!userId || userId.length === 0) {
            throw new Error('User cannot be null');
        }
        return this.select((x) => x[userId]);
    }
}
