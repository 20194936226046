import {
    AbstractControl,
    FormControl,
    ValidationErrors,
    Validator,
} from '@angular/forms';

import { DynamicValidator, Group, Rule } from './dynamic';

export class EmailValidator extends DynamicValidator implements Validator {
    constructor(
        private readonly message?: string,
        rules?: Array<Rule | Group>
    ) {
        super(rules);
    }

    validate(control: AbstractControl): ValidationErrors | null {
        if (control instanceof FormControl) {
            const value: string = control.value;
            const emailPattern =
                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (value != null && value.trim() && !emailPattern.test(value)) {
                return {
                    email: {
                        email: value,
                        message: this.message,
                    },
                };
            }
        }

        return null;
    }
}
