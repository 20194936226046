import { ComponentType } from '@angular/cdk/portal';
import { InjectionToken } from '@angular/core';

export interface ToastOptions {
    toastClass?: string | string[];
    duration?: number;
    onClick?: (id: string) => void;
}

export const TOAST_DATA: InjectionToken<{}> = new InjectionToken<{}>(
    'TOAST_DATA'
);

export const TOAST_CONFIG: InjectionToken<ToastOptions> =
    new InjectionToken<ToastOptions>('TOAST_CONFIG');

export const DEFAULT_TOAST_CONFIG: ToastOptions = {
    toastClass: '',
    duration: 5000,
};

export class Toast {
    id?: string;
    title?: string;
    message: string;
    icon?: string;
    options?: ToastOptions;
    active?: boolean = true;
    toastComponent?: ComponentType<any>;
    data?: any;

    constructor(toast: Partial<Toast>) {
        Object.assign(this, toast);
    }
}
