import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';

import { TranslocoModule } from '@ngneat/transloco';

import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { IconModule } from '@shared/icon/icon.module';
import { JoinChatModule } from '@shared/join-chat/join-chat.module';
import { JoinClassModule } from '@shared/join-class/join-class.module';
import { ListViewModule } from '@shared/list-view/list-view.module';
import { MatSpinnerDirectiveModule } from '@shared/mat-spinner/mat-spinner.module';
import { MaterialModule } from '@shared/material.module';
import { PipesModule } from '@shared/pipes/pipes.module';

import { AllNotificationsComponent } from './all-notifications.component';
import { NOTIFICATION_DELEGATE_PROVIDERS } from './delegates/notification-action';
import { NotificationActionComponent } from './notification-action/notification-action.component';
import { NotificationItemComponent } from './notification-item/notification-item.component';
import { NotificationToastComponent } from './notification-toast/notification-toast.component';
import { NotificationsPanelTriggerComponent } from './notifications-panel-trigger/notifications-panel-trigger.component';
import { NotificationsPanelComponent } from './notifications-panel/notifications-panel.component';

@NgModule({
    imports: [
        CommonModule,
        FlexLayoutModule,
        PipesModule,
        MatButtonModule,
        TranslocoModule,
        JoinClassModule,
        JoinChatModule,
        IconModule,
        MaterialModule,
        RouterModule,
        ListViewModule,
        InfiniteScrollModule,
        MatSpinnerDirectiveModule,
    ],
    declarations: [
        NotificationToastComponent,
        NotificationActionComponent,
        AllNotificationsComponent,
        NotificationItemComponent,
        NotificationsPanelComponent,
        NotificationsPanelTriggerComponent,
    ],
    exports: [
        NotificationToastComponent,
        NotificationsPanelComponent,
        NotificationsPanelTriggerComponent,
    ],
    providers: [...NOTIFICATION_DELEGATE_PROVIDERS],
})
export class NotificationsModule {}
