import {
    Component,
    Inject,
    OnDestroy,
    ChangeDetectionStrategy,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NavigationExtras, Router } from '@angular/router';

import { Subscription } from 'rxjs';

export interface LeaveChatData {
    chatId: string;
    redirectUrl: string;
    title: string;
}

@Component({
    templateUrl: './leave-chat.component.html',
    styleUrls: ['./leave-chat.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LeaveChatComponent implements OnDestroy {
    private subscriptions: Array<Subscription> = [];

    constructor(
        private dialogRef: MatDialogRef<LeaveChatComponent>,
        @Inject(MAT_DIALOG_DATA) public data: LeaveChatData,
        private router: Router
    ) {}

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription) =>
            subscription.unsubscribe()
        );
    }

    leaveChat(): void {
        const navigationExtras: NavigationExtras = {
            state: {
                byPassLeaveGuard: 1,
            },
        };
        this.router.navigate([this.data.redirectUrl], navigationExtras);
        this.dialogRef.close();
    }

    cancel(): void {
        this.dialogRef.close();
    }
}
