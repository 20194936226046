import {
    Directive,
    Host,
    Optional,
    Renderer2,
    Self,
    ViewContainerRef,
    Input,
    OnInit,
    ChangeDetectorRef,
    AfterViewInit,
    OnDestroy,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';

import { TranslocoService } from '@ngneat/transloco';

import { Subscription } from 'rxjs';

import { CollectionViewComponent } from './collection-view.component';

@Directive({
    selector: '[vsc-style-paginator]',
})
export class StylePaginatorDirective
    implements OnInit, AfterViewInit, OnDestroy
{
    private currentPage: number = 1;
    private pageGapTxt: string = '...';
    private rangeStart: number;
    private rangeEnd: number;
    private buttons: Array<any> = [];
    private subscriptions: Array<Subscription> = [];

    @Input()
    get showTotalPages(): number {
        return this.totalPages;
    }
    set showTotalPages(value: number) {
        this.totalPages = value % 2 === 0 ? value + 1 : value;
    }
    private totalPages: number = 2;

    constructor(
        @Host() @Self() @Optional() private readonly matPag: MatPaginator,
        private vr: ViewContainerRef,
        private ren: Renderer2,
        private translocoService: TranslocoService,
        private changeDetector: ChangeDetectorRef,
        private collectionViewComponent: CollectionViewComponent
    ) {
        // Sub to rerender buttons when next page and last page is used
        this.subscriptions.push(
            this.matPag.page.subscribe((v) => {
                this.switchPage(v.pageIndex, false);
            })
        );

        this.subscriptions.push(
            this.collectionViewComponent.pageLoaded.subscribe(() => {
                setTimeout(() => {
                    this.initPageRange();
                });
            })
        );
    }

    ngOnInit(): void {
        this.subscriptions.push(
            this.translocoService.langChanges$.subscribe(() => this.translate())
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription) =>
            subscription.unsubscribe()
        );
    }

    private translate(): void {
        const nextPageNode = this.vr.element.nativeElement.querySelector(
            'button.mat-paginator-navigation-next'
        );
        const previousPageNode = this.vr.element.nativeElement.querySelector(
            'button.mat-paginator-navigation-previous'
        );
        nextPageNode.innerText = this.translocoService.translate('Next');
        previousPageNode.innerText =
            this.translocoService.translate('Previous');
    }

    private buildPageNumbers(): void {
        const actionContainer = this.vr.element.nativeElement.querySelector(
            'div.mat-paginator-range-actions'
        );

        const nextPageNode = this.vr.element.nativeElement.querySelector(
            'button.mat-paginator-navigation-next'
        );
        this.ren.addClass(actionContainer, 'range-container');
        const prevButtonCount = this.buttons.length;

        // remove buttons before creating new ones
        if (this.buttons.length > 0) {
            this.buttons.forEach((button) => {
                this.ren.removeChild(actionContainer, button);
            });
            // Empty state array
            this.buttons.length = 0;
        }

        // initialize next page and last page buttons
        if (this.buttons.length === 0) {
            const nodeArray =
                this.vr.element.nativeElement.childNodes[0].childNodes[0]
                    .childNodes[2].childNodes;
            setTimeout(() => {
                // tslint:disable-next-line: prefer-for-of
                for (let i = 0; i < nodeArray.length; i++) {
                    if (nodeArray[i].nodeName === 'BUTTON') {
                        if (nodeArray[i].disabled) {
                            this.ren.setStyle(
                                nodeArray[i],
                                'background-color',
                                '#2b76d6'
                            );
                            this.ren.setStyle(nodeArray[i], 'color', '#2b76d6');
                            this.ren.setStyle(nodeArray[i], 'margin', '0');
                            this.ren.setStyle(
                                nodeArray[i],
                                'border',
                                '1px solid #2b76d6'
                            );
                            this.ren.setStyle(
                                nodeArray[i],
                                'border-radius',
                                '8px'
                            );
                            this.ren.setStyle(nodeArray[i], 'padding', '16px');
                            this.ren.setStyle(nodeArray[i], 'height', '56px');
                            this.ren.setStyle(nodeArray[i], 'width', 'auto');
                            this.ren.setStyle(
                                nodeArray[i],
                                'font-size',
                                '24px'
                            );
                            this.ren.setStyle(
                                nodeArray[i],
                                'background-color',
                                '#FFFFFF'
                            );
                            this.ren.setStyle(nodeArray[i], 'opacity', '0.64');
                        } else {
                            this.ren.setStyle(
                                nodeArray[i],
                                'background-color',
                                '#2b76d6'
                            );
                            this.ren.setStyle(nodeArray[i], 'color', 'white');
                            this.ren.setStyle(nodeArray[i], 'margin', '0');
                            this.ren.setStyle(
                                nodeArray[i],
                                'border',
                                '1px solid #2b76d6'
                            );
                            this.ren.setStyle(
                                nodeArray[i],
                                'border-radius',
                                '8px'
                            );
                            this.ren.setStyle(nodeArray[i], 'padding', '16px');
                            this.ren.setStyle(nodeArray[i], 'height', '56px');
                            this.ren.setStyle(nodeArray[i], 'width', 'auto');
                            this.ren.setStyle(
                                nodeArray[i],
                                'font-size',
                                '24px'
                            );
                            this.ren.setStyle(nodeArray[i], 'opacity', '1');
                        }
                    }
                }
            });
        }

        let dots = false;
        let container: any = document.querySelector('.button-container');
        if (!container) {
            container = this.ren.createElement('div');
            this.ren.addClass(container, 'button-container');
            this.ren.insertBefore(actionContainer, container, nextPageNode);
            this.ren.setStyle(container, 'display', 'flex');
            this.ren.setStyle(container, 'align-items', 'center');
            this.ren.setStyle(container, 'justify-content', 'center');
        }

        for (let i = 0; i < this.matPag.getNumberOfPages(); i = i + 1) {
            if (
                (i < this.totalPages &&
                    this.currentPage < this.totalPages &&
                    i > this.rangeStart) ||
                (i >= this.rangeStart && i <= this.rangeEnd)
            ) {
                this.ren.appendChild(
                    container,
                    this.createButton(i, this.matPag.pageIndex)
                );
            } else {
                if (i > this.rangeEnd && !dots) {
                    this.ren.appendChild(
                        container,
                        this.createButton(
                            this.pageGapTxt,
                            this.matPag.pageIndex
                        )
                    );
                    dots = true;
                }
            }
        }
        this.removeNotNeeded();
    }

    private createButton(i: any, pageIndex: number): any {
        const linkBtn = this.ren.createElement('mat-button');
        this.ren.addClass(linkBtn, 'range-button');
        this.ren.setStyle(linkBtn, 'height', '56px');
        this.ren.setStyle(linkBtn, 'width', '56px');
        this.ren.setStyle(linkBtn, 'border', '1px solid #2b76d6');
        this.ren.setStyle(linkBtn, 'display', 'flex');
        this.ren.setStyle(linkBtn, 'align-items', 'center');
        this.ren.setStyle(linkBtn, 'justify-content', 'center');
        this.ren.setStyle(linkBtn, 'background-color', '#FFF');
        this.ren.setStyle(linkBtn, 'font-size', '20px');
        this.ren.setStyle(linkBtn, 'font-weight', 'bold');
        this.ren.setStyle(linkBtn, 'color', '#2b76d6');
        this.ren.setStyle(linkBtn, 'cursor', 'pointer');

        const pagingTxt = isNaN(i) ? this.pageGapTxt : +(i + 1);
        const text = this.ren.createText(pagingTxt + '');

        this.ren.addClass(linkBtn, 'mat-custom-page');
        switch (i) {
            case pageIndex:
                this.ren.setAttribute(linkBtn, 'disabled', 'disabled');
                this.ren.setStyle(linkBtn, 'background-color', '#2b76d6');
                this.ren.setStyle(linkBtn, 'color', '#fff');
                this.ren.setStyle(linkBtn, 'cursor', 'default');
                break;
            case this.pageGapTxt:
                this.ren.listen(linkBtn, 'click', () => {
                    this.switchPage(this.currentPage + this.totalPages, true);
                });
                break;
            default:
                this.ren.listen(linkBtn, 'click', () => {
                    this.switchPage(i, true);
                });
                break;
        }

        this.ren.appendChild(linkBtn, text);
        // Add button to private array for state
        this.buttons.push(linkBtn);
        return linkBtn;
    }

    private initPageRange(): void {
        this.rangeStart = this.currentPage - this.totalPages / 2;
        this.rangeEnd = this.currentPage + this.totalPages / 2;

        this.buildPageNumbers();
    }

    private switchPage(i: number, emitChange: boolean): void {
        this.currentPage = i;

        if (emitChange) {
            this.matPag.page.emit({
                previousPageIndex: this.matPag.pageIndex,
                pageIndex: i,
                pageSize: this.matPag.pageSize,
                length: this.matPag.length,
            });
        }
        this.matPag.pageIndex = i;

        this.initPageRange();
    }

    ngAfterViewInit(): void {
        this.initPageRange();
    }

    private removeNotNeeded(): void {
        this.vr.element.nativeElement
            .querySelector('.mat-paginator-range-label')
            ?.remove();

        const pageSizeElement = this.vr.element.nativeElement.querySelector(
            '.mat-paginator-page-size'
        );
        pageSizeElement && (pageSizeElement.style = 'display:none');
    }
}
