<div fxFlex fxLayout="column">
    <label *ngIf="label">
        <div fxLayout="row" fxLayoutGap="4">
            <span
                >{{ label | transloco }}
                {{ isRequired(formControl) ? '*' : '' }}</span
            >
            <vsc-icon
                *ngIf="this.infoTooltipHint"
                [matTooltip]="this.infoTooltipHint | transloco"
                class="vsc-icon-hint"
                iconStyle="solid"
                color="accent"
                >circle-info</vsc-icon
            >
        </div>
    </label>
    <mat-form-field
        [class.pt-17]="!label"
        appearance="outline"
        floatLabel="never"
        *ngLet="placeholder | transloco as translatedPlaceholder"
    >
        <mat-select
            *ngIf="multiple"
            multiple
            [placeholder]="translatedPlaceholder"
            [aria-label]="translatedPlaceholder"
            (blur)="onBlur(formControl)"
            (selectionChange)="__onMultiSelectionChange($event.value)"
            [value]="formControl?.value != null ? formControl.value : ''"
            appearance="outline"
            [disabled]="readonly"
        >
            <mat-option
                *ngFor="let item of options"
                [value]="valueMapper(item)"
            >
                {{
                    isTranslatable
                        ? (textMapper(item) | transloco)
                        : textMapper(item)
                }}
            </mat-option>
        </mat-select>
        <mat-select
            *ngIf="!multiple"
            [compareWith]="compareFn"
            [placeholder]="translatedPlaceholder"
            [aria-label]="translatedPlaceholder"
            (blur)="onBlur(formControl)"
            (selectionChange)="__onSelectionChange($event.value)"
            [value]="valueRef || value"
            appearance="outline"
            [disabled]="readonly"
        >
            <ng-container *ngFor="let item of options">
                <mat-option
                    [value]="valueMapper(item)"
                    *ngIf="shouldDisplayOption(item)"
                >
                    {{
                        isTranslatable
                            ? (textMapper(item) | transloco)
                            : textMapper(item)
                    }}
                </mat-option>
            </ng-container>
        </mat-select>
        <mat-hint *ngIf="hint !== ''">{{ hint | transloco }}</mat-hint>
        <mat-error>
            <vsc-validation-message
                *ngIf="formControl"
                [controlName]="formControlName"
            ></vsc-validation-message>
        </mat-error>
    </mat-form-field>
</div>
