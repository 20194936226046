import { Injectable } from '@angular/core';

import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

import { Organization } from './organization';

export type OrganizationsState = EntityState<Organization>;

export const initialState: OrganizationsState = {
    loading: false,
    statusChangeInProgress: false,
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'organizations' })
export class OrganizationsStore extends EntityStore<
    OrganizationsState,
    Organization
> {
    constructor() {
        super(initialState);
    }

    updateStatusChangeInProgress(statusChangeInProgress: boolean): void {
        this.update({ statusChangeInProgress });
    }
}
