import {
    HttpHandler,
    HttpRequest,
    HttpEvent,
    HttpErrorResponse,
    HttpInterceptor,
    HttpHeaders,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Observable, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';

import { Constants } from '@shared/constants';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
    constructor(private router: Router) {}

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        /**
         * If the request has the `X-Skip-Http-Error-Interceptor` header, then
         * skip the interceptor.
         */
        if (this.hasSkipHttpErrorInterceptorHeader(request?.headers)) {
            const req = this.clearSkipHttpErrorInterceptorHeader(request);
            return next.handle(req);
        }

        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                this.handleError(error);
                return throwError(() => error);
            }),
            finalize(() => {})
        ) as Observable<HttpEvent<any>>;
    }

    handleError(error: HttpErrorResponse) {
        switch (error.status) {
            case Constants.ERROR_STATUS_CODE_401:
            case Constants.ERROR_STATUS_CODE_403:
                this.navigateTo('unauthorized');
                break;

            case Constants.ERROR_STATUS_CODE_404:
                this.navigateTo('not-found');
                break;

            case Constants.ERROR_STATUS_CODE_400:
            case Constants.ERROR_STATUS_CODE_422:
                this.navigateTo('bad-request');
                break;

            default:
                break;
        }
    }

    navigateTo(route: string): void {
        if (this.router.url.includes('admin')) {
            this.router.navigate(['admin/' + route]);
        } else {
            this.router.navigate([route]);
        }
    }

    private clearSkipHttpErrorInterceptorHeader(
        req: HttpRequest<any>
    ): HttpRequest<any> {
        if (req) {
            const headers = req.headers.delete(
                Constants.SKIP_HTTP_ERROR_INTERCEPTOR_HEADER
            );

            return req.clone({ headers });
        }
    }

    /**
     * Checks if the given headers contain the `X-Skip-HttpError-Interceptor` header set to `true`.
     * @param headers The headers to check.
     * @returns `true` if the given headers contain the `X-Skip-HttpError-Interceptor` header set to `true`, `false` otherwise.
     */
    private hasSkipHttpErrorInterceptorHeader(headers: HttpHeaders): boolean {
        return (
            headers?.has(Constants.SKIP_HTTP_ERROR_INTERCEPTOR_HEADER) ?? false
        );
    }
}
