import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function dateWithoutDayValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const value = control.value;
        let invalid = false;

        if (value === null) {
            return null;
        } else {
            if (value.length < 7) {
                invalid = true;
            } else {
                const month = value.split('/')[0];
                const year = value.split('/')[1];
                if (
                    parseInt(year) < 1900 ||
                    parseInt(year) > new Date().getFullYear() ||
                    (parseInt(year) === new Date().getFullYear() &&
                        parseInt(month) > new Date().getMonth() + 1)
                ) {
                    invalid = true;
                }
            }
        }

        return invalid ? { invalidYear: true } : null;
    };
}
