import {
    animate,
    keyframes,
    state,
    style,
    transition,
    trigger,
} from '@angular/animations';

export const slideAnimation = trigger('slideAnimation', [
    state('stateOne', style({})),
    state('stateTwo', style({})),
    transition('stateOne <=> stateTwo', [
        animate(
            '500ms',
            keyframes([
                style({
                    opacity: 0.7,
                }),
                style({
                    opacity: 1,
                }),
            ])
        ),
    ]),
]);
