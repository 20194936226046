<form [formGroup]="formGroup" #ngForm="ngForm" (ngSubmit)="save()">
    <h1 mat-dialog-title>{{ surveyType | transloco }} {{ name }}</h1>
    <mat-dialog-content>
        <h3
            *ngIf="!data.isClassSurvey"
            class="subtitle"
            [innerHTML]="data.subtitle | transloco"
        ></h3>
        <div
            class="radio-type"
            *ngIf="
                data.surveyQuestionOptionType == surveyQuestionOptionType.Radio
            "
        >
            <div formArrayName="userAnswers">
                <div
                    *ngFor="
                        let userAnswerFormGroup of userAnswers.controls;
                        let i = index
                    "
                >
                    <div
                        [formGroup]="userAnswerFormGroup"
                        class="survey-question"
                    >
                        <div
                            class="question"
                            [class.invalid]="
                                userAnswerFormGroup.get(
                                    'surveyQuestionOptionId'
                                ).invalid && ngForm.submitted
                            "
                        >
                            {{ i + 1 }}.
                            {{
                                userAnswerFormGroup.get('question').value
                                    | transloco
                            }}
                        </div>
                        <div
                            class="error"
                            *ngIf="
                                userAnswerFormGroup.get(
                                    'surveyQuestionOptionId'
                                ).invalid && ngForm.submitted
                            "
                        >
                            {{ 'Please answer this question' | transloco }}
                        </div>
                        <div class="question-options">
                            <mat-radio-group
                                formControlName="surveyQuestionOptionId"
                                fxLayout="row"
                                fxLayoutGap="24px"
                            >
                                <div
                                    *ngFor="
                                        let option of data.survey
                                            .questionOptions
                                    "
                                >
                                    <mat-radio-button
                                        tabindex="-1"
                                        value="{{ option.id }}"
                                        >{{
                                            option.name | transloco
                                        }}</mat-radio-button
                                    >
                                </div>
                            </mat-radio-group>
                        </div>
                    </div>
                </div>
            </div>
            <vsc-input
                *ngIf="data.showCommentField"
                formControlName="comment"
                type="area"
                label="Any other comments?"
                placeholder="Any other comments?"
            >
            </vsc-input>
        </div>
        <div
            class="star-type"
            *ngIf="
                data.surveyQuestionOptionType == surveyQuestionOptionType.Star
            "
        >
            <div class="label-holder">
                <span>
                    {{ 'Poor' | transloco }}
                </span>
                <span>
                    {{ 'Fair' | transloco }}
                </span>
                <span>
                    {{ 'Average' | transloco }}
                </span>
                <span>
                    {{ 'Good' | transloco }}
                </span>
                <span>
                    {{ 'Excellent' | transloco }}
                </span>
            </div>
            <div formArrayName="userAnswers">
                <div
                    *ngFor="
                        let userAnswerFormGroup of userAnswers.controls;
                        let i = index
                    "
                >
                    <div
                        [formGroup]="userAnswerFormGroup"
                        class="survey-question"
                        fxLayout="row"
                    >
                        <div fxFlex="fill" fxLayoutAlign="start center">
                            <div
                                class="question"
                                [class.invalid]="
                                    userAnswerFormGroup.get(
                                        'surveyQuestionOptionId'
                                    ).invalid && ngForm.submitted
                                "
                            >
                                {{ i + 1 }}.
                                {{
                                    userAnswerFormGroup.get('question').value
                                        | transloco
                                }}
                            </div>
                            <div
                                class="error"
                                *ngIf="
                                    userAnswerFormGroup.get(
                                        'surveyQuestionOptionId'
                                    ).invalid && ngForm.submitted
                                "
                            >
                                {{ 'Please answer this question' | transloco }}
                            </div>
                        </div>
                        <div class="question-options" fxLayout="row">
                            <div
                                *ngFor="
                                    let option of data.survey.questionOptions
                                "
                            >
                                <button
                                    type="button"
                                    mat-icon-button
                                    (click)="
                                        userAnswerFormGroup.patchValue({
                                            surveyQuestionOptionId: option.id
                                        })
                                    "
                                    matTooltip="{{ option.name | transloco }}"
                                >
                                    <vsc-icon
                                        *ngIf="
                                            option.id !=
                                            userAnswerFormGroup.get(
                                                'surveyQuestionOptionId'
                                            ).value
                                        "
                                        >star</vsc-icon
                                    >
                                    <vsc-icon
                                        *ngIf="
                                            option.id ==
                                            userAnswerFormGroup.get(
                                                'surveyQuestionOptionId'
                                            ).value
                                        "
                                        iconStyle="solid"
                                        >star</vsc-icon
                                    >
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <vsc-input
                *ngIf="data.showCommentField"
                formControlName="comment"
                type="area"
                label="Any other comments?"
                placeholder="Any other comments?"
            >
            </vsc-input>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions>
        <div fxLayout="row" fxLayoutAlign="space-between start">
            <button
                mat-stroked-button
                (click)="skip()"
                color="primary"
                class="border"
            >
                {{ 'Skip' | transloco }}
            </button>
            <button type="submit" mat-flat-button color="primary">
                {{ 'Submit' | transloco }}
            </button>
        </div>
    </mat-dialog-actions>
</form>
