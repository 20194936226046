import { ID } from '@datorama/akita';

import { Moment } from 'moment';

import { UserClassSession } from '../class-session-state/user-class-session';

export class UserClass {
    id: ID;
    name: string;
    description: string;
    reccurence: string;
    reccurenceDescription: string;
    facilitator: string;
    facilitatorOther: string;
    imageUrl: string;
    brandedLogoUrl: string;
    language: string;
    isFavorite: boolean;
    isHostOrCoHost: boolean;
    startDateTime: Moment;
    endDate: Moment;
    scheduledClassTime: Moment;
    duration: number;
    classSessionId: ID;
    classSession: UserClassSession;
    classCategory: ClassCategory;
    isZoomEmbedded: boolean;
    isLiveStream: boolean;
    embedCode: string;
    hasAnySessionWithAttachments: boolean;
}

export interface ClassCategory {
    id: string;
    name: string;
    color: string;
}
