import { ID } from '@datorama/akita';

import * as moment from 'moment';

export class UserClassSession {
    id: ID;
    name: string;
    startDateTime: moment.Moment;
    duration: number;
    isHostOrCoHost: boolean;
    isZoomAlternativeHost: boolean;
    isClassRecorded: boolean;
    hasAnyMaterialsAdded: boolean;
    hasAnyRecordingsAdded: boolean;

    constructor(obj: any) {
        if (!obj) {
            return;
        }
        this.id = obj.id;
        this.name = obj.name;
        this.startDateTime = obj.startDateTime
            ? moment(obj.startDateTime)
            : null;
        this.duration = obj.duration;
        this.isHostOrCoHost = obj.isHostOrCoHost;
        this.isZoomAlternativeHost = obj.isZoomAlternativeHost;
        this.isClassRecorded = obj.isClassRecorded;
        this.hasAnyMaterialsAdded = obj.hasAnyMaterialsAdded;
        this.hasAnyRecordingsAdded = obj.hasAnyRecordingsAdded;
    }
}
