import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';

import { TranslocoModule } from '@ngneat/transloco';

import { FormsModule } from '@shared/forms/forms.module';
import { IconModule } from '@shared/icon/icon.module';
import { MaterialModule } from '@shared/material.module';

import { SurveyComponent } from './survey.component';

@NgModule({
    declarations: [SurveyComponent],
    imports: [
        CommonModule,
        TranslocoModule,
        MaterialModule,
        FormsModule,
        FlexLayoutModule,
        IconModule,
    ],
})
export class SurveyModule {}
