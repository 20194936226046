import { formatDate } from '@angular/common';

import * as moment from 'moment';

import { Constants } from '@shared/constants';

export {};

declare global {
    interface Date {
        addDays(days: number, useThis?: boolean): Date;
        isToday(): boolean;
        clone(): Date;
        isAnotherMonth(date: Date): boolean;
        isWeekend(): boolean;
        isSameDate(date: Date): boolean;
        getStringDate(): string;
        dateAdd(interval: string, units: number): Date;
        toApiStringFormat(): string;
        differenceInMinutesFrom(date: Date): number;
        toTimeFormat(): string;
        toDateFormatWithLeadingZeros();
        toApiDateTimeStringFormat(): string;
        isValid(): boolean;
        addMinutes(minutes: number): Date;
        toMoment(): moment.Moment;
        applyTimeZone(): Date;
    }

    interface DateConstructor {
        isDefaultTimezone: boolean;
        timezone: string;
        differenceInMonths(from: Date, to: Date): number;
        differenceInDays(from: Date, to: Date): number;
        differenceInYears(from: Date, to: Date): number;
        getNumberOfDaysInMonth(month: number, year: number): number;
        dateTimeNow(): Date;
        utcNow(): number;
    }
}

Date.prototype.toMoment = function (): moment.Moment {
    return moment(this);
};

Date.prototype.applyTimeZone = function (): Date {
    return moment(this).toDateObj();
};

Date.prototype.toApiStringFormat = function (): string {
    return this.isValid() ? formatDate(this, 'yyyy-MM-dd', 'en') : null;
};
/**
 * @param date  Date to start with
 * @param interval  One of: year, quarter, month, week, day, hour, minute, second
 * @param units  Number of units of the given interval to add.
 * Returns new date
 */
// tslint:disable-next-line: only-arrow-functions
Date.prototype.dateAdd = function (
    // date: Date,
    interval: string,
    units: number
): Date {
    let ret = new Date(this); // don't change original date
    const checkRollover = () => {
        if (ret.getDate() !== this.getDate()) {
            ret.setDate(0);
        }
    };
    switch (String(interval).toLowerCase()) {
        case 'year':
            ret.setFullYear(ret.getFullYear() + units);
            checkRollover();
            break;
        case 'quarter':
            ret.setMonth(ret.getMonth() + 3 * units);
            checkRollover();
            break;
        case 'month':
            ret.setMonth(ret.getMonth() + units);
            checkRollover();
            break;
        case 'week':
            ret.setDate(ret.getDate() + 7 * units);
            break;
        case 'day':
            ret.setDate(ret.getDate() + units);
            break;
        case 'hour':
            ret.setTime(ret.getTime() + units * 3600000);
            break;
        case 'minute':
            ret.setTime(ret.getTime() + units * 60000);
            break;
        case 'second':
            ret.setTime(ret.getTime() + units * 1000);
            break;
        default:
            ret = undefined;
            break;
    }
    return ret;
};

Date.prototype.differenceInMinutesFrom = function (date: Date): number {
    const difference = date.getTime() - this.getTime();
    const resultInMinutes = Math.round(difference / 60000);
    return resultInMinutes;
};

Date.differenceInDays = (from: Date, to?: Date) => {
    if (to == null) {
        return 1;
    }
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;

    const utc1 = Date.UTC(from.getFullYear(), from.getMonth(), from.getDate());
    const utc2 = Date.UTC(to.getFullYear(), to.getMonth(), to.getDate());
    return Math.floor((utc2 - utc1) / _MS_PER_DAY);
};

Date.differenceInYears = (from: Date, to?: Date) => {
    if (to == null) {
        return 0;
    }
    return to.getFullYear() - from.getFullYear();
};

Date.differenceInMonths = (from: Date, to?: Date) => {
    if (to == null) {
        return 0;
    }
    let months =
        to.getMonth() -
        from.getMonth() +
        12 * (to.getFullYear() - from.getFullYear());

    if (to.getDate() < from.getDate()) {
        months--;
    }

    return months;
};

Date.getNumberOfDaysInMonth = (month: number, year: number) => {
    if (!isNaN(month) && !isNaN(year)) {
        const date = new Date(year, month, 0);

        if (date?.isValid()) {
            return date.getDate();
        }
    }
    return null;
};

Date.dateTimeNow = () => {
    const timeZoneOffset = moment.tz(Date.timezone).utcOffset();
    return new Date(
        Date.utcNow() +
            timeZoneOffset * Constants.MS_PER_MINUTE +
            new Date().getTimezoneOffset() * Constants.MS_PER_MINUTE
    );
};

Date.utcNow = () => {
    const date = new Date();
    return Date.UTC(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate(),
        date.getUTCHours(),
        date.getUTCMinutes(),
        date.getUTCSeconds(),
        date.getUTCMilliseconds()
    );
};

Date.prototype.isToday = function (): boolean {
    const today = Date.dateTimeNow();
    return this.isSameDate(today);
};

Date.prototype.clone = function (): Date {
    return new Date(+this);
};

Date.prototype.isAnotherMonth = function (date: Date): boolean {
    return date && this.getMonth() !== date.getMonth();
};
Date.prototype.isWeekend = function (): boolean {
    return this.getDay() === 0 || this.getDay() === 6;
};

Date.prototype.isSameDate = function (date: Date): boolean {
    return (
        date &&
        this.getFullYear() === date.getFullYear() &&
        this.getMonth() === date.getMonth() &&
        this.getDate() === date.getDate()
    );
};

Date.prototype.toTimeFormat = function (): string {
    return formatDate(this, Constants.TIME_FORMAT_24h, 'en');
};

Date.prototype.toApiDateTimeStringFormat = function (): string {
    return `${this.toApiStringFormat()}T${this.toTimeFormat()}`;
};

Date.prototype.toDateFormatWithLeadingZeros = function (): string {
    return this?.isValid()
        ? ('0' + +(this.getMonth() + 1)).slice(-2) +
              '/' +
              ('0' + this.getDate()).slice(-2) +
              '/' +
              ('000' + this.getFullYear()).slice(-4)
        : null;
};

Date.prototype.isValid = function (): boolean {
    return this && this.toString() !== Constants.INVALID_DATE;
};
