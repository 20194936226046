import { Component, Output, EventEmitter } from '@angular/core';

import { NgcCookieConsentService } from 'ngx-cookieconsent';

@Component({
    selector: 'vsc-cookie-settings',
    templateUrl: './cookie-settings.component.html',
    styleUrls: ['./cookie-settings.component.scss'],
})
export class CookieSettingsComponent {
    isGACookieEnabled = true;
    @Output() cookieStatusChanged = new EventEmitter<boolean>();
    constructor(private cookieConsentService: NgcCookieConsentService) {
        if (this.cookieConsentService.hasAnswered()) {
            this.isGACookieEnabled = this.cookieConsentService.hasConsented();
        }
    }

    onToggle($event): void {
        this.cookieStatusChanged.emit($event.checked);
    }
}
