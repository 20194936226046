import {
    ChangeDetectionStrategy,
    Component,
    Inject,
    OnInit,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { take } from 'rxjs/operators';

import { Validators } from '@shared/forms/validators/validators';

import { SurveyDialogData } from './models/survey-dialog-data.model';
import { SurveyQuestionOptionType } from './models/survey-question-option-type.enum';
import { SurveyTypeName } from './models/survey-type.enum';
import { UserSurvey } from './models/user-survey.model';
import { SurveyDataService } from './services/survey.dataservice';

@Component({
    templateUrl: './survey.component.html',
    styleUrls: ['./survey.component.scss'],
    providers: [FormBuilder],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SurveyComponent implements OnInit {
    formGroup: FormGroup;
    surveyQuestionOptionType: any = SurveyQuestionOptionType;
    surveyType: string = '';
    name: string = '';
    classSessionId: string = '';

    constructor(
        private formBuilder: FormBuilder,
        private dialogRef: MatDialogRef<SurveyComponent>,
        private surveyDataService: SurveyDataService,
        @Inject(MAT_DIALOG_DATA) public data: SurveyDialogData
    ) {}

    ngOnInit(): void {
        this.classSessionId = this.data.classSessionId;
        this.formGroup = this.formBuilder.group({
            surveyId: [this.data.survey.id],
            userAnswers: this.formBuilder.array(
                this.data.survey.questions.map((surveyQuestion) =>
                    this.formBuilder.group({
                        question: [surveyQuestion.question],
                        surveyQuestionId: [surveyQuestion.id],
                        surveyQuestionOptionId: [null, Validators.required()],
                    })
                )
            ),
            comment: [null, [Validators.maxLength(500)]],
        });
        if (this.data.isClassSurvey) {
            this.surveyType = SurveyTypeName.ClassAssessment;
            this.name = this.data.title;
        } else {
            this.surveyType = SurveyTypeName.SelfAssessment;
        }
    }

    get userAnswers(): FormArray {
        return this.formGroup.get('userAnswers') as FormArray;
    }

    skip(): void {
        const userSurvey = this.formGroup.value as UserSurvey;
        userSurvey.comment = null;
        userSurvey.userAnswers = null;
        userSurvey.classSessionId = this.classSessionId;
        this.surveyDataService
            .saveUserSurvey(userSurvey)
            .pipe(take(1))
            .subscribe();
        this.dialogRef.close();
    }

    save(): void {
        if (this.formGroup.valid) {
            const userSurvey = this.formGroup.value as UserSurvey;
            userSurvey.classSessionId = this.classSessionId;
            this.surveyDataService
                .saveUserSurvey(userSurvey)
                .pipe(take(1))
                .subscribe();
            this.dialogRef.close();
        }
    }
}
