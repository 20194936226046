import {
    AbstractControl,
    AsyncValidator as AngularAsyncValidator,
    ValidationErrors,
} from '@angular/forms';

import { Observable, timer, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

import { ValidationMessage } from '../validation-message/validation-message';
import { Group, Rule } from './dynamic';

export class AsyncValidator implements AngularAsyncValidator {
    // TO DO: Implemented dynamic validation. It is throwing an error in production mode currentley
    constructor(
        private readonly func: (
            value: any
        ) => Observable<boolean | ValidationMessage>,
        private readonly messageBuilder: (
            value: string
        ) => string | Observable<string>,
        rules?: Array<Rule | Group>
    ) {}

    validate(c: AbstractControl): Observable<ValidationErrors | null> {
        if (c.value) {
            return timer(500).pipe(
                switchMap(() => {
                    return this.func
                        .bind(this)(c.value)
                        .pipe(
                            map((result: boolean | any) => {
                                if (result === true || result == null) {
                                    return undefined;
                                }
                                return {
                                    async: {
                                        message:
                                            this.messageBuilder != null
                                                ? this.messageBuilder(c.value)
                                                : result.message,
                                    },
                                };
                            })
                        );
                }),
                take(1)
            );
        }

        return of(undefined);
    }
}
