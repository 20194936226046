import { NgModule } from '@angular/core';

import { PipesModule } from '@shared/pipes/pipes.module';

import { VideoComponent } from './video.component';

@NgModule({
    declarations: [VideoComponent],
    exports: [VideoComponent],
    imports: [PipesModule],
})
export class VideoModule {}
