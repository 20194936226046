import { Injectable } from '@angular/core';

import { QueryEntity, combineQueries } from '@datorama/akita';
import { RouterQuery } from '@datorama/akita-ng-router-store';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { HelpPage } from './help-page';
import { HelpPageState, HelpPageStore } from './help-page.store';

@Injectable({
    providedIn: 'root',
})
export class HelpPageQuery extends QueryEntity<HelpPageState, HelpPage> {
    constructor(
        protected store: HelpPageStore,
        private routerQuery: RouterQuery
    ) {
        super(store);
    }

    selectActiveHelpPage$: Observable<HelpPage> = combineQueries([
        this.routerQuery.selectParams(),
        this.selectAll(),
    ]).pipe(
        map((combine) => {
            return combine[1].find((x) => x.id === combine[0]['help-id']);
        })
    );

    selectIsEditMode$: Observable<boolean> = this.routerQuery
        .selectParams('help-id')
        .pipe(map((value) => value !== undefined));

    getIsEditMode(): boolean {
        return this.routerQuery.getParams('help-id') !== undefined;
    }

    getEditingId(): string {
        return this.routerQuery.getParams('help-id');
    }

    getActiveHelpPage(): HelpPage {
        return this.getEntity(this.routerQuery.getParams('help-id'));
    }
}
