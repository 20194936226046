import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    HostListener,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { Router } from '@angular/router';

import { Order } from '@datorama/akita';

import { Observable, Subscription, take } from 'rxjs';

import { PagePaths } from '@shared/constants/page-paths';
import { PageRequest } from '@shared/table/page';

import { Notification } from '../models/notification';
import { NotificationsService } from '../services/notifications.service';
import { NotificationsSearchQuery } from '../state/notifications-search.query';
import { NotificationsQuery } from '../state/notifications.query';

@Component({
    selector: 'vsc-notifications-panel',
    templateUrl: './notifications-panel.component.html',
    styleUrls: ['./notifications-panel.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationsPanelComponent implements OnInit, OnDestroy {
    @Output() closePanelEvent: EventEmitter<void> = new EventEmitter<void>();
    isLoading$: Observable<boolean> = this.notificationsQuery.selectLoading();
    notifications$: Observable<Notification[]> =
        this.notificationsQuery.selectAll({
            sortBy: 'createdAt',
            sortByOrder: Order.DESC,
            limitTo: 6,
        });
    notificationIds: Array<string> = [];
    private subscriptions: Subscription[] = [];

    constructor(
        private notificationsQuery: NotificationsQuery,
        private notificationsService: NotificationsService,
        private router: Router
    ) {}

    @HostListener('document:click', ['$event']) onDocumentClick(): void {
        this.closePanelEvent.emit();
    }

    ngOnInit(): void {
        // Get newest user notifications
        const request: PageRequest<Notification> = {
            page: 0,
            size: 6,
        } as PageRequest<Notification>;

        const query: NotificationsSearchQuery = {
            search: '',
        };

        this.subscriptions.push(
            this.notificationsService
                .getNotifications(request, query)
                .subscribe()
        );

        this.subscriptions.push(
            this.notifications$.subscribe((resp: Notification[]) => {
                this.notificationIds = resp
                    .filter((item: Notification) => item.isRead === false)
                    .map((item: Notification) => item.id);
            })
        );
    }

    trackByFn(notification: Notification): string {
        return notification?.id;
    }

    redirectAndClose(): void {
        this.router.navigate([PagePaths.Public.Notifications]);
        this.close();
    }

    close(): void {
        this.closePanelEvent.emit();
    }

    markAllAsRead(): void {
        this.notificationsService
            .markNotificationsAsRead(
                this.notificationIds,
                this.notificationIds.length
            )
            .pipe(take(1))
            .subscribe();
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((sub: Subscription) => sub?.unsubscribe());
    }
}
