import { HttpClient } from '@angular/common/http';
import { Injectable, NgModule } from '@angular/core';

import {
    TRANSLOCO_LOADER,
    Translation,
    TranslocoLoader,
    TRANSLOCO_CONFIG,
    translocoConfig,
    TranslocoModule,
} from '@ngneat/transloco';

import { catchError, EMPTY, forkJoin, map, Observable, of } from 'rxjs';

import { environment } from '../environments/environment';
import { AuthService } from './core/auth/auth.service';

const isDEVOrQA = ['dev', 'qa'].includes(environment.namespace);

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
    /** Contains list of supported override translations */
    private readonly SUPPORTED_OVERRIDES = ['sepsis alliance'];
    constructor(private http: HttpClient, private authService: AuthService) {}

    getTranslation(lang: string): Observable<Translation> {
        return forkJoin([
            this.http.get<Translation>(`/assets/i18n/${lang}.json`),
            this.getOverrideTranslation(lang),
        ])
            .pipe(
                map((x) => {
                    return { ...x[0], ...x[1] };
                })
            )
            .pipe(
                catchError((value) => {
                    console.error('Failed to load translations.', value);
                    return EMPTY;
                })
            );
    }

    private getOverrideTranslation(lang: string): Observable<Translation> {
        const overrideTranslation = this.SUPPORTED_OVERRIDES.find(
            (val: string) => val === this.authService.tenantName?.toLowerCase()
        );

        if (!overrideTranslation) {
            return of([]);
        }

        return this.http
            .get<Translation>(
                `/assets/i18n/${overrideTranslation}/${lang}.json`
            )
            .pipe(
                catchError((value) => {
                    console.error(
                        'Failed to load override translations.',
                        value
                    );
                    return EMPTY;
                })
            );
    }
}

@NgModule({
    exports: [TranslocoModule],
    providers: [
        {
            provide: TRANSLOCO_CONFIG,
            useValue: translocoConfig({
                availableLangs: [
                    { id: 'en', label: 'English' },
                    { id: 'ar', label: 'العربية' },
                    { id: 'zh', label: '中文' },
                    { id: 'he', label: 'עִברִית' },
                    { id: 'ko', label: '한국어' },
                    { id: 'ru', label: 'Pусский' },
                    { id: 'es', label: 'Español' },
                    { id: 'vi', label: 'Tiếng Việt' },
                ],

                defaultLang: 'en',
                // Remove this option if your application doesn't support changing language in runtime.
                reRenderOnLangChange: true,
                prodMode: environment.production && !isDEVOrQA,
                flatten: {
                    aot: environment.production,
                },
                missingHandler: {
                    logMissingKey: !environment.production || isDEVOrQA,
                },
            }),
        },
        { provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader },
    ],
})
export class TranslocoRootModule {}
