import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { MaterialModule } from '@shared/material.module';
import { PublicCardsModule } from '@shared/public-cards/public-cards.module';
import { ScrollToTopModule } from '@shared/scroll-to-top/scroll-to-top.module';
import { SharedModule } from '@shared/shared.module';

import { AlertsComponent } from './alerts/alerts.component';
import { AnnouncementsComponent } from './announcements/announcements.component';
import { HomeRoutingModule } from './home-routing.module';
import { HomeComponent } from './home.component';
import { NewsComponent } from './news/news.component';
import { PromotionsComponent } from './promotions/promotions.component';
import { TodaysScheduleComponent } from './todays-schedule/todays-schedule.component';
import { WeatherComponent } from './weather/weather.component';

@NgModule({
    declarations: [
        HomeComponent,
        TodaysScheduleComponent,
        AnnouncementsComponent,
        WeatherComponent,
        NewsComponent,
        PromotionsComponent,
        AlertsComponent,
    ],
    imports: [
        CommonModule,
        HomeRoutingModule,
        MaterialModule,
        SharedModule,
        BrowserAnimationsModule,
        PublicCardsModule,
        InfiniteScrollModule,
        ScrollToTopModule,
    ],
})
export class HomeModule {}
