import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { environment } from '@environment/environment';

import { Language } from '../state/languages';

@Injectable({ providedIn: 'root' })
export class LanguageDataService {
    constructor(private httpClient: HttpClient) {}

    getLanguages(filterByOrganization: boolean = true): Observable<Language[]> {
        const params = new HttpParams({
            fromObject: {
                filterByOrganization: filterByOrganization,
            },
        });
        const uri = `${environment.apiUrl}/vsc/languages`;
        return this.httpClient.get<Language[]>(uri, {
            params,
        });
    }
    getConsumerLanguages(): Observable<Array<Language>> {
        return this.httpClient.get<Array<Language>>(
            environment.apiUrl + '/vsc/languages/consumer'
        );
    }
}
