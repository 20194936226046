import { Injectable } from '@angular/core';
import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
} from '@angular/router';

import { environment } from '@environment/environment';

import { AnalyticsService } from './analytics.service';
import { AppUnit } from './app-unit';
import { PlatformType } from './platform-type';
import { UserActivity } from './user-activity';

@Injectable({
    providedIn: 'root',
})
export class VisitPageGuard implements CanActivate {
    constructor(private analyticsService: AnalyticsService) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<boolean> {
        if (environment.userAnalyticsEnabled) {
            const userActivity = new UserActivity();
            userActivity.appUnit = route?.data?.appRoute as AppUnit;
            userActivity.route = state?.url?.toString();
            userActivity.platformType = PlatformType.Web;
            this.analyticsService.addUserActivity(userActivity);
        }
        return Promise.resolve(true);
    }
}
