import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';

import { TranslocoModule } from '@ngneat/transloco';

import { DirectiveModule } from '@shared/directives/directive.module';
import { ScrollToTopModule } from '@shared/scroll-to-top/scroll-to-top.module';

import { MaterialModule } from '../material.module';
import { ListViewComponent } from './list-view.component';

@NgModule({
    declarations: [ListViewComponent],
    imports: [
        CommonModule,
        FlexLayoutModule,
        MaterialModule,
        TranslocoModule,
        DirectiveModule,
        ScrollToTopModule,
    ],
    exports: [ListViewComponent],
})
export class ListViewModule {}
