import {
    AbstractControl,
    FormControl,
    ValidationErrors,
    Validator,
} from '@angular/forms';

import * as moment from 'moment';

import { DynamicValidator, Group, Rule } from './dynamic';

export class FutureDateTimeValidator
    extends DynamicValidator
    implements Validator
{
    constructor(
        private message: string,
        private messageResolver: () => string,
        private exceptDateResolver?: () => moment.Moment,
        rules?: Array<Rule | Group>
    ) {
        super(rules);
    }

    validate(control: AbstractControl): ValidationErrors | null {
        return super.validateControl(control, () => {
            if (control instanceof FormControl) {
                const isExceptDateIncluded = !!this.exceptDateResolver;
                const future = moment();

                const invalidFutureDateResult = {
                    futureDateTime: {
                        currentDate: control.value,
                        futureDateTime: future,
                        message: this.message,
                        messageResolver: this.messageResolver,
                    },
                };
                const dateToValidate = moment(control.value);

                if (isExceptDateIncluded) {
                    return dateToValidate.diff(
                        this.exceptDateResolver(),
                        'm'
                    ) === 0 || this.isValid(control, dateToValidate, future)
                        ? null
                        : invalidFutureDateResult;
                }

                future.addMinutes(1);

                if (this.isValid(control, dateToValidate, future)) {
                    return null;
                }

                return invalidFutureDateResult;
            }

            return null;
        });
    }

    isValid(
        control: FormControl,
        dateTime: moment.Moment,
        dateToCompare: moment.Moment
    ): boolean {
        return (
            !control.value ||
            dateTime.isSameOrAfter(dateToCompare) ||
            control.pristine
        );
    }
}
