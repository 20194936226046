import { Injectable } from '@angular/core';

import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

import { Promotion } from './promotion';

export type PromotionState = EntityState<Promotion>;

export const initialState: PromotionState = {
    loading: false,
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'public-promotions' })
export class PromotionStore extends EntityStore<PromotionState, Promotion> {
    constructor() {
        super(initialState);
    }
}
