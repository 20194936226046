<div class="bell-with-panel">
    <button
        class="bell-wrapper"
        (click)="toggleNotificationsPanel($event)"
        [matTooltip]="'Notifications' | transloco"
    >
        <vsc-icon class="bell-icon" [iconStyle]="'solid'">bell</vsc-icon>
        <span class="count">{{ notificationsCount$ | async }}</span>
    </button>
    <vsc-notifications-panel
        class="notifications-panel"
        (closePanelEvent)="closePanel()"
        [class.visible]="panelVisible"
    ></vsc-notifications-panel>
</div>
