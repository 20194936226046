<button
    [matMenuTriggerFor]="menu"
    [matTooltip]="'Change Language' | transloco"
    class="btn-language"
>
    <vsc-icon class="mr-8" color="accent">globe</vsc-icon>
    {{ activeLangName | async }}
</button>

<mat-menu #menu="matMenu">
    <button
        *ngFor="let language of availableLangs"
        mat-menu-item
        (click)="change(language.id)"
    >
        <span
            [style.font-weight]="
                (activeLang | async) === language.id ? 'bold' : null
            "
        >
            {{ language.label }}</span
        >
    </button>
</mat-menu>
