import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';

import { TranslocoModule } from '@ngneat/transloco';

import { NgxMaskModule } from 'ngx-mask';

import { CopyToClipboardModule } from '@shared/copy-to-clipboard/copy-to-clipboard.module';
import { IconModule } from '@shared/icon/icon.module';
import { MaterialModule } from '@shared/material.module';

import { ValidationMessageModule } from '../validation-message/validation-message.module';
import { VSCInputComponent } from './input.component';

@NgModule({
    declarations: [VSCInputComponent],
    imports: [
        CommonModule,
        TranslocoModule,
        FormsModule,
        ValidationMessageModule,
        MaterialModule,
        NgxMaskModule,
        FlexLayoutModule,
        CopyToClipboardModule,
        IconModule,
    ],

    exports: [VSCInputComponent],
})
export class VscInputModule {}
