import { AbstractControl, ValidationErrors, Validator } from '@angular/forms';

import { Observable } from 'rxjs';

import { DynamicValidator, Group, Rule } from './dynamic';

export class RequiredValidator extends DynamicValidator implements Validator {
    constructor(
        private readonly message?: string,
        rules?: Array<Rule | Group>
    ) {
        super(rules);
    }

    validate(
        control: AbstractControl
    ): Observable<ValidationErrors> | ValidationErrors | null {
        return super.validateControl(
            control,
            () => {
                if (
                    control.value === null ||
                    control.value === undefined ||
                    (typeof control.value === 'string' && !control.value) ||
                    (control.value instanceof Array &&
                        control.value.length === 0)
                ) {
                    return {
                        required: {
                            current: control.value,
                            required: true,
                            message: this.message,
                        },
                    };
                }

                return null;
            },
            (isApplied: boolean) => {
                if (isApplied) {
                    (control as any).required = true;
                } else {
                    (control as any).required = false;
                }
            }
        );
    }
}
