import { AbstractControl, FormGroup } from '@angular/forms';

export enum Relation {
    and,
    or,
}

export type EvalFn = (value: any) => boolean;

export interface Rule {
    controlName: string;
    eval?: string | EvalFn;
    relation?: Relation;
}

export interface Group {
    relation?: Relation;
    rules: Array<Rule | Group>;
}

export class DynamicValidator {
    private dependencyControls: Array<AbstractControl>;
    private expression: string;
    private isDynamicControlSetUp: boolean;

    constructor(private readonly rules: Array<Rule | Group>) {}

    private getExpression(control: AbstractControl): string {
        if (this.expression == null || this.expression === undefined) {
            this.evalExpression(control);
        }

        return this.expression;
    }

    private setUpDependencyControls(control: AbstractControl): void {
        if (!this.isDynamicControlSetUp) {
            this.isDynamicControlSetUp = true;
            const dependencyControls = this.getDependencyControls(control);

            for (const dependecyControl of dependencyControls) {
                dependecyControl.valueChanges.subscribe(() => {
                    control.updateValueAndValidity({ emitEvent: false });
                });
            }
        }
    }

    private getDependencyControls(
        control: AbstractControl
    ): Array<AbstractControl> {
        if (
            this.dependencyControls == null ||
            this.dependencyControls === undefined
        ) {
            this.evalExpression(control);
        }

        return this.dependencyControls;
    }

    private evalExpression(control: AbstractControl): void {
        this.dependencyControls = [];
        this.expression = '';

        for (const iterator of this.rules) {
            const rule: Rule | Group = iterator;
            const isGroup = rule['controlName'] == null;

            if (isGroup) {
                this.evalGroupExpression(control, rule as Group);
            } else {
                this.evalRuleExpression(control, rule as Rule);
            }
        }
    }

    private evalRuleExpression(control: AbstractControl, rule: Rule): void {
        const c = control.parent.controls[rule.controlName] as FormGroup;
        this.dependencyControls.push(c);

        if (typeof rule.eval === 'string') {
            if (rule.eval.indexOf('#data') > -1) {
                rule.eval = rule.eval.replace(
                    new RegExp('#data', 'g'),
                    `control.parent.controls['${rule.controlName}'].data`
                );
            }

            if (rule.eval.indexOf('#value') > -1) {
                rule.eval = rule.eval.replace(
                    new RegExp('#value', 'g'),
                    `control.parent.controls['${rule.controlName}'].value`
                );
            }
            this.expression = this.expression + rule.eval;
        } else {
            if (control['evalFns'] == null) {
                control['evalFns'] = [];
            }

            const index = control['evalFns'].length;
            control['evalFns'].push(rule.eval);

            this.expression =
                this.expression +
                `control['evalFns'][` +
                index +
                `](control.parent.controls['` +
                rule.controlName +
                `'].value)`;
        }

        switch (rule.relation) {
            case Relation.and:
                this.expression = `${this.expression} && `;
                break;
            case Relation.or:
                this.expression = `${this.expression} || `;
                break;
            default:
        }
    }

    private evalGroupExpression(control: AbstractControl, group: Group): void {
        this.expression = this.expression + ' ( ';

        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < group.rules.length; i++) {
            const rule: Rule | Group = group.rules[i];
            const isGroup = rule['controlName'] == null;

            if (isGroup) {
                this.evalGroupExpression(control, rule as Group);
            } else {
                this.evalRuleExpression(control, rule as Rule);
            }
        }

        this.expression = this.expression + ' ) ';

        switch (group.relation) {
            case Relation.and:
                this.expression = `${this.expression} && `;
                break;
            case Relation.or:
                this.expression = `${this.expression} || `;
                break;
            default:
        }
    }

    protected validateControl(
        control: AbstractControl,
        validationFunc: () => any,
        applied?: (isApplied: boolean) => void
    ): any {
        if (this.rules == null) {
            if (applied != null) {
                applied(true);
            }

            return validationFunc();
        }
        if (control && control.parent != null) {
            this.setUpDependencyControls(control);
            // tslint:disable-next-line:no-eval
            const expre = this.getExpression(control);
            // tslint:disable-next-line: no-eval
            const result = eval(expre);

            if (result) {
                if (applied != null) {
                    applied(true);
                }
                return validationFunc();
            }
            if (applied != null) {
                applied(false);
            }

            return null;
        }
    }
}
