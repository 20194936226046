import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ID } from '@datorama/akita';

import { Observable } from 'rxjs';

import { environment } from '@environment/environment';

import { PageRequest } from '@shared/collection-view/page';
import { BaseUserInfo } from '@shared/models/base-user-info';
import { PagedEntities } from '@shared/models/paged-entities';
import { QueryParamsUtils } from '@shared/utils/query-params.utils';

import { Chat } from '../chat-state/chat';
import { UserChatVisibilityStatusModel } from '../chat-state/user-chat-visibility-status';
import { ChatInviteStatus } from './../chat-state/chat-invite-status.enum';
import { UserInvitedChatsQueryParams } from './query-params/user-chat-invited.queryparams';
import { UserChatsQueryParams } from './query-params/user-chats.queryparams';

@Injectable({ providedIn: 'root' })
export class ChatDataService {
    readonly CHATS_API_PATH: string = `${environment.apiUrl}/vsc/chats`;

    constructor(private httpClient: HttpClient) {}

    getById(id: string): Observable<Chat> {
        return this.httpClient.get<Chat>(`${this.CHATS_API_PATH}/${id}`);
    }

    getUserChats(
        queryParams: UserChatsQueryParams,
        pageRequest?: PageRequest<Chat>
    ): Observable<PagedEntities<Chat>> {
        const params = new HttpParams({
            fromObject: QueryParamsUtils.convertParametersToString({
                ...queryParams,
                ...pageRequest,
            }),
        });
        return this.httpClient.get<PagedEntities<Chat>>(
            `${this.CHATS_API_PATH}/me`,
            {
                params,
            }
        );
    }

    getUsersVisibleForChat(
        search: string,
        exceptUsers: string[]
    ): Observable<PagedEntities<BaseUserInfo>> {
        const params = new HttpParams({
            fromObject: {
                searchTerm: search,
                page: '0',
                pageSize: '5',
                exceptUsers,
            },
        });
        return this.httpClient.get<PagedEntities<BaseUserInfo>>(
            `${environment.apiUrl}/vsc/users/chat-visible`,
            {
                params,
            }
        );
    }

    getUserChatVisibilityStatus(
        userIds: string[],
        chatId: ID
    ): Observable<UserChatVisibilityStatusModel[]> {
        const params = new HttpParams({
            fromObject: {
                userIds,
                relativeChatId: (chatId as string) ?? '',
            },
        });
        return this.httpClient.get<UserChatVisibilityStatusModel[]>(
            `${environment.apiUrl}/vsc/users/chat-visibility`,
            {
                params,
            }
        );
    }

    getUserInvitedChats(
        queryParams: UserInvitedChatsQueryParams
    ): Observable<PagedEntities<Chat>> {
        const params = new HttpParams({
            fromObject: QueryParamsUtils.convertParametersToString(queryParams),
        });
        return this.httpClient.get<PagedEntities<Chat>>(
            `${environment.apiUrl}/vsc/chats/me/invited`,
            {
                params,
            }
        );
    }

    post(chatItem: Chat): Observable<Chat> {
        return this.httpClient.post<Chat>(
            `${environment.apiUrl}/ag/chats`,
            chatItem
        );
    }

    put(chatItem: Chat): Observable<Chat> {
        return this.httpClient.put<Chat>(
            `${environment.apiUrl}/ag/chats`,
            chatItem
        );
    }

    updateChatInviteStatus(
        chatId: ID,
        inviteStatus: ChatInviteStatus
    ): Observable<Chat> {
        return this.httpClient.put<Chat>(
            `${this.CHATS_API_PATH}/me/${chatId}/invite?status=${inviteStatus}`,
            {}
        );
    }
}
