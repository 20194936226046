export enum PrimarySpokenLanguage {
    'American Sign Language',
    Arabic,
    'Cambodian (Khmer)',
    Chinese,
    English,
    French,
    German,
    Greek,
    Gujarati,
    'Haitian Creole',
    Italian,
    Korean,
    Polish,
    Portuguese,
    Russian,
    Spanish,
    'Tactical Sign Language',
    Turkish,
    Urdu,
    Vietnamese,
    Other,
}
