import { ChangeDetectionStrategy, Component } from '@angular/core';

import { Observable } from 'rxjs';

import { OrganizationSettings } from '@app/core/auth/organization-settings';
import { OrganizationSettingsService } from '@app/core/auth/organization-settings.service';

import { Constants } from '@shared/constants';
import { PromotionCategory } from '@shared/promotion-category';

import { Promotion } from '../state/promotion-state/promotion';
import { PromotionQuery } from '../state/promotion-state/promotion.query';
import { slideAnimation } from './slide.animation';

@Component({
    selector: 'vsc-promotional-widget',
    templateUrl: './promotions.component.html',
    styleUrls: ['./promotions.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [slideAnimation],
})
export class PromotionsComponent {
    category = PromotionCategory;
    animationState = 0;
    currentIndex = 0;
    promotions$: Observable<Array<Promotion>> = this.promotionQuery.selectAll();

    isLoading$: Observable<boolean> = this.promotionQuery.selectLoading();
    sponsoredContentImage$ = this.organizationSettingsService.selectSetting(
        OrganizationSettings.SponsoredContentImage
    );
    defaultSponsoredContentImage = Constants.PROMOTION_DEFAULT_IMAGE_URL;
    constructor(
        private promotionQuery: PromotionQuery,
        private organizationSettingsService: OrganizationSettingsService
    ) {}

    openPage(link: string): void {
        window.open(link);
    }

    prevSlide(length) {
        this.currentIndex =
            this.currentIndex > 0 ? --this.currentIndex : length - 1;
        this.animationState = this.animationState === 0 ? 1 : 0;
    }

    nextSlide(length) {
        this.currentIndex =
            this.currentIndex < length - 1 ? ++this.currentIndex : 0;
        this.animationState = this.animationState === 0 ? 1 : 0;
    }
}
