<div fxLayout="column" fxLayoutGap="10px">
    <form (keydown)="keyPress($event)">
        <div fxLayout="column">
            <vsc-dynamic-form
                class="entryField"
                #basicFilters
                [fieldset]="basicFilterFields"
            >
            </vsc-dynamic-form>
        </div>
        <div fxLayout="column">
            <vsc-dynamic-form
                #advancedFilters
                [fieldset]="advancedFilterFields"
            >
            </vsc-dynamic-form>
            <hr />
        </div>
        <div
            fxLayout="row"
            fxLayoutAlign="space-between stretch"
            *ngIf="showFilterControls"
            fxLayoutGap="10px"
        >
            <button
                fxFlex="50"
                mat-flat-button
                color="primary"
                (click)="filterClick()"
            >
                {{ 'Filter' | transloco }}
            </button>
            <button
                fxFlex="50"
                mat-stroked-button
                color="primary"
                class="border"
                (click)="reset()"
            >
                {{ 'Reset' | transloco }}
            </button>
        </div>
    </form>
</div>
