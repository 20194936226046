import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'vsc-not-authorized',
    templateUrl: './not-authorized.component.html',
    styleUrls: ['./not-authorized.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotAuthorizedComponent {
    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute
    ) {}

    navigateToHome(): void {
        this.router.navigate(['../home'], { relativeTo: this.activatedRoute });
    }
}
