import { InjectionToken } from '@angular/core';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';

import { Notification } from '@shared/notifications/models/notification';
import { NotificationAction } from '@shared/notifications/models/notification-action.enum';

import { ChatService } from '@public/chats/services/chat.service';

import {
    formatNotificationDelegateInjectionToken,
    INotificationDelegate,
} from './notification-action';

export const ChatStartedNotificationDelegateInjectionToken =
    new InjectionToken<ChatStartedNotificationDelegate>(
        formatNotificationDelegateInjectionToken(NotificationAction.ChatStarted)
    );

export const ChatAboutToStartNotificationDelegateInjectionToken =
    new InjectionToken<ChatStartedNotificationDelegate>(
        formatNotificationDelegateInjectionToken(
            NotificationAction.ChatAboutToStart
        )
    );

export class ChatStartedNotificationDelegate implements INotificationDelegate {
    constructor(private router: Router, private chatService: ChatService) {}

    invokeAction(_: Notification): void {}

    rejectAction(_: Notification): void {}

    navigateFromNotification(
        notification: Notification
    ): void | Observable<any> {
        const entityId = notification?.entityId;
        if (!entityId) {
            return;
        }

        this.chatService.setBackUrl(this.router.url);
        this.router.navigate([`/chats/${entityId}/details`]);
    }

    navigateFromNotificationBody(
        notification: Notification
    ): void | Observable<any> {
        const entityId = notification?.entityId;
        if (!entityId) {
            return;
        }

        this.router.navigate([`/chats/schedule`]);
    }
}
