<vsc-toast *ngIf="(hasAnyToast$ | async) === true"></vsc-toast>

<ng-container *ngIf="this.authService.hasValidToken(); else loggedOut">
    <router-outlet></router-outlet>
</ng-container>

<ng-template #loggedOut>
    <div
        class="loading"
        fxLayout="column"
        fxLayoutGap="10px"
        fxLayoutAlign="center center"
    >
        <img
            src="../assets/images/enliveo-logo.svg"
            alt="enliveo Logo"
            height="200"
        />
    </div>
</ng-template>
