import { Component, ChangeDetectionStrategy } from '@angular/core';

import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'vsc-clock',
    templateUrl: './clock.component.html',
    styleUrls: ['./clock.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClockComponent {
    constructor() {
        setInterval(() => {
            this.now$.next(moment());
        }, 60);
    }

    now$: BehaviorSubject<moment.Moment> = new BehaviorSubject<moment.Moment>(
        moment()
    );
}
