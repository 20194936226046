import {
    ChangeDetectionStrategy,
    Component,
    Inject,
    OnDestroy,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NavigationExtras, Router } from '@angular/router';

import { Subscription } from 'rxjs';

export interface LeaveClassData {
    classSessionId: string;
    redirectUrl: string;
}

@Component({
    templateUrl: './leave-class.component.html',
    styleUrls: ['./leave-class.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LeaveClassComponent implements OnDestroy {
    private subscriptions: Array<Subscription> = [];
    isHostOrCoHost: boolean;

    constructor(
        private dialogRef: MatDialogRef<LeaveClassComponent>,
        @Inject(MAT_DIALOG_DATA) public data: LeaveClassData,
        private router: Router
    ) {}

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription) =>
            subscription.unsubscribe()
        );
    }

    cancel(): void {
        this.dialogRef.close();
    }

    leaveClass(): void {
        const navigationExtras: NavigationExtras = {
            state: {
                byPassLeaveGuard: 1,
            },
        };
        this.router.navigate([this.data.redirectUrl], navigationExtras);
        this.dialogRef.close();
    }
}
