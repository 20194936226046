import { Pipe, PipeTransform, Injectable } from '@angular/core';

import { TranslocoService } from '@ngneat/transloco';

import * as moment from 'moment';

import { Constants } from '@shared/constants';

@Pipe({ name: 'localizedTime', pure: false })
@Injectable({ providedIn: 'root' })
export class LocalizedTimePipe implements PipeTransform {
    constructor(private translocoService: TranslocoService) {}
    transform(
        value: any,
        units?: number,
        interval?: 'hours' | 'minutes' | 'seconds',
        pattern?: string
    ): string | null {
        if (moment.isMoment(value)) {
            if (units != null && interval != null) {
                value = moment(value).add(units, interval);
            }

            return moment(value)
                .setLocale(this.translocoService.getActiveLang())
                .format(pattern ?? Constants.TIME_FORMAT_US);
        }
    }
}
