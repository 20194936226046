import { Injectable } from '@angular/core';

import * as signalR from '@microsoft/signalr';
import * as signalRMsgPack from '@microsoft/signalr-protocol-msgpack';

import { environment } from '@environment/environment';

/**
 * Provides real-time connectivity using SignalR library.
 */
@Injectable({
    providedIn: 'root',
})
export class SignalRService {
    constructor() {
        if (!environment.production) {
            if (!signalR) {
                console.error(
                    "Cannot find SignalR, is it included in the 'dependencies'? Hint: yarn add @microsoft/signalr"
                );
            }

            if (!signalRMsgPack) {
                console.error(
                    "Cannot find SignalR MessagePack, is it included in the 'dependencies'? Hint: yarn add @microsoft/signalr-protocol-msgpack"
                );
            }
        }
    }

    /**
     * Starts WebSockets connection with SignalR library.
     * @param url WebSockets server URL.
     * @returns Active {@link @microsoft/signalr.HubConnection}.
     */
    public startConnection(url: string): signalR.HubConnection {
        if (!url) {
            console.error(
                'SignalR: Url must be specified to start connection.'
            );
        }

        const hubConnection = new signalR.HubConnectionBuilder()
            .configureLogging(
                environment.production
                    ? signalR.LogLevel.Information
                    : signalR.LogLevel.Debug
            )
            .withUrl(url, {
                accessTokenFactory: () => localStorage.getItem('access_token'),
            })
            .withHubProtocol(new signalRMsgPack.MessagePackHubProtocol())
            .build();

        hubConnection
            .start()
            .catch((err) =>
                console.error('Error while starting connection: ' + err)
            );

        return hubConnection;
    }
}
