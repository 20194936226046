import {
    AbstractControl,
    FormArray,
    FormControl,
    ValidationErrors,
    Validator,
} from '@angular/forms';

import { DynamicValidator, Group, Rule } from './dynamic';

export class MaxLengthValidator extends DynamicValidator implements Validator {
    constructor(
        private readonly maxLength: number,
        private readonly message?: string,
        rules?: Array<Rule | Group>
    ) {
        super(rules);
    }

    validate(control: AbstractControl): ValidationErrors | null {
        if (control instanceof FormControl || control instanceof FormArray) {
            if (
                control.value === null ||
                control.value === undefined ||
                (typeof control.value === 'string' &&
                    control.value.length <= this.maxLength) ||
                (control.value instanceof Array &&
                    control.value.length <= this.maxLength)
            ) {
                return null;
            }

            return {
                maxlength: {
                    current: control.value,
                    requiredLength: this.maxLength,
                    message: this.message,
                },
            };
        }

        return null;
    }
}
