import * as moment from 'moment';

import { Constants } from '@shared/constants';

declare type Moment = moment.Moment;
const _MOMENT_PROTOTYPE_: any = moment.fn as any;

declare module 'moment' {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    interface Moment {
        timeZone: string;
        addDays(days: number): Moment;
        addHours(hours: number): Moment;
        addMinutes(minutes: number): Moment;
        addSeconds(seconds: number): Moment;
        toApiStringFormat(): string;
        toTimeFormat(): string;
        toApiDateTimeStringFormat(): string;
        isSameAs(momentToCompare: Moment): boolean;
        isSameDate(momentToCompare: Moment): boolean;
        isBefore(momentToCompare: Moment): boolean;
        isWeekend(): boolean;
        isToday(): boolean;
        isPastDate(): boolean;
        isFutureDate(): boolean;
        toDateString(): string;
        toDateObj(): Date;
        setMidnightTime(): Moment;
        differenceInDays(momentToCompare: Moment): number;
        getMinutesUntilQuarter(): number;
        getMillisecondsUntilQuarter(): number;
        setLocale(locale: string): Moment;

        /**
         * @description Returns formatted UTC offset of current time-zone
         * @returns Formatted UTC offset string like +2:00
         * */
        utcOffsetFormatted(): string;
    }
}

_MOMENT_PROTOTYPE_.addDays = function (days: number): Moment {
    return this.add(days, 'days');
};

_MOMENT_PROTOTYPE_.addHours = function (hours: number): Moment {
    return this.add(hours, 'hours');
};

_MOMENT_PROTOTYPE_.addMinutes = function (minutes: number): Moment {
    return (this as moment.Moment).add(minutes, 'minutes');
};

_MOMENT_PROTOTYPE_.addSeconds = function (seconds: number): Moment {
    return this.add(seconds, 'seconds');
};

_MOMENT_PROTOTYPE_.toApiStringFormat = function (): string {
    return this.isValid() ? this.format(Constants.DATE_FORMAT_ISO) : null;
};

_MOMENT_PROTOTYPE_.toTimeFormat = function (): string {
    return this.isValid() ? this.format(Constants.TIME_FORMAT_24h) : null;
};

_MOMENT_PROTOTYPE_.toApiDateTimeStringFormat = function (): string {
    return `${this.toApiStringFormat()}T${this.toTimeFormat()}`;
};

_MOMENT_PROTOTYPE_.isSameDate = function (momentToCompare: Moment): boolean {
    return (
        this.year() === momentToCompare.year() &&
        this.dayOfYear() === momentToCompare.dayOfYear()
    );
};

_MOMENT_PROTOTYPE_.isSameAs = function (momentToCompare: Moment): boolean {
    return this.diff(momentToCompare, 's') === 0;
};

_MOMENT_PROTOTYPE_.isBefore = function (momentToCompare: Moment): boolean {
    return !this.isSameOrAfter(momentToCompare, 's');
};

_MOMENT_PROTOTYPE_.isWeekend = function (): boolean {
    return this.day() === 0 || this.day() === 6;
};

_MOMENT_PROTOTYPE_.isToday = function (): boolean {
    return this.isSameDate(moment());
};

_MOMENT_PROTOTYPE_.isPastDate = function (): boolean {
    return moment(this).setMidnightTime() < moment().setMidnightTime();
};

_MOMENT_PROTOTYPE_.isFutureDate = function (): boolean {
    return moment(this).setMidnightTime() > moment().setMidnightTime();
};

_MOMENT_PROTOTYPE_.toDateString = function (): string {
    return this.format(Constants.DATE_FORMAT_US);
};

_MOMENT_PROTOTYPE_.toDateObj = function (): Date {
    const _this = this as moment.Moment;
    const timeZoneOffset = moment.tz(_this.timeZone).utcOffset();
    const date = _this.toDate();

    return new Date(
        +date +
            date.getTimezoneOffset() * Constants.MS_PER_MINUTE +
            timeZoneOffset * Constants.MS_PER_MINUTE
    );
};

_MOMENT_PROTOTYPE_.setMidnightTime = function (): Moment {
    return this.startOf('day');
};

_MOMENT_PROTOTYPE_.differenceInDays = function (
    momentToCompare: Moment
): number {
    return moment(this)
        .setMidnightTime()
        .diff(momentToCompare.setMidnightTime(), 'd');
};

_MOMENT_PROTOTYPE_.getMinutesUntilQuarter = function (): number {
    const _this = this as moment.Moment;
    const minutes = 15 - (_this.minutes() % 15);
    return minutes === 0 ? 15 : minutes;
};

_MOMENT_PROTOTYPE_.getMillisecondsUntilQuarter = function (): number {
    const _this = this as moment.Moment;
    return (
        this.getMinutesUntilQuarter() * Constants.MS_PER_MINUTE -
        _this.seconds() * 1000
    );
};

_MOMENT_PROTOTYPE_.setLocale = function (locale: string): Moment {
    return (this as Moment).locale(locale === 'zh' ? 'zh-cn' : locale);
};

_MOMENT_PROTOTYPE_.utcOffsetFormatted = function (): string {
    const _this = this as moment.Moment;
    let minutes = _this.utcOffset();

    if (minutes === 0) {
        return '+0:00';
    }

    const sign = minutes > 0 ? '+' : '-';
    minutes = Math.abs(minutes);

    const hours = Math.floor(minutes / 60);
    const minutesLeft = `0${minutes % 60}`.slice(-2);
    return `${sign}${hours}:${minutesLeft}`;
};

export abstract class MomentUtils {
    /**
     * If the value is `Truthy` parses the value to a Moment object, otherwise returns `null`.
     * @returns `Moment`
     */
    public static parseIfTruthy(
        value: any,
        format?: string,
        locale?: string
    ): Moment {
        return value ? moment(value, format, locale) : null;
    }
}
