<ng-container *ngIf="promotions$ | async as promotions">
    <mat-card class="promotions-mat-card" *ngIf="promotions.length">
        <div
            class="container slider"
            fxLayout="column"
            fxLayoutAlign="start"
            [@slideAnimation]="animationState === 0 ? 'stateOne' : 'stateTwo'"
        >
            <ng-container
                *ngIf="
                    promotions[currentIndex].category ===
                    category.SponsoredContent
                "
            >
                <div
                    class="background slide-1"
                    [style.background-image]="
                        'url(' + (sponsoredContentImage$ | async) + ')'
                    "
                    fxFlex="70"
                >
                    <div class="content-top">
                        {{ 'Sponsored content' | transloco | uppercase }}
                    </div>
                </div>
            </ng-container>

            <ng-container
                *ngIf="
                    promotions[currentIndex].category === category.PromotedClass
                "
            >
                <div
                    class="background slide-2"
                    fxFlex="70"
                    [style.background-image]="
                        promotions[currentIndex].imagePath
                            ? 'url(' + promotions[currentIndex].imagePath + ')'
                            : 'url(' + defaultSponsoredContentImage + ')'
                    "
                >
                    <div class="content-top">
                        {{ 'Promoted program' | transloco | uppercase }}
                    </div>
                </div>
            </ng-container>

            <div
                class="details"
                fxLayout="column"
                fxLayoutGap="5"
                fxLayoutAlign="space-between start"
                fxFlex
            >
                <div class="main">
                    <p class="title">
                        {{ promotions[currentIndex].title }}
                    </p>
                    <p class="description">
                        {{ promotions[currentIndex].description }}
                    </p>
                </div>
                <div
                    class="footer"
                    fxLayout="row"
                    fxLayoutAlign="space-between end"
                >
                    <div>
                        <a
                            *ngIf="promotions[currentIndex].link"
                            (click)="openPage(promotions[currentIndex].link)"
                            class="view-more-button"
                            fxLayout="row"
                            fxLayoutAlign="center center"
                        >
                            <span> {{ 'Learn more' | transloco }}</span>
                            <vsc-icon iconStyle="solid" color="accent"
                                >caret-right</vsc-icon
                            >
                        </a>
                    </div>
                    <div
                        *ngIf="promotions.length > 0"
                        fxLayout="row"
                        fxLayoutAlign="center center"
                        class="controls-row"
                    >
                        <button
                            class="navigation-button"
                            (click)="prevSlide(promotions.length)"
                            mat-button
                            [attr.aria-label]="'Previous promotion' | transloco"
                        >
                            <vsc-icon class="m-0">chevron-left</vsc-icon>
                        </button>
                        <p class="title-text">
                            {{ currentIndex + 1 }} {{ 'of' | transloco }}
                            {{ promotions.length }}
                        </p>
                        <button
                            class="navigation-button"
                            (click)="nextSlide(promotions.length)"
                            mat-button
                            [attr.aria-label]="'Next promotion' | transloco"
                        >
                            <vsc-icon class="m-0">chevron-right</vsc-icon>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </mat-card>
</ng-container>
