import { Injectable } from '@angular/core';

import { Query } from '@datorama/akita';

import { AlertState, AlertStore } from './alert.store';

@Injectable({ providedIn: 'root' })
export class AlertQuery extends Query<AlertState> {
    constructor(protected store: AlertStore) {
        super(store);
    }
}
