import { Injectable } from '@angular/core';

import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

import { Notification } from '@shared/notifications/models/notification';

export interface NotificationsState extends EntityState<Notification> {
    unreadCount: number;
}

export const initialState: NotificationsState = {
    loading: false,
    unreadCount: 0,
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'notifications' })
export class NotificationsStore extends EntityStore<
    NotificationsState,
    Notification
> {
    static readonly INITIAL_STATE: NotificationsState = {
        loading: false,
        unreadCount: 0,
    };

    constructor() {
        super(initialState);
    }

    updateUnreadCount(changeValue?: number): void {
        if (changeValue !== undefined) {
            this.update((state: NotificationsState) => ({
                unreadCount:
                    state.unreadCount + changeValue < 0
                        ? 0
                        : state.unreadCount + changeValue,
            }));
        } else {
            this.update({ unreadCount: 0 });
        }
    }
}
