<div [class]="'toast-item ' + toastClass" fxLayout="row">
    <div class="toast-content" *ngIf="!toastComponentPortal; else component">
        <div class="icon" fxLayout="row" fxLayoutAlign="start center">
            <vsc-icon>{{ toast.icon }}</vsc-icon>
        </div>
        <div class="content">
            <p class="message" *ngIf="toast.message">
                {{ toast.message }}
            </p>
        </div>
    </div>
    <ng-template
        #component
        [cdkPortalOutlet]="toastComponentPortal"
    ></ng-template>
</div>
