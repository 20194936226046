<div class="notification-item-wrapper">
    <ng-container *ngIf="item">
        <div
            class="notification-item"
            fxLayout="row"
            fxFlex="100"
            [class.grey-out]="item.isRead"
            (click)="markAsRead()"
        >
            <div
                fxLayout="row"
                fxLayout.lt-lg="column"
                fxFlex="100"
                class="content"
            >
                <div
                    class="image-section-wrapper"
                    fxFlexAlign="center"
                    (click)="navigateFromNotificationBody()"
                >
                    <div
                        *ngIf="item.imageUrl; else icon"
                        class="image-container"
                    >
                        <img
                            class="image"
                            [src]="item.imageUrl"
                            [alt]="'Notification image' | transloco"
                        />
                    </div>
                    <ng-template #icon>
                        <vsc-icon class="bell-icon" [iconStyle]="'solid'"
                            >bell</vsc-icon
                        >
                    </ng-template>
                </div>
                <div
                    class="body"
                    fxFlex
                    fxLayout="column"
                    fxLayoutAlign="start"
                    (click)="navigateFromNotificationBody()"
                >
                    <div
                        class="main"
                        fxFlex
                        fxLayout="column"
                        fxLayoutAlign="start"
                    >
                        <div class="message">
                            <h3>
                                <span *ngIf="item.template; else plainText">
                                    {{
                                        item.template
                                            | transloco
                                            | formatText: templateParams
                                    }}</span
                                >
                                <ng-template #plainText>{{
                                    item.message | transloco
                                }}</ng-template>
                            </h3>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="start center">
                            <div class="date-time" fxFlex="70" fxLayout="row">
                                <vsc-icon
                                    class="schedule-icon"
                                    [iconStyle]="'light'"
                                    >clock</vsc-icon
                                >
                                {{ createdAt }}
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    fxLayoutGap="8px"
                    fxLayoutGap.md="12px"
                    fxLayoutGap.lg="16px"
                    fxLayoutGap.xl="20px"
                    class="buttons"
                >
                    <vsc-notification-action
                        [notification]="item"
                        (actionInvoked)="onActionInvoked()"
                        (actionRejected)="onActionRejected()"
                    ></vsc-notification-action>
                </div>
            </div>
        </div>
    </ng-container>
</div>
