import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { setLoading } from '@datorama/akita';
import { TranslocoService } from '@ngneat/transloco';

import * as moment from 'moment';
import {
    Observable,
    EMPTY,
    catchError,
    mergeMap,
    iif,
    map,
    take,
    of,
    filter,
    tap,
} from 'rxjs';

import { NotificationsSettingsService } from '@core/services/notifications-settings.service';
import { environment } from '@environment/environment';

import { PagedEntities } from '@shared/models/paged-entities';
import { NotificationToastComponent } from '@shared/notifications/notification-toast/notification-toast.component';
import { SnackBarService } from '@shared/snack-bar/snack-bar.service';
import { Page, PageRequest } from '@shared/table/page';
import { ToastService } from '@shared/toast/services/toast.service';

import { Notification } from '../models/notification';
import { NotificationDeliveryMethod } from '../state/notification-delivery-method';
import { NotificationDeliveryMethodsQuery } from '../state/notification-delivery-methods.query';
import { NotificationDeliveryMethodsStore } from '../state/notification-delivery-methods.store';
import { NotificationEventReminder } from '../state/notification-event-reminder';
import { NotificationEventRemindersQuery } from '../state/notification-event-reminders.query';
import { NotificationEventRemindersStore } from '../state/notification-event-reminders.store';
import { NotificationsSearchQuery } from '../state/notifications-search.query';
import { NotificationsStore } from '../state/notifications.store';
import { NotificationsDataService } from './notifications.dataservice';

@Injectable({
    providedIn: 'root',
})
export class NotificationsService {
    private notificationSound: HTMLAudioElement;

    constructor(
        private notificationsDataService: NotificationsDataService,
        private notificationsSettingsService: NotificationsSettingsService,
        private toastService: ToastService,
        private notificationEventRemindersStore: NotificationEventRemindersStore,
        private notificationEventRemindersQuery: NotificationEventRemindersQuery,
        private snackBar: SnackBarService,
        private translocoService: TranslocoService,
        private notificationsStore: NotificationsStore,
        private notificationDeliveryMethodsStore: NotificationDeliveryMethodsStore,
        private notificationDeliveryMethodsQuery: NotificationDeliveryMethodsQuery
    ) {}

    getNotifications(
        request: PageRequest<Notification>,
        query: NotificationsSearchQuery
    ): Observable<Page<Notification>> {
        return this.notificationsDataService
            .getNotifications(query, request)
            .pipe(
                filter(Boolean),
                map((response: PagedEntities<Notification>) => {
                    let entities: Notification[] = response.entities;
                    entities = entities.map((notification: Notification) => ({
                        ...notification,
                        createdAt: moment(notification.createdAt),
                    }));

                    this.notificationsStore.upsertMany(entities);

                    return {
                        content: entities,
                        size: entities?.length,
                        totalElements: response.totalCount,
                        number: request.page,
                    } as Page<Notification>;
                }),
                setLoading(this.notificationsStore),
                catchError((err: HttpErrorResponse) => {
                    console.error(err);
                    return EMPTY;
                })
            );
    }

    getUnreadUserNotificationsCount(): Observable<number> {
        return this.notificationsDataService
            .getUnreadUserNotificationsCount()
            .pipe(
                tap((response: number) => {
                    this.notificationsStore.update({ unreadCount: response });
                    return response;
                }),
                setLoading(this.notificationsStore),
                catchError((err: HttpErrorResponse) => {
                    console.error(err);
                    return EMPTY;
                })
            );
    }

    markNotificationsAsRead(
        ids: string[],
        countToUpdate: number
    ): Observable<void> {
        if (ids === null || ids?.length === 0) {
            return EMPTY;
        }

        return this.notificationsDataService.markNotificationsAsRead(ids).pipe(
            take(1),
            tap(() => {
                this.notificationsStore.update(ids, {
                    isRead: true,
                });

                this.notificationsStore.updateUnreadCount(-countToUpdate);
            }),

            setLoading(this.notificationsStore),
            catchError((err: HttpErrorResponse) => {
                console.error(err);
                return EMPTY;
            })
        );
    }

    markAllNotificationsAsRead(): Observable<void> {
        return this.notificationsDataService.markAllNotificationsAsRead().pipe(
            take(1),
            tap(() => {
                this.notificationsStore.update(null, { isRead: true });
                this.notificationsStore.updateUnreadCount();
            }),
            setLoading(this.notificationsStore),
            catchError((err: HttpErrorResponse) => {
                console.error(err);
                return EMPTY;
            })
        );
    }

    deleteNotification(id: string): Observable<any> {
        return this.notificationsDataService.deleteNotification(id).pipe(
            tap(() => {
                this.notificationsStore.remove(id);
            }),
            setLoading(this.notificationsStore),
            catchError((err: HttpErrorResponse) => {
                console.error(err);
                return EMPTY;
            })
        );
    }

    /**
     * Shows a notification toast.
     * @param notification The notification to show.
     */
    showNotification(notification: Notification): void {
        if (notification) {
            this.toastService.showComponent({
                id: notification.id,
                toastComponent: NotificationToastComponent,
                data: notification,
                options: {
                    duration: 10000,
                },
            });

            this.playNotificationSound();
        }
    }

    getUserNotificationEventReminders(
        userId: string
    ): Observable<Array<NotificationEventReminder>> {
        return this.notificationsDataService.getUserNotificationEventReminders(
            userId
        );
    }

    selectUserNotificationEventReminders(
        userId: string
    ): Observable<Array<NotificationEventReminder>> {
        if (!userId || userId.length === 0) {
            throw new Error('User cannot be null');
        }
        return this.notificationEventRemindersQuery
            .selectUserNotificationEventReminders(userId)
            .pipe(
                mergeMap((notifEventReminders: NotificationEventReminder[]) =>
                    iif(
                        () => !notifEventReminders,
                        this.getUserNotificationEventReminders(userId)
                            .pipe(
                                map(
                                    (
                                        loadedNotifEventReminders: NotificationEventReminder[]
                                    ) => {
                                        this.notificationEventRemindersStore.setUserNotificationEventReminders(
                                            loadedNotifEventReminders,
                                            userId
                                        );
                                        return loadedNotifEventReminders;
                                    }
                                ),
                                setLoading(
                                    this.notificationEventRemindersStore
                                ),
                                catchError(() => {
                                    this.snackBar.open(
                                        this.translocoService.translate(
                                            'Failed to load user notification event reminders.'
                                        )
                                    );
                                    return EMPTY;
                                })
                            )
                            .pipe(take(1)),
                        of(notifEventReminders)
                    )
                )
            );
    }

    removeUserNotificationEventReminders(userId: string): void {
        if (!userId || userId.length === 0) {
            throw new Error('User cannot be null');
        }
        this.notificationEventRemindersStore.setUserNotificationEventReminders(
            null,
            userId
        );
    }

    fetchNotificationDeliveryMethodsIfNotPresent(): Observable<
        Array<NotificationDeliveryMethod>
    > {
        const deliveryMethods = this.notificationDeliveryMethodsQuery.getAll();
        if (deliveryMethods?.length === 0) {
            return this.getNotificationDeliveryMethods();
        }
        return of(deliveryMethods);
    }

    getNotificationDeliveryMethods(): Observable<
        Array<NotificationDeliveryMethod>
    > {
        return this.notificationsDataService
            .getNotificationDeliveryMethods()
            .pipe(
                tap(
                    (
                        notificationDeliveryMethods: Array<NotificationDeliveryMethod>
                    ) => {
                        this.notificationDeliveryMethodsStore.set(
                            notificationDeliveryMethods
                        );
                        return notificationDeliveryMethods;
                    }
                ),
                setLoading(this.notificationDeliveryMethodsStore),
                catchError(() => {
                    this.snackBar.open(
                        this.translocoService.translate(
                            'Failed to load notification delivery methods.'
                        )
                    );
                    return EMPTY;
                })
            );
    }

    private playNotificationSound(): void {
        if (this.notificationsSettingsService.isNotificationSoundEnabled()) {
            if (!this.notificationSound) {
                this.notificationSound = new Audio(
                    environment.notificationSoundUrl
                );
            }

            this.notificationSound.load();

            /**
             * Chrome 66+ has a new autoplay policy that requires a user gesture to play a sound.
             * https://developers.google.com/web/updates/2017/09/autoplay-policy-changes
             */
            this.notificationSound.play().catch(() => Promise.resolve());
        }
    }
}
