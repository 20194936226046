import { DataSource } from '@angular/cdk/collections';

import {
    BehaviorSubject,
    combineLatest,
    Observable,
    Subject,
    Subscription,
} from 'rxjs';
import { pluck, share, startWith, switchMap } from 'rxjs/operators';

import { Constants } from '../constants';
import { indicate } from './operators';
import { Page, PaginatedEndpoint, Sort } from './page';

export interface SimpleDataSource<T> extends DataSource<T> {
    connect(): Observable<T[]>;
    disconnect(): void;
}

export class PaginatedDataSource<T, Q> implements SimpleDataSource<T> {
    private pageNumber: Subject<{
        page: number;
        pageSize: number;
    }> = new Subject<{
        page: number;
        pageSize: number;
    }>();
    private sort: Subject<Sort> = new Subject<Sort>();
    private query: BehaviorSubject<Q>;
    private loading: Subject<boolean> = new Subject<boolean>();

    public loading$: Observable<boolean> = this.loading.asObservable();
    public page$: Observable<Page<T>>;
    public content: T[];
    public totalCount: number;
    private defaultPage: number;
    private subscriptions: Subscription[] = [];

    constructor(
        private endpoint: PaginatedEndpoint<T, Q>,
        initialSort: Sort,
        initialQuery: Q,
        public pageSize: number = Constants.INITIAL_PAGE_SIZE
    ) {
        this.query = new BehaviorSubject<Q>(initialQuery);

        const param$ = combineLatest([
            this.query,
            this.sort.pipe(startWith(initialSort)),
        ]);
        this.page$ = param$.pipe(
            switchMap(([query, sort]) =>
                this.pageNumber.pipe(
                    startWith({
                        page: this.defaultPage,
                        pageSize: this.pageSize,
                    }),
                    switchMap((pager) => {
                        this.pageSize = pager.pageSize;
                        return this.endpoint(
                            { page: pager.page, sort, size: pager.pageSize },
                            query
                        ).pipe(indicate(this.loading));
                    })
                )
            ),
            share()
        );
    }

    setDefaultPage(page: number): void {
        this.defaultPage = page;
    }

    sortBy(sort: Sort): void {
        this.sort.next(sort);
    }

    queryBy(query: Partial<Q>): void {
        const lastQuery = this.query.getValue();
        const nextQuery = { ...lastQuery, ...query };
        this.query.next(nextQuery);
    }

    fetch(page: number, pageSize: number): void {
        this.pageNumber.next({ page, pageSize });
    }

    connect(defaultPage: number = 0): Observable<T[]> {
        this.defaultPage = defaultPage;
        this.subscriptions.push(
            this.page$.subscribe((page) => {
                this.content = page.content;
                this.totalCount = page.totalElements;
            })
        );
        return this.page$.pipe(pluck('content'));
    }

    disconnect(): void {
        this.subscriptions.forEach((sub: Subscription) => sub.unsubscribe());
    }
}
