<div fxLayoutAlign="center center" fxLayout="column">
    <h1 class="error-message">{{ 'Bad request' | transloco }}</h1>
    <img
        src="./../../../assets/images/backgrounds/bad-request.svg"
        alt="Bad Request Image"
        class="bad-request"
    />
    <div class="description-message">
        <p class="message">
            {{
                'Sorry, this page isn’t working at the moment. If the problem persists, please let us know.'
                    | transloco
            }}
        </p>
        <button
            (click)="navigateToHome()"
            mat-flat-button
            color="primary"
            class="btn-bad-request"
        >
            {{ 'Go To Homepage' | transloco }}
        </button>
    </div>
</div>
