import { Pipe, PipeTransform } from '@angular/core';

/**
 * Formats string by inserting arguments into the string.
 * @param value Value to format.
 * @param params Parameters to insert into the string.
 * ## Example
 * ```html
 * <span>{{ '{0} {1}' | formatText : ['Hello', 'World'] }}</span>
 * ```
 * will produce
 * ```html
 * <span>{{ 'Hello World' }}</span>
 * ```
 */
@Pipe({ name: 'formatText' })
export class FormatTextPipe implements PipeTransform {
    transform(value: string, params: string[]): string {
        if (params.length > 0) {
            for (let i = 0; i < params.length; i++) {
                value = value.replace(`{${i}}`, params[i]);
            }
        }
        return value;
    }
}
