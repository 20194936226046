import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    RouterStateSnapshot,
} from '@angular/router';

import { Observable, of } from 'rxjs';
import { map, take, switchMap } from 'rxjs/operators';

import { AuthService } from '@core/auth/auth.service';

import { OrganizationsQuery } from '@admin/organizations/state/organizations.query';

import { UserTypes } from './user-types.enum';

@Injectable({
    providedIn: 'root',
})
export class ConsumerInformationGuard implements CanActivate {
    constructor(
        private router: Router,
        private authService: AuthService,
        private organizationQuery: OrganizationsQuery
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> {
        return this.organizationQuery.selectIsCurrentOrgProfilePersonalizationAllowed$.pipe(
            take(1),
            switchMap((isCurrentOrgProfilePersonalizationAllowed: boolean) => {
                if (
                    isCurrentOrgProfilePersonalizationAllowed ||
                    this.authService.userType === UserTypes.Dtc
                ) {
                    return this.areUserInterestsSubmitted();
                } else {
                    return of(!isCurrentOrgProfilePersonalizationAllowed);
                }
            })
        );
    }
    areUserInterestsSubmitted(): Observable<boolean> {
        return this.authService.areUserInterestsSubmitted$.pipe(
            map((areUserInterestsSubmitted) => {
                if (!areUserInterestsSubmitted) {
                    this.router.navigate(['/consumer-information']);
                    return false;
                }
                return true;
            })
        );
    }
}
