import {
    ChangeDetectorRef,
    EventEmitter,
    OnDestroy,
    Pipe,
    PipeTransform,
    ɵisSubscribable,
} from '@angular/core';

import { Observable, Subscribable, Unsubscribable } from 'rxjs';

interface SubscriptionStrategy {
    createSubscription(
        async: Subscribable<any>,
        updateLatestValue: any
    ): Unsubscribable;
    dispose(subscription: Unsubscribable): void;
    onDestroy(subscription: Unsubscribable): void;
}

class SubscribableStrategy implements SubscriptionStrategy {
    createSubscription(
        async: Subscribable<any>,
        updateLatestValue: any
    ): Unsubscribable {
        return async.subscribe({
            next: updateLatestValue,
            error: (e: any) => {
                throw e;
            },
        });
    }

    dispose(subscription: Unsubscribable): void {
        subscription.unsubscribe();
    }

    onDestroy(subscription: Unsubscribable): void {
        subscription.unsubscribe();
    }
}

const _subscribableStrategy = new SubscribableStrategy();

/**
 * Unwraps a value from an asynchronous primitive.
 *
 * The `asyncIfObservablePipe` pipe checks for `obj` type and if `Observable` then subscribes and returns the latest value it has
 * emitted. When a new value is emitted, the `asyncIfObservablePipe` pipe marks the component to be checked for
 * changes. When the component gets destroyed, the `asyncIfObservablePipe` pipe unsubscribes automatically to avoid
 * potential memory leaks. When the reference of the expression changes, the `asyncIfObservablePipe` pipe
 * automatically unsubscribes from the old `Observable` or `Promise` and subscribes to the new one.
 *
 * This is the modified implementation of the `AsyncPipe` class from the Angular source code.
 */
@Pipe({ name: 'asyncIfObservable', pure: false })
export class AsyncIfObservablePipe implements OnDestroy, PipeTransform {
    private _latestValue: any = null;

    private _subscription: Unsubscribable | null = null;
    private _obj: Subscribable<any> | EventEmitter<any> | null = null;
    private _strategy: SubscriptionStrategy = null!;

    constructor(private _ref: ChangeDetectorRef) {}

    ngOnDestroy(): void {
        if (this._subscription) {
            this._dispose();
        }
    }

    transform<T>(
        obj: Observable<T> | Subscribable<T> | T | null | undefined
    ): T | null {
        if (!(obj instanceof Observable)) {
            return obj as any;
        }

        if (!this._obj) {
            if (obj) {
                this._subscribe(obj);
            }
            return this._latestValue;
        }

        if (obj !== this._obj) {
            this._dispose();
            return this.transform(obj);
        }

        return this._latestValue;
    }

    private _subscribe(obj: Subscribable<any> | EventEmitter<any>): void {
        this._obj = obj;
        this._strategy = this._selectStrategy(obj);
        this._subscription = this._strategy.createSubscription(
            obj,
            (value: Object) => this._updateLatestValue(obj, value)
        );
    }

    private _selectStrategy(obj: Subscribable<any> | EventEmitter<any>): any {
        if (ɵisSubscribable(obj)) {
            return _subscribableStrategy;
        }
    }

    private _dispose(): void {
        this._strategy.dispose(this._subscription!);
        this._latestValue = null;
        this._subscription = null;
        this._obj = null;
    }

    private _updateLatestValue(async: any, value: Object): void {
        if (async === this._obj) {
            this._latestValue = value;
            this._ref.markForCheck();
        }
    }
}
