<ng-container *ngLet="item.children | asyncIfObservable as itemChildren">
    <ng-container
        *ngLet="
            item.hasVisibleChildren | asyncIfObservable as hasVisibleChildren
        "
    >
        <ng-container *ngLet="isExpanded$ | asyncIfObservable as isExpanded">
            <a
                mat-list-item
                (click)="onItemSelected(item, !!itemChildren?.length)"
                [ngClass]="{
                    active: active,
                    expanded: isExpanded,
                    'expanded-menu': menuState === 'out'
                }"
                class="menu-list-item"
            >
                <div class="icon">
                    <vsc-icon
                        [icon]="item.iconName"
                        [iconStyle]="active ? 'solid' : 'regular'"
                    ></vsc-icon>
                </div>
                <span
                    [ngStyle]="item.iconStyle"
                    #menuTitle
                    class="menu-item-title"
                >
                    {{ item.displayName | asyncIfObservable }}
                    <span
                        fxFlex
                        *ngIf="
                            itemChildren &&
                            itemChildren.length &&
                            hasVisibleChildren
                        "
                        class="drop-arrow"
                    >
                        <span fxFlex></span>
                        <vsc-icon
                            *ngIf="menuState === 'out'"
                            [@indicatorRotate]="
                                isExpanded ? 'expanded' : 'collapsed'
                            "
                            iconStyle="solid"
                        >
                            angle-down
                        </vsc-icon>
                    </span>
                </span>
            </a>
            <div *ngIf="isExpanded && hasVisibleChildren" class="expanded-menu">
                <ng-container *ngFor="let child of itemChildren">
                    <vsc-side-menu-item
                        [menuState]="menuState"
                        *ngIf="child.visible === true"
                        [item]="child"
                        [depth]="depth + 1"
                    >
                    </vsc-side-menu-item>
                </ng-container>
            </div>
        </ng-container>
    </ng-container>
</ng-container>
