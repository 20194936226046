import { SelectionModel } from '@angular/cdk/collections';
import {
    AfterViewInit,
    Component,
    EventEmitter,
    HostBinding,
    Input,
    OnDestroy,
    OnInit,
    Output,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';

import { Observable, Subscription } from 'rxjs';

import { FilterComponent } from '@shared/forms/filter/filter.component';

import { PaginatedEndpoint } from './page';
import { PaginatedDataSource } from './paginated-datasource';
import {
    ColumnSettingsModel,
    TablePaginationSettingsModel,
} from './table-settings.model';
import { TableDelegate } from './table.delegate';

@Component({
    selector: 'vsc-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnInit, AfterViewInit, OnDestroy {
    selectedRowIndex: number = -1;
    private subscriptions: Subscription[] = [];

    /**
     * @description enable selection of rows
     */
    @Input() noRecordsFoundText: Observable<string> | any = 'No Records Found';
    /**
     * @description Column names for the table
     */
    columnNames: string[] = [];
    /**
     * @description enable selection of rows
     */
    @Input() enableCheckbox: boolean;
    /**
     * @description Allowing/Dis-allowing multi-selection of rows
     */
    @Input() allowMultiSelect: boolean;
    /**
     * @description `sqColumnDefinition` is additional configuration settings provided to `sq-table`.Refer [sqColumnDefinition].
     */
    sqColumnDefinition: ColumnSettingsModel[];
    /**
     * @description `sqPaginationConfig` is additional configuration settings provided to `sq-table`.Refer [SqTablePaginationSettingsModel].
     */
    sqPaginationConfig?: TablePaginationSettingsModel;

    /**
     * @description Paginated endpoint configuration
     */
    paginatedEndpoint: PaginatedEndpoint<{}, {}>;

    /**
     * @description `sqColumnDefinition` is additional configuration settings provided to `sq-table`.Refer [sqColumnDefinition].
     */
    filtersDefinition: any[];

    /**
     * @description Enables or disables table filtering
     */
    @Input() filteringEnabled: boolean = false;

    /**
     * @description Table delegate for configuration
     */
    @Input() delegate: TableDelegate<{}, {}>;

    /**
     * @description Inserting template from parent element
     */
    @Input() actionTemplateRef: TemplateRef<any>;

    /**
     * @description variable to store selection data
     */
    selection: SelectionModel<{}> = new SelectionModel<{}>();
    /**
     * @description Local variable to keep datasource
     */
    dataSource: PaginatedDataSource<{}, {}>;
    /**
     * @description ViewChild to get the MatSort directive from DOM
     */
    @ViewChild(MatSort) sort: MatSort;
    /**
     * @description Lifecycle hook that is called after a component's view has been fully initialized.
     */
    @Output() getSelectedRows: EventEmitter<object> = new EventEmitter();

    @Input() columnTemplates: Array<{
        columnName: string;
        template: TemplateRef<any>;
    }> = [];

    @ViewChild(FilterComponent) filters: FilterComponent;

    @ViewChild('matTable') matTable: MatTable<any>;

    @HostBinding('class.filters-opened') filtersOpened: boolean = false;

    ngAfterViewInit(): void {
        this.subscriptions.concat([
            // Trigger sort change
            this.sort.sortChange.subscribe((sort) => {
                this.dataSource.sortBy({
                    property: sort.active,
                    order: sort.direction,
                });
            }),

            // Pass elements to delegate
            this.getSelectedRows.subscribe((rows: {}[]) => {
                if (this.delegate) {
                    this.delegate.onRowSelected(rows);
                }
            }),
        ]);
    }

    ngOnDestroy(): void {
        this.delegate.destroy();
        this.subscriptions.forEach((sub: Subscription) => sub.unsubscribe());
    }

    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected(): boolean {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.content?.length;
        return numSelected === numRows;
    }

    toggleFilters() {
        this.filtersOpened = !this.filtersOpened;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle(): void {
        this.isAllSelected()
            ? this.selection.clear()
            : this.dataSource.content?.forEach((row) =>
                  this.selection.select(row)
              );
        this.getSelectedRows.emit(this.selection.selected);
    }
    /** Gets the selected rows array on row select. */
    rowSelect(): void {
        this.getSelectedRows.emit(this.selection.selected);
    }
    /**
     * @hidden
     */
    /**
     * Initialize the directive/component after Angular first displays the data-bound properties
     * and sets the directive/component's input properties
     */
    ngOnInit(): void {
        if (this.delegate == null) {
            throw new Error('You must set the table delegate.');
        }

        this.delegate.tableComponent = this;
        this.sqColumnDefinition = this.delegate.getColumnSettings();
        this.sqPaginationConfig = this.delegate.getPaginationSetings();
        this.paginatedEndpoint = this.delegate.getPaginatedEndpoint();
        this.filtersDefinition = this.delegate.getFilterConfig();

        for (const column of this.sqColumnDefinition) {
            if (!column.hidden) {
                this.columnNames.push(column.name);
            }
        }
        // Condition to add selection column to the table
        if (this.enableCheckbox) {
            this.columnNames.splice(0, 0, 'select');
            this.sqColumnDefinition.splice(0, 0, {
                name: 'select',
                displayName: '#',
            });
        }
        // Setting selection model
        this.selection = new SelectionModel<{}>(this.allowMultiSelect, []);
        this.dataSource = new PaginatedDataSource(
            this.paginatedEndpoint,
            // tslint:disable-next-line: no-any
            { property: '', order: '' } as any,
            {},
            this.sqPaginationConfig ? this.sqPaginationConfig.pageSize : null,
            this.delegate.reload$
        );
    }
    /** Highlights the selected row on row click. */
    // tslint:disable-next-line: no-any
    highlight(row: any): void {
        this.selectedRowIndex = row.position;
    }

    onFiltersClosedStart() {
        this.filtersOpened = false;
    }

    onFilter(filters: any) {
        this.filtersOpened = false;
        this.delegate.query(filters);
    }

    onFilterReset(filters: any) {
        this.onFilter(filters);
    }

    filter() {
        this.filters.filterClick();
    }
    resetFilter() {
        this.filters.reset();
    }

    isObservable(value: any): boolean {
        return value instanceof Observable;
    }

    getColumnTemplate(columnName: string): TemplateRef<any> {
        const columnTemplate = this.columnTemplates.find(
            (x) => x.columnName === columnName
        );
        if (columnTemplate) {
            return columnTemplate.template;
        }
        return null;
    }

    removeRow(predicate: (value: any) => boolean): void {
        this.dataSource.removeItem(predicate);
        this.matTable && this.matTable.renderRows();
    }

    updateRow(predicate: (value: any) => boolean, item: any): void {
        this.dataSource.updateItem(predicate, item);
        this.matTable && this.matTable.renderRows();
    }
}
