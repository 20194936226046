import { permissionAuthorize } from './permission-authorize.decorator';

/**
 * @description Checks if the user has permissions.
 * @param permissions Single or multiple permissions allowed to access resource.
 * @returns Actual decorator function.
 */
export function PermissionAuthorize(
    permissions: string | string[]
): (target: any, name: string, descriptor: PropertyDescriptor) => void {
    return (target: any, name: string, descriptor: PropertyDescriptor) =>
        permissionAuthorize(target, name, descriptor, permissions);
}
