import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IconModule } from '@shared/icon/icon.module';
import { MaterialModule } from '@shared/material.module';

import { SearchInputComponent } from './search-input.component';

@NgModule({
    imports: [CommonModule, MaterialModule, IconModule],
    declarations: [SearchInputComponent],
    exports: [SearchInputComponent],
})
export class SearchInputModule {}
