import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnInit,
    TemplateRef,
} from '@angular/core';

import * as moment from 'moment';
import { map, timer } from 'rxjs';

import { PublicEvent } from './state/public-event';

@Component({
    selector: 'vsc-public-event-card',
    templateUrl: './public-event-card.component.html',
    styleUrls: ['./public-event-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PublicEventCardComponent implements OnInit {
    constructor() {}

    @Input() orientation: 'horizontal' | 'vertical' = 'vertical';

    @Input() publicEvent: PublicEvent;

    @Input() verticalCardActionsTemplateRef: TemplateRef<any>;

    @Input() horizontalCardActionsTemplateRef: TemplateRef<any>;

    @Input() clickable: boolean = true;

    isVerticalOrientation: boolean;

    // * check every 10 seconds for the time left
    leftMinutes$ = timer(0, 1000 * 10).pipe(
        map(() => {
            const now = moment();

            const startDateTime = moment(this.publicEvent.startDateTime);

            const duration = moment.duration(startDateTime.diff(now));

            return Math.ceil(duration.asMinutes());
        })
    );

    ngOnInit(): void {
        this.isVerticalOrientation = this.orientation === 'vertical';
    }

    dateStatusHelper(
        startDateTime: moment.Moment,
        duration: number
    ): PublicEventDateStatus {
        const now = moment();

        const endDateTime = moment(startDateTime).addMinutes(duration);

        if (now >= startDateTime && now <= endDateTime) {
            return PublicEventDateStatus.HappeningNow;
        }

        if (now < startDateTime && now.add(1, 'hour') >= startDateTime) {
            return PublicEventDateStatus.WillHappenInOneHour;
        }

        return PublicEventDateStatus.WillHappenLater;
    }
}

enum PublicEventDateStatus {
    HappeningNow,
    WillHappenInOneHour,
    WillHappenLater,
}
