<ng-container *ngIf="alert$ | async as alert">
    <mat-card class="alerts-mat-card" *ngIf="alert !== null && isVisible">
        <div class="container" fxLayout="column" fxLayoutAlign="end start">
            <ng-container>
                <div class="alerts-widget-wrapper">
                    <a (click)="closeWidget(alert.id)">
                        <vsc-icon class="x-mark-icon">circle-xmark</vsc-icon>
                    </a>
                    <div class="content">
                        <h4 class="content-title">{{ alert.title }}</h4>
                        <p class="content-text">
                            {{ alert.description }}
                        </p>
                        <a
                            *ngIf="alert.link"
                            (click)="openPage(alert.link)"
                            class="see-details-button"
                        >
                            {{ 'Click here to learn more' | transloco }}
                            <vsc-icon iconStyle="solid">caret-right</vsc-icon>
                        </a>
                    </div>
                    <div class="widget-image">
                        <img
                            src="../../../../../assets/images/alert-widget.png"
                            [alt]="'Alerts widget image' | transloco"
                        />
                    </div>
                </div>
            </ng-container>
        </div>
    </mat-card>
</ng-container>
