import {
    AbstractControl,
    FormControl,
    ValidationErrors,
    Validator,
} from '@angular/forms';

import { DynamicValidator, Group, Rule } from './dynamic';

export class RegularExpressionValidator
    extends DynamicValidator
    implements Validator
{
    constructor(rules?: Array<Rule | Group>) {
        super(rules);
    }

    validate(control: AbstractControl): ValidationErrors | null {
        if (control instanceof FormControl) {
            const value = control.value;
            let isValid = true;

            try {
                const regEx = new RegExp(value);
            } catch (e) {
                isValid = false;
            }

            if (!value || isValid) {
                return null;
            }

            return {
                regularExpression: {
                    current: control.value,
                    validRegEx: true,
                },
            };
        }

        return null;
    }
}
