import {
    ChangeDetectionStrategy,
    Component,
    OnDestroy,
    OnInit,
} from '@angular/core';

import { Subscription } from 'rxjs';

import { Permissions } from '@core/auth/permissions';

import { AlertService } from './services/alert-services/alert.service';
import { PromotionService } from './services/promotion-services/promotion.service';

@Component({
    selector: 'vsc-public-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomeComponent implements OnInit, OnDestroy {
    hasAnyPromotion: boolean;
    hasAnyAlert: boolean;
    isAlertVisible: boolean;

    private subscriptions: Subscription[] = [];

    Permissions: any = Permissions;

    constructor(
        private promotionService: PromotionService,
        private alertService: AlertService
    ) {}

    ngOnInit(): void {
        this.subscriptions.push(
            this.promotionService.getPromotions().subscribe((promotions) => {
                this.hasAnyPromotion = promotions?.length > 0;
            })
        );

        this.subscriptions.push(
            this.alertService.getActiveAlert().subscribe((alert) => {
                this.hasAnyAlert = alert !== null;
            })
        );
    }

    onAlertVisibilityChange(isVisible: boolean): void {
        this.isAlertVisible = isVisible;
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((sub: Subscription) => sub?.unsubscribe());
    }
}
