import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    DoCheck,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { FormControl, FormGroupDirective } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { ErrorStateMatcher, MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';

import {
    controlValueAccessorProvider,
    ControlValueAccessor,
} from '../control-value-accessor';
import { SelectItemSingle } from '../select';

@Component({
    selector: 'vsc-select-search',
    templateUrl: './select-search.component.html',
    styleUrls: ['./select-search.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        controlValueAccessorProvider(SelectSearchComponent as Component),
    ],
})
export class SelectSearchComponent
    extends ControlValueAccessor<string>
    implements AfterViewInit, OnInit, DoCheck, OnChanges
{
    @Input() formControlName: string;
    @Input() placeholder: string;
    @Input() sourceProcId: string;
    @Input() multiple: boolean;
    @Input() label: string;
    @Input() description: string;
    @Input() readonly: boolean;
    @Input() hint: string = '';
    @Input() infoTooltipHint: string;
    @Input() valueRef: any;
    @Input() searchable: boolean = false;
    @Input() searchPlaceholder: string;
    @Input() selectAllText: string = 'Select all';
    @Input() isSelectAllEnabled: boolean = true;
    @Output() readonly selectionChanged: EventEmitter<SelectItemSingle> =
        new EventEmitter<SelectItemSingle>();
    @Input() isTranslatable?: boolean;
    formControl: FormControl;
    searchTextboxControl: FormControl = new FormControl();
    @ViewChild(MatSelect, { static: false }) matSelect: MatSelect;
    private items: Array<SelectItemSingle>;
    selectedValues: Array<string>;
    filteredOptions: Array<SelectItemSingle>;
    isSelectAllChecked: boolean = false;
    get options(): Array<SelectItemSingle> {
        // transform value for display
        return this.items;
    }

    @Input() set options(items: Array<SelectItemSingle>) {
        const itemsData = items ? [...items] : [];
        this.items = itemsData;
    }

    @Input() textMapper = (option: any) => {
        return option['name'] ?? option['description'];
    };
    @Input() valueMapper = (option: any) => {
        return option['id'];
    };
    @Input() shouldDisplayOption = (option: any) => {
        return true;
    };

    constructor(
        private readonly formGroupDirective: FormGroupDirective,
        private readonly errorStateMatcher: ErrorStateMatcher,
        private changeDetectorRef: ChangeDetectorRef
    ) {
        super();
        this.selectedValues = [];
    }

    ngOnInit(): void {
        this.formControl = this.formGroupDirective.control.get(
            this.formControlName
        ) as FormControl;
    }

    ngOnChanges(): void {
        this.filteredOptions = this.options;
    }

    ngAfterViewInit(): void {
        this.matSelect.ngControl = null;
    }

    ngDoCheck(): void {
        if (this.matSelect) {
            this.matSelect.errorState = this.errorStateMatcher.isErrorState(
                this.formControl,
                this.formGroupDirective
            );
            this.matSelect.stateChanges.next();
        }
    }

    __onSelectionChange(value: string): void {
        this.value = value === '' ? null : value;
        this.selectionChanged.emit(
            this.options && this.options.find((x) => x.id === value)
        );
    }

    compareFn(o1: any, o2: any): boolean {
        return o1 === o2;
    }

    openedChange(): void {
        this.searchTextboxControl.patchValue('');
        if (this.multiple) {
            this.updateSelectFormControl();
            this.isSelectAllChecked = this.isSelectAllCheckedFn();
        }
        this.filteredOptions = this.options;
        this.changeDetectorRef.detectChanges();
    }

    updateSelectFormControl(): void {
        this.setSelectedValues();
        this.formControl.patchValue(this.selectedValues);
    }

    setSelectedValues(): void {
        if (this.formControl.value) {
            this.formControl.value.length === 0
                ? this.deselectAll()
                : this.formControl.value.forEach((element) => {
                      if (this.selectedValues.indexOf(element) === -1) {
                          this.selectedValues.push(element);
                      }
                  });
        }
    }

    onKeyUp(name: string): SelectItemSingle[] {
        const filterValue = name.trim().toLowerCase();
        this.filteredOptions = this.options.filter(
            (option) =>
                option.name.toLowerCase().indexOf(filterValue) === 0 ||
                option.name.toLowerCase().includes(filterValue)
        );
        return this.filteredOptions;
    }

    handleInput(event: KeyboardEvent): void {
        if (
            this.searchTextboxControl.value === '' &&
            !this.isSelectAllCheckedFn()
        ) {
            this.isSelectAllChecked = false;
        }
        event.stopPropagation();
    }

    isSelectAllCheckedFn(): boolean {
        return (
            this.formControl.value &&
            this.items.length &&
            this.formControl.value.length === this.items.length
        );
    }

    optionSelectionChange(event: any): void {
        if (event.isUserInput) {
            const index = this.selectedValues.indexOf(event.source.value);
            event.source.selected
                ? this.selectedValues.push(event.source.value)
                : this.selectedValues.splice(index, 1);
        }
    }

    toggleSelection(change: MatCheckboxChange): void {
        change.checked ? this.selectAll() : this.deselectAll();
    }

    private selectAll(): void {
        this.matSelect.options.forEach((item: MatOption) => {
            item.select();
            if (this.selectedValues.indexOf(item.value) === -1) {
                this.selectedValues.push(item.value);
            }
        });
    }

    private deselectAll(): void {
        if (
            this.filteredOptions.length &&
            this.filteredOptions.length < this.selectedValues.length
        ) {
            this.matSelect.options.forEach((item: MatOption) => {
                if (this.filteredOptions.indexOf(item.value) === -1) {
                    item.deselect();
                    this.selectedValues.splice(
                        this.selectedValues.indexOf(item.value),
                        1
                    );
                }
            });
        } else {
            this.matSelect.options.forEach((item: MatOption) =>
                item.deselect()
            );
            this.selectedValues = [];
        }
    }
}
