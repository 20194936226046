import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { Breadcrumb } from '../vsc-breadcrumbs.shared';

export type IPostProcessFunc = (
    crumbs: Breadcrumb[]
) => Promise<Breadcrumb[]> | Observable<Breadcrumb[]> | Breadcrumb[];

@Injectable()
export class VscBreadcrumbsConfig {
    postProcess: IPostProcessFunc;
}
