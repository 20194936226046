import { Injectable } from '@angular/core';

import { setLoading } from '@datorama/akita';
import { TranslocoService } from '@ngneat/transloco';

import * as moment from 'moment';
import { Observable, EMPTY } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { SnackBarService } from '@shared/snack-bar/snack-bar.service';

import { Announcement } from '../../state/announcement-state/announcement';
import { AnnouncementStore } from '../../state/announcement-state/announcement.store';
import { AnnouncementDataService } from './announcement.dataservice';

@Injectable({ providedIn: 'root' })
export class AnnouncementService {
    constructor(
        private announcementStore: AnnouncementStore,
        private announcementDataService: AnnouncementDataService,
        private snackBar: SnackBarService,
        private translocoService: TranslocoService
    ) {}

    getAnnouncements(): Observable<Array<Announcement>> {
        return this.announcementDataService.getAnnouncements().pipe(
            map((response: Array<Announcement>) => {
                const announcements = response.map(
                    (announcement: Announcement) =>
                        ({
                            ...announcement,
                            startDate: announcement.startDate
                                ? moment(announcement.startDate)
                                : null,
                        } as Announcement)
                );
                this.announcementStore.set(announcements);
                return announcements;
            }),
            setLoading(this.announcementStore),
            catchError(() => {
                this.snackBar.open(
                    this.translocoService.translate(
                        'Failed to load Announcements.'
                    )
                );
                return EMPTY;
            })
        );
    }
}
