import { DOCUMENT } from '@angular/common';
import { Component, OnDestroy, OnInit, Inject } from '@angular/core';

import {
    AvailableLangs,
    LangDefinition,
    TranslocoService,
} from '@ngneat/transloco';

import { CookieService } from 'ngx-cookie-service';
import { Subscription, BehaviorSubject, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { environment } from '@environment/environment';

import { OneSignalHelperService } from '@shared/notifications/services/onesignal-helper.service';
import { CommonUtilities } from '@shared/utils/common-utilities';

@Component({
    selector: 'vsc-lng-switcher',
    templateUrl: './lng-switcher.component.html',
    styleUrls: ['./lng-switcher.component.scss'],
})
export class LngSwitcherComponent implements OnInit, OnDestroy {
    availableLangs: AvailableLangs;
    activeLang: BehaviorSubject<string>;
    activeLangName: Observable<string>;
    readonly cookieName: string = '.AspNetCore.Culture';

    private subscription: Subscription = Subscription.EMPTY;

    constructor(
        private service: TranslocoService,
        private cookieService: CookieService,
        private oneSignalHelperService: OneSignalHelperService,
        @Inject(DOCUMENT) private document: Document
    ) {
        this.availableLangs = service.getAvailableLangs();
    }
    ngOnInit(): void {
        const languageFromCookie: string = this.cookieService
            .get(this.cookieName)
            .substr(2, 2);
        if (languageFromCookie === '') {
            this.activeLang = new BehaviorSubject<string>(
                this.service.getDefaultLang()
            );
        } else {
            this.service.setActiveLang(languageFromCookie);
            this.activeLang = new BehaviorSubject<string>(languageFromCookie);

            this.service.langChanges$.subscribe(() =>
                this.service.getAvailableLangs()
            );
        }

        this.activeLangName = this.activeLang.pipe(
            map((value: string) => {
                return (this.availableLangs as LangDefinition[]).find(
                    (x: LangDefinition) => x.id === value
                )?.label;
            })
        );
    }

    change(lang: string): void {
        // Ensure new active lang is loaded
        this.activeLang.next(lang);
        this.subscription.unsubscribe();
        this.subscription = this.service
            .load(lang)
            .pipe(take(1))
            .subscribe(() => {
                this.service.setActiveLang(lang);
                this.cookieService.delete(this.cookieName);
                this.cookieService.set(
                    this.cookieName,
                    'c=' + lang + '|uic=' + lang,
                    365,
                    '/',
                    CommonUtilities.getDomainFromUrl(environment.identityUrl)
                );
            });
        this.document.documentElement.lang = lang;

        this.oneSignalHelperService.updatePlayerLanguage(lang);
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
