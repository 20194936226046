import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { environment } from '@environment/environment';

import { Promotion } from '../../state/promotion-state/promotion';

@Injectable({ providedIn: 'root' })
export class PromotionDataService {
    constructor(private httpClient: HttpClient) {}

    getPromotions(): Observable<Array<Promotion>> {
        return this.httpClient.get<Array<Promotion>>(
            environment.apiUrl + '/vsc/promotions/active'
        );
    }
}
