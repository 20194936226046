import { Injectable } from '@angular/core';

import { ID, QueryEntity } from '@datorama/akita';

import * as moment from 'moment';
import { Observable, timer } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import { UserClass } from './class';
import { TodaysClassesState, TodaysClassesStore } from './todays-classes.store';

@Injectable({
    providedIn: 'root',
})
export class TodaysClassesQuery extends QueryEntity<
    TodaysClassesState,
    UserClass
> {
    constructor(protected store: TodaysClassesStore) {
        super(store);
    }

    todaysClasses$: Observable<UserClass[]> = timer(0, 60000).pipe(
        mergeMap(() =>
            this.selectAll().pipe(
                map((userClasses: UserClass[]) =>
                    userClasses.filter((userClass: UserClass) => {
                        const session = userClass.classSession;
                        const fromDate = moment();
                        fromDate.setMidnightTime();
                        const classStartDate = moment(session?.startDateTime);

                        return (
                            session &&
                            (classStartDate.isToday() ||
                                classStartDate.isBefore(fromDate)) &&
                            classStartDate
                                .addMinutes(session.duration)
                                .valueOf() > moment().valueOf()
                        );
                    })
                )
            )
        )
    );

    getSession(id: ID): UserClass {
        return this.getAll().find((x) => x.classSessionId === id);
    }
}
