import {
    trigger,
    state,
    style,
    transition,
    animate,
} from '@angular/animations';
import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';

import { Subscription, merge } from 'rxjs';

import { DynamicFormComponent } from '../dynamic-form/dynamic-form/dynamic-form.component';
import {
    Field,
    KeyValuePair,
} from '../dynamic-form/dynamic-reactive-form.model';
import { FilterField, FilterType } from './filter.config';

@Component({
    selector: 'vsc-filter',
    templateUrl: './filter.component.html',
    styleUrls: ['./filter.component.scss'],
    animations: [
        trigger('openClose', [
            // ...
            state(
                'open',
                style({
                    'min-height': '100px',
                    'margin-bottom': '20px',
                    opacity: 1,
                })
            ),
            state(
                'closed',
                style({
                    height: '0px',
                    'margin-bottom': '-40px',
                    opacity: 0,
                })
            ),
            transition('open => closed', [animate('200ms')]),
            transition('closed => open', [animate('200ms')]),
        ]),
    ],
})
export class FilterComponent implements OnInit, OnDestroy, AfterViewInit {
    private subscription: Subscription;

    @Input() filterFields: FilterField[] = [];
    @Input() defaultValues: KeyValuePair[];
    @Input() showFilterControls: boolean = false;
    @Input() filterOnType: boolean = false;

    @Output() filterChange: EventEmitter<KeyValuePair> = new EventEmitter();
    @Output() filter: EventEmitter<KeyValuePair> = new EventEmitter();
    @Output() filterReset: EventEmitter<KeyValuePair[]> = new EventEmitter();

    @ViewChild('basicFilters')
    basicFiltersDynamicFormComponent: DynamicFormComponent;
    @ViewChild('advancedFilters')
    advancedFiltersDynamicFormComponent: DynamicFormComponent;

    public basicFilterFields: Field[];
    public advancedFilterFields: Field[];
    public shouldShowAdvancedFilters: boolean = false;

    constructor() {}

    ngOnInit() {
        this.basicFilterFields = this.filterFields
            .filter((x) => x.type == FilterType.Basic)
            .map((x) => x.field);
        this.advancedFilterFields = this.filterFields
            .filter((x) => x.type == FilterType.Advanced)
            .map((x) => x.field);
    }

    ngAfterViewInit(): void {
        this.subscription = merge(
            this.basicFiltersDynamicFormComponent.form.valueChanges,
            this.advancedFiltersDynamicFormComponent.form.valueChanges
        ).subscribe(() => {
            if (this.filterOnType) {
                const filter = {
                    ...this.basicFiltersDynamicFormComponent.form.value,
                    ...this.advancedFiltersDynamicFormComponent.form.value,
                };
                this.filterChange.emit(filter);
            }
        });
    }

    keyPress(event) {
        if (event.keyCode === 13) {
            this.filterClick();
        }
    }

    filterClick() {
        const filter = {
            ...this.basicFiltersDynamicFormComponent.form.value,
            ...this.advancedFiltersDynamicFormComponent.form.value,
        };
        this.filter.emit(filter);
    }

    reset() {
        this.advancedFiltersDynamicFormComponent.form.reset(this.defaultValues);
        this.basicFiltersDynamicFormComponent.form.reset(this.defaultValues);

        const filter = {
            ...this.basicFiltersDynamicFormComponent.form.value,
            ...this.advancedFiltersDynamicFormComponent.form.value,
        };

        this.filterReset.emit(filter);
    }

    toggleAdvancedFilters() {
        this.shouldShowAdvancedFilters = !this.shouldShowAdvancedFilters;
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
        this.filterChange.unsubscribe();
    }
}
