import {
    AbstractControl,
    FormControl,
    ValidationErrors,
    Validator,
} from '@angular/forms';

import { DynamicValidator, Group, Rule } from './dynamic';

export class UrlValidator extends DynamicValidator implements Validator {
    private urlRegex: RegExp;

    constructor(
        private messageResolver?: () => string,
        rules?: Array<Rule | Group>,
        allowOnlySecureUrl?: boolean
    ) {
        super(rules);
        if (allowOnlySecureUrl) {
            this.urlRegex =
                /https:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
        } else {
            this.urlRegex =
                /((http|https):\/\/)(www.)?[-a-zA-Z0-9@:%._\+~#?&\/\/=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%._\+~#?&\/\/=]*)/;
        }
    }

    validate(control: AbstractControl): ValidationErrors | null {
        if (
            control instanceof FormControl &&
            control.value &&
            !this.urlRegex.test(control.value)
        ) {
            let message;
            if (
                !(control.value as string).startsWith('https://') &&
                !(control.value as string).startsWith('http://')
            ) {
                message = 'Url should start with https:// or http://';
            } else {
                message = 'Url is not valid';
            }
            return {
                url: {
                    current: control.value,
                    message,
                    messageResolver: this.messageResolver,
                },
            };
        }

        return null;
    }
}
