import * as template from 'lodash.template';
import * as templateSettings from 'lodash.templatesettings';
import { from, of, Observable } from 'rxjs';

const _ = {
    template,
    templateSettings,
};

_.templateSettings.interpolate = /{{([\s\S]+?)}}/g;

export abstract class Breadcrumb {
    text: string | Observable<string>;
    path: string;
    isTranslatable: boolean = true;
}

declare let require: any;

export function stringFormat(interpolation: string, binding: any): string {
    const compiled = _.template(interpolation);
    return compiled(binding);
}

export function isPromise(value: any): boolean {
    return value && typeof value.then === 'function';
}

export function wrapIntoObservable<T>(
    value: T | Promise<T> | Observable<T>
): Observable<T> {
    if (value instanceof Observable) {
        return value;
    }

    if (isPromise(value)) {
        return from(Promise.resolve(value));
    }

    return of(value as T);
}
