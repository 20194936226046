import { Lookup } from '@shared/state/lookup/lookup';

export abstract class CommonUtilities {
    /**
     * @description Extracts domain from URL.
     * @param url URL from which to extract domain.
     * @returns Domain as string.
     */
    static getDomainFromUrl(url: string): string {
        const hostname = new URL(url).hostname;
        return hostname.substring(
            hostname.lastIndexOf('.', hostname.lastIndexOf('.') - 1) + 1
        );
    }
    static applyPhoneMask(phoneNumber: string): string {
        if (phoneNumber === null || phoneNumber === '') {
            return null;
        }
        const phoneNumberDigits = phoneNumber.replace(/\D/g, '');
        let phoneNumberPosition = 0;
        const skipPosition = phoneNumberDigits.length - 1;
        let maskedPhoneNumber = '';
        while (phoneNumberPosition < phoneNumberDigits.length) {
            maskedPhoneNumber += phoneNumberDigits.substring(
                phoneNumberPosition,
                (phoneNumberPosition += 3)
            );
            if (phoneNumberPosition > phoneNumberDigits.length - 1) {
                break;
            }
            if (phoneNumberPosition != skipPosition) {
                maskedPhoneNumber += '-';
            }
        }
        return maskedPhoneNumber;
    }
    static renderEmailAddress(input: string): string {
        let result, replacePattern;

        // Replace email addresses with mailto:: links.
        replacePattern =
            /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim;
        result = input.replace(replacePattern, '<a href="mailto:$1">$1</a>');

        return result;
    }

    /**
     * @description Creates a new script element and appends it to the DOM.
     * If the script with the same source already exists, it won't be added again.
     */
    static loadScript({
        src,
        appendTo = 'body',
        checkIfExists = false,
        async = false,
        defer = true,
        crossOrigin = '',
        id = '',
    }: {
        src: string;
        appendTo: 'body' | 'head';
        checkIfExists?: boolean;
        async?: boolean;
        defer?: boolean;
        crossOrigin?: string;
        id?: string;
    }): void {
        if (!src) {
            return;
        }

        if (checkIfExists && document.querySelector(`script[src="${src}"]`)) {
            console.error(`Script with URL "${src}" already loaded.`);
            return;
        }

        const element = document.getElementsByTagName(appendTo)[0];
        if (!element) {
            console.error(
                `Failed to append script to DOM. Element ${appendTo} not found.`
            );
            return;
        }
        const script = document.createElement('script');
        script.innerHTML = '';
        script.src = src;
        script.async = async;
        script.defer = defer;
        if (crossOrigin) {
            script.crossOrigin = crossOrigin;
        }
        if (id) {
            script.id = id;
        }
        element.appendChild(script);
    }

    static enumToList(enumeration: any): Lookup[] {
        return Object.entries(enumeration)
            .filter(
                ([key, _]) => typeof key === 'string' && isFinite(Number(key))
            )
            .map(([key, value]) => {
                return {
                    id: key,
                    name: value,
                } as Lookup;
            });
    }
}
