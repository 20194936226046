import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TranslocoModule } from '@ngneat/transloco';

import { JoinClassComponent } from '../join-class/join-class.component';
import { MaterialModule } from '../material.module';

@NgModule({
    declarations: [JoinClassComponent],
    exports: [JoinClassComponent],
    imports: [MaterialModule, TranslocoModule, CommonModule],
})
export class JoinClassModule {}
