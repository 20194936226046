import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    RouterStateSnapshot,
} from '@angular/router';

import { Observable } from 'rxjs';
import { take, map } from 'rxjs/operators';

import { UserService } from '@app/admin/admin/users/services/user.service';
import { AuthService } from '@core/auth/auth.service';

@Injectable({
    providedIn: 'root',
})
export class ConsumerInformationCanActivateGuard implements CanActivate {
    constructor(
        private router: Router,
        private authService: AuthService,
        private usersService: UserService
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> {
        return this.usersService
            .GetConsumerInfoPageVisibility(this.authService.userId)
            .pipe(
                take(1),
                map((userHasVisitedPage: boolean) => {
                    if (userHasVisitedPage === true) {
                        this.router.navigate(['not-found']);
                        return false;
                    } else {
                        return true;
                    }
                })
            );
    }
}
