import { Injectable, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { TranslocoService } from '@ngneat/transloco';

import { CookieService } from 'ngx-cookie-service';
import {
    NgcCookieConsentConfig,
    NgcCookieConsentService,
    NgcStatusChangeEvent,
} from 'ngx-cookieconsent';
import { Subscription } from 'rxjs';

import { environment } from '@environment/environment';

import { Constants } from '@shared/constants';
import { CommonUtilities } from '@shared/utils/common-utilities';

import { CookiesManageComponent } from '../cookies-manage/cookies-manage.component';

@Injectable({
    providedIn: 'root',
})
export class CookieHelperService implements OnDestroy {
    private statusChangeSubscription: Subscription;

    private readonly gaTrackingCode: string = environment.gaTrackingCode;
    readonly cookieConsentStatus: string = 'cookieconsent_status';
    readonly gaCookieName: string = '_ga';

    constructor(
        private translocoService: TranslocoService,
        private cookieService: CookieService,
        private cookieConsentService: NgcCookieConsentService,
        private dialog: MatDialog
    ) {}

    ngOnDestroy(): void {
        this.statusChangeSubscription?.unsubscribe();
    }

    setCookieConsentStatus(isCookieAllowed: boolean): void {
        const expiryDate = new Date(
            new Date().setFullYear(new Date().getFullYear() + 1)
        );

        const cookie = isCookieAllowed
            ? 'cookieconsent_status=allow;'
            : 'cookieconsent_status=deny;';

        document.cookie = cookie.concat(
            'expires=' +
                expiryDate +
                ';path=/;domain=' +
                CommonUtilities.getDomainFromUrl(environment.identityUrl)
        );
    }

    setGACookiesTrackingStatus(isCookieAllowed: boolean): void {
        window['ga-disable-' + this.gaTrackingCode] = !isCookieAllowed;
    }

    getCookieConsentConfig(): NgcCookieConsentConfig {
        const config: NgcCookieConsentConfig = {
            cookie: {
                domain: CommonUtilities.getDomainFromUrl(
                    environment.identityUrl
                ),
            },
            type: 'opt-in',
            revokable: true,
            showLink: false,
            content: {
                message: this.translocoService.translate(
                    'We use cookies to improve user experience and analyze website traffic. By using enliveo you consent to all cookies in accordance with our Cookie Policy. You can change your cookie settings at any time by clicking Preferences.'
                ),
                allow: this.translocoService.translate('Preferences'),
                deny: this.translocoService.translate('Accept and Continue'),
            },
        };
        return config;
    }

    getCookieByName(cookieName: string): string {
        return this.cookieService.get(cookieName);
    }

    isCookieSet(): boolean {
        const gaCookie = this.getCookieByName(this.gaCookieName);
        const consentStatus = this.getCookieByName(this.cookieConsentStatus);
        return (
            consentStatus === Constants.COOKIE_CONSENT_ALLOWED &&
            gaCookie !== ''
        );
    }

    removeCookieConsent(): void {
        document.cookie =
            Constants.COOKIE_EXPIRED +
            CommonUtilities.getDomainFromUrl(environment.identityUrl);
    }

    hasCookieConsent(cookieConsentStatus: string): boolean {
        const isAllowed =
            cookieConsentStatus === Constants.COOKIE_CONSENT_ALLOWED;
        return !isAllowed;
    }

    initializeCookies() {
        const isCookieSet = this.isCookieSet();
        if (isCookieSet) {
            this.cookieConsentService.close(false);
        } else {
            this.setGACookiesTrackingStatus(false);
            if (!this.cookieConsentService.hasAnswered()) {
                this.cookieConsentService.open();
            }
        }

        this.statusChangeSubscription?.unsubscribe();
        this.statusChangeSubscription =
            this.cookieConsentService.statusChange$.subscribe(
                (event: NgcStatusChangeEvent) => {
                    const cookieConsentStatus = this.hasCookieConsent(
                        event.status
                    );
                    this.setGACookiesTrackingStatus(cookieConsentStatus);
                    this.setCookieConsentStatus(cookieConsentStatus);
                    if (!cookieConsentStatus) {
                        this.removeCookieConsent();
                        this.dialog.open(CookiesManageComponent, {
                            width: '480px',
                            disableClose: true,
                            data: { isGaEnabled: true },
                        });
                    }
                }
            );
    }

    public configureCookieConsent(): NgcCookieConsentConfig {
        const config = this.getCookieConsentConfig();

        if (
            !this.cookieConsentService.isOpen() &&
            !this.cookieConsentService.hasConsented()
        ) {
            this.cookieConsentService.open();
        }

        return config;
    }
}
