import { Injectable } from '@angular/core';
import { DateRange } from '@angular/material/datepicker';

import { setLoading } from '@datorama/akita';
import { TranslocoService } from '@ngneat/transloco';

import * as moment from 'moment';
import { EMPTY, Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { Page } from '@shared/collection-view/page';
import { Constants } from '@shared/constants';
import { ListViewQuery } from '@shared/list-view/page';
import { PagedEntities } from '@shared/models/paged-entities';
import { RouterService } from '@shared/services/router.service';
import { SnackBarService } from '@shared/snack-bar/snack-bar.service';
import { PageRequest } from '@shared/table/page';

import { UserClassSession } from '@public/classes/class-session-state/user-class-session';

import { ClassDateFilterType } from '../class-filter/class-filter-state/class-filter-type.enum';
import { ClassSession } from '../class-session-state/class-session';
import { ClassSessionFilterQuery } from '../class-session-state/class-sessions-filter.query';
import { ClassSessionsStore } from '../class-session-state/class-sessions.store';
import { UserClass } from '../class-state/class';
import { FilterType } from '../class-state/filter-type.enum';
import { TodaysClassesStore } from '../class-state/todays-classes.store';
import { ClassesState, ClassesStore } from './../class-state/classes.store';
import { ClassDataService } from './classes.dataservice';

declare type Moment = moment.Moment;

export interface ClassFilterQuery extends ListViewQuery {
    display?: string;
    lastDisplay?: string;
    classId?: string;
    search?: string;
    date?: DateRange<Moment>;
    categories?: Array<string>;
    dateFilterType?: ClassDateFilterType;
}

@Injectable({ providedIn: 'root' })
export class ClassService {
    constructor(
        private classDataService: ClassDataService,
        private classesStore: ClassesStore,
        private classSessionsStore: ClassSessionsStore,
        private routerService: RouterService,
        private snackBar: SnackBarService,
        private todaysClassesStore: TodaysClassesStore,
        private translocoService: TranslocoService
    ) {}

    page(
        request: PageRequest<UserClass>,
        query: ClassFilterQuery
    ): Observable<Page<UserClass>> {
        return this.classDataService.getClasses(query, request).pipe(
            map((response: PagedEntities<UserClass>) => {
                const userClasses = this.mapUserClasses(response.entities);
                this.updateClassSessionsStore(
                    userClasses.map(
                        (userClass: UserClass) => userClass?.classSession
                    )
                );
                return {
                    content: userClasses,
                    size: response.entities.length,
                    totalElements: response.totalCount,
                    number: request.page,
                    display: query.display,
                } as Page<UserClass>;
            }),
            catchError(() => {
                this.snackBar.open('Failed to load Classes.');
                return EMPTY;
            })
        );
    }

    pageClassSessions(
        request: PageRequest<ClassSession>,
        query: ClassSessionFilterQuery
    ): Observable<Page<ClassSession>> {
        return this.classDataService.getClassSessionsPage(query, request).pipe(
            map((response: PagedEntities<ClassSession>) => {
                this.classSessionsStore.upsertMany(response.entities);
                return {
                    content: response.entities,
                    size: response.entities.length,
                    totalElements: response.totalCount,
                    number: request.page,
                } as Page<ClassSession>;
            }),
            setLoading(this.classSessionsStore),
            catchError(() => {
                this.snackBar.open('Failed to load Classes.');
                return EMPTY;
            })
        );
    }

    getRecommendedPrograms(programId: string): Observable<UserClass[]> {
        return this.classDataService.getRecommendedPrograms(programId).pipe(
            map((response: UserClass[]) => {
                const userClasses = this.mapUserClasses(response);
                this.classesStore.upsertMany(userClasses);

                return userClasses;
            }),
            setLoading(this.classesStore),
            catchError(() => {
                this.snackBar.open('Failed to load Recommended Programs.');
                return EMPTY;
            })
        );
    }

    getById(id: string): Observable<UserClass> {
        return this.classDataService.getById(id).pipe(
            map((classItem: UserClass) => {
                this.classesStore.upsert(classItem.id, classItem);

                return classItem;
            }),
            catchError(() => {
                this.snackBar.open('Failed to load Program.');
                return EMPTY;
            })
        );
    }

    addToFavorite(id: string): Observable<any> {
        return this.classDataService.addToFavorite(id).pipe(
            map((response) => {
                this.classesStore.update(
                    (userClass: UserClass) => userClass.id == id,
                    { isFavorite: true }
                );

                // * update todays classes
                this.todaysClassesStore.update(
                    (userClass: UserClass) => userClass.id == id,
                    { isFavorite: true }
                );

                this.snackBar.open('Program added to my calendar.');
                return response;
            }),
            catchError(() => {
                this.snackBar.open('Failed to add program to my calendar.');
                return EMPTY;
            })
        );
    }

    removeFromFavorite(id: string): Observable<any> {
        return this.classDataService.removeFromFavorite(id).pipe(
            map((response) => {
                this.classesStore.update(
                    (userClass: UserClass) => userClass.id == id,
                    { isFavorite: false }
                );

                // * update todays classes
                this.todaysClassesStore.update(
                    (userClass: UserClass) => userClass.id == id,
                    { isFavorite: false }
                );

                this.snackBar.open('Program removed from my calendar.');
                return response;
            }),
            catchError(() => {
                this.snackBar.open(
                    'Failed to remove program from my calendar.'
                );
                return EMPTY;
            })
        );
    }

    getClassSessions(
        query: ClassSessionFilterQuery
    ): Observable<Array<ClassSession>> {
        return this.getSessions(
            query,
            this.classDataService.getClassSessions.bind(this.classDataService)
        );
    }

    getUserClassSessions(
        query: ClassSessionFilterQuery,
        isStoreUpdateEnabled: boolean = true
    ): Observable<Array<ClassSession>> {
        return this.getSessions(
            query,
            this.classDataService.getUserClassSessions.bind(
                this.classDataService
            ),
            isStoreUpdateEnabled
        );
    }

    getTodaysClasses(request: PageRequest<UserClass>): Observable<UserClass[]> {
        return this.classDataService
            .getClasses(
                {
                    display: 'upcoming',
                    date: new DateRange(
                        moment().setMidnightTime(),
                        moment().addDays(1).setMidnightTime()
                    ),
                },
                request
            )
            .pipe(
                map((pagedEntities: PagedEntities<UserClass>) => {
                    const userClasses: UserClass[] = this.mapUserClasses(
                        pagedEntities.entities
                    );
                    request.page === 0
                        ? this.todaysClassesStore.set(userClasses)
                        : this.todaysClassesStore.upsertMany(userClasses);
                    this.classesStore.upsertMany(userClasses);
                    this.updateClassSessionsStore(
                        userClasses.map(
                            (userClass: UserClass) => userClass?.classSession
                        )
                    );

                    return userClasses;
                }),
                setLoading(this.todaysClassesStore),
                catchError(() => {
                    this.snackBar.open("Failed to load today's classes.");
                    return EMPTY;
                })
            );
    }

    getClassSessionById(classSessionId: string): Observable<ClassSession> {
        return this.classDataService.getClassSessionById(classSessionId).pipe(
            map((classSession: ClassSession) => {
                this.classSessionsStore.upsert(classSession.id, classSession);

                return classSession;
            }),
            catchError(() => {
                this.snackBar.open('Failed to load Program Session.');
                return EMPTY;
            })
        );
    }

    setBackUrl(backUrl: string): void {
        this.classesStore.update({ backUrl });
    }

    setDefaultPage(defaultPage: number): void {
        this.classesStore.update({ defaultPage });
    }

    setFilter(filterType: FilterType): void {
        this.classesStore.update({ filterType });
    }

    setActive(classSessionId: string): void {
        this.classesStore.setActive(classSessionId);
    }

    setDateFilter(date: Moment): void {
        this.classesStore.update({ dateFilter: date });
    }

    updateState(state: Partial<ClassesState>): void {
        this.classesStore.update(state);
    }

    setDefaultState(): void {
        this.classesStore.update(ClassesStore.INITIAL_STATE);
    }

    /**
     * Sets the default values of classes state if the URL of the next navigation does not match the specified URL or RegExp.
     * There is no need to unsubscribe from this observable since only the first emitted NavigationStart event is taken.
     * @param url The URL of next navigation.
     * @param urlToIgnore The URL of the next navigation to ignore.
     * @returns Observable of boolean that is true if the next URL does not match the passed one, otherwise false.
     */
    setDefaultStateIfNextUrlNotMatch(
        url: string | RegExp,
        urlToIgnore?: string | RegExp
    ): Observable<boolean> {
        return this.routerService.checkIfNextUrlDoesNotMatch(
            url,
            () => this.setDefaultState(),
            urlToIgnore
        );
    }

    translateRecurrenceDescription(recurrencePattern: string): string {
        if (!recurrencePattern) {
            return '';
        }

        if (recurrencePattern === Constants.SINGLE_OCCURRENCE_TITLE) {
            return this.translocoService.translate(
                Constants.SINGLE_OCCURRENCE_TITLE
            );
        }

        const cronFragments = recurrencePattern.split(' ');

        if (!cronFragments) {
            return '';
        }

        let translatedDescription = '';

        for (let i = 0; i < cronFragments.length; i++) {
            const fragment = cronFragments[i];
            if (!fragment) {
                continue;
            }

            if (
                !isNaN(+fragment[0]) &&
                /(st|nd|rd|th)$/.test(fragment) &&
                cronFragments[i + 1] === 'day'
            ) {
                // If ordinal number + 'day', combine them and skip next iteration
                translatedDescription += ` ${this.translocoService.translate(
                    fragment + ' ' + cronFragments[i + 1]
                )}`;
                i += 1;
            } else if (
                !isNaN(+fragment) ||
                moment(fragment, Constants.TIME_FORMAT_US).isValid()
            ) {
                // Do not translate if fragment is number or time
                translatedDescription += ` ${fragment}`;
            } else if (fragment === 'the') {
                // If not english, ignore 'the'
                if (this.translocoService.getActiveLang() !== 'en') {
                    continue;
                } else {
                    translatedDescription += ' the';
                }
            } else {
                // Regular fragment translate
                let fragmentToTranslate = '';

                fragmentToTranslate = fragment[fragment.length - 1].includes(
                    ','
                )
                    ? fragment.replace(',', '')
                    : fragment;

                const translation = fragment.replace(
                    fragmentToTranslate,
                    this.translocoService.translate(fragmentToTranslate)
                );

                translatedDescription += ` ${translation}`;
            }
        }

        return translatedDescription;
    }

    private getSessions(
        query: ClassSessionFilterQuery,
        dataFunc: (
            query: ClassSessionFilterQuery
        ) => Observable<Array<ClassSession>>,
        isStoreUpdateEnabled: boolean = true
    ): Observable<Array<ClassSession>> {
        return dataFunc(query).pipe(
            tap((response: Array<ClassSession>) => {
                isStoreUpdateEnabled && this.classSessionsStore.set(response);
            }),
            setLoading(this.classSessionsStore),
            catchError((e) => {
                this.snackBar.open('Failed to load Program Sessions.');
                return EMPTY;
            })
        );
    }

    private mapUserClasses(userClasses: UserClass[]): UserClass[] {
        return userClasses
            .filter((userClass: UserClass) => !!userClass?.classSession)
            .map((userClass: UserClass) => {
                const classSession = userClass.classSession;

                return {
                    ...userClass,
                    classSessionId: classSession?.id,
                    classSession: new UserClassSession(classSession),
                };
            });
    }

    /**
     * @description Updates the public class sessions store by merging the {@link UserClassSession} objects with the existing {@link ClassSession} objects.
     * @param userClassSessions User class sessions to merge with class sessions
     */
    private updateClassSessionsStore(
        userClassSessions: UserClassSession[]
    ): void {
        userClassSessions?.forEach((userClassSession: UserClassSession) =>
            this.classSessionsStore.update(
                userClassSession.id,
                (session: ClassSession) => ({
                    ...session,
                    ...userClassSession,
                })
            )
        );
    }
}
