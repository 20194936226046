import { OverlayModule } from '@angular/cdk/overlay';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AkitaNgRouterStoreModule } from '@datorama/akita-ng-router-store';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import * as Sentry from '@sentry/browser';

import {
    CalendarDateFormatter,
    CalendarModule,
    CalendarMomentDateFormatter,
    DateAdapter,
    MOMENT,
} from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/moment';
import * as moment from 'moment';
import 'moment-timezone';
import { NgcCookieConsentModule } from 'ngx-cookieconsent';
import {
    NgxGoogleAnalyticsModule,
    NgxGoogleAnalyticsRouterModule,
} from 'ngx-google-analytics';
import { NgxMaskModule } from 'ngx-mask';
import { NgxPermissionsModule, NgxPermissionsService } from 'ngx-permissions';
import { firstValueFrom, forkJoin } from 'rxjs';

import { AuthService } from '@core/auth/auth.service';
import { CoreModule } from '@core/core.module';
import { SentryErrorHandler } from '@core/sentry-error.handler';
import { environment } from '@environment/environment';

import '@shared/date-utilities';
import { DecoratorService } from '@shared/decorators/decorator.service';
import { MaterialModule } from '@shared/material.module';
import '@shared/moment-utilities';
import { NotificationsModule } from '@shared/notifications/notifications.module';
import '@shared/string-utilities';
import { ToastModule } from '@shared/toast/toast.module';
import { VscBreadcrumbsModule } from '@shared/vsc-breadcrumbs/vsc-breadcrumbs.module';

import { EMBED_TOKEN_PERSIST_STATE_PROVIDER } from '@admin/reports/state/embed-token-store.config';
import { REPORTS_PERSIST_STATE_PROVIDER } from '@admin/reports/state/reports-store.config';
import { PublicModule } from '@public/public.module';

import { VersionCheckService } from '../version-check.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ConsumerInformationCanActivateGuard } from './consumer-information/consumer-information-can-activate.guard';
import { OrganizationSettingsService } from './core/auth/organization-settings.service';
import { CookieConfig } from './shared/cookies/cookie-config';
import { TranslocoRootModule } from './transloco-root.module';

Sentry.init({
    enabled: environment.production,
    environment: environment.namespace?.toUpperCase(),
    dsn: 'https://95392c164ac64277a580bd671a058d90@o478238.ingest.sentry.io/5523314',
    // TryCatch has to be configured to disable XMLHttpRequest wrapping, as we are going to handle
    // http module exceptions manually in Angular's ErrorHandler and we don't want it to capture the same error twice.
    // Please note that TryCatch configuration requires at least @sentry/browser v5.16.0.
    integrations: [
        new Sentry.Integrations.TryCatch({
            XMLHttpRequest: false,
        }),
    ],
});

export function momentAdapterFactory() {
    return adapterFactory(moment);
}

export function appInitializerFactory(
    authService: AuthService,
    permissionService: NgxPermissionsService,
    organizationSettingsService: OrganizationSettingsService
): () => Promise<boolean> {
    return (): Promise<boolean> =>
        authService.runInitialLoginSequence().then(() => {
            if (authService.hasValidToken()) {
                return firstValueFrom(
                    forkJoin(
                        [
                            authService.loadPermissions(),
                            authService.loadOrganizationSettings(),
                        ],
                        (permissions, settings) => {
                            permissionService.loadPermissions(permissions);
                            organizationSettingsService.loadSettings(settings);
                        }
                    )
                )
                    .then(() => Promise.resolve(true))
                    .catch(() => Promise.resolve(true));
            }
            return Promise.resolve(true);
        });
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        CoreModule.forRoot(),
        NgxPermissionsModule.forRoot(),
        VscBreadcrumbsModule.forRoot(),
        NgxMaskModule.forRoot(),
        TranslocoRootModule,
        FlexLayoutModule,
        OverlayModule,
        MaterialModule,
        AkitaNgRouterStoreModule,
        environment.production ? [] : AkitaNgDevtools,
        CalendarModule.forRoot(
            {
                provide: DateAdapter,
                useFactory: momentAdapterFactory,
            },
            {
                dateFormatter: {
                    provide: CalendarDateFormatter,
                    useClass: CalendarMomentDateFormatter,
                },
            }
        ),
        PublicModule,
        environment.production
            ? NgxGoogleAnalyticsModule.forRoot(environment.gaTrackingCode)
            : [],
        environment.production ? NgxGoogleAnalyticsRouterModule : [],
        NgcCookieConsentModule.forRoot(CookieConfig),
        ToastModule,
        NotificationsModule,
    ],
    bootstrap: [AppComponent],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializerFactory,
            deps: [
                AuthService,
                NgxPermissionsService,
                OrganizationSettingsService,
                DecoratorService,
            ],
            multi: true,
        },
        { provide: ErrorHandler, useClass: SentryErrorHandler },
        VersionCheckService,
        {
            provide: MOMENT,
            useValue: moment,
        },
        REPORTS_PERSIST_STATE_PROVIDER,
        EMBED_TOKEN_PERSIST_STATE_PROVIDER,
        ConsumerInformationCanActivateGuard,
    ],
})
export class AppModule {}
