import { AuthConfig } from 'angular-oauth2-oidc';

import { environment } from '@environment/environment';

const scopes: string[] = [
    'openid',
    'profile',
    'email',
    'roles',
    'offline_access',
    'vsc_api',
    'vsc_http_aggregator_api',
    'identity_admin_api',
    'vsc_analytics_api',
    'vsc_notifications_api',
];

export const authConfig: AuthConfig = {
    issuer: environment.identityUrl,
    redirectUri: window.location.origin,
    clientId: 'vsc_spa',
    responseType: 'code',
    scope: scopes.join(' '),
    silentRefreshRedirectUri: `${window.location.origin}/silent-refresh.html`,
    useSilentRefresh: environment.production,
    showDebugInformation: false,
    clearHashAfterLogin: false,
    requireHttps: environment.requireIdentityHttps,
};
