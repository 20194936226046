import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    templateUrl: './tos.component.html',
    styleUrls: ['./tos.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TosComponent {
    constructor() {}
}
