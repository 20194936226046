import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroupDirective } from '@angular/forms';

import { Observable } from 'rxjs';

@Component({
    selector: 'vsc-validation-message',
    templateUrl: './validation-message.component.html',
    styleUrls: ['./validation-message.component.scss'],
})
export class ValidationMessageComponent implements OnInit {
    @Input() controlName: string;
    @Input() formControl: FormControl;

    constructor(private readonly formGroup: FormGroupDirective) {}

    ngOnInit(): void {
        this.formControl = this.formGroup.control.get(
            this.controlName
        ) as FormControl;
    }

    __getParams(value: any): { value: any } {
        return {
            value,
        };
    }

    isObservable(value: any): boolean {
        return value instanceof Observable;
    }
}
