import { Injectable } from '@angular/core';

import { QueryEntity } from '@datorama/akita';

import { Promotion } from './promotion';
import { PromotionState, PromotionStore } from './promotion.store';

@Injectable({
    providedIn: 'root',
})
export class PromotionQuery extends QueryEntity<PromotionState, Promotion> {
    constructor(protected store: PromotionStore) {
        super(store);
    }
}
