import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';

import { TranslocoModule } from '@ngneat/transloco';

import { IconModule } from '@shared/icon/icon.module';
import { MaterialModule } from '@shared/material.module';

import { CheckboxMultiSelectComponent } from './checkbox-multi-select.component';

@NgModule({
    declarations: [CheckboxMultiSelectComponent],
    imports: [
        CommonModule,
        TranslocoModule,
        FormsModule,
        MaterialModule,
        FlexLayoutModule,
        IconModule,
    ],

    exports: [CheckboxMultiSelectComponent],
})
export class CheckboxMultiSelectModule {}
