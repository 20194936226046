import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import * as moment from 'moment';

import { PagePaths } from '@shared/constants/page-paths';

import { PublicComponent } from '@public/public.component';

@Component({
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    selector: 'vsc-footer',
})
export class FooterComponent {
    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute
    ) {}
    year: number = moment().year();

    navigateToTOS(): void {
        if (this.activatedRoute.snapshot.component === PublicComponent) {
            this.router.navigate(['tos']);
        } else {
            this.router.navigate(['admin/tos']);
        }
    }

    navigateToPrivacy(): void {
        if (this.activatedRoute.snapshot.component === PublicComponent) {
            this.router.navigate(['privacy-policy']);
        } else {
            this.router.navigate(['admin/privacy-policy']);
        }
    }

    navigateToCookiesPage(): void {
        if (this.activatedRoute.snapshot.component === PublicComponent) {
            this.router.navigate([PagePaths.Public.Cookies]);
        } else {
            this.router.navigate(['admin' + PagePaths.Public.Cookies]);
        }
    }
}
