import { Component, ChangeDetectionStrategy } from '@angular/core';

import { Observable } from 'rxjs';

import { VscBreadcrumbsService } from '../service/vsc-breadcrumbs.service';
import { Breadcrumb } from '../vsc-breadcrumbs.shared';

@Component({
    selector: 'vsc-breadcrumbs',
    templateUrl: './vsc-breadcrumbs.component.html',
    styleUrls: ['./vsc-breadcrumbs.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VscBreadcrumbsComponent {
    constructor(public service: VscBreadcrumbsService) {}

    crumbs$: Observable<Breadcrumb[]> = this.service.crumbs$;
}
