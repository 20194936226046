import { Injectable } from '@angular/core';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';

import { combineQueries, ID, QueryEntity } from '@datorama/akita';
import { RouterQuery } from '@datorama/akita-ng-router-store';

import * as moment from 'moment';
import { Observable, of, timer, combineLatest } from 'rxjs';
import {
    concatAll,
    filter,
    map,
    mergeMap,
    skip,
    startWith,
    switchMap,
    take,
} from 'rxjs/operators';

import { AuthService } from '@core/auth/auth.service';

import { Constants } from '@shared/constants';

import { Chat, ParticipantInfo } from './chat';
import { ChatInviteStatus } from './chat-invite-status.enum';
import { ChatsState, ChatsStore } from './chats.store';
import { ScheduledChats } from './scheduled-chats';

@Injectable({
    providedIn: 'root',
})
export class ChatsQuery extends QueryEntity<ChatsState, Chat> {
    constructor(
        private authService: AuthService,
        protected store: ChatsStore,
        private routerQuery: RouterQuery,
        private router: Router
    ) {
        super(store);
    }
    private _selectAll$: Observable<Chat[]> = this.selectAll().pipe(
        map((chats: Chat[]) => {
            const memberId = this.authService.userId;
            return chats.map((chat: Chat) => ({
                ...chat,
                memberInviteStatus: chat.participantsInfo.find(
                    (participantInfo: ParticipantInfo) =>
                        participantInfo.id === memberId
                )?.inviteStatus,
            }));
        })
    );

    private _memberAcceptedChats$: Observable<ScheduledChats[]> =
        this._selectAll$.pipe(
            map((chats: Chat[]) => {
                const memberId = this.authService.userId;

                const acceptedChats = chats.filter((chat: Chat) => {
                    const consideredStartDateTime = moment(
                        chat.startDateTime
                    ).addMinutes(-5);
                    const date = moment();

                    const isAcceptedChat =
                        chat.organizerId == memberId ||
                        chat.memberInviteStatus === ChatInviteStatus.Accepted ||
                        consideredStartDateTime.isSameOrBefore(date, 'm');

                    return (
                        isAcceptedChat &&
                        this.isChatOngoingOrUpcoming(chat?.startDateTime)
                    );
                });

                return this.groupChatsByStartDate(acceptedChats);
            })
        );

    private _memberPendingChats$: Observable<Chat[]> = this._selectAll$.pipe(
        map((chats: Chat[]) =>
            chats.filter(
                (chat: Chat) =>
                    chat.memberInviteStatus === ChatInviteStatus.Pending &&
                    moment(chat.startDateTime).addMinutes(-5).isAfter()
            )
        )
    );

    /**
     * Returns the accepted chats at the beginning of each minute.
     */
    memberAcceptedChats$: Observable<ScheduledChats[]> =
        this.getTimerEveryMinute(this._memberAcceptedChats$);

    /**
     * Returns the pending chats at the beginning of each minute.
     */
    memberPendingChats$: Observable<Chat[]> = this.getTimerEveryMinute(
        this._memberPendingChats$
    );

    selectActiveChat$: Observable<Chat> = combineQueries([
        this.routerQuery.selectParams(),
        this.selectAll(),
    ]).pipe(
        map((combine) => {
            const chatItem = combine[1].find((x) => x.id == combine[0]['id']);
            if (chatItem) {
                return {
                    ...chatItem,
                    startDateTime: chatItem.startDateTime
                        ? moment(chatItem.startDateTime)
                        : null,
                };
            }
            return chatItem;
        })
    );

    /**
     * Returns the number of pending chats at the beginning of each minute.
     */
    selectScheduledPendingChatsCount$: Observable<number> =
        this.memberPendingChats$.pipe(map((chats: Chat[]) => chats?.length));

    selectPendingChatsCount$: Observable<number> =
        this._memberPendingChats$.pipe(map((chats: Chat[]) => chats?.length));

    /**
     * Returns the number of pending chat invites, but if the user is currently on the chats schedule page, then returns null.
     */
    selectPendingChatsCountConditionally$: Observable<number> = combineLatest([
        this.selectPendingChatsCount$.pipe(
            skip(1),
            mergeMap(() => this.selectScheduledPendingChatsCount$)
        ),
        this.router.events.pipe(
            filter((event: RouterEvent) => event instanceof NavigationEnd),
            map((event: NavigationEnd) =>
                event.urlAfterRedirects?.includes(Constants.CHATS_SCHEDULE_PATH)
            ),
            startWith(this.router.url?.includes(Constants.CHATS_SCHEDULE_PATH))
        ),
    ]).pipe(
        map(([chatInvitesCount, isChatScheduleUrl]: [number, boolean]) =>
            isChatScheduleUrl ? null : chatInvitesCount
        )
    );

    todaysChats$: Observable<Chat[]> = timer(0, 60000).pipe(
        mergeMap(() =>
            this.select('todaysChats').pipe(
                map((chats: Chat[]) =>
                    chats.filter((chat: Chat) =>
                        this.isChatOngoingOrUpcoming(chat?.startDateTime)
                    )
                )
            )
        )
    );

    selectIsEditMode$: Observable<boolean> = this.routerQuery
        .selectParams('id')
        .pipe(map((value) => !!value));

    selectBackUrl$: Observable<string> = this.select(
        (chatsState: ChatsState) => chatsState.backUrl
    );

    selectIsEditable(id: ID): Observable<boolean> {
        return timer(0, 1000).pipe(
            switchMap(() =>
                this.selectEntity(id).pipe(
                    filter(Boolean),
                    map(
                        (chat: Chat) =>
                            moment(chat.startDateTime).diff(moment(), 'm') > 5
                    )
                )
            )
        );
    }

    selectChatReady(id: ID, isTodaysChat?: boolean): Observable<boolean> {
        let chatObservable: Observable<Chat>;

        if (isTodaysChat) {
            // Check today's chats from store
            const filteredChats = this.store
                .getValue()
                .todaysChats?.filter((chat: Chat) => chat.id === id);

            if (!filteredChats.length) {
                return of(false);
            }

            chatObservable = of(filteredChats[0]);
        } else {
            chatObservable = this.selectEntity(id);
        }

        return timer(0, 1000).pipe(
            switchMap(() =>
                chatObservable.pipe(
                    filter(Boolean),
                    map((chat: Chat) => {
                        const now = moment();
                        /** User can join to chat 5 minutes before scheduled time
                         *  Joining to chat is limited for 2 hours since scheduled time */
                        const minutes = -5;
                        const duration = 120;

                        let startDateTime = null;
                        return (
                            chat &&
                            (startDateTime = moment(chat.startDateTime)) &&
                            moment(startDateTime)
                                .addMinutes(minutes)
                                .isSameOrBefore(now) &&
                            startDateTime
                                .addMinutes(duration)
                                .isSameOrAfter(now)
                        );
                    })
                )
            )
        );
    }

    getIsEditMode(): boolean {
        return this.routerQuery.getParams('id') !== undefined;
    }

    getEditingId(): string {
        return this.routerQuery.getParams('id');
    }

    getActiveChat(): Chat {
        return this.getEntity(this.routerQuery.getParams('id'));
    }

    getBackUrl(): string {
        return this.getValue()?.backUrl ?? Constants.CHAT_DEFAULT_BACK_URL;
    }

    private isChatOngoingOrUpcoming(startDateTime: moment.Moment): boolean {
        if (!startDateTime) {
            return false;
        }

        const now = moment();
        const consideredStartDateTime = moment(startDateTime);

        return (
            consideredStartDateTime.isSameOrAfter(now, 'm') ||
            (consideredStartDateTime.isSameOrBefore(now, 'm') &&
                moment(startDateTime).addMinutes(120).isSameOrAfter())
        );
    }

    /** Returns a timer with repetitions at the beginning of each minute  */
    private getTimerEveryMinute(observable: Observable<any>): Observable<any> {
        return of(
            observable.pipe(take(2)),
            timer(0, 1000).pipe(
                map(() => new Date()),
                filter((d: Date) => d.getSeconds() === 0),
                switchMap(() => observable)
            )
        ).pipe(concatAll());
    }

    private groupChatsByStartDate(array: Chat[]): ScheduledChats[] {
        const groupedChats = array.reduce((result, currentItem) => {
            const dateFormatted = moment(currentItem.startDateTime).format(
                Constants.DATE_FORMAT_ISO
            );
            (result[dateFormatted] = result[dateFormatted] || []).push(
                currentItem
            );
            return result;
        }, {});

        const scheduledChats: ScheduledChats[] = [];

        Object.keys(groupedChats).forEach((startDateFormatted: string) => {
            scheduledChats.push({
                scheduledDate: moment(
                    startDateFormatted,
                    Constants.DATE_FORMAT_ISO
                ),
                chats: groupedChats[startDateFormatted],
            });
        });

        return scheduledChats;
    }
}
