<mat-slide-toggle checked="true" disabled="true">
    {{ 'Essential cookies' | transloco }}
</mat-slide-toggle>
<p class="mt-4 pt-0">
    {{
        'Essential cookies enable core functionality such as security, network management and accessibility. These cookies are mandatory and cannot be disabled.'
            | transloco
    }}
</p>

<mat-slide-toggle checked="{{ isGACookieEnabled }}" (change)="onToggle($event)">
    {{ 'Google Analytics' | transloco }}
</mat-slide-toggle>
<p class="mt-4 pt-0">
    {{
        "We'd like to set Google Analytics cookies to help us improve our website by collecting and reporting information on how you use it. The cookies collect information in a way that does not directly identify anyone."
            | transloco
    }}
</p>
