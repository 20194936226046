import { BaseUserInfo } from '@shared/models/base-user-info';

export class SingleItem {
    id?: any;
    name?: string;
    isValid?: boolean = true;
    invalidMessage?: string;
    index: number;
    isDisplayed: boolean;
    hasEmptySelection?: boolean = false;

    constructor(obj: unknown, isDisplayed: boolean = true) {
        if (typeof obj === 'number') {
            this.index = obj as number;
        } else {
            const user = obj as BaseUserInfo;
            this.id = user?.id;
            this.name = `${user?.firstName} ${user?.lastName}`;
        }

        this.isDisplayed = isDisplayed;
        this.isValid = true;
    }
}
