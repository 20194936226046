export enum NotificationAction {
    ChatCreated,
    ChatAccepted,
    ChatStarted,
    ChatUpdated,
    ChatCancelled,
    ChatPending,
    NotRSVPdChat,
    ChatAboutToStart,
    ProgramAboutToStart,
    ProgramStarted,
    ProgramUpdated,
    ProgramSessionExpiration,
    ProgramSessionWatched,
    ProgramCancelled,
    SessionCancelled,
    SessionUpdated,
    ProgramExpiring,
    PlatformAccessAdmittance,
    UserWaitingForApproval,
}
