import {
    animate,
    state,
    style,
    transition,
    trigger,
} from '@angular/animations';
import {
    Component,
    ElementRef,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Renderer2,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { Event, NavigationEnd, Router } from '@angular/router';

import { Subscription, Observable, BehaviorSubject } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { SideMenuItem } from '../side-menu-item.model';
import { SideMenuState } from '../side-menu-state';

@Component({
    selector: 'vsc-side-menu-item',
    templateUrl: './side-menu-item.component.html',
    styleUrls: ['./side-menu-item.component.scss'],
    animations: [
        trigger('indicatorRotate', [
            state('collapsed', style({ transform: 'rotate(0deg)' })),
            state('expanded', style({ transform: 'rotate(180deg)' })),
            transition(
                'expanded <=> collapsed',
                animate('225ms cubic-bezier(0.4,0.0,0.2,1)')
            ),
        ]),
    ],
})
export class SideMenuItemComponent implements OnInit, OnChanges, OnDestroy {
    active: boolean = false;
    watcher: Subscription;
    mobileDevice: boolean;
    isExpanded$: Observable<boolean>;

    @ViewChild('menuTitle', { static: true }) menuTitle: ElementRef;
    @Input() item: SideMenuItem;
    @Input() depth: number;
    @Input() menuState: SideMenuState;

    private expandMenuSub$: BehaviorSubject<boolean> = new BehaviorSubject(
        false
    );

    private subscriptions: Subscription[] = [];

    private readonly ARIA_EXPANDED_ATTR: string = 'aria-expanded';

    constructor(
        private elementRef: ElementRef,
        private renderer: Renderer2,
        public router: Router,
        media: MediaObserver
    ) {
        if (this.depth === undefined) {
            this.depth = 0;
        }
        this.isExpanded$ = this.expandMenuSub$.asObservable();

        // tslint:disable-next-line: deprecation
        this.watcher = media.media$.subscribe((change: MediaChange) => {
            if (change.mqAlias === 'xs') {
                this.mobileDevice = true;
            }
        });
        this.subscriptions.push(this.watcher);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.menuState && this.menuTitle) {
            this.menuTitle.nativeElement.style.opacity = `${
                changes.menuState.currentValue === SideMenuState.In ? '0' : '1'
            }`;
        }
    }

    ngOnInit(): void {
        this.setAriaExpanded(false);
        this.setExpanded(this.router.url);

        this.subscriptions.push(
            this.router.events.subscribe((event: Event) => {
                if (event instanceof NavigationEnd) {
                    this.setExpanded(event.urlAfterRedirects);
                }
            })
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((sub: Subscription) => sub.unsubscribe());
    }

    onItemSelected(item: SideMenuItem, hasChildren: boolean): void {
        if (!hasChildren) {
            const route = item.route;
            this.router.navigate([route]);
        }

        if (!item.route) {
            this.toggleMenu();
        }
    }

    isObservable(value: any): boolean {
        return value instanceof Observable;
    }

    asObservable(value: any): Observable<any> {
        return value as Observable<any>;
    }

    private setExpanded(url: string): void {
        url = url.trimLeadingSlash();

        if (
            url === this.item.route &&
            this.item.route &&
            this.item.route.length > 0
        ) {
            this.active = true;
        } else if (!this.item.route && this.item.children) {
            const hasActiveChildRoute = this.hasChildWithActiveRoute(
                url,
                this.item.children
            );

            if (this.isObservable(hasActiveChildRoute)) {
                this.asObservable(hasActiveChildRoute)
                    .pipe(take(1))
                    .subscribe((isExpanded: boolean) => {
                        this.expandMenuSub$.next(isExpanded);
                    });
            } else {
                this.expandMenuSub$.next(hasActiveChildRoute as boolean);
            }
            this.active = false;
        } else if (!this.item.children && url.includes(this.item.route)) {
            this.active = true;
        } else {
            this.active = false;
        }
    }

    private hasChildWithActiveRoute(
        url: string,
        items: SideMenuItem[] | Observable<SideMenuItem[]>
    ): boolean | Observable<boolean> {
        const hasActiveRoute = (items: SideMenuItem[]): boolean => {
            return items?.some((child: SideMenuItem) => {
                const childRoute = child?.route;
                return url && childRoute && url.startsWith(childRoute);
            });
        };

        return this.isObservable(items)
            ? this.asObservable(items)?.pipe(
                  map((children: SideMenuItem[]) => hasActiveRoute(children))
              )
            : hasActiveRoute(items as SideMenuItem[]);
    }

    private setAriaExpanded(expanded: boolean): void {
        this.renderer.setAttribute(
            this.elementRef?.nativeElement,
            this.ARIA_EXPANDED_ATTR,
            expanded.toString()
        );
    }

    private toggleMenu(isExpanded: boolean = undefined): void {
        this.expandMenuSub$.next(
            isExpanded !== undefined
                ? isExpanded
                : !this.expandMenuSub$.getValue()
        );
    }
}
