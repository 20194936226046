import { Component, Inject, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { TranslocoService } from '@ngneat/transloco';

import { NgcCookieConsentService } from 'ngx-cookieconsent';

import { PagePaths } from '@shared/constants/page-paths';
import { SnackBarService } from '@shared/snack-bar/snack-bar.service';

import { CookieHelperService } from '../services/cookie-helper.service';

export interface DialogData {
    isGaEnabled: boolean;
}

@Component({
    selector: 'vsc-cookies-manage',
    templateUrl: './cookies-manage.component.html',
    styleUrls: ['./cookies-manage.component.scss'],
})
export class CookiesManageComponent {
    isGaEnabled: boolean = true;
    constructor(
        private router: Router,
        private snackBar: SnackBarService,
        private cookieHelperService: CookieHelperService,
        private translocoService: TranslocoService,
        private cookieConsentService: NgcCookieConsentService,
        @Optional()
        private dialogRef: MatDialogRef<CookiesManageComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData
    ) {
        this.isGaEnabled = this.data.isGaEnabled;
    }

    onToggle(isChecked: boolean): void {
        this.isGaEnabled = isChecked;
    }

    savePreferences(): void {
        this.cookieHelperService.setGACookiesTrackingStatus(this.isGaEnabled);
        this.cookieHelperService.setCookieConsentStatus(this.isGaEnabled);
        this.cookieConsentService.close(false);
        this.snackBar.open(
            this.translocoService.translate('Cookie settings are updated')
        );
        this.dialogRef.close();
    }

    navigateToPrivacy(): void {
        if (this.router.url?.startsWith('/admin')) {
            this.router.navigate(['admin' + PagePaths.Public.Privacy]);
        } else {
            this.router.navigate([PagePaths.Public.Privacy]);
            if (!this.cookieConsentService.hasAnswered()) {
                this.cookieConsentService.open();
            }
        }
        this.dialogRef.close();
    }

    navigateToCookiesPage(): void {
        if (this.router.url?.startsWith('/admin')) {
            this.router.navigate(['admin' + PagePaths.Public.Cookies]);
        } else {
            this.router.navigate([PagePaths.Public.Cookies]);
            if (!this.cookieConsentService.hasAnswered()) {
                this.cookieConsentService.open();
            }
        }
        this.dialogRef.close();
    }

    close(): void {
        this.dialogRef.close();
    }
}
