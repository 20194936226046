export const Lookups = {
    Roles: 'roles',
    RestrictedRoles: 'restrictedRoles',
    Groups: 'groups',
    ActiveGroups: 'activeGroups',
    GroupAdmins: 'groupAdmins',
    Facilitators: 'facilitators',
    BookmarkTypes: 'bookmarkTypes',
    ClassCategories: 'classCategories',
    Languages: 'languages',
    BrandedGroups: 'brandedGroups',
    GroupStatus: 'groupStatus',
    GroupType: 'groupType',
    GroupLanguages: 'groupLanguages',
    ContentExchangeEnabledOrganizations: 'contentExchangeEnabledOrganizations',
    ProfilePersonalizationAllowedOrganizations:
        'profilePersonalizationAllowedOrganizations',
    Countries: 'countries',
    ClassCreators: 'ClassCreators',
    ConsumerLanguages: 'consumerLanguages',
    Months: 'months',
    OrganizationLanguages: 'organizationLanguages',
    VideoProviderStatus: 'videoProviderStatus',
    NotificationCaseCategories: 'notificationCaseCategories',
    Gender: 'gender',
    Race: 'race',
    Ethnicity: 'ethnicity',
    PrimarySpokenLanguage: 'primarySpokenLanguage',
    LivingArrangements: 'livingArrangements',
    ILiveAlone: 'iLiveAlone',
    ILiveWithMySpouse: 'iLiveWithMySpouse',
} as const;
