import {
    ChangeDetectionStrategy,
    Component,
    OnInit,
    OnDestroy,
} from '@angular/core';

import { Observable, Subscription } from 'rxjs';

import { AnnouncementService } from '../services/announcement-services/announcement.service';
import { Announcement } from '../state/announcement-state/announcement';
import { AnnouncementQuery } from '../state/announcement-state/announcement.query';

@Component({
    selector: 'vsc-announcements-widget',
    templateUrl: './announcements.component.html',
    styleUrls: ['./announcements.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnnouncementsComponent implements OnInit, OnDestroy {
    announcements$: Observable<Array<Announcement>> =
        this.announcementQuery.selectAll();

    isLoading$: Observable<boolean> = this.announcementQuery.selectLoading();

    private subscription: Subscription;

    constructor(
        private announcementQuery: AnnouncementQuery,
        private announcementService: AnnouncementService
    ) {}

    ngOnInit(): void {
        this.subscription = this.announcementService
            .getAnnouncements()
            .subscribe();
    }

    ngOnDestroy(): void {
        this.subscription?.unsubscribe();
    }

    openPage(announcement: Announcement): void {
        if (announcement.link) {
            window.open(announcement.link);
        }
    }
}
