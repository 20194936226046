import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
    selector: 'vsc-snack-bar-icon',
    templateUrl: './snack-bar-icon.component.html',
    styleUrls: ['./snack-bar-icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SnackBarIconComponent {
    constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) {}

    getIcon() {
        return this.data.icon;
    }
}
