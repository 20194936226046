<div
    *ngIf="notification"
    fxLayout="row"
    class="notification"
    fxLayoutAlign="space-between center"
>
    <div fxLayout="row" fxLayoutAlign="start center">
        <div class="graphic" fxLayout="row" fxLayoutAlign="start center">
            <div *ngIf="notification.imageUrl; else icon" class="image">
                <img
                    [src]="notification.imageUrl"
                    [alt]="'Notification image' | transloco"
                />
            </div>
            <ng-template #icon>
                <vsc-icon class="icon">notifications</vsc-icon>
            </ng-template>
        </div>
        <div
            fxLayout="column"
            class="details"
            [class.full-width]="notification.actionType === undefined"
        >
            <p class="message">
                <!-- Formatted message using template and parameters -->
                <span *ngIf="notification.template; else plainText">
                    {{
                        notification.template
                            | transloco
                            | formatText: templateParams
                    }}
                </span>
                <!-- /Formatted message using template and parameters -->

                <ng-template #plainText>
                    {{ notification.message | transloco }}
                </ng-template>
            </p>

            <p class="created-date" fxLayout="row" fxLayoutAlign="start center">
                <vsc-icon class="schedule-icon" [iconStyle]="'light'"
                    >clock</vsc-icon
                >
                {{ createdAt }}
            </p>
        </div>
    </div>
    <div class="actions">
        <vsc-notification-action
            [notification]="notification"
            (actionInvoked)="onActionInvoked()"
            (actionRejected)="onActionRejected()"
        ></vsc-notification-action>
    </div>
</div>
