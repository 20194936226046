import { Field } from '../dynamic-form/dynamic-reactive-form.model';

export class FilterField {
    public field: Field;
    public type: FilterType;
}

export enum FilterType {
    Basic,
    Advanced,
}
