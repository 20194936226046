import { Injectable } from '@angular/core';

import { QueryEntity } from '@datorama/akita';

import { Observable } from 'rxjs';

import { Lookup } from './lookup';
import { LookupState, LookupStore } from './lookup.store';

@Injectable({
    providedIn: 'root',
})
export class LookupQuery extends QueryEntity<LookupState, Lookup> {
    constructor(protected store: LookupStore) {
        super(store);
    }
    selectLookup(type: string): Observable<Array<Lookup>> {
        if (!type || type.length === 0) {
            throw new Error('Type cannot be null');
        }
        return this.select((x) => x[type]);
    }
}
