import { AbstractControl, ValidationErrors, Validator } from '@angular/forms';

import { DynamicValidator, Group, Rule } from './dynamic';

export class LessThanValidator extends DynamicValidator implements Validator {
    constructor(
        private readonly controlToValidateName: string,
        private readonly controlToCompareName: string,
        rules?: Array<Rule | Group>
    ) {
        super(rules);
    }

    validate(controlToValidate: AbstractControl): ValidationErrors | null {
        const start = controlToValidate.value;

        if (controlToValidate.parent !== undefined) {
            const controlToCompare =
                controlToValidate.parent.controls[this.controlToCompareName];
            const end = controlToCompare.value;

            if ((isNaN(start) && (!start || !end)) || +start < +end) {
                if (controlToCompare.invalid) {
                    controlToCompare.updateValueAndValidity({
                        onlySelf: true,
                        emitEvent: false,
                    });
                }

                return null;
            }

            return {
                lessThan: {
                    lessThan: true,
                    controlToValidateName: this.controlToValidateName,
                    controlToCompareName: this.controlToCompareName,
                },
            };
        }

        return null;
    }
}
