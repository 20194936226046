<ng-template #menuItem let-isActive="isActive">
    <div class="list-item" fxLayout="column">
        <a mat-list-item title [routerLink]="path" (click)="onMenuItemClick()">
            <vsc-icon
                [icon]="icon"
                [iconStyle]="isActive ? 'solid' : 'regular'"
            ></vsc-icon>
            <div
                fxFlex
                fxLayout="row"
                fxLayoutAlign="space-between center"
                class="content"
            >
                <span class="title">{{ title | transloco }}</span>
                <span class="note" *ngIf="note">
                    {{ note }}
                </span>
            </div>
        </a>
        <div class="border"></div>
    </div>
</ng-template>
<ng-container
    *ngTemplateOutlet="menuItem; context: { isActive: isActive$ | async }"
></ng-container>
