<div class="widget-title">
    <h4>{{ "Today's Schedule" | transloco }}</h4>
    <a [routerLink]="classesPath" class="view-more-programs"
        >{{ 'View All Programs' | transloco }}
        <vsc-icon color="accent" iconStyle="solid">caret-right</vsc-icon>
    </a>
</div>
<div
    class="vsc-card todays-schedule-container"
    fxLayout="column"
    *ngLet="isLoading$ | async as isLoading"
    [vscMatSpinner]="isLoading"
>
    <ng-container *ngLet="todaysEvents$ | async as todaysEvents">
        <ng-container *ngIf="todaysEvents?.length; else noItems">
            <div
                class="list-view"
                infinite-scroll
                [infiniteScrollThrottle]="50"
                [infiniteScrollDistance]="1"
                [fromRoot]="true"
                [infiniteScrollDisabled]="
                    isLoading || (moreToFetch | async) === false
                "
                (scrolled)="onScrollDown()"
            >
                <ng-container
                    *ngFor="
                        let todaysEvent of todaysEvents;
                        trackBy: userClassTrackBy
                    "
                >
                    <vsc-public-class-card
                        *ngIf="isUserClass(todaysEvent)"
                        [class$]="asObservable(todaysEvent.event)"
                        (addClassToFavorite)="__addedClassToFavorite($event)"
                        (removeClassFromFavorite)="
                            __removedClassFromFavorite($event)
                        "
                        (navigate)="__navigatedFromCard($event)"
                        orientation="horizontal"
                        align-items="start"
                    ></vsc-public-class-card>

                    <vsc-public-chat-card
                        *ngIf="isChat(todaysEvent)"
                        [chat$]="asObservable(todaysEvent.event)"
                        orientation="horizontal"
                        align-items="start"
                    >
                    </vsc-public-chat-card>
                </ng-container>
            </div>
        </ng-container>
        <ng-template #noItems>
            <div
                class="no-items"
                fxLayoutAlign="center center"
                fxLayout="column"
            >
                <ng-container *ngIf="!isLoading">
                    <img
                        src="assets/images/empty-schedule.svg"
                        alt=""
                        height="320"
                    />
                    <h3>
                        {{
                            'There are no programs scheduled for today. Would you like to see upcoming programs?'
                                | transloco
                        }}
                    </h3>

                    <button
                        mat-flat-button
                        color="primary"
                        [routerLink]="classesPath"
                        class="upcoming-classes"
                    >
                        {{ 'View Programs' | transloco }}
                    </button>
                </ng-container>
            </div>
        </ng-template>
    </ng-container>
</div>

<!-- scroll to top -->
<vsc-scroll-to-top></vsc-scroll-to-top>
<!-- /scroll to top -->
