import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MaterialModule } from '@shared/material.module';
import { NotificationsModule } from '@shared/notifications/notifications.module';
import { PasswordManageModule } from '@shared/password-manage/password-manage.module';
import { SharedModule } from '@shared/shared.module';
import { SurveyModule } from '@shared/survey/survey.module';

import { HomeModule } from './home/home.module';
import { MenuModule } from './menu/menu.module';
import { PublicRoutingModule } from './public-routing.module';
import { PublicComponent } from './public.component';
import { WidgetsModule } from './widgets/widgets.module';

@NgModule({
    declarations: [PublicComponent],
    imports: [
        CommonModule,
        PublicRoutingModule,
        MaterialModule,
        WidgetsModule,
        SharedModule,
        MenuModule,
        SurveyModule,
        PasswordManageModule,
        HomeModule,
        NotificationsModule,
    ],
})
export class PublicModule {}
