import { Component } from '@angular/core';

@Component({
    selector: 'vsc-consumer-information',
    templateUrl: './consumer-information.component.html',
    styleUrls: ['./consumer-information.component.scss'],
})
export class ConsumerInformationComponent {
    constructor() {}
}
