import { ID } from '@datorama/akita';

import { Moment } from 'moment';

import { BaseUserInfo } from '@shared/models/base-user-info';

import { ChatInviteStatus } from './chat-invite-status.enum';

export class Chat {
    id: ID;
    title: string;
    description: string;
    organizerId: string;
    organizer: string;
    startDateTime: Moment;
    participants: string[];
    participantsInfo: ParticipantInfo[];
    memberInviteStatus: ChatInviteStatus;
}
export interface ParticipantInfo extends BaseUserInfo {
    inviteStatus: ChatInviteStatus;
}
