import {
    HttpClient,
    HttpContext,
    HttpHeaders,
    HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { Constants } from '@shared/constants';

/**
 * Wrapper service for the Angular HttpClient.
 */
@Injectable({
    providedIn: 'root',
})
export class HttpService {
    constructor(public httpClient: HttpClient) {}

    /**
     * Constructs a `GET` request that interprets the body as a JSON object and returns
     * the response body in a given type.
     *
     * @param url     The endpoint URL.
     * @param options The HTTP options to send with the request.
     *
     * @return An `Observable` of the `HttpResponse`, with a response body in the requested type.
     */
    get<T>(url: string, options?: HttpRequestOptions): Observable<T> {
        return this.httpClient.get<T>(url, this._prepareOptions(options));
    }

    /**
     * Constructs a `POST` request that interprets the body as a JSON object
     * and returns an observable of the response.
     *
     * @param url The endpoint URL.
     * @param body The content to replace with.
     * @param options HTTP options
     *
     * @return  An `Observable` of the `HttpResponse` for the request, with a response body in the
     * requested type.
     */
    post<T>(
        url: string,
        body: any | null,
        options?: HttpRequestOptions
    ): Observable<T> {
        return this.httpClient.post<T>(
            url,
            body,
            this._prepareOptions(options)
        );
    }

    /**
     * Constructs a `PUT` request that interprets the body as an instance of the requested type
     * and returns an observable of the requested type.
     *
     * @param url The endpoint URL.
     * @param body The resources to add/update.
     * @param options HTTP options
     *
     * @return An `Observable` of the requested type.
     */
    put<T>(
        url: string,
        body: any | null,
        options?: HttpRequestOptions
    ): Observable<T> {
        return this.httpClient.put<T>(url, body, this._prepareOptions(options));
    }

    /**
     * Constructs a `PATCH` request that interprets the body as a JSON object
     * and returns the response in a given type.
     *
     * @param url The endpoint URL.
     * @param body The resources to edit.
     * @param options HTTP options.
     *
     * @return  An `Observable` of the `HttpResponse` for the request,
     * with a response body in the given type.
     */
    patch<T>(
        url: string,
        body: any | null,
        options?: HttpRequestOptions
    ): Observable<T> {
        return this.httpClient.patch<T>(
            url,
            body,
            this._prepareOptions(options)
        );
    }

    /**
     * Constructs a DELETE request that interprets the body as a JSON object and returns
     * the response in a given type.
     *
     * @param url     The endpoint URL.
     * @param options The HTTP options to send with the request.
     *
     * @return An `Observable` of the `HttpResponse`, with response body in the requested type.
     */
    delete(url: string, options?: HttpRequestOptions): Observable<any> {
        return this.httpClient.delete(url, this._prepareOptions(options));
    }

    private _prepareOptions(options: HttpRequestOptions): HttpRequestOptions {
        if (options && !options.interceptHttpError) {
            let headers = new HttpHeaders();
            headers = headers.set(
                Constants.SKIP_HTTP_ERROR_INTERCEPTOR_HEADER,
                '1'
            );
            options ??= {};
            options.headers = headers;
        }

        return options;
    }
}

abstract class HttpRequestOptions {
    headers?:
        | HttpHeaders
        | {
              [header: string]: string | string[];
          };
    context?: HttpContext;
    observe?: 'body';
    params?:
        | HttpParams
        | {
              [param: string]:
                  | string
                  | number
                  | boolean
                  | ReadonlyArray<string | number | boolean>;
          };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;

    /**
     * If `false`, the HTTP error interceptor will not be triggered, so user will not be redirected to the error page.
     * This is useful when you want to execute a request silently.
     * Default value is `true`
     */
    interceptHttpError?: boolean = true;
}
