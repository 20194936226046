import { Clipboard } from '@angular/cdk/clipboard';
import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
    selector: 'vsc-copy-to-clipboard',
    templateUrl: './copy-to-clipboard.component.html',
    styleUrls: ['./copy-to-clipboard.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CopyToClipboardComponent {
    @Input() value: string;

    /**
     * Duration in milliseconds for tooltip to be visible on click.
     */
    @Input() tooltipDuration: number = 800;

    constructor(private clipboard: Clipboard) {}

    copyToClipboard(tooltip: MatTooltip): void {
        this.clipboard.copy(this.value);
        tooltip.show();
        tooltip.tooltipClass = 'v-visible';

        setTimeout(() => {
            tooltip.tooltipClass = 'v-hidden';
        }, this.tooltipDuration);
    }
}
