<div fxFlex fxLayout="column" [class]="cssClass ? cssClass : ''">
    <label
        [ngStyle]="{ display: showLabel ? '' : 'none' }"
        [for]="formControlName + '_' + id"
        >{{ label | transloco }}
        {{ isRequired(formControl) && showRequiredStar ? '*' : '' }}</label
    >
    <mat-hint *ngIf="hint !== '' && hintPosition === 'between'">{{
        hint | transloco
    }}</mat-hint>
    <mat-form-field appearance="outline" floatLabel="never">
        <input
            *ngIf="mask && type !== 'area'"
            matInput
            #txt
            [autocomplete]="autocomplete"
            [type]="type"
            placeholder="{{ placeholder | transloco }}"
            (blur)="onBlur(formControl)"
            [value]="formControl.value !== null ? formControl.value : ''"
            (keyup)="__valueChanged(txt.value)"
            (change)="onChange(txt.value)"
            color="primary"
            [mask]="mask"
            [id]="formControlName + '_' + id"
            [disabled]="readonly"
            [leadZeroDateTime]="true"
        />
        <div
            class="text-input"
            *ngIf="
                !mask &&
                type !== 'area' &&
                type !== 'date' &&
                type !== 'time' &&
                type !== 'password' &&
                type !== 'credit-card' &&
                type !== 'number'
            "
        >
            <input
                matInput
                #txt
                [autocomplete]="autocomplete"
                [type]="type"
                placeholder="{{ placeholder | transloco }}"
                [value]="
                    formControl.value !== null &&
                    formControl.value !== undefined
                        ? formControl.value
                        : ''
                "
                (blur)="onBlur(formControl)"
                (keyup)="__valueChanged(txt.value)"
                (change)="onChange(txt.value)"
                color="primary"
                [id]="formControlName + '_' + id"
                [disabled]="readonly"
            />
            <vsc-copy-to-clipboard
                *ngIf="showCopyIcon"
                [value]="formControl.value !== null ? formControl.value : ''"
                [tooltipDuration]="500"
                class="copy-to-clipboard"
            ></vsc-copy-to-clipboard>
        </div>
        <input
            *ngIf="type === 'credit-card'"
            matInput
            #txt
            [autocomplete]="autocomplete"
            [type]="type"
            placeholder="{{ placeholder | transloco }}"
            [value]="
                formControl.value !== null
                    ? '**** **** **** ' + formControl.value
                    : ''
            "
            (blur)="onBlur(formControl)"
            (keyup)="__valueChanged(txt.value)"
            (change)="onChange(txt.value)"
            color="primary"
            [id]="formControlName + '_' + id"
            [disabled]="readonly"
        />
        <vsc-icon *ngIf="type === 'credit-card'" matSuffix
            >credit-card</vsc-icon
        >
        <input
            *ngIf="!mask && (type === 'date' || type === 'time')"
            matInput
            #txt
            [type]="type"
            [value]="
                formControl.value !== null ? formatValue(formControl.value) : ''
            "
            (blur)="onBlur(formControl)"
            (change)="__valueChanged(txt.value)"
            color="primary"
            [id]="formControlName + '_' + id"
            [disabled]="readonly"
            max="9999-12-31"
        />

        <input
            *ngIf="type === 'password'"
            matInput
            #txt
            [type]="hidePassword ? 'password' : 'text'"
            [value]="formControl.value !== null ? formControl.value : ''"
            (blur)="onBlur(formControl)"
            (change)="__valueChanged(txt.value)"
            color="primary"
            [id]="formControlName + '_' + id"
            placeholder="{{ placeholder | transloco }}"
        />

        <input
            *ngIf="type === 'number'"
            matInput
            #txt
            type="number"
            [value]="formControl.value !== null ? formControl.value : ''"
            (blur)="onBlur(formControl)"
            (change)="__valueChanged(txt.value)"
            color="primary"
            [id]="formControlName + '_' + id"
            placeholder="{{ placeholder | transloco }}"
        />

        <textarea
            *ngIf="type === 'area'"
            matInput
            #txt
            [title]="label"
            [type]="type"
            placeholder="{{ placeholder | transloco }}"
            [attr.aria-label]="ariaLabel | transloco"
            [value]="formControl.value !== null ? formControl.value : ''"
            (blur)="onBlur(formControl)"
            (keyup)="__valueChanged(txt.value)"
            (change)="onChange(txt.value)"
            color="primary"
            [id]="formControlName + '_' + id"
            [disabled]="readonly"
            [rows]="textAreaRows"
        ></textarea>
        <vsc-icon *ngIf="showIcon" matSuffix>{{ iconName }}</vsc-icon>
        <vsc-icon
            *ngIf="type === 'password' && showIcon"
            matSuffix
            (click)="hidePassword = !hidePassword"
            [icon]="hidePassword ? 'eye-slash' : 'eye'"
        ></vsc-icon>
        <mat-hint *ngIf="hint !== '' && hintPosition === 'end'">{{
            hint | transloco
        }}</mat-hint>
        <mat-error>
            <vsc-validation-message
                [controlName]="formControlName"
            ></vsc-validation-message>
        </mat-error>
    </mat-form-field>
</div>
