import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MaterialModule } from '@shared/material.module';
import { SharedModule } from '@shared/shared.module';

import { PublicChatCardComponent } from './public-chat-card/public-chat-card.component';
import { PublicClassCardComponent } from './public-class-card/public-class-card.component';
import { PublicEventCardComponent } from './public-event-card/public-event-card.component';
import { PublicVideoCardComponent } from './public-video-card/public-video-card.component';
import { SliderItemDirective } from './public-video-card/slider/slider-item.directive';
import { SliderComponent } from './public-video-card/slider/slider.component';

@NgModule({
    imports: [CommonModule, SharedModule, MaterialModule],
    declarations: [
        PublicEventCardComponent,
        PublicClassCardComponent,
        PublicChatCardComponent,
        PublicVideoCardComponent,
        SliderComponent,
        SliderItemDirective,
    ],
    exports: [
        PublicEventCardComponent,
        PublicClassCardComponent,
        PublicChatCardComponent,
        PublicVideoCardComponent,
    ],
})
export class PublicCardsModule {}
