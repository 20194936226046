import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core';

import * as moment from 'moment';
import { isObservable, Observable, Subscription, take } from 'rxjs';

import { Constants } from '@shared/constants';
import { LocalizedDatePipe } from '@shared/pipes/localized-date.pipe';
import { isNullOrUndefined } from '@shared/utils/helpers';

import { INotificationDelegate } from '../delegates/notification-action';
import { Notification } from '../models/notification';
import { NotificationAction } from '../models/notification-action.enum';
import { NotificationDelegateResolver } from '../services/notification-delegate.resolver';
import { NotificationsService } from '../services/notifications.service';

@Component({
    selector: 'vsc-notification-item',
    templateUrl: './notification-item.component.html',
    styleUrls: ['./notification-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationItemComponent implements OnInit, OnDestroy {
    _item: Notification;

    @Input() set item(value: Notification) {
        this._item = value;
        this.setComponentData();
    }

    get item(): Notification {
        return this._item;
    }

    NotificationAction: typeof NotificationAction = NotificationAction;

    createdAt: string;
    newDateTime: string;
    templateParams: string[];

    notificationDelegate: INotificationDelegate;
    itemIds: string[] = [];

    private subscriptions: Subscription[] = [];

    constructor(
        private localizedDatePipe: LocalizedDatePipe,
        private notificationsService: NotificationsService,
        private notificationDelegateResolver: NotificationDelegateResolver
    ) {}

    ngOnInit(): void {
        this.setTemplateData();
    }

    setComponentData(): void {
        if (this.item) {
            const itemActionValue: NotificationAction = this.item.actionType;
            if (!isNullOrUndefined(itemActionValue)) {
                this.notificationDelegate =
                    this.notificationDelegateResolver.resolve(itemActionValue);
            }

            this.setTemplateData();
        }
    }

    setTemplateData(): void {
        this.createdAt = this.localizedDatePipe.transform(
            this.item.createdAt,
            Constants.FULL_DATE_TIME_FORMAT_US
        );

        this.templateParams = [...this.item.templateParameters];
        if (this.item.metadata['newDateTime'] !== undefined) {
            const constantForTransform: string =
                this.item.actionType !== NotificationAction.ProgramStarted
                    ? Constants.FULL_DATE_TIME_FORMAT_US
                    : Constants.TIME_FORMAT_US;
            this.newDateTime = this.localizedDatePipe.transform(
                moment(this.item.metadata['newDateTime']),
                constantForTransform
            );

            this.templateParams.splice(
                this.templateParams.length - 1,
                1,
                this.newDateTime
            );
        }
    }

    markAsRead(): void {
        if (this.item.isRead === false && this.item?.id !== null) {
            this.itemIds.push(this.item?.id);
            this.subscriptions.push(
                this.notificationsService
                    .markNotificationsAsRead(this.itemIds, 1)
                    .pipe(take(1))
                    .subscribe(() => {
                        this.itemIds = [];
                    })
            );
        }
    }

    navigateFromNotificationBody(): void {
        this.notificationDelegate?.navigateFromNotificationBody(this.item);
    }

    onActionInvoked(): void {
        const notificationId: string = this.item?.id;
        const method: void | Observable<any> =
            this.notificationDelegate?.invokeAction(this.item);

        if (isObservable(method)) {
            method?.pipe(take(1)).subscribe(() => {
                this.notificationDelegate?.navigateFromNotification(this.item);
            });
        } else {
            this.notificationDelegate?.navigateFromNotification(this.item);
        }

        this.notificationsService
            .deleteNotification(notificationId)
            .subscribe();

        this.markAsRead();
    }

    onActionRejected(): void {
        const notificationId: string = this.item?.id;
        const method: void | Observable<any> =
            this.notificationDelegate?.rejectAction(this.item);

        if (isObservable(method)) {
            method?.pipe(take(1)).subscribe(() => {
                this.notificationDelegate?.navigateFromNotification(this.item);
            });
        } else {
            this.notificationDelegate?.navigateFromNotification(this.item);
        }

        this.notificationsService
            .deleteNotification(notificationId)
            .subscribe();
        this.markAsRead();
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((sub: Subscription) => sub?.unsubscribe());
    }
}
