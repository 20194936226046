<div fxFlex fxLayout="column">
    <label
        >{{ label | transloco }}
        {{ isRequired(formControl) ? '*' : '' }}
    </label>
    <mat-hint *ngIf="hintPosition === 'between' && hint !== ''">{{
        hint | transloco
    }}</mat-hint>
    <mat-form-field
        appearance="outline"
        floatLabel="never"
        (click)="!inputEnabled && openCalendar()"
    >
        <div class="input-container">
            <input
                #txtDate
                matInput
                [readonly]="!inputEnabled"
                type="text"
                spellcheck="false"
                [placeholder]="InputMask.Date"
                [value]="dateValue"
                [disabled]="readonly"
                [attr.aria-label]="'Date' | transloco"
                (blur)="onBlur(formControl)"
                (change)="__valueChanged(txtDate.value)"
                color="primary"
                vscDateMask
            />
            <div *ngIf="!inputEnabled" class="overlay"></div>
        </div>
        <input
            #matDateInput
            aria-label="Date picker"
            [value]="pickerDateValue"
            [matDatepicker]="picker"
            [max]="this.maxDate"
            [min]="this.minDate"
            [hidden]="true"
            (dateChange)="__valueChanged(matDateInput.value)"
        />
        <mat-datepicker-toggle
            matSuffix
            [for]="picker"
            [hidden]="!this.showDatepicker || readonly"
        >
            <mat-icon matDatepickerToggleIcon>
                <vsc-icon>calendar-lines</vsc-icon>
            </mat-icon>
        </mat-datepicker-toggle>
        <mat-datepicker
            #picker
            [startView]="this.datePickerStartView"
            [startAt]="this.startAtDate"
        ></mat-datepicker>

        <mat-error>
            <vsc-validation-message
                [controlName]="formControlName"
            ></vsc-validation-message>
        </mat-error>
    </mat-form-field>
    <mat-hint *ngIf="hintPosition === 'end' && hint !== ''">{{
        hint | transloco
    }}</mat-hint>
</div>
