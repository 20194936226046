import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TranslocoModule } from '@ngneat/transloco';

import { JoinChatComponent } from '../join-chat/join-chat.component';
import { MaterialModule } from '../material.module';

@NgModule({
    declarations: [JoinChatComponent],
    exports: [JoinChatComponent],
    imports: [MaterialModule, TranslocoModule, CommonModule],
})
export class JoinChatModule {}
