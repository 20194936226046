import { Injectable } from '@angular/core';

import { QueryEntity, combineQueries } from '@datorama/akita';
import { RouterQuery } from '@datorama/akita-ng-router-store';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthService } from '@app/core/auth/auth.service';

import { Constants } from '@shared/constants';
import { Lookup } from '@shared/state/lookup/lookup';
import { LookupService } from '@shared/state/lookup/lookup.service';
import { Lookups } from '@shared/state/lookup/lookups';

import { Organization } from './organization';
import { OrganizationsState, OrganizationsStore } from './organizations.store';

@Injectable({
    providedIn: 'root',
})
export class OrganizationsQuery extends QueryEntity<
    OrganizationsState,
    Organization
> {
    constructor(
        protected store: OrganizationsStore,
        private routerQuery: RouterQuery,
        private lookupService: LookupService,
        private authService: AuthService
    ) {
        super(store);
    }
    organizations$: Observable<Array<Lookup>> = this.lookupService.selectLookup(
        Lookups.ProfilePersonalizationAllowedOrganizations
    );

    selectActiveOrganization$: Observable<Organization> = combineQueries([
        this.routerQuery.selectParams(),
        this.selectAll(),
    ]).pipe(
        map((combine) => {
            return combine[1].find((x) => x.id === combine[0]['id']);
        })
    );

    selectIsMainOrganization$: Observable<boolean> = this.routerQuery
        .selectParams('id')
        .pipe(map((value) => value === Constants.SELFHELP_ORGANIZATION_ID));

    selectIsEditMode$: Observable<boolean> = this.routerQuery
        .selectParams('id')
        .pipe(map((value) => value !== undefined));

    selectIsCurrentOrgProfilePersonalizationAllowed$: Observable<boolean> =
        this.organizations$.pipe(
            map((organizations: Lookup[]) => {
                const organizationIds = organizations.map(
                    (organization: Lookup) => organization.id
                );
                const tenantId = this.authService.tenantId;
                return organizationIds.includes(tenantId);
            })
        );

    getIsEditMode(): boolean {
        return this.routerQuery.getParams('id') !== undefined;
    }

    getEditingId(): string {
        return this.routerQuery.getParams('id');
    }

    getActiveOrganization(): Organization {
        return this.getEntity(this.routerQuery.getParams('id'));
    }

    getLogoUrl(): string {
        const organization = this.getEntity(this.routerQuery.getParams('id'));
        if (organization) {
            return organization.logoImageUrl;
        }
    }
}
