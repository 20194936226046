<div fxLayoutAlign="center center" fxLayout="column">
    <h1 class="error-message">{{ 'Page not found' | transloco }}</h1>
    <img
        src="./../../../assets/images/backgrounds/not-found.svg"
        alt="Not Found Image"
        class="not-found"
    />
    <div class="description-message">
        <p class="message">
            {{ 'Sorry, we couldn’t find that page.' | transloco }}
        </p>
        <button
            (click)="navigateToHome()"
            mat-flat-button
            color="primary"
            class="btn-not-found"
        >
            {{ 'Go To Homepage' | transloco }}
        </button>
    </div>
</div>
