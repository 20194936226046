import { InjectionToken } from '@angular/core';
import { Router } from '@angular/router';

import { take, Observable } from 'rxjs';

import { Notification } from '@shared/notifications/models/notification';
import { NotificationAction } from '@shared/notifications/models/notification-action.enum';

import { ClassService } from '@public/classes/services/classes.service';

import {
    formatNotificationDelegateInjectionToken,
    INotificationDelegate,
} from './notification-action';

export const ProgramSessionWatchedNotificationDelegateInjectionToken =
    new InjectionToken<ProgramSessionWatchedNotificationDelegate>(
        formatNotificationDelegateInjectionToken(
            NotificationAction.ProgramSessionWatched
        )
    );

export class ProgramSessionWatchedNotificationDelegate
    implements INotificationDelegate
{
    constructor(private classService: ClassService, private router: Router) {}

    invokeAction(notification: Notification): Observable<any> {
        const classId: string = notification?.metadata['classId'];

        if (classId) {
            return this.classService.addToFavorite(classId).pipe(take(1));
        }
    }

    rejectAction(_: Notification): void {}

    navigateFromNotification(notification: Notification): void {
        this.router.navigate(['/calendar']);
    }

    navigateFromNotificationBody(_: Notification): void {}
}
