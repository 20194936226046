<div class="container" *ngIf="toastService?.items as items">
    <div
        *ngFor="let item of items; trackBy: trackItemsFn"
        [class.active]="item.active"
        @slideIn
    >
        <vsc-toast-item
            *ngIf="item"
            [toast]="item"
            [class.active]="item.active"
        >
        </vsc-toast-item>
    </div>
</div>
