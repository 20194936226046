import { Injectable } from '@angular/core';

import { QueryEntity } from '@datorama/akita';

import { Weather } from './weather.model';
import { WeatherStore, WeatherState } from './weather.store';

@Injectable({
    providedIn: 'root',
})
export class WeatherQuery extends QueryEntity<WeatherState, Weather> {
    constructor(protected store: WeatherStore) {
        super(store);
    }
}
