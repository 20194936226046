<ng-container *ngIf="chat$ | async as chat">
    <ng-container *ngLet="(isChatReady$ | async) === true as isChatReady">
        <vsc-public-event-card
            *ngLet="
                !isChatReady && chat.organizerId === memberId as isClickable
            "
            [publicEvent]="publicEvent$ | async"
            [orientation]="orientation"
            [horizontalCardActionsTemplateRef]="actionsTemplate"
            [clickable]="isClickable"
            (click)="isClickable && navigateToChatManage(chat.id)"
        >
            <!-- Actions template -->
            <ng-template #actionsTemplate>
                <vsc-join-chat
                    #room
                    [chatId]="chat.id"
                    *ngIf="isChatReady"
                    (click)="$event.stopPropagation()"
                >
                </vsc-join-chat>
            </ng-template>
            <!-- /Actions template -->
        </vsc-public-event-card>
    </ng-container>
</ng-container>
