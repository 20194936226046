import { Injectable } from '@angular/core';

import * as moment from 'moment';
import { Observable, timer } from 'rxjs';
import { map } from 'rxjs/operators';

import { Permissions } from '@core/auth/permissions';
import { PermissionService } from '@core/auth/permissions.service';

import { UserClassSession } from '@public/classes/class-session-state/user-class-session';

@Injectable({
    providedIn: 'root',
})
export class PublicClassCardService {
    constructor(private permissionService: PermissionService) {}

    /** Returns if session is ready to be started or joined. */
    selectSessionReady(
        userClassSession: UserClassSession
    ): Observable<boolean> {
        return timer(0, 1000).pipe(
            map(() => this.isClassSessionReady(userClassSession))
        );
    }

    /** Returns if session is ready to be started or joined. */
    isClassSessionReady(userClassSession: UserClassSession): boolean {
        if (!userClassSession?.startDateTime) {
            return false;
        }
        const now = moment();
        /** If member is super/organization admin Start/Join button
         * should be displayed 15 minutes before the start time.
         * Otherwise, if member is facilitator/cohost for specific class
         * Start/Join button should be displayed 10 minutes before start time,
         * and 5 minutes before for participants */
        const minutes =
            this.permissionService.hasPermission(
                Permissions.Classes.EarlyJoin
            ) || userClassSession.isZoomAlternativeHost
                ? -15
                : userClassSession.isHostOrCoHost
                ? -10
                : -5;

        let startDateTime = null;
        return (
            (startDateTime = moment(userClassSession.startDateTime)) &&
            moment(startDateTime).addMinutes(minutes).isSameOrBefore(now) &&
            moment(startDateTime)
                .addMinutes(userClassSession.duration)
                .isSameOrAfter(now)
        );
    }
}
