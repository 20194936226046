import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import * as moment from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthService } from '@core/auth/auth.service';
import { environment } from '@environment/environment';

import { PagedEntities } from '@shared/models/paged-entities';
import { PageRequest } from '@shared/table/page';

import { ClassSession } from '../class-session-state/class-session';
import { ClassSessionFilterQuery } from '../class-session-state/class-sessions-filter.query';
import { UserClass } from '../class-state/class';
import { ClassFilterQuery } from './classes.service';

@Injectable({ providedIn: 'root' })
export class ClassDataService {
    constructor(
        private httpClient: HttpClient,
        public authService: AuthService
    ) {}

    getClasses(
        query: ClassFilterQuery,
        request: PageRequest<UserClass>
    ): Observable<PagedEntities<UserClass>> {
        const params = new HttpParams({
            fromObject: {
                page: request.page.toString(),
                pageSize: request.size.toString(),
                sortBy: request?.sort?.property ?? '',
                sortDirection: request?.sort?.order ?? '',
                display: query.display ?? '',
                classId: query.classId ?? '',
                search: query.search ?? '',
                categories: query.categories?.join(',') ?? '',
                fromDate: query.date?.start
                    ? `${query.date.start.toApiDateTimeStringFormat()}`
                    : '',
                toDate: query.date?.end
                    ? `${query.date.end.toApiDateTimeStringFormat()}`
                    : '',
            },
        });

        return this.httpClient
            .get<PagedEntities<UserClass>>(
                environment.apiUrl + '/vsc/classes/me',
                {
                    params,
                }
            )
            .pipe(
                map((response) => {
                    response.entities = response.entities.map((userClass) => {
                        userClass.startDateTime =
                            userClass.startDateTime &&
                            moment(userClass.startDateTime);

                        return userClass;
                    });
                    return response;
                })
            );
    }

    getRecommendedPrograms(programId: string): Observable<UserClass[]> {
        const params = new HttpParams({
            fromObject: {
                numberOfRecommendedPrograms: 2,
                programId: programId ?? '',
            },
        });

        return this.httpClient.get<UserClass[]>(
            environment.apiUrl + '/vsc/classes/me/recommended',
            {
                params,
            }
        );
    }

    getClassSessionsPage(
        query: ClassSessionFilterQuery,
        request: PageRequest<ClassSession>
    ): Observable<PagedEntities<ClassSession>> {
        const params = new HttpParams({
            fromObject: {
                page: request.page.toString(),
                pageSize: request.size.toString(),
                sortBy: request?.sort?.property ?? '',
                sortDirection: request?.sort?.order ?? '',
                toDate: query.toDate.toApiStringFormat(),
            },
        });

        return this.httpClient
            .get<PagedEntities<ClassSession>>(
                environment.apiUrl +
                    `/vsc/classes/details/${query.classId}/sessions`,
                {
                    params,
                }
            )
            .pipe(
                map((response) => {
                    response.entities = response.entities.map(
                        (classSession) => {
                            classSession.startDateTime = moment(
                                classSession.startDateTime
                            );
                            return classSession;
                        }
                    );

                    return response;
                })
            );
    }

    getById(id: string): Observable<UserClass> {
        return this.httpClient
            .get<UserClass>(environment.apiUrl + `/vsc/classes/${id}`)
            .pipe(
                map((userClass) => ({
                    ...userClass,
                    startDateTime: moment(userClass.startDateTime),
                    endDate: moment(userClass.endDate),
                }))
            );
    }

    addToFavorite(id: string): Observable<any> {
        return this.httpClient.post<UserClass>(
            environment.apiUrl + `/vsc/users/me/favorite/classes`,
            {
                id,
            }
        );
    }

    removeFromFavorite(id: string): Observable<any> {
        return this.httpClient.delete<UserClass>(
            environment.apiUrl + `/vsc/users/me/favorite/classes/${id}`
        );
    }

    getClassSessions(
        query: ClassSessionFilterQuery
    ): Observable<Array<ClassSession>> {
        return this.getSessions(
            query,
            environment.apiUrl + '/vsc/classes/sessions'
        );
    }

    getUserClassSessions(
        query: ClassSessionFilterQuery
    ): Observable<Array<ClassSession>> {
        return this.getSessions(
            query,
            environment.apiUrl + '/vsc/classes/me/sessions'
        );
    }

    getClassSessionById(id: string): Observable<ClassSession> {
        return this.httpClient.get<ClassSession>(
            environment.apiUrl + `/vsc/classes/sessions/${id}`
        );
    }

    private getSessions(
        query: ClassSessionFilterQuery,
        apiUrl: string
    ): Observable<Array<ClassSession>> {
        const params = new HttpParams({
            fromObject: {
                fromDate: query.fromDate.toApiStringFormat(),
                toDate: query.toDate.toApiStringFormat(),
            },
        });
        return this.httpClient
            .get<Array<ClassSession>>(apiUrl, {
                params,
            })
            .pipe(
                map((response) => {
                    return response.map((classSession: ClassSession) => ({
                        ...classSession,
                        startDateTime: moment(classSession.startDateTime),
                    }));
                })
            );
    }
}
