<div class="all-notifications-page-wrapper">
    <div class="notifications-title">
        {{ 'Notifications' | transloco }}
        <a [routerLink]="notificationsProfilePath" class="settings-link">
            <vsc-icon class="vsc-icon" [iconStyle]="'solid'">gear</vsc-icon>
            {{ 'Notifications settings' | transloco }}
        </a>
        <a (click)="markAllAsRead()" class="mark-all-link">
            <vsc-icon class="vsc-icon" [iconStyle]="'solid'">flag</vsc-icon>
            {{ 'Mark All As Read' | transloco }}
        </a>
    </div>
    <div
        class="vsc-card vsc-scrollbar all-notifications-container"
        fxLayout="column"
        [vscMatSpinner]="isLoading$"
    >
        <ng-container *ngIf="notifications$ | async as notifications">
            <ng-container *ngIf="notifications?.length; else noItems">
                <div
                    class="list-view"
                    infinite-scroll
                    [infiniteScrollThrottle]="1000"
                    [scrollWindow]="false"
                    [fromRoot]="true"
                    [infiniteScrollContainer]="'.all-notifications-container'"
                    (scrolled)="onScroll()"
                >
                    <ng-container
                        *ngFor="
                            let notification of notifications;
                            trackBy: trackByFn
                        "
                    >
                        <vsc-notification-item
                            [item]="notification"
                        ></vsc-notification-item>
                    </ng-container>
                </div>
            </ng-container>
            <ng-template #noItems>
                <div
                    class="no-items"
                    fxLayoutAlign="center center"
                    fxLayout="column"
                >
                    <ng-container *ngIf="(isLoading$ | async) === false">
                        <h3>
                            {{ 'There are no notifications' | transloco }}
                        </h3>
                    </ng-container>
                </div>
            </ng-template>
        </ng-container>
    </div>
</div>
