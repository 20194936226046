import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';

import { ChatService } from '@public/chats/services/chat.service';

@Component({
    selector: 'vsc-join-chat',
    templateUrl: './join-chat.component.html',
    styleUrls: ['./join-chat.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JoinChatComponent {
    constructor(private chatService: ChatService, private router: Router) {}

    @Input() chatId: string;
    @Input() text: string = 'Join chat';

    navigateToChat(chatId: string): void {
        this.chatService.setBackUrl(this.router.url);
        this.router.navigate([`/chats/${chatId}/join`]);
    }
}
