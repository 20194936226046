import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { environment } from '@environment/environment';

import { ClassAssessmentSurvey } from '../models/class-assessment-survey.model';
import { Survey } from '../models/survey.model';
import { UserSurvey } from '../models/user-survey.model';

@Injectable({ providedIn: 'root' })
export class SurveyDataService {
    constructor(private httpClient: HttpClient) {}

    getSelfAssessmentSurvey(): Observable<Survey> {
        return this.httpClient.get<Survey>(
            `${environment.apiUrl}/vsc/surveys/self-assessment`
        );
    }

    getClassAssessmentSurvey(): Observable<ClassAssessmentSurvey> {
        return this.httpClient.get<ClassAssessmentSurvey>(
            `${environment.apiUrl}/vsc/surveys/class-assessment`
        );
    }
    getIsolationSurvey(): Observable<Survey> {
        return this.httpClient.get<Survey>(
            `${environment.apiUrl}/vsc/surveys/isolation`
        );
    }

    saveUserSurvey(userSurvey: UserSurvey): Observable<UserSurvey> {
        if (
            typeof userSurvey.classSessionId !== 'undefined' &&
            userSurvey.classSessionId !== ''
        ) {
            return this.httpClient.post<UserSurvey>(
                `${environment.apiUrl}/vsc/surveys/class-assessment/me?classSessionId=` +
                    userSurvey.classSessionId,
                userSurvey
            );
        } else {
            return this.httpClient.post<UserSurvey>(
                `${environment.apiUrl}/vsc/surveys/me`,
                userSurvey
            );
        }
    }
}
