import {
    Component,
    DoCheck,
    Input,
    OnDestroy,
    OnInit,
    Renderer2,
    ViewChild,
} from '@angular/core';
import { FormControl, FormGroupDirective, ValidatorFn } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import {
    DateRange,
    MatDatepicker,
    MatDatepickerInputEvent,
    MatEndDate,
    MatStartDate,
} from '@angular/material/datepicker';
import { MatInput } from '@angular/material/input';

import * as moment from 'moment';
import { Subscription } from 'rxjs';

import {
    ControlValueAccessor,
    controlValueAccessorProvider,
} from '../control-value-accessor';
import { InputMask } from '../input';
import { Validators } from '../validators/validators';

type NewType = MatInput;
type Moment = moment.Moment;

@Component({
    selector: 'vsc-date-range',
    templateUrl: './date-range.component.html',
    styleUrls: ['./date-range.component.scss'],
    providers: [controlValueAccessorProvider(DateRangeComponent as Component)],
})
export class DateRangeComponent
    extends ControlValueAccessor<DateRange<Moment>>
    implements OnInit, OnDestroy, DoCheck
{
    @Input() formControlName: string;
    @Input() label: string;
    @Input() readonly: boolean = false;
    @Input() minDate: Moment;
    @Input() maxDate: Moment;
    @Input() startAtDate: Moment;
    @Input() showDatepicker: boolean = true;
    @Input() hint: string = '';
    @Input() hintPosition: 'between' | 'end' = 'end';
    @Input() inputEnabled: boolean = true;
    @Input() isUtc: boolean = false;

    dateValue: DateRange<Moment> = new DateRange(null, null);
    formControl: FormControl;
    isRequiredFormControl: boolean = false;
    subscriptions: Subscription[] = [];
    @ViewChild(MatInput, { static: false }) private readonly matInput: NewType;
    @ViewChild('picker') private readonly matDatepicker: MatDatepicker<any>;

    InputMask: any = InputMask;

    constructor(
        private formGroupDirective: FormGroupDirective,
        private errorStateMatcher: ErrorStateMatcher,
        private renderer: Renderer2
    ) {
        super();
    }

    ngOnInit(): void {
        this.formControl = this.formGroupDirective.control.get(
            this.formControlName
        ) as FormControl;
        if (this.matInput) {
            this.matInput.ngControl = null;
        }

        const validatorFns: ValidatorFn[] = [];

        if (this.formControl.validator) {
            const validator: any =
                this.formControl.validator &&
                this.formControl.validator(this.formControl);
            this.isRequiredFormControl =
                validator && validator.required && validator.required.required;
            validatorFns.push(this.formControl.validator);
        }

        validatorFns.push(Validators.validDate());

        if (this.maxDate) {
            validatorFns.push(Validators.maxDate(this.maxDate));
        }

        if (this.minDate) {
            validatorFns.push(Validators.minDate(this.minDate));
        }

        this.formControl.setValidators(validatorFns);
        this.formControl.updateValueAndValidity();
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((sub) => sub.unsubscribe());
    }

    ngDoCheck(): void {
        if (this.matInput) {
            this.matInput.errorState = this.errorStateMatcher.isErrorState(
                this.formControl,
                this.formGroupDirective
            );
            this.matInput.stateChanges.next();
        }
    }

    writeValue(value: DateRange<Moment>): void {
        if (this.value !== value) {
            this.dateValue = value;
        }
    }

    __valueChanged(event: MatDatepickerInputEvent<any, any>): void {
        if (event.target instanceof MatStartDate) {
            this.dateValue = new DateRange(event.value, this.dateValue?.end);
        } else if (event.target instanceof MatEndDate) {
            this.dateValue = new DateRange(this.dateValue?.start, event.value);
        }

        this.formGroupDirective.control
            .get(this.formControlName)
            .patchValue(this.dateValue);

        this.valueChanged.emit(this.dateValue);
    }

    openCalendar(): void {
        !this.readonly && this.matDatepicker.open();
    }
}
