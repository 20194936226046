import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
    HttpEvent,
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import * as moment from 'moment';
import { Observable } from 'rxjs';

import { environment } from '@environment/environment';

@Injectable()
export class TimeZoneInterceptor implements HttpInterceptor {
    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        if (request.url.includes(environment.apiUrl)) {
            const timezone = moment.fn.timeZone;

            if (timezone) {
                request = request.clone({
                    setHeaders: {
                        TimeZone: timezone,
                    },
                });
            }
        }
        return next.handle(request);
    }
}
