<div id="page">
    <header>
        <mat-toolbar
            [style.color]="headerColor$ | async"
            [style.background-color]="headerBackgroundColor$ | async"
        >
            <button mat-icon-button id="menu" (click)="sideMenu.toggleMenu()">
                <vsc-icon>bars</vsc-icon>
            </button>
            <vsc-logo link="/admin"></vsc-logo>
            <span style="flex: 1 1 auto"></span>
            <div fxLayoutAlign="center center" fxLayoutGap="15px">
                <vsc-lng-switcher></vsc-lng-switcher>
                <vsc-notifications-panel-trigger></vsc-notifications-panel-trigger>
                <vsc-tenant-switcher *isIdpAdmin></vsc-tenant-switcher>
                <vsc-user-menu></vsc-user-menu>
            </div>
        </mat-toolbar>
    </header>
    <nav>
        <vsc-side-menu
            #sideMenu
            [items]="menuItems"
            [menuState]="this.sideMenuState"
        ></vsc-side-menu>
    </nav>
    <div [hidden]="breadcrumb === undefined" id="toolbar">
        <mat-toolbar color="accent">
            <vsc-breadcrumbs></vsc-breadcrumbs>
        </mat-toolbar>
    </div>
    <main>
        <router-outlet></router-outlet>
    </main>

    <vsc-footer></vsc-footer>
</div>
