export {};

declare global {
    interface String {}

    interface StringConstructor {
        generateRandomCharacters(length: number);
    }

    interface String {
        /**
         * Replaces the last separator with a new string.
         *
         * @param separator Separator to replace.
         * @param replaceWith Replace with string.
         */
        replaceLastSeparator(separator: string, replaceWith: string): string;

        /**
         * Trims slash from the beginning of the string.
         */
        trimLeadingSlash(): string;

        /**
         * Formats string by inserting arguments into the string.
         * @param args Arguments to insert into the string.
         * ## Example
         * ```ts
         * '{0} {1}'.format('Hello', 'World') => 'Hello World'
         * ```
         */
        format(...args: string[] | number[]): string;

        /**
         * Replaces a character at a given index.
         * @param index Index of the character to replace.
         * @param replacementStr Replacement string.
         */
        replaceAt(index: number, replacementStr: string);

        /**
         * Gets the nth index of a string.
         * @param stringToFind String to find.
         * @param nthOccurrence Nth occurrence of the string.
         * ## Example
         * ```ts
         * 'Hello World My World'.nthIndex('World', 2) => 15
         * ```
         */
        nthIndexOf(stringToFind: string, nthOccurrence: number): number;

        /**
         * Inserts a new line at the half of the string.
         */
        insertNewLineAtHalfOfString(): string;

        /**
         * @description Splits string into array of trimmed strings.
         *
         * @param separator Separator to be used to split the string.
         * @returns {string[]} Array of trimmed strings.
         **/
        splitAndTrim(separator: string): string[];
    }
}

String.generateRandomCharacters = (length: number) => {
    const chars =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = length; i > 0; --i) {
        result += chars[Math.floor(Math.random() * chars.length)];
    }
    return result;
};

String.prototype.replaceLastSeparator = function (
    separator: string,
    replaceWith: string
): string {
    const _this = this as string;
    const separatorIndex = _this.lastIndexOf(separator);

    if (separatorIndex === -1) {
        return _this;
    }

    return (
        _this.substring(0, separatorIndex) +
        replaceWith +
        _this.substring(
            separatorIndex + 1,
            _this.length - separator.length + replaceWith.length + 1
        )
    );
};

String.prototype.trimLeadingSlash = function (): string {
    return (this as string)?.replace(/^\//, '');
};

String.prototype.format = function (...args: string[] | number[]) {
    return (this as string)?.replace(
        /{(\d+)}/g,
        (match, index) => args[index] + '' || ''
    );
};

String.prototype.replaceAt = function (index: number, replacementStr: string) {
    return (
        this.substr(0, index) +
        replacementStr +
        this.substr(index + replacementStr.length)
    );
};

String.prototype.nthIndexOf = function (
    stringToFind: string,
    nthOccurrence: number
): number {
    const str = this as string;

    if (!str) {
        return -1;
    }

    let strLength = str.length,
        i = -1;
    while (nthOccurrence-- && i++ < strLength) {
        i = str.indexOf(stringToFind, i);
        if (i < 0) {
            break;
        }
    }
    return i;
};

String.prototype.insertNewLineAtHalfOfString = function (): string {
    const str = this as string;

    if (!str) {
        return '';
    }

    const spacesCount = str.match(/\s/g)?.length;

    const nthIndexOfSpaceAtHalf = str.nthIndexOf(
        ' ',
        Math.ceil(spacesCount / 2)
    );

    return str.replaceAt(nthIndexOfSpaceAtHalf, '\n');
};

String.prototype.splitAndTrim = function (separator: string): string[] {
    return (this as string)?.split(separator).map((x: string) => x?.trim());
};
