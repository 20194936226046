import {
    AbstractControl,
    FormControl,
    ValidationErrors,
    Validator,
} from '@angular/forms';

import { DynamicValidator, Group, Rule } from './dynamic';

export class MinValidator extends DynamicValidator implements Validator {
    constructor(
        private readonly minValue: number,
        rules?: Array<Rule | Group>
    ) {
        super(rules);
    }

    validate(control: AbstractControl): ValidationErrors | null {
        if (control instanceof FormControl) {
            if (
                control.value == null ||
                isNaN(parseFloat(control.value)) ||
                parseFloat(control.value) >= this.minValue
            ) {
                return null;
            }

            return {
                min: {
                    current: control.value,
                    min: this.minValue,
                },
            };
        }

        return null;
    }
}
