import { Injectable, OnDestroy } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';

import { TranslocoService } from '@ngneat/transloco';

import { Subscription } from 'rxjs';

@Injectable()
export class PaginatorTranslation
    extends MatPaginatorIntl
    implements OnDestroy
{
    private subscription: Subscription;

    constructor(private translocoService: TranslocoService) {
        super();

        this.subscription = this.translocoService.langChanges$.subscribe(() =>
            this.getTranslations()
        );
    }

    ngOnDestroy(): void {
        this.subscription?.unsubscribe();
    }

    getTranslations(): void {
        this.itemsPerPageLabel =
            this.translocoService.translate('Items per page');
        this.nextPageLabel = this.translocoService.translate('Next Page');
        this.previousPageLabel =
            this.translocoService.translate('Previous Page');
        this.firstPageLabel = this.translocoService.translate('First Page');
        this.lastPageLabel = this.translocoService.translate('Last Page');
        this.getRangeLabel = this.getRangeLabel;
        this.changes.next();
    }

    getRangeLabel = (page: number, pageSize: number, length: number) => {
        return (
            page * pageSize +
            1 +
            ' - ' +
            (page * pageSize + (pageSize < length ? pageSize : length)) +
            ` ${this.translocoService.translate('of')} ` +
            ' ' +
            length
        );
    };
}
