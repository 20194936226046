import { NgcCookieConsentConfig } from 'ngx-cookieconsent';

export const CookieConfig: NgcCookieConsentConfig = {
    cookie: {
        domain: '',
    },
    type: 'opt-in',
    revokable: true,
    showLink: false,
    content: {
        message: '',
        allow: '',
        deny: '',
    },
};
