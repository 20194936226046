import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { DirectiveModule } from '@shared/directives/directive.module';
import { IconModule } from '@shared/icon/icon.module';

import { TOAST_CONFIG, DEFAULT_TOAST_CONFIG, TOAST_DATA } from './toast';
import { ToastItemComponent } from './toast-item/toast-item.component';
import { ToastComponent } from './toast.component';

@NgModule({
    imports: [
        CommonModule,
        BrowserAnimationsModule,
        DirectiveModule,
        FlexLayoutModule,
        PortalModule,
        IconModule,
    ],
    declarations: [ToastComponent, ToastItemComponent],
    exports: [ToastComponent],
    providers: [
        {
            provide: TOAST_CONFIG,
            useValue: DEFAULT_TOAST_CONFIG,
        },
        {
            provide: TOAST_DATA,
            useValue: {},
        },
    ],
})
export class ToastModule {}
