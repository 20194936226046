import {
    ChangeDetectionStrategy,
    Component,
    OnDestroy,
    OnInit,
} from '@angular/core';

import { Order } from '@datorama/akita';

import { Observable, Subscription, take } from 'rxjs';

import { PagePaths } from '@shared/constants/page-paths';
import { PageRequest } from '@shared/list-view/page';

import { Notification } from './models/notification';
import { NotificationsService } from './services/notifications.service';
import { NotificationsSearchQuery } from './state/notifications-search.query';
import { NotificationsQuery } from './state/notifications.query';

@Component({
    selector: 'vsc-all-notifications',
    templateUrl: './all-notifications.component.html',
    styleUrls: ['./all-notifications.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AllNotificationsComponent implements OnInit, OnDestroy {
    notifications$: Observable<Notification[]> =
        this.notificationsQuery.selectAll({
            sortBy: 'createdAt',
            sortByOrder: Order.DESC,
        });

    isLoading$: Observable<boolean> = this.notificationsQuery.selectLoading();
    notificationsProfilePath: string = PagePaths.Public.ProfileNotifications;
    page: number = 0;

    private subscriptions: Subscription[] = [];

    constructor(
        private notificationsQuery: NotificationsQuery,
        private notificationsService: NotificationsService
    ) {}

    ngOnInit(): void {
        this.getData();
    }

    markAllAsRead(): void {
        this.notificationsService
            .markAllNotificationsAsRead()
            .pipe(take(1))
            .subscribe();
    }

    trackByFn(notification: Notification): string {
        return notification?.id;
    }

    getData(): void {
        const request: PageRequest<Notification> = {
            page: this.page,
            size: 10,
        } as PageRequest<Notification>;

        const query: NotificationsSearchQuery = {
            search: '',
        };

        this.subscriptions.push(
            this.notificationsService
                .getNotifications(request, query)
                .subscribe(() => this.page++)
        );
    }

    onScroll(): void {
        this.getData();
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((sub: Subscription) => sub?.unsubscribe());
    }
}
