import { Injectable } from '@angular/core';

import { setLoading } from '@datorama/akita';
import { TranslocoService } from '@ngneat/transloco';

import { Observable, EMPTY } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { SnackBarService } from '@shared/snack-bar/snack-bar.service';

import { Promotion } from '../../state/promotion-state/promotion';
import { PromotionStore } from '../../state/promotion-state/promotion.store';
import { PromotionDataService } from './promotion.dataservice';

@Injectable({ providedIn: 'root' })
export class PromotionService {
    constructor(
        private promotionStore: PromotionStore,
        private promotionDataService: PromotionDataService,
        private snackBar: SnackBarService,
        private translocoService: TranslocoService
    ) {}

    getPromotions(): Observable<Array<Promotion>> {
        return this.promotionDataService.getPromotions().pipe(
            tap((response: Array<Promotion>) => {
                this.promotionStore.set(response);
            }),
            setLoading(this.promotionStore),
            catchError(() => {
                this.snackBar.open(
                    this.translocoService.translate(
                        'Failed to load Promotions.'
                    )
                );
                return EMPTY;
            })
        );
    }
}
