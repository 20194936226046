import { Injectable } from '@angular/core';

import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

import { ClassSession } from './class-session';

export type ClasseSessionsState = EntityState<ClassSession>;

export const initialState: ClasseSessionsState = {
    loading: false,
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'public-class-sessions' })
export class ClassSessionsStore extends EntityStore<
    ClasseSessionsState,
    ClassSession
> {
    constructor() {
        super(initialState);
    }
}
