import {
    AbstractControl,
    FormControl,
    ValidationErrors,
    Validator,
} from '@angular/forms';

import { DynamicValidator, Group, Rule } from './dynamic';

export class MaxValidator extends DynamicValidator implements Validator {
    constructor(
        private readonly maxValue: number,
        private readonly messageResolver: () => string,
        rules?: Array<Rule | Group>
    ) {
        super(rules);
    }

    validate(control: AbstractControl): ValidationErrors | null {
        if (control instanceof FormControl) {
            if (
                control.value == null ||
                isNaN(parseFloat(control.value)) ||
                parseFloat(control.value) <= this.maxValue
            ) {
                return null;
            }

            return {
                max: {
                    current: control.value,
                    max: this.maxValue,
                    messageResolver: this.messageResolver,
                },
            };
        }

        return null;
    }
}
