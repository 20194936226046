import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FormManagerDirective } from './connect-form.directive';
import { DateMaskDirective } from './date-mask.directive';
import { DebounceClickDirective } from './debounce-click.directive';
import { EllipsisDirective } from './ellipsis.directive';
import { IsIdpAdminDirective } from './is-idp-admin.directive';
import { NgLetDirective } from './ng-let.directive';

@NgModule({
    declarations: [
        FormManagerDirective,
        DateMaskDirective,
        EllipsisDirective,
        IsIdpAdminDirective,
        NgLetDirective,
        DebounceClickDirective,
    ],
    exports: [
        FormManagerDirective,
        DateMaskDirective,
        EllipsisDirective,
        IsIdpAdminDirective,
        NgLetDirective,
        DebounceClickDirective,
    ],
    imports: [CommonModule],
})
export class DirectiveModule {}
