import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IconModule } from '@shared/icon/icon.module';
import { MaterialModule } from '@shared/material.module';
import { PipesModule } from '@shared/pipes/pipes.module';

import { ClockComponent } from './clock.component';

@NgModule({
    declarations: [ClockComponent],
    exports: [ClockComponent],
    imports: [CommonModule, PipesModule, MaterialModule, IconModule],
})
export class ClockModule {}
