import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatPaginatorModule } from '@angular/material/paginator';

import { PaginatorSwitchComponent } from '@shared/paginator/paginator-switch/paginator-switch.component';

import { CollectionViewComponent } from './collection-view.component';
import { StylePaginatorDirective } from './style-paginator.directive';

@NgModule({
    declarations: [
        CollectionViewComponent,
        StylePaginatorDirective,
        PaginatorSwitchComponent,
    ],
    imports: [CommonModule, MatPaginatorModule, FlexLayoutModule],

    exports: [
        CollectionViewComponent,
        StylePaginatorDirective,
        PaginatorSwitchComponent,
    ],
})
export class CollectionViewModule {}
