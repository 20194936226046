import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';

import { TranslocoModule } from '@ngneat/transloco';

import { NgxMatIntlTelInputModule } from 'ngx-mat-intl-tel-input';

import { MaterialModule } from '@shared/material.module';

import { DynamicFormModule } from '../dynamic-form/dynamic-form.module';
import { ValidationMessageModule } from '../validation-message/validation-message.module';
import { TelInputComponent } from './tel-input.component';

@NgModule({
    declarations: [TelInputComponent],
    imports: [
        CommonModule,
        TranslocoModule,
        DynamicFormModule,
        ReactiveFormsModule,
        MaterialModule,
        FlexLayoutModule,
        ValidationMessageModule,
        NgxMatIntlTelInputModule,
    ],

    exports: [TelInputComponent],
})
export class VscTelInput {}
