import { Injectable } from '@angular/core';

import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

import { PagePaths } from '@shared/constants/page-paths';

import { ClassSession } from '@public/classes/class-session-state/class-session';

export interface ClassroomState extends EntityState<ClassSession> {
    backUrl: string;
}

export const initialState: ClassroomState = {
    loading: false,
    backUrl: PagePaths.Public.Classes,
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'public-classroom' })
export class ClassroomStore extends EntityStore<ClassroomState, ClassSession> {
    constructor() {
        super(initialState);
    }
}
