import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import '@angular/common/locales/global/ar';
import '@angular/common/locales/global/es';
import '@angular/common/locales/global/he';
import '@angular/common/locales/global/ko';
import '@angular/common/locales/global/ru';
import '@angular/common/locales/global/vi';
import '@angular/common/locales/global/zh';
import {
    ModuleWithProviders,
    NgModule,
    Optional,
    SkipSelf,
    LOCALE_ID,
} from '@angular/core';

import { TranslocoService } from '@ngneat/transloco';

import {
    AuthConfig,
    OAuthModule,
    OAuthModuleConfig,
    OAuthStorage,
} from 'angular-oauth2-oidc';
import { DeviceDetectorService } from 'ngx-device-detector';

import {
    CONFIRM_DIALOG_DEFAULT_OPTIONS,
    ConfrimDialogDefaultOptions,
} from '@shared/confirm-dialog/confirm-dialog.service';

import { authConfig } from './auth/auth-config';
import { AuthGuard } from './auth/auth-guard.service';
import { authModuleConfig } from './auth/auth-module-config';
import { AuthService } from './auth/auth.service';
import { ErrorHandlerModule } from './erorrs/error-handler.module';
import { TimeZoneInterceptor } from './time-zone-interceptor';

// We need a factory since localStorage is not available at AOT build time
export function storageFactory(): OAuthStorage {
    return localStorage;
}

export function getAuthConfig(deviceDetectorService: DeviceDetectorService) {
    authConfig.sessionChecksEnabled = !(
        (deviceDetectorService.isTablet() ||
            deviceDetectorService.isMobile()) &&
        deviceDetectorService.browser.toLocaleLowerCase() === 'chrome' &&
        (deviceDetectorService.os.toLocaleLowerCase() === 'mac' ||
            deviceDetectorService.os.toLocaleLowerCase() === 'ios')
    );

    return authConfig;
}

@NgModule({
    imports: [HttpClientModule, OAuthModule.forRoot(), ErrorHandlerModule],
    providers: [AuthService, AuthGuard],
})
export class CoreModule {
    static forRoot(): ModuleWithProviders<CoreModule> {
        return {
            ngModule: CoreModule,
            providers: [
                HttpClientModule,
                {
                    provide: AuthConfig,
                    useFactory: getAuthConfig,
                    deps: [DeviceDetectorService],
                },
                { provide: OAuthModuleConfig, useValue: authModuleConfig },
                { provide: OAuthStorage, useFactory: storageFactory },
                {
                    provide: CONFIRM_DIALOG_DEFAULT_OPTIONS,
                    useFactory: (translocoService: TranslocoService) => {
                        return {
                            title: translocoService.selectTranslate<string>(
                                'Confirmation'
                            ),
                            message: translocoService.selectTranslate<string>(
                                'Do you really want to do that?'
                            ),
                            yesText:
                                translocoService.selectTranslate<string>('Yes'),
                            noText: translocoService.selectTranslate<string>(
                                'No'
                            ),
                        } as ConfrimDialogDefaultOptions;
                    },
                    deps: [TranslocoService],
                },
                {
                    provide: LOCALE_ID,
                    useFactory: (translocoService: TranslocoService) =>
                        translocoService.getActiveLang(),
                    deps: [TranslocoService],
                },
                {
                    provide: HTTP_INTERCEPTORS,
                    useClass: TimeZoneInterceptor,
                    multi: true,
                },
            ],
        };
    }

    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        if (parentModule) {
            throw new Error(
                'CoreModule is already loaded. Import it in the AppModule only'
            );
        }
    }
}
