import {
    Directive,
    ElementRef,
    OnInit,
    TemplateRef,
    ViewContainerRef,
} from '@angular/core';

import { AuthService } from '@app/core/auth/auth.service';

@Directive({
    selector: '[isIdpAdmin]',
})
export class IsIdpAdminDirective implements OnInit {
    constructor(
        private element: ElementRef,
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private authService: AuthService
    ) {}

    ngOnInit() {
        if (this.authService.idpRole == 'admin') {
            this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
            this.viewContainer.clear();
        }
    }
}
