import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { environment } from '@environment/environment';

import { PBIDatasetRefreshSchedule } from '../report/models/pbi-dataset-refresh-schedule';
import { PBIEmbedToken } from '../report/models/pbi-embed-token';
import { IReport } from '../state/report';

@Injectable({
    providedIn: 'root',
})
export class ReportDataService {
    private readonly REPORTS_API_URL: string = `${environment.apiUrl}/vsc/reports`;

    constructor(private httpClient: HttpClient) {}

    getAvailableReports(): Observable<IReport[]> {
        return this.httpClient.get<IReport[]>(this.REPORTS_API_URL);
    }

    getReport(reportId: string): Observable<IReport> {
        return this.httpClient.get<IReport>(
            `${this.REPORTS_API_URL}/${reportId}`
        );
    }

    getDatasetRefreshSchedule(
        datasetId: string
    ): Observable<PBIDatasetRefreshSchedule> {
        return this.httpClient.get<PBIDatasetRefreshSchedule>(
            `${this.REPORTS_API_URL}/datasets/${datasetId}/refresh-schedule`
        );
    }

    getEmbedToken(): Observable<PBIEmbedToken> {
        return this.httpClient.get<PBIEmbedToken>(
            `${this.REPORTS_API_URL}/power-bi/token`
        );
    }
}
