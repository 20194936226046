import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgModule } from '@angular/core';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import {
    MatPaginatorIntl,
    MatPaginatorModule,
} from '@angular/material/paginator';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import {
    MatSnackBarModule,
    MAT_SNACK_BAR_DEFAULT_OPTIONS,
} from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';

import { Constants } from '@shared/constants';

import { PaginatorTranslation } from './table/paginator-translation';

export const DATE_FORMATS = {
    parse: {
        dateInput: Constants.DATE_FORMAT_ISO,
    },
    display: {
        dateInput: Constants.DATE_FORMAT_US,
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

@NgModule({
    imports: [
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatToolbarModule,
        MatSidenavModule,
        MatListModule,
        MatMenuModule,
        MatExpansionModule,
        MatDialogModule,
        MatTooltipModule,
        MatCardModule,
        MatChipsModule,
        MatSnackBarModule,
        MatSelectModule,
        MatRadioModule,
        MatTabsModule,
        MatCheckboxModule,
        MatPaginatorModule,
        MatSlideToggleModule,
        MatButtonToggleModule,
        MatDatepickerModule,
        MatMomentDateModule,
        MatDividerModule,
        MatAutocompleteModule,
        ScrollingModule,
        MatGridListModule,
    ],
    exports: [
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatToolbarModule,
        MatSidenavModule,
        MatListModule,
        MatMenuModule,
        MatExpansionModule,
        MatDialogModule,
        MatTooltipModule,
        MatCardModule,
        MatChipsModule,
        MatSnackBarModule,
        MatSelectModule,
        MatRadioModule,
        MatTabsModule,
        MatCheckboxModule,
        MatPaginatorModule,
        MatSlideToggleModule,
        MatButtonToggleModule,
        MatDatepickerModule,
        MatDividerModule,
        MatAutocompleteModule,
        ScrollingModule,
        MatGridListModule,
    ],
    providers: [
        {
            provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
            useValue: { duration: 3000 },
        },
        { provide: MatPaginatorIntl, useClass: PaginatorTranslation },
        { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS },
    ],
})
export class MaterialModule {}
