import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import { TranslocoService } from '@ngneat/transloco';

import { SnackBarIconComponent } from '@shared/snack-bar/snack-bar-icon/snack-bar-icon.component';

@Injectable({
    providedIn: 'root',
})
export class SnackBarService {
    constructor(
        private snackBar: MatSnackBar,
        private translocoService: TranslocoService
    ) {}
    /** Displays message in snack bar. Message is automatically translated. */
    open(message: string): void {
        this.snackBar.open(
            this.translocoService.translate<string>(message),
            null,
            {
                duration: 5000,
            }
        );
    }

    /**
     * Displays message in snack bar with icon. Message is automatically translated.
     *
     * @param message Message to display.
     * @param icon Material icon code.
     * @param snackType The type of snack bar that will affect the background color of the panel.
     */
    openWithIcon(
        message: string,
        icon: string,
        snackType: 'info' | 'success' = 'info'
    ) {
        this.snackBar.openFromComponent(SnackBarIconComponent, {
            horizontalPosition: 'center',
            verticalPosition: 'bottom',
            panelClass: this.getPanelClass(snackType),
            data: { message: this.translocoService.translate(message), icon },
        });
    }

    private getPanelClass(snackType: 'info' | 'success'): string {
        switch (snackType) {
            case 'success':
                return 'snack-success';
            default:
                return '';
        }
    }
}
