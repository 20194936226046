import { Injectable } from '@angular/core';

import { Store, StoreConfig } from '@datorama/akita';

import { Alert } from './alert';

export interface AlertState {
    loading: boolean;
    activeAlert: Alert;
}

export function createInitialState(): AlertState {
    return {
        loading: false,
        activeAlert: null,
    };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'public-alerts' })
export class AlertStore extends Store<AlertState> {
    constructor() {
        super(createInitialState());
    }
}
