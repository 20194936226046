import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';

import { TranslocoModule } from '@ngneat/transloco';

import { MaterialModule } from '@shared/material.module';

import { DynamicFormModule } from '../dynamic-form/dynamic-form.module';
import { FilterComponent } from './filter.component';

@NgModule({
    declarations: [FilterComponent],
    imports: [
        CommonModule,
        TranslocoModule,
        DynamicFormModule,
        MaterialModule,
        FlexLayoutModule,
    ],

    exports: [FilterComponent],
})
export class FilterModule {}
