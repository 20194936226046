import { Component } from '@angular/core';
import { ActivatedRoute, UrlSegment } from '@angular/router';

import { map, Observable } from 'rxjs';

import { AuthService } from '@core/auth/auth.service';
import { Permissions } from '@core/auth/permissions';

import { PagePaths } from '@shared/constants/page-paths';

@Component({
    selector: 'vsc-user-menu',
    templateUrl: './user-menu.component.html',
    styleUrls: ['./user-menu.component.scss'],
})
export class UserMenuComponent {
    PagePaths: typeof PagePaths = PagePaths;
    Permissions: typeof Permissions = Permissions;

    firstName$: Observable<string> = this.authService.firstName$;
    isAdminRoute$: Observable<boolean> = this.activatedRoute.url.pipe(
        map(
            (urlSegments: UrlSegment[]) =>
                urlSegments?.length > 0 &&
                urlSegments[0].path?.startsWith(
                    PagePaths.Admin.Root.substring(1)
                )
        )
    );

    constructor(
        private authService: AuthService,
        private activatedRoute: ActivatedRoute
    ) {}

    logout(): void {
        this.authService.logout();
    }
}
