import { Injectable } from '@angular/core';

import { setLoading } from '@datorama/akita';
import { TranslocoService } from '@ngneat/transloco';

import { Observable, EMPTY } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { SnackBarService } from '@shared/snack-bar/snack-bar.service';

import { Alert } from '../../state/alert-state/alert';
import { AlertStore } from '../../state/alert-state/alert.store';
import { AlertDataService } from './alert.dataservice';

@Injectable({ providedIn: 'root' })
export class AlertService {
    constructor(
        private alertStore: AlertStore,
        private alertDataService: AlertDataService,
        private snackBar: SnackBarService,
        private translocoService: TranslocoService
    ) {}

    getActiveAlert(): Observable<Alert> {
        return this.alertDataService.getActiveAlert().pipe(
            tap((response: Alert) => {
                if (response !== null) {
                    this.alertStore.update({ activeAlert: response });
                }
            }),
            setLoading(this.alertStore),
            catchError(() => {
                this.snackBar.open(
                    this.translocoService.translate('Failed to load Alert.')
                );
                return EMPTY;
            })
        );
    }
}
