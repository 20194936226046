import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { take } from 'rxjs/operators';

import { BookmarkClickAnalyticsEvent } from '@app/public/public/bookmarks/state/bookmark-click-analytics-event';
import { environment } from '@environment/environment';

import { UserActivity } from './user-activity';

@Injectable({ providedIn: 'root' })
export class AnalyticsService {
    constructor(private httpClient: HttpClient) {}

    addUserActivity(userActivity: UserActivity): void {
        this.httpClient
            .post<UserActivity>(
                environment.apiUrl + '/an/user-activities',
                userActivity
            )
            .pipe(take(1))
            .subscribe();
    }

    addBookmarkClicks(
        bookmarkClickAnalyticsEvent: BookmarkClickAnalyticsEvent
    ): void {
        this.httpClient
            .post<BookmarkClickAnalyticsEvent>(
                environment.apiUrl + '/an/bookmarks/events/click',
                bookmarkClickAnalyticsEvent
            )
            .subscribe();
    }
}
