import { Injectable } from '@angular/core';

import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

import { UserClass } from '../class-state/class';

export type TodaysClassesState = EntityState<UserClass>;

export const initialState: TodaysClassesState = {
    loading: false,
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'public-todays-classes', idKey: 'classSessionId' })
export class TodaysClassesStore extends EntityStore<
    TodaysClassesState,
    UserClass
> {
    constructor() {
        super(initialState);
    }
}
