import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';

import { TranslocoModule } from '@ngneat/transloco';

import { DirectiveModule } from '@shared/directives/directive.module';
import { IconModule } from '@shared/icon/icon.module';
import { MaterialModule } from '@shared/material.module';

import { ValidationMessageModule } from '../validation-message/validation-message.module';
import { DateComponent } from './date.component';

@NgModule({
    declarations: [DateComponent],
    imports: [
        CommonModule,
        TranslocoModule,
        FormsModule,
        ValidationMessageModule,
        MaterialModule,
        FlexLayoutModule,
        DirectiveModule,
        IconModule,
    ],

    exports: [DateComponent],
})
export class VscDateModule {}
