import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';

import { TranslocoModule } from '@ngneat/transloco';

import { CalendarModule } from 'angular-calendar';
import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { NgxPermissionsModule } from 'ngx-permissions';

import { TranslocoRootModule } from '../transloco-root.module';
import { BackButtonModule } from './back-button/back-button.module';
import { BadRequestComponent } from './bad-request/bad-request.component';
import { CarouselComponent } from './carousel/carousel.component';
import { CloseButtonComponent } from './close-button/close-button.component';
import { CollectionViewModule } from './collection-view/collection-view.module';
import { ComingSoonComponent } from './coming-soon/coming-soon.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { CookiesModule } from './cookies/cookies.module';
import { DirectiveModule } from './directives/directive.module';
import { IconModule } from './icon/icon.module';
import { ImageSelectionComponent } from './image-selection/image-selection.component';
import { JoinChatModule } from './join-chat/join-chat.module';
import { JoinClassModule } from './join-class/join-class.module';
import { LayoutModule } from './layout/layout.module';
import { ListViewModule } from './list-view/list-view.module';
import { LngSwitcherModule } from './lng-switcher/lng-switcher.module';
import { MatSectionHeaderModule } from './mat-section-header/mat-section-header.module';
import { MatSpinnerDirectiveModule } from './mat-spinner/mat-spinner.module';
import { MaterialModule } from './material.module';
import { NotAuthorizedComponent } from './not-authorized/not-authorized.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { PipesModule } from './pipes/pipes.module';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { SearchInputModule } from './search-input/search-input.module';
import { SnackBarIconComponent } from './snack-bar/snack-bar-icon/snack-bar-icon.component';
import { TableModule } from './table/table.module';
import { TenantSwitcherModule } from './tenant-switcher/tenant-switcher.module';
import { TosComponent } from './tos/tos.component';
import { UserMenuModule } from './user-menu/user-menu.module';
import { VideoSliderModule } from './video-slider/video-slider.module';
import { VideoModule } from './video/video.module';
import { VscBreadcrumbsModule } from './vsc-breadcrumbs/vsc-breadcrumbs.module';

@NgModule({
    imports: [
        TableModule,
        TranslocoModule,
        LngSwitcherModule,
        MatSpinnerDirectiveModule,
        SearchInputModule,
        NgxPermissionsModule,
        FlexLayoutModule,
        MatSectionHeaderModule,
        LayoutModule,
        CalendarModule,
        CollectionViewModule,
        JoinClassModule,
        VideoModule,
        CommonModule,
        MaterialModule,
        DropzoneModule,
        JoinChatModule,
        VideoSliderModule,
        ListViewModule,
        CookiesModule,
        TenantSwitcherModule,
        PipesModule,
        DirectiveModule,
        IconModule,
        BackButtonModule,
        UserMenuModule,
    ],
    exports: [
        TableModule,
        LngSwitcherModule,
        MatSpinnerDirectiveModule,
        SearchInputModule,
        NgxPermissionsModule,
        FlexLayoutModule,
        MatSectionHeaderModule,
        VscBreadcrumbsModule,
        LayoutModule,
        CalendarModule,
        CollectionViewModule,
        JoinClassModule,
        VideoModule,
        TranslocoRootModule,
        JoinChatModule,
        VideoSliderModule,
        ListViewModule,
        ImageSelectionComponent,
        CarouselComponent,
        CookiesModule,
        CloseButtonComponent,
        TenantSwitcherModule,
        PipesModule,
        DirectiveModule,
        IconModule,
        BackButtonModule,
        UserMenuModule,
    ],
    declarations: [
        NotAuthorizedComponent,
        NotFoundComponent,
        ComingSoonComponent,
        TosComponent,
        PrivacyPolicyComponent,
        BadRequestComponent,
        SnackBarIconComponent,
        CarouselComponent,
        CloseButtonComponent,
        ConfirmDialogComponent,
        ImageSelectionComponent,
    ],
})
export class SharedModule {}
