import { Injectable } from '@angular/core';

import { StoreConfig, EntityStore } from '@datorama/akita';

import { PBIEmbedToken } from '../report/models/pbi-embed-token';

export interface EmbedTokenState {
    pbiEmbedToken: PBIEmbedToken;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'embedtoken', idKey: 'tenantId' })
export class EmbedTokenStore extends EntityStore<
    EmbedTokenState,
    PBIEmbedToken
> {
    static readonly INITIAL_STATE: EmbedTokenState = {
        pbiEmbedToken: null,
    };
    constructor() {
        super(EmbedTokenStore.INITIAL_STATE);
    }
}
