<div class="scroll-to-top" [ngClass]="{ 'show-scrollTop': windowScrolled }">
    <a
        class="back-top-btn-style border"
        data-toggle="button"
        aria-pressed="true"
        (click)="scrollToTop()"
    >
        <span>{{ 'Go to top' | transloco }}</span>
        <i class="fa-regular fa-up-from-line"></i>
    </a>
</div>
