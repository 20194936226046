export enum ValidatorTypes {
    NumberValidator = 'NumberValidator',
    IntegerValidator = 'IntegerValidator',
    RequiredValidator = 'RequiredValidator',
    MaxValidator = 'MaxValidator',
    MinValidator = 'MinValidator',
    MaxLengthValidator = 'MaxLengthValidator',
    MinLengthValidator = 'MinLengthValidator',
    ValidDateValidator = 'ValidDateValidator',
    MaxDateValidator = 'MaxDateValidator',
    MinDateValidator = 'MinDateValidator',
    GreaterThanDateTimeValidator = 'GreaterThanDateTimeValidator',
    FutureDateTimeValidator = 'FutureDateTimeValidator',
    EmailValidator = 'EmailValidator',
    PatternValidator = 'PatternValidator',
    RegularExpressionValidator = 'RegularExpressionValidator',
    LessThanValidator = 'LessThanValidator',
    GreaterThanValidator = 'GreaterThanValidator',
    AsyncValidator = 'AsyncValidator',
    CustomValidator = 'CustomValidator',
    AllowedCharactersValidator = 'AllowedCharactersValidator',
    SameAsPatternValidator = 'SameAsPatternValidator',
    UserNameValidator = 'UserNameValidator',
    DynamicAyncValidator = 'DynamicAyncValidator',
    SameAsValidator = 'SameAsValidator',
    UrlValidator = 'UrlValidator',
    SecureUrlValidator = 'SecureUrlValidator',
}
