import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { setLoading } from '@datorama/akita';
import { TranslocoService } from '@ngneat/transloco';

import { Observable, EMPTY, of, Subject, BehaviorSubject } from 'rxjs';
import { map, switchMap, tap, catchError } from 'rxjs/operators';

import { ConfirmDialogService } from '@shared/confirm-dialog/confirm-dialog.service';
import { PagedEntities } from '@shared/models/paged-entities';
import { setLoadingCallback } from '@shared/operators/rxjs-operators';
import { SnackBarService } from '@shared/snack-bar/snack-bar.service';
import { LookupService } from '@shared/state/lookup/lookup.service';
import { Lookups } from '@shared/state/lookup/lookups';
import { PageRequest, Page } from '@shared/table/page';

import { HelpPage } from '../manage/help-page/state/help-page';
import { HelpPageQuery } from '../manage/help-page/state/help-page.query';
import { HelpPageStore } from '../manage/help-page/state/help-page.store';
import { Organization } from '../state/organization';
import { OrganizationsQuery } from '../state/organizations.query';
import { OrganizationsStore } from '../state/organizations.store';
import { OrganizationDataService } from './organization.dataservice';

export interface OrganizationSearchQuery {
    search: string;
}

@Injectable({ providedIn: 'root' })
export class OrganizationService {
    private organizationLogo$: Subject<string> = new BehaviorSubject<string>(
        ''
    );

    constructor(
        private organizationsStore: OrganizationsStore,
        private organizationQuery: OrganizationsQuery,
        private organizationDataService: OrganizationDataService,
        private router: Router,
        private confirmDialog: ConfirmDialogService,
        private snackBar: SnackBarService,
        private helpPageQuery: HelpPageQuery,
        private helpPageStore: HelpPageStore,
        private translocoService: TranslocoService,
        private lookupService: LookupService
    ) {}

    page(
        request: PageRequest<Organization>,
        query: OrganizationSearchQuery
    ): Observable<Page<Organization>> {
        return this.organizationDataService
            .getOrganizations(request, query)
            .pipe(
                map((response: PagedEntities<Organization>) => {
                    response.entities.map((el) => {
                        el.status = el.isActive
                            ? this.translocoService.translate('Active')
                            : this.translocoService.translate('Inactive');
                    });
                    this.organizationsStore.set(response.entities);

                    return {
                        content: response.entities,
                        size: response.entities.length,
                        totalElements: response.totalCount,
                        number: request.page,
                    } as Page<Organization>;
                }),
                setLoading(this.organizationsStore),
                catchError(() => {
                    this.snackBar.open('Failed to load Organizations.');
                    return EMPTY;
                })
            );
    }

    getById(id: string): Observable<Organization> {
        return this.organizationDataService.getById(id).pipe(
            map((organization: Organization) => {
                this.organizationsStore.upsert(organization.id, organization);

                return organization;
            }),
            catchError(() => {
                this.snackBar.open('Failed to load Organization.');
                return EMPTY;
            })
        );
    }

    getOrganizationLogoUrl(id: string): Observable<string> {
        return this.organizationDataService.getOrganizationLogoUrl(id).pipe(
            map((organizationLogoUrl: string) => {
                return organizationLogoUrl;
            })
        );
    }

    isNameValid(name: string): Observable<boolean> {
        const organization = this.organizationQuery.getActiveOrganization();

        if (
            organization &&
            organization.name.toLowerCase() === name.toLowerCase()
        ) {
            return of(true);
        }

        return this.organizationDataService
            .exists(name)
            .pipe(map((exists) => !exists));
    }

    post(organization: any): Observable<any> {
        return this.organizationDataService.post(organization).pipe(
            map((response) => {
                this.lookupService.removeLookup(
                    Lookups.ContentExchangeEnabledOrganizations
                );
                this.organizationsStore.add(response);
                this.snackBar.open('Organization created.');
                return response;
            }),
            setLoading(this.organizationsStore),
            catchError(() => {
                this.snackBar.open('Failed to create Organization.');
                return EMPTY;
            })
        );
    }

    put(organization: any): Observable<any> {
        return this.organizationDataService.put(organization).pipe(
            map((response) => {
                this.lookupService.removeLookup(
                    Lookups.ContentExchangeEnabledOrganizations
                );
                this.lookupService.removeLookup(Lookups.Languages);
                this.organizationsStore.update(response.id, response);
                this.snackBar.open('Organization updated.');
                return response;
            }),
            setLoading(this.organizationsStore),
            catchError(() => {
                this.snackBar.open('Failed to update Organization.');
                return EMPTY;
            })
        );
    }

    upsert(
        organization: Organization,
        file: File,
        organizationLogoUrl: string
    ): Observable<any> {
        organization.logoImageUrl = organizationLogoUrl;
        const isEditMode = this.organizationQuery.getIsEditMode();
        const formData: FormData = new FormData();
        formData.append('file', file);
        formData.append('organization', JSON.stringify(organization));
        if (!isEditMode) {
            return this.post(formData).pipe(
                map((response: any) => {
                    this.router.navigate([
                        `/admin/organizations/${response.id}`,
                    ]);
                    return response;
                })
            );
        } else {
            return this.put(formData);
        }
    }

    delete(id: string): Observable<any> {
        return this.confirmDialog.confirm().pipe(
            switchMap((result) => {
                if (result) {
                    return this.organizationDataService
                        .delete(id)
                        .pipe(setLoading(this.organizationsStore));
                }
                return EMPTY;
            }),
            tap(() => {
                this.snackBar.open('Organization deleted.');
            }),
            catchError(() => {
                this.snackBar.open('Failed to delete Organization.');
                return EMPTY;
            })
        );
    }

    fetchIfNotPresent(): Observable<Organization> {
        const organization = this.organizationQuery.getActiveOrganization();
        const isEditMode = this.organizationQuery.getIsEditMode();
        if (!organization && isEditMode) {
            return this.getById(this.organizationQuery.getEditingId());
        }
        return EMPTY;
    }
    fetchHelpPageIfNotPresent(): Observable<HelpPage> {
        const helpPage = this.helpPageQuery.getActiveHelpPage();
        const isEditMode = this.helpPageQuery.getIsEditMode();
        if (!helpPage && isEditMode) {
            return this.getHelpPageByOrganizationId(
                this.organizationQuery.getEditingId()
            );
        }
        return EMPTY;
    }

    getHelpPageByOrganizationId(id: string): Observable<HelpPage> {
        return this.organizationDataService
            .getHelpPageByOrganizationId(id)
            .pipe(
                map((helpPage: HelpPage) => {
                    this.helpPageStore.add(helpPage);
                    return helpPage;
                }),
                catchError(() => {
                    this.snackBar.open(
                        this.translocoService.translate(
                            'Failed to load Help Page.'
                        )
                    );
                    return EMPTY;
                })
            );
    }

    createHelpPage(helpPage: HelpPage): Observable<HelpPage> {
        return this.organizationDataService
            .createHelpPage(helpPage, this.organizationQuery.getEditingId())
            .pipe(
                map((response) => {
                    this.helpPageStore.add(response);
                    this.snackBar.open(
                        this.translocoService.translate('Help page created.')
                    );
                    return response;
                }),
                setLoading(this.helpPageStore),
                catchError(() => {
                    this.snackBar.open(
                        this.translocoService.translate(
                            'Failed to create Help page.'
                        )
                    );
                    return EMPTY;
                })
            );
    }

    updateHelpPage(helpPage: HelpPage): Observable<HelpPage> {
        return this.organizationDataService
            .updateHelpPage(helpPage, this.organizationQuery.getEditingId())
            .pipe(
                map((response) => {
                    this.helpPageStore.update(response.id, response);
                    this.snackBar.open(
                        this.translocoService.translate('Help page updated.')
                    );
                    return response;
                }),
                setLoading(this.helpPageStore),
                catchError(() => {
                    this.snackBar.open(
                        this.translocoService.translate(
                            'Failed to update Help page.'
                        )
                    );
                    return EMPTY;
                })
            );
    }
    upsertHelpPage(helpPage: HelpPage): Observable<HelpPage> {
        const isEditMode = this.helpPageQuery.getIsEditMode();
        if (!isEditMode) {
            return this.createHelpPage(helpPage).pipe(
                map((response: HelpPage) => {
                    this.router.navigate([
                        `admin/organizations/` +
                            `${this.organizationQuery.getEditingId()}` +
                            `/help/` +
                            `/${response.id}`,
                    ]);
                    return response;
                })
            );
        } else {
            return this.updateHelpPage(helpPage);
        }
    }
    get organizationLogoUrl$() {
        return this.organizationLogo$.asObservable();
    }

    addOrganizationLogoUrl(value: string) {
        this.organizationLogo$.next(value);
    }

    activateOrganization(id: string): Observable<Organization> {
        return this.organizationDataService.activateOrganization(id).pipe(
            tap((organization: Organization) => {
                this.organizationsStore.update(
                    organization.id,
                    (entity: Organization) => ({
                        ...entity,
                        isActive: true,
                    })
                );
                this.snackBar.open(
                    this.translocoService.translate('Organization activated.')
                );
            }),
            setLoadingCallback((isLoading: boolean) =>
                this.organizationsStore.updateStatusChangeInProgress(isLoading)
            ),
            catchError(() => {
                this.snackBar.open(
                    this.translocoService.translate(
                        'Failed to activate Organization.'
                    )
                );
                return EMPTY;
            })
        );
    }

    deactivateOrganization(id: string): Observable<Organization> {
        return this.organizationDataService.deactivateOrganization(id).pipe(
            tap((organization: Organization) => {
                this.organizationsStore.update(
                    organization.id,
                    (entity: Organization) => ({
                        ...entity,
                        isActive: false,
                    })
                );
                this.snackBar.open(
                    this.translocoService.translate('Organization deactivated.')
                );
            }),
            setLoadingCallback((isLoading: boolean) =>
                this.organizationsStore.updateStatusChangeInProgress(isLoading)
            ),
            catchError(() => {
                this.snackBar.open(
                    this.translocoService.translate(
                        'Failed to deactivate Organization.'
                    )
                );
                return EMPTY;
            })
        );
    }
}
