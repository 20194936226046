import {
    AfterViewInit,
    Directive,
    ElementRef,
    HostListener,
    OnInit,
    Renderer2,
} from '@angular/core';

import * as moment from 'moment';

import { Constants } from '@shared/constants';
import { InputMask } from '@shared/forms/input';

interface Selection {
    start: number;
    end: number;
}

type Moment = moment.Moment;

@Directive({
    selector: '[vscDateMask]',
})
export class DateMaskDirective implements OnInit, AfterViewInit {
    element: HTMLInputElement;

    readonly YEAR_MASK: string = 'yyyy';
    readonly DATE_OF_MONTH_MASK: string = 'dd';
    readonly MONTH_MASK: string = 'mm';

    readonly VALUE_ATTR = 'value';
    readonly INVALID_FORMAT_ATTR = 'invalidFormat';

    year: string;
    month: string;
    dateOfMonth: string;
    oldValue: string;

    isChangeEventTriggered: boolean = true;
    isPasteEventTriggered: boolean = false;
    isPastedCompletelyInvalidDate: boolean = false;
    isKeepInvalidValuesEnabled: boolean = false;
    isReadOnly: boolean;

    readonly MONTH_SELECTION: Selection = {
        start: 0,
        end: 2,
    };

    readonly DATE_OF_MONTH_SELECTION: Selection = {
        start: 3,
        end: 5,
    };

    readonly YEAR_SELECTION: Selection = {
        start: 6,
        end: 10,
    };

    get dateValue(): string {
        return `${this.month}/${this.dateOfMonth}/${this.year}`;
    }
    set dateValue(value: string) {
        this.month = value.substring(
            this.MONTH_SELECTION.start,
            this.MONTH_SELECTION.end
        );
        this.dateOfMonth = value.substring(
            this.DATE_OF_MONTH_SELECTION.start,
            this.DATE_OF_MONTH_SELECTION.end
        );
        this.year = value.substring(
            this.YEAR_SELECTION.start,
            this.YEAR_SELECTION.end
        );
    }

    constructor(
        private elementRef: ElementRef<HTMLInputElement>,
        private renderer: Renderer2
    ) {}

    ngOnInit(): void {
        this.element = this.elementRef.nativeElement;
        this.dateValue = InputMask.Date;

        const elValue = this.element.value;
        const date = moment(elValue, Constants.DATE_FORMAT_US);
        if (elValue && date?.isValid()) {
            this.dateValue = date.toDateString();
        }
        this.renderer.setProperty(this.element, this.VALUE_ATTR, '');
    }
    ngAfterViewInit(): void {
        setTimeout(() => {
            this.oldValue = this.element.value;
            this.isReadOnly =
                !!this.element.getAttribute('readonly') ||
                !!this.element.getAttribute('disabled');
        });
    }
    isMonthSelected = (selectionStart: number): boolean => {
        return selectionStart <= 2 && selectionStart >= 0;
    };

    isDateOfMonthSelected = (selectionStart: number): boolean => {
        return selectionStart >= 3 && selectionStart <= 5;
    };

    isYearSelected = (selectionStart: number): boolean => {
        return selectionStart >= 6;
    };

    isIgnoredSelectionStart = (selectionStart: number): boolean => {
        return (
            selectionStart === 2 || selectionStart === 5 || selectionStart > 10
        );
    };

    // Triggers on form control patch value
    @HostListener('patchValue')
    onPatchValue(): void {
        if (!this.element.value) {
            this.updateValue();
        }
    }

    @HostListener('focus')
    onFocus(): void {
        if (!this.isReadOnly && !this.element.value) {
            this.updateValue();
        }
    }

    @HostListener('blur', ['$event'])
    onBlur(event: Event): void {
        if (this.isReadOnly) {
            return;
        }

        event.preventDefault();

        if (
            !this.isChangeEventTriggered &&
            !this.isPastedCompletelyInvalidDate &&
            this.dateValue !== this.oldValue
        ) {
            this.oldValue = this.dateValue;
            this.validateDateValues(this.isKeepInvalidValuesEnabled);
            this.triggerInputChangeEvent();
            this.renderer.setProperty(
                this.element,
                this.VALUE_ATTR,
                this.dateValue
            );
        } else if ((this.element as any)?.isResetForm as boolean) {
            this.renderer.setProperty(
                this.element,
                Constants.IS_RESET_FORM_ATTR,
                false
            );

            this.oldValue = null;
            this.dateValue = InputMask.Date;
            this.renderer.setProperty(this.element, this.VALUE_ATTR, '');
        }
    }

    @HostListener('change', ['$event'])
    onChange(event: Event): void {
        event.preventDefault();
        event.stopImmediatePropagation();

        if (this.isPasteEventTriggered) {
            const currentInputValue = this.element.value;
            const date = this.getDateFromInputValue();
            this.isPasteEventTriggered = false;
            const isValidDate = date?.isValid();
            this.isKeepInvalidValuesEnabled = !isValidDate;
            if (isValidDate) {
                this.dateValue = date.toDateString();
                this.validateDateValues();
                this.isKeepInvalidValuesEnabled = false;
            } else if (this.isPastedCompletelyInvalidDate) {
                this.dateValue = InputMask.Date;
                this.renderer.setProperty(
                    this.element,
                    this.INVALID_FORMAT_ATTR,
                    true
                );
                this.triggerInputChangeEvent();
                this.renderer.setProperty(
                    this.element,
                    this.VALUE_ATTR,
                    currentInputValue
                );
            }
        }
    }

    @HostListener('paste')
    onPaste(): void {
        if (this.isReadOnly) {
            return;
        }
        this.dateValue = InputMask.Date;
        this.renderer.setProperty(this.element, this.VALUE_ATTR, '');
        this.isPasteEventTriggered = true;
        return;
    }

    @HostListener('dblclick', ['$event'])
    onDoubleClick(event: MouseEvent): void {
        if (this.isReadOnly) {
            return;
        }
        event.preventDefault();
        event.stopImmediatePropagation();
        this.setInputSelectionRange(0, 10);
    }

    @HostListener('click')
    onClick(): void {
        if (this.isReadOnly) {
            return;
        }
        if (this.isPastedCompletelyInvalidDate) {
            this.dateValue = InputMask.Date;
            this.isPastedCompletelyInvalidDate = false;
            this.updateValue();
            return;
        }
        const selectionStart = this.element.selectionStart;
        this.dateValue = this.element.value;

        if (this.isIgnoredSelectionStart(selectionStart)) {
            return;
        }

        let selection: Selection;
        switch (true) {
            case (this.dateValue === InputMask.Date && selectionStart === 10) ||
                this.isMonthSelected(selectionStart):
                selection = this.MONTH_SELECTION;
                break;
            case (selectionStart === 10 &&
                this.month !== this.MONTH_MASK &&
                this.dateOfMonth === this.DATE_OF_MONTH_MASK) ||
                this.isDateOfMonthSelected(selectionStart):
                selection = this.DATE_OF_MONTH_SELECTION;
                break;
            case this.isYearSelected(selectionStart):
                selection = this.YEAR_SELECTION;
                break;
        }

        this.setInputSelectionRange(selection.start, selection.end);
    }

    @HostListener('keydown', ['$event'])
    onKeyUp(event: KeyboardEvent): void {
        if (!event.key || this.isReadOnly) {
            return;
        }
        if (event.ctrlKey || event.metaKey) {
            const keyLowercase = event.key.toLowerCase();
            if (keyLowercase === 'c' || keyLowercase === 'v') {
                return;
            }
        }
        const selectionStart = this.element.selectionStart;
        const isBackspaceKey = event.key === Constants.KEY_BACKSPACE;
        event.preventDefault();
        event.stopImmediatePropagation();

        if (this.isPastedCompletelyInvalidDate && isBackspaceKey) {
            this.dateValue = InputMask.Date;
            this.updateValue();
            this.setValueAndReverseSelection(0);
            return;
        }

        if (this.isIgnoredSelectionStart(selectionStart)) {
            return;
        }

        this.dateValue = this.element.value;

        if (isBackspaceKey && this.dateValue !== InputMask.Date) {
            let isTwiceBackspace = false;

            if (selectionStart === 0 && this.element.selectionEnd === 10) {
                this.dateValue = InputMask.Date;
            } else if (this.isMonthSelected(selectionStart)) {
                if (this.month === this.MONTH_MASK) {
                    isTwiceBackspace = true;
                } else {
                    this.month = this.MONTH_MASK;
                }
            } else if (this.isDateOfMonthSelected(selectionStart)) {
                if (this.dateOfMonth === this.DATE_OF_MONTH_MASK) {
                    isTwiceBackspace = true;
                } else {
                    this.dateOfMonth = this.DATE_OF_MONTH_MASK;
                }
            } else if (this.isYearSelected(selectionStart)) {
                if (this.year === this.YEAR_MASK) {
                    isTwiceBackspace = true;
                } else {
                    this.year = this.YEAR_MASK;
                }
            }
            this.setValueAndReverseSelection(selectionStart, isTwiceBackspace);
        } else if (selectionStart < 10) {
            const charCode = event.key.charCodeAt(0);
            if (event.key.length === 1 && charCode >= 48 && charCode <= 57) {
                this.dateValue = this.replaceAt(
                    this.dateValue,
                    selectionStart,
                    event.key
                );
                this.isKeepInvalidValuesEnabled = false;
                this.setValueAndSelection(selectionStart);
            }
        }
    }

    setValueAndSelection(selectionStart: number): void {
        this.updateValue();
        if ([1, 4, 9].indexOf(selectionStart) != -1) {
            this.validateDateValues();
        }

        const NEXT_SELECTION: Selection = {
            start: selectionStart + 1,
            end: selectionStart + 2,
        };

        let selection: Selection;

        switch (true) {
            case selectionStart === 0 || selectionStart === 3:
                selection = NEXT_SELECTION;
                break;
            case selectionStart === 1:
                selection = this.DATE_OF_MONTH_SELECTION;
                break;
            case selectionStart >= 4 && selectionStart <= 10:
                selection = {
                    start: selectionStart >= 6 ? selectionStart + 1 : 6,
                    end: 10,
                } as Selection;
                break;
        }

        this.setInputSelectionRange(selection.start, selection.end);
    }

    setValueAndReverseSelection(
        selectionStart: number,
        isBackspacePressedTwice: boolean = false
    ): void {
        this.updateValue();
        let selection = this.getSelection(selectionStart);

        switch (selection) {
            case this.YEAR_SELECTION:
                selection = isBackspacePressedTwice
                    ? this.DATE_OF_MONTH_SELECTION
                    : this.YEAR_SELECTION;
                break;
            case this.DATE_OF_MONTH_SELECTION:
                selection = isBackspacePressedTwice
                    ? this.MONTH_SELECTION
                    : this.DATE_OF_MONTH_SELECTION;
                break;
            case this.MONTH_SELECTION:
                selection = this.MONTH_SELECTION;
                break;
        }

        this.setInputSelectionRange(selection.start, selection.end);
    }

    validateDateValues(keepInvalidValues: boolean = false): void {
        const yearNum = parseInt(this.year.replace(/y/g, ''));
        const monthNum = parseInt(this.month.replace('m', ''));
        const dateOfMonthNum = parseInt(this.dateOfMonth.replace('d', ''));

        if (yearNum) {
            if (this.year.includes('y')) {
                this.year = ('0000' + yearNum).slice(-4);
            }
            if (!keepInvalidValues) {
                switch (true) {
                    case yearNum < 1:
                        this.year = '2001';
                        break;
                    case yearNum < 100:
                        this.year = `19${
                            yearNum / 10 < 1 ? '0' + yearNum : yearNum
                        }`;
                        break;
                }
            }
        } else {
            this.year = this.YEAR_MASK;
        }

        if (monthNum) {
            if (!keepInvalidValues) {
                if (monthNum > 12) {
                    this.month = '12';
                } else if (monthNum < 1) {
                    this.month = '01';
                }
            }

            if (this.month.includes('m')) {
                this.month = ('0' + monthNum).slice(-2);
            }
        } else {
            this.month = this.MONTH_MASK;
        }

        const maxDateOfMonth = moment({
            year: yearNum,
            month: monthNum - 1,
        }).daysInMonth();

        if (dateOfMonthNum) {
            if (this.dateOfMonth.includes('d')) {
                this.dateOfMonth = ('0' + dateOfMonthNum).slice(-2);
            }

            if (!keepInvalidValues) {
                if (dateOfMonthNum > maxDateOfMonth) {
                    this.dateOfMonth = maxDateOfMonth.toString();
                } else if (dateOfMonthNum < 1) {
                    this.dateOfMonth = '01';
                }
            } else if (monthNum === 2 && dateOfMonthNum > maxDateOfMonth) {
                this.isPastedCompletelyInvalidDate = true;
                this.oldValue = this.element.value;
            }
        } else {
            this.dateOfMonth = this.DATE_OF_MONTH_MASK;
        }

        this.updateValue();
    }

    updateValue(): void {
        this.renderer.setProperty(
            this.element,
            this.INVALID_FORMAT_ATTR,
            false
        );
        this.renderer.setProperty(
            this.element,
            this.VALUE_ATTR,
            this.dateValue
        );
        this.isChangeEventTriggered = false;
    }

    replaceAt(str, index, replacement): string {
        return (
            str.substr(0, index) +
            replacement +
            str.substr(index + replacement.length)
        );
    }

    getDateFromInputValue(): Moment {
        const dateString = this.element.value;
        let date: Moment = null;
        let dateOfMonth: number;
        let month: number;
        let year: number;

        if (dateString && dateString.length >= 2) {
            const delimiter = (dateString.match(/[.\-\/]/g) || [''])[0];

            if (!delimiter) {
                if (
                    dateString.match(/^[0-9]*$/) &&
                    [6, 8].includes(dateString.length)
                ) {
                    switch (dateString.length) {
                        case 6:
                            month = parseInt(dateString[0]);
                            dateOfMonth = parseInt(dateString[1]);
                            year = parseInt(dateString.substring(2, 6));
                            break;
                        case 8:
                            month = parseInt(dateString.substring(0, 2));
                            dateOfMonth = parseInt(dateString.substring(2, 4));
                            year = parseInt(dateString.substring(4, 8));
                            break;
                    }
                }
            } else {
                const dateParts = dateString.split(delimiter);
                if (dateParts.length > 0 && dateParts.length <= 3) {
                    dateParts[0].length <= 2 &&
                        (month = parseInt(dateParts[0]));

                    dateParts.length > 1 &&
                        dateParts[1].length <= 2 &&
                        (dateOfMonth = parseInt(dateParts[1]));

                    dateParts.length > 2 &&
                        dateParts[2].length <= 4 &&
                        (year = parseInt(dateParts[2]));
                }
            }
        }

        dateOfMonth &&
            (this.dateOfMonth = ('d' + dateOfMonth.toString()).slice(-2));
        year && (this.year = ('yyy' + year.toString()).slice(-4));
        month && (this.month = ('m' + month.toString()).slice(-2));
        this.isPastedCompletelyInvalidDate = !dateOfMonth && !year && !month;

        if (
            year >= 1 &&
            month >= 1 &&
            month <= 12 &&
            dateOfMonth >= 1 &&
            dateOfMonth <= moment({ year, month }).daysInMonth()
        ) {
            date = moment()
                .year(year)
                .month(month - 1)
                .date(dateOfMonth);
        } else if (!this.isPastedCompletelyInvalidDate) {
            this.validateDateValues(true);
        } else {
            this.oldValue = this.element.value;
        }

        return date;
    }

    getSelection(selectionStart: number): Selection {
        switch (true) {
            case selectionStart >= 6 && selectionStart <= 10:
                return this.YEAR_SELECTION;
            case selectionStart >= 3 && selectionStart <= 5:
                return this.DATE_OF_MONTH_SELECTION;
            case selectionStart >= 0 && selectionStart <= 2:
                return this.MONTH_SELECTION;
        }
    }

    triggerInputChangeEvent(): void {
        this.element?.dispatchEvent(new CustomEvent('change'));
        this.isChangeEventTriggered = true;
    }

    setInputSelectionRange(selectionStart: number, selectionEnd: number): void {
        this.renderer.setProperty(
            this.element,
            'selectionStart',
            selectionStart
        );
        this.renderer.setProperty(this.element, 'selectionEnd', selectionEnd);
    }
}
