<ng-container *ngFor="let item of items">
    <mat-nav-list>
        <vsc-side-menu-item
            [menuState]="menuState"
            *ngIf="
                item.visible &&
                ((item.hasVisibleChildren | asyncIfObservable) ||
                    item.route != null)
            "
            [item]="item"
            matTooltipPosition="right"
            [matTooltip]="
                menuState === 'in' ? (item.displayName | asyncIfObservable) : ''
            "
        ></vsc-side-menu-item>
    </mat-nav-list>
</ng-container>
