import {
    AbstractControl,
    AsyncValidatorFn,
    FormControl,
    ValidatorFn,
    FormGroup,
} from '@angular/forms';

import { Moment } from 'moment';
import { Observable } from 'rxjs';

import { ValidationMessage } from '../validation-message/validation-message';
import { AllowedCharactersValidator } from './allowed-characters.validator';
import { AsyncValidator } from './async.validator';
import { CustomValidator } from './custom.validator';
import { Group, Rule } from './dynamic';
import { DynamicAsyncValidator } from './dynamic-async.validator';
import { EmailValidator } from './email.validator';
import { FutureDateTimeValidator } from './future-date-time.validator';
import { GreaterThanDateTimeValidator } from './greater-than-date-time.validator';
import { IntegerValidator } from './integer.validator';
import { MaxDateValidator } from './max-date.validator';
import { MaxLengthValidator } from './max-length.validator';
import { MaxValidator } from './max.validator';
import { MinDateValidator } from './min-date.validator';
import { MinLengthValidator } from './min-length.validator';
import { MinValidator } from './min.validator';
import { NumberValidator } from './number.validator';
import { PatternValidator } from './pattern.validator';
import { RegularExpressionValidator } from './regular-expression.validator';
import { RequiredValidator } from './required.validator';
import { SameAsPatternValidator } from './same-as-pattern.validator';
import { UrlValidator } from './url.validator';
import { ValidDateValidator } from './valid-date.validator';
import { ValidatorTypes } from './validator-types.enum';
import { GreaterThanValidator } from './value-greater-than.validator';
import { LessThanValidator } from './value-less-than.validator';

// @dynamic
// Async and custom validator requires name property if they are used multiple times in one control
export class Validators {
    static number(rules?: Array<Rule | Group>): ValidatorFn {
        return (c: AbstractControl) => {
            let validator = c[ValidatorTypes.NumberValidator];
            if (!validator) {
                validator = c[ValidatorTypes.NumberValidator] =
                    new NumberValidator(rules);
            }

            return validator.validate(c);
        };
    }

    static integer(rules?: Array<Rule | Group>): ValidatorFn {
        return (c: AbstractControl) => {
            let validator = c[ValidatorTypes.IntegerValidator];
            if (!validator) {
                validator = c[ValidatorTypes.IntegerValidator] =
                    new IntegerValidator(rules);
            }

            return validator.validate(c);
        };
    }

    static required(
        message?: string,
        rules?: Array<Rule | Group>
    ): ValidatorFn {
        return (c: AbstractControl) => {
            let validator = c[ValidatorTypes.RequiredValidator];
            if (!validator) {
                validator = c[ValidatorTypes.RequiredValidator] =
                    new RequiredValidator(message, rules);
            }

            return validator.validate(c);
        };
    }

    static max(
        maxValue: number,
        messageResolver?: () => string,
        rules?: Array<Rule | Group>
    ): ValidatorFn {
        return (c: AbstractControl) => {
            let validator = c[ValidatorTypes.MaxValidator];
            if (!validator) {
                validator = c[ValidatorTypes.MaxValidator] = new MaxValidator(
                    maxValue,
                    messageResolver,
                    rules
                );
            }

            return validator.validate(c);
        };
    }

    static min(minValue: number, rules?: Array<Rule | Group>): ValidatorFn {
        return (c: AbstractControl) => {
            let validator = c[ValidatorTypes.MinValidator];
            if (!validator) {
                validator = c[ValidatorTypes.MinValidator] = new MinValidator(
                    minValue,
                    rules
                );
            }

            return validator.validate(c);
        };
    }

    static maxLength(
        maxLength: number,
        message?: string,
        rules?: Array<Rule | Group>
    ): ValidatorFn {
        return (c: AbstractControl) => {
            let validator = c[ValidatorTypes.MaxLengthValidator];
            if (!validator) {
                validator = c[ValidatorTypes.MaxLengthValidator] =
                    new MaxLengthValidator(maxLength, message, rules);
            }

            return validator.validate(c);
        };
    }

    static minLength(
        minLength: number,
        message?: string,
        rules?: Array<Rule | Group>
    ): ValidatorFn {
        return (c: AbstractControl) => {
            let validator = c[ValidatorTypes.MinLengthValidator];
            if (!validator) {
                validator = c[ValidatorTypes.MinLengthValidator] =
                    new MinLengthValidator(minLength, message, rules);
            }

            return validator.validate(c);
        };
    }

    static validDate(
        message?: string,
        rules?: Array<Rule | Group>
    ): ValidatorFn {
        return (c: AbstractControl) => {
            let validator = c[ValidatorTypes.ValidDateValidator];
            if (!validator) {
                validator = c[ValidatorTypes.ValidDateValidator] =
                    new ValidDateValidator(message, rules);
            }
            return validator.validate(c);
        };
    }

    static maxDate(maxDate: Moment, rules?: Array<Rule | Group>): ValidatorFn {
        return (c: AbstractControl) => {
            let validator = c[ValidatorTypes.MaxDateValidator];
            if (!validator) {
                validator = c[ValidatorTypes.MaxDateValidator] =
                    new MaxDateValidator(maxDate, rules);
            }

            return validator.validate(c);
        };
    }

    static minDate(minDate: Moment, rules?: Array<Rule | Group>): ValidatorFn {
        return (c: AbstractControl) => {
            let validator = c[ValidatorTypes.MinDateValidator];
            if (!validator) {
                validator = c[ValidatorTypes.MinDateValidator] =
                    new MinDateValidator(minDate, rules);
            }

            return validator.validate(c);
        };
    }

    static futureDateTime(
        message: string,
        messageResolver?: () => string,
        exceptDateResolver?: () => Moment,
        rules?: Array<Rule | Group>
    ): ValidatorFn {
        return (c: AbstractControl) => {
            let validator = c[FutureDateTimeValidator.name];
            if (!validator) {
                validator = c[ValidatorTypes.FutureDateTimeValidator] =
                    new FutureDateTimeValidator(
                        message,
                        messageResolver,
                        exceptDateResolver,
                        rules
                    );
            }

            return validator.validate(c);
        };
    }

    static greaterThanDateTime(
        controlToCompareName: string,
        invalidDateMessage: string,
        invalidTimeMessage: string,
        rules?: Array<Rule | Group>
    ): ValidatorFn {
        return (c: AbstractControl) => {
            let validator = c[ValidatorTypes.GreaterThanValidator];
            if (!validator) {
                validator = c[ValidatorTypes.GreaterThanValidator] =
                    new GreaterThanDateTimeValidator(
                        controlToCompareName,
                        invalidDateMessage,
                        invalidTimeMessage,
                        rules
                    );
            }

            return validator.validate(c);
        };
    }

    static email(message?: string, rules?: Array<Rule | Group>): ValidatorFn {
        return (c: AbstractControl) => {
            let validator = c[ValidatorTypes.EmailValidator];
            if (!validator) {
                validator = c[ValidatorTypes.EmailValidator] =
                    new EmailValidator(message, rules);
            }

            return validator.validate(c);
        };
    }

    static pattern(
        pattern: string,
        message?: string,
        rules?: Array<Rule | Group>
    ): ValidatorFn {
        return (c: AbstractControl) => {
            let validator = c[ValidatorTypes.PatternValidator];
            if (!validator) {
                validator = c[ValidatorTypes.PatternValidator] =
                    new PatternValidator(pattern, message, rules);
            }

            return validator.validate(c);
        };
    }

    static regularExpression(rules?: Array<Rule | Group>): ValidatorFn {
        return (c: AbstractControl) => {
            let validator = c[ValidatorTypes.RegularExpressionValidator];
            if (!validator) {
                validator = c[ValidatorTypes.RegularExpressionValidator] =
                    new RegularExpressionValidator(rules);
            }

            return validator.validate(c);
        };
    }

    static lessThan(
        controlToValidateName: string,
        controlToCompareName: string,
        rules?: Array<Rule | Group>
    ): ValidatorFn {
        return (c: AbstractControl) => {
            let validator = c[ValidatorTypes.LessThanValidator];
            if (!validator) {
                validator = c[ValidatorTypes.LessThanValidator] =
                    new LessThanValidator(
                        controlToValidateName,
                        controlToCompareName,
                        rules
                    );
            }

            return validator.validate(c);
        };
    }

    static greaterThan(
        controlToValidateName: string,
        controlToCompareName: string,
        message?: string,
        rules?: Array<Rule | Group>
    ): ValidatorFn {
        return (c: AbstractControl) => {
            let validator = c[ValidatorTypes.GreaterThanValidator];
            if (!validator) {
                validator = c[ValidatorTypes.GreaterThanValidator] =
                    new GreaterThanValidator(
                        controlToValidateName,
                        controlToCompareName,
                        message,
                        rules
                    );
            }

            return validator.validate(c);
        };
    }

    static async(
        func: (value: any) => Observable<boolean | ValidationMessage>,
        messageBuilder?: (value: string) => string | Observable<string>,
        name?: string,
        rules?: Array<Rule | Group>
    ): AsyncValidatorFn {
        return (c: AbstractControl) => {
            let validator = c[ValidatorTypes.AsyncValidator + name];
            if (!validator) {
                validator = c[ValidatorTypes.AsyncValidator + name] =
                    new AsyncValidator(func, messageBuilder, rules);
            }

            return validator.validate(c);
        };
    }

    static dynamicAsync(
        func: (value: string) => Observable<any>,
        name: string = '',
        rules?: Array<Rule | Group>
    ): AsyncValidatorFn {
        return (c: AbstractControl) => {
            let validator = c[ValidatorTypes.DynamicAyncValidator + name];
            if (!validator) {
                validator = c[ValidatorTypes.DynamicAyncValidator + name] =
                    new DynamicAsyncValidator(func, rules);
            }

            return validator.validate(c);
        };
    }

    static custom(
        validatorFn: (control: FormControl) => boolean,
        message?: string,
        messageResolver?: () => string,
        name?: string,
        rules?: Array<Rule | Group>
    ): ValidatorFn {
        return (c: AbstractControl) => {
            let validator = c[ValidatorTypes.CustomValidator + name];
            if (!validator) {
                validator = c[ValidatorTypes.CustomValidator + name] =
                    new CustomValidator(
                        validatorFn,
                        message,
                        messageResolver,
                        rules
                    );
            }

            return validator.validate(c);
        };
    }

    static allowedCharacters(
        allowedCharacters: Array<string>,
        rules?: Array<Rule | Group>
    ): ValidatorFn {
        return (c: AbstractControl) => {
            let validator = c[ValidatorTypes.AllowedCharactersValidator];
            if (!validator) {
                validator = c[ValidatorTypes.AllowedCharactersValidator] =
                    new AllowedCharactersValidator(allowedCharacters, rules);
            }

            return validator.validate(c);
        };
    }

    static sameAsPattern(
        pattern: string,
        rules?: Array<Rule | Group>
    ): ValidatorFn {
        return (c: AbstractControl) => {
            let validator = c[ValidatorTypes.SameAsPatternValidator];
            if (!validator) {
                validator = c[ValidatorTypes.SameAsPatternValidator] =
                    new SameAsPatternValidator(pattern, rules);
            }

            return validator.validate(c);
        };
    }

    static sameAs(
        controlName: string,
        matchingControlName: string,
        controlNameDescription: string,
        controlToCompareDescription: string
    ): any {
        return (formGroup: FormGroup) => {
            const control = formGroup.controls[controlName];
            const matchingControl = formGroup.controls[matchingControlName];

            if (matchingControl.errors && !matchingControl.errors.mustMatch) {
                return;
            }

            if (control.value !== matchingControl.value) {
                matchingControl.setErrors({
                    sameAs: {
                        sameAs: true,
                        controlToValidateName: controlNameDescription,
                        controlToCompareName: controlToCompareDescription,
                    },
                });
            } else {
                matchingControl.setErrors(null);
            }
        };
    }

    static url(
        messageResolver?: () => string,
        rules?: Array<Rule | Group>
    ): ValidatorFn {
        return (c: AbstractControl) => {
            let validator = c[ValidatorTypes.UrlValidator];
            if (!validator) {
                validator = c[ValidatorTypes.UrlValidator] = new UrlValidator(
                    messageResolver,
                    rules,
                    false
                );
            }

            return validator.validate(c);
        };
    }

    static secureUrl(
        messageResolver?: () => string,
        rules?: Array<Rule | Group>
    ): ValidatorFn {
        return (c: AbstractControl) => {
            let validator = c[ValidatorTypes.SecureUrlValidator];
            if (!validator) {
                validator = c[ValidatorTypes.SecureUrlValidator] =
                    new UrlValidator(messageResolver, rules, true);
            }

            return validator.validate(c);
        };
    }
}
