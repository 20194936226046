export const environment: any = {
    production: true,
    requireIdentityHttps: window['env']['requireIdentityHttps'] || false,
    identityUrl: window['env']['identityUrl'] || 'default',
    apiUrl: window['env']['apiUrl'] || 'default',
    wetherApi: {
        baseUrl:
            window['env']['wetherBaseUrl'] ||
            '//api.openweathermap.org/data/3.0',
        apiKey:
            window['env']['wetherApiKey'] || '95b5afa7136c78ed3eb92707b81b642f',
    },
    versionCheckURL: `${window.location.origin}/version.json`,
    userAnalyticsEnabled: window['env']['userAnalyticsEnabled'] || true,
    gaAnalyticsEnabled: window['env']['gaAnalyticsEnabled'] || false,
    jitsiUrl: window['env']['jitsiUrl'] || '',
    zoomApiKey: window['env']['zoomApiKey'] || '',
    namespace: window['env']['namespace'] || '',
    zoomEmbeddedEnabled: window['env']['zoomEmbeddedEnabled'] || false,
    gaTrackingCode: window['env']['gaTrackingCode'] || '',
    todaysScheduleRefreshInterval:
        window['env']['todaysScheduleRefreshInterval'] || 5,
    allowedExtensionsForLogoUpload:
        window['env']['allowedExtensionsForLogoUpload'] || '.png, .svg',
    notificationSoundUrl: window['env']['notificationSoundUrl'] || '',
    fontAwesomeKitScriptUrl: window['env']['fontAwesomeKitScriptUrl'] || '',
    featuredVideosNumber: window['env']['featuredVideosNumber'] || '',
    oneSignalKey: window['env']['oneSignalAppId'] || '',
};
