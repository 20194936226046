import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { environment } from '@environment/environment';

import { Announcement } from '../../state/announcement-state/announcement';

@Injectable({ providedIn: 'root' })
export class AnnouncementDataService {
    constructor(private httpClient: HttpClient) {}

    getAnnouncements(): Observable<Array<Announcement>> {
        return this.httpClient.get<Array<Announcement>>(
            environment.apiUrl + '/vsc/announcements/me'
        );
    }
}
