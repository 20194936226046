import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BehaviorSubject, map, Observable } from 'rxjs';

import { environment } from '@environment/environment';

export interface NotificationsSettings {
    isInAppEnabled: boolean;
    isPushNotificationsEnabled: boolean;
    isNotificationSoundEnabled: boolean;
}

@Injectable({
    providedIn: 'root',
})
export class NotificationsSettingsService {
    private settings$: BehaviorSubject<NotificationsSettings>;

    constructor(private httpClient: HttpClient) {}

    loadNotificationsSettings(settings: NotificationsSettings): void {
        this.settings$ = new BehaviorSubject<NotificationsSettings>(settings);
    }

    fetchNotificationsSettings(): Observable<NotificationsSettings> {
        return this.httpClient.get<NotificationsSettings>(
            `${environment.apiUrl}/vsc/users/settings/notifications`
        );
    }

    getNotificationsSettings(): NotificationsSettings {
        return this.settings$?.getValue();
    }

    selectNotificationsSettings(): Observable<NotificationsSettings> {
        return this.settings$?.asObservable();
    }

    isInAppEnabled(): boolean {
        return this.settings$?.getValue()?.isInAppEnabled;
    }

    selectIsInAppEnabled(): Observable<boolean> {
        return this.settings$.asObservable().pipe(
            map((settings: NotificationsSettings) => {
                return settings?.isInAppEnabled;
            })
        );
    }

    isPushNotificationEnabled(): boolean {
        return this.settings$?.getValue()?.isPushNotificationsEnabled;
    }

    selectIsPushNotificationEnabled(): Observable<boolean> {
        return this.settings$.asObservable().pipe(
            map((settings: NotificationsSettings) => {
                return settings?.isPushNotificationsEnabled;
            })
        );
    }

    isNotificationSoundEnabled(): boolean {
        return this.settings$?.getValue()?.isNotificationSoundEnabled;
    }

    selectIsNotificationSoundEnabled(): Observable<boolean> {
        return this.settings$.asObservable().pipe(
            map((settings: NotificationsSettings) => {
                return settings?.isNotificationSoundEnabled;
            })
        );
    }
}
