<div>
    <div fxLayout="row" fxLayoutAlign="end center" class="filter-toggle">
        <button *ngIf="filteringEnabled" (click)="toggleFilters()" mat-button>
            <vsc-icon iconStyle="solid" class="filter-icon">filter</vsc-icon>
            {{ 'Filters' | transloco }}
        </button>
    </div>
    <mat-drawer-container>
        <mat-drawer
            [opened]="filtersOpened"
            (closedStart)="onFiltersClosedStart()"
            mode="over"
            position="end"
            class="filters"
        >
            <div
                fxLayout="column"
                fxLayoutAlign="space-between stretch"
                fxLayoutGap="20px"
                style="height: 100%"
                class="filters-container"
            >
                <vsc-filter
                    #filter
                    style="overflow: auto"
                    *ngIf="filteringEnabled"
                    [filterFields]="filtersDefinition"
                    (filter)="onFilter($event)"
                    (filterChange)="onFilter($event)"
                    (filterReset)="onFilterReset($event)"
                >
                </vsc-filter>
                <div
                    fxLayout="row"
                    fxLayoutAlign="space-between stretch"
                    fxLayoutGap="10px"
                    class="actions"
                >
                    <button
                        fxFlex="50"
                        (click)="resetFilter()"
                        mat-stroked-button
                        color="primary"
                        class="border"
                    >
                        {{ 'Reset' | transloco }}
                    </button>
                    <button
                        fxFlex="50"
                        (click)="filter()"
                        mat-flat-button
                        color="primary"
                    >
                        {{ 'Filter' | transloco }}
                    </button>
                </div>
            </div>
        </mat-drawer>
        <mat-drawer-content>
            <main>
                <mat-table #matTable [dataSource]="dataSource" matSort>
                    <ng-container
                        *ngFor="let column of sqColumnDefinition"
                        matColumnDef="{{ column.name }}"
                    >
                        <ng-container [ngSwitch]="column.name">
                            <ng-container *ngSwitchCase="'select'">
                                <mat-header-cell *matHeaderCellDef>
                                    <mat-checkbox
                                        *ngIf="allowMultiSelect"
                                        (change)="
                                            $event ? masterToggle() : null
                                        "
                                        [checked]="
                                            selection.hasValue() &&
                                            isAllSelected()
                                        "
                                        [indeterminate]="
                                            selection.hasValue() &&
                                            !isAllSelected()
                                        "
                                    >
                                    </mat-checkbox>
                                </mat-header-cell>
                                <mat-cell *matCellDef="let row">
                                    <mat-checkbox
                                        (click)="$event.stopPropagation()"
                                        (change)="
                                            $event
                                                ? selection.toggle(row)
                                                : null;
                                            rowSelect()
                                        "
                                        [checked]="selection.isSelected(row)"
                                    >
                                    </mat-checkbox>
                                </mat-cell>
                            </ng-container>
                            <ng-container *ngSwitchCase="'actions'">
                                <mat-header-cell
                                    *matHeaderCellDef
                                    class="first-column-actions"
                                >
                                    <!--For displaying the column label & Icon-->
                                    <div class="column-header-content">
                                        <span>
                                            <strong>
                                                {{
                                                    (isObservable(
                                                        column.displayName
                                                    )
                                                        ? (column.displayName
                                                          | async)
                                                        : column.displayName
                                                    ) | uppercase
                                                }}
                                            </strong></span
                                        >
                                    </div>
                                </mat-header-cell>
                                <mat-cell
                                    *matCellDef="let element"
                                    class="first-column-actions"
                                >
                                    <ng-container
                                        *ngTemplateOutlet="
                                            actionTemplateRef;
                                            context: { row: element }
                                        "
                                    ></ng-container>
                                </mat-cell>
                            </ng-container>
                            <ng-container *ngSwitchDefault>
                                <mat-header-cell
                                    *matHeaderCellDef
                                    [mat-sort-header]="column.sortKey"
                                    [disabled]="column.disableSorting"
                                >
                                    <!--For displaying the column label & Icon-->
                                    <div class="column-header-content">
                                        <span
                                            ><strong>
                                                {{
                                                    (isObservable(
                                                        column.displayName
                                                    )
                                                        ? (column.displayName
                                                          | async)
                                                        : column.displayName
                                                    ) | uppercase
                                                }}
                                            </strong>
                                        </span>
                                        <vsc-icon
                                            *ngIf="column.icon"
                                            class="icon-align"
                                            >{{ column.icon }}</vsc-icon
                                        >
                                    </div>
                                </mat-header-cell>

                                <ng-container
                                    *ngIf="!getColumnTemplate(column.name)"
                                >
                                    <mat-cell
                                        *matCellDef="let element"
                                        class="cell-text"
                                        #cellElement
                                        [ngClass]="{
                                            'column-center-value-align':
                                                column.centered === true
                                        }"
                                    >
                                        <ng-container
                                            *ngIf="
                                                column.formatFn;
                                                else plainCell
                                            "
                                        >
                                            <ng-container
                                                *ngIf="
                                                    column.formatFn(
                                                        element[column.name]
                                                    )
                                                        | asyncIfObservable as formattedValue
                                                "
                                            >
                                                <span
                                                    vscEllipsis
                                                    [matTooltip]="
                                                        formattedValue
                                                    "
                                                >
                                                    {{ formattedValue }}
                                                </span>
                                            </ng-container>
                                        </ng-container>

                                        <ng-template #plainCell>
                                            <span
                                                vscEllipsis
                                                [matTooltip]="
                                                    element[column.name]
                                                "
                                            >
                                                {{
                                                    column.isTranslatable
                                                        ? (element[column.name]
                                                          | transloco)
                                                        : element[column.name]
                                                }}
                                            </span>
                                        </ng-template>
                                    </mat-cell>
                                </ng-container>
                                <ng-container
                                    *ngIf="
                                        getColumnTemplate(
                                            column.name
                                        ) as columnTemplate
                                    "
                                >
                                    <mat-cell
                                        *matCellDef="let element"
                                        class="cell-text"
                                        #cellElement
                                    >
                                        <ng-container
                                            *ngTemplateOutlet="
                                                columnTemplate;
                                                context: { row: element }
                                            "
                                        ></ng-container>
                                    </mat-cell>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                    <mat-header-row
                        *matHeaderRowDef="columnNames"
                    ></mat-header-row>
                    <mat-row
                        *matRowDef="let row; columns: columnNames"
                        [ngClass]="{
                            highlight: selectedRowIndex == row.position
                        }"
                        (click)="
                            enableCheckbox == false && allowMultiSelect == false
                                ? highlight(row)
                                : false;
                            selection.toggle(row);
                            rowSelect()
                        "
                    >
                    </mat-row>
                </mat-table>
                <div
                    class="no-record-found"
                    *ngIf="
                        dataSource.content == undefined ||
                        dataSource.content.length == 0
                    "
                >
                    {{
                        isObservable(noRecordsFoundText)
                            ? (noRecordsFoundText | async)
                            : noRecordsFoundText
                    }}
                </div>

                <div
                    [hidden]="
                        dataSource.content == undefined ||
                        dataSource.content.length == 0
                    "
                    fxLayoutAlign="end center"
                >
                    <ng-container
                        *ngIf="
                            sqPaginationConfig &&
                            sqPaginationConfig.enablePagination
                        "
                    >
                        <mat-paginator
                            *ngIf="dataSource.page$ | async as page"
                            [pageSize]="sqPaginationConfig.pageSize"
                            [pageSizeOptions]="
                                sqPaginationConfig.pageSizeOptions
                            "
                            [showFirstLastButtons]="
                                sqPaginationConfig.showFirstLastButtons
                            "
                            [length]="page.totalElements"
                            [pageIndex]="page.number"
                            (page)="
                                dataSource.fetch(
                                    $event.pageIndex,
                                    $event.pageSize
                                )
                            "
                        >
                        </mat-paginator>
                    </ng-container>
                </div>
            </main>
        </mat-drawer-content>
    </mat-drawer-container>
</div>
