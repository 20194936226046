import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { EMPTY, Observable } from 'rxjs';

import { environment } from '@environment/environment';

import { PagedEntities } from '@shared/models/paged-entities';
import { HttpService } from '@shared/services/http.service';
import { PageRequest } from '@shared/table/page';

import { Notification } from '../models/notification';
import { NotificationCaseCategory } from '../state/notification-case-category';
import { NotificationDeliveryMethod } from '../state/notification-delivery-method';
import { NotificationEventReminder } from '../state/notification-event-reminder';
import { NotificationsSearchQuery } from '../state/notifications-search.query';

@Injectable({
    providedIn: 'root',
})
export class NotificationsDataService {
    private readonly NOTIFICATIONS_URL: string = `${environment.apiUrl}/notis/notifications`;

    constructor(
        private httpService: HttpService,
        private httpClient: HttpClient
    ) {}

    getNotifications(
        query: NotificationsSearchQuery,
        request: PageRequest<Notification>
    ): Observable<PagedEntities<Notification>> {
        const params: HttpParams = new HttpParams({
            fromObject: {
                page: request.page.toString(),
                pageSize: request.size.toString(),
                sortBy: request?.sort?.property ?? '',
                sortDirection: request?.sort?.order ?? '',
                search: query.search ?? '',
            },
        });

        return this.httpService.get<PagedEntities<Notification>>(
            `${this.NOTIFICATIONS_URL}/me`,
            {
                params: params,
                interceptHttpError: false,
            }
        );
    }

    getUnreadUserNotificationsCount(): Observable<number> {
        return this.httpService.get<number>(
            `${this.NOTIFICATIONS_URL}/me/unread-count`,
            {
                interceptHttpError: false,
            }
        );
    }

    markNotificationsAsRead(ids: string[]): Observable<void> {
        if (ids.length > 0) {
            return this.httpService.patch<void>(
                `${this.NOTIFICATIONS_URL}/me/mark-as-read/set`,
                {
                    ids: ids,
                },
                {
                    interceptHttpError: false,
                }
            );
        }

        return EMPTY;
    }

    markAllNotificationsAsRead(): Observable<void> {
        return this.httpService.patch<void>(
            `${this.NOTIFICATIONS_URL}/me/mark-as-read/all`,
            {
                interceptHttpError: false,
            }
        );
    }

    getNotificationDeliveryMethods(): Observable<
        Array<NotificationDeliveryMethod>
    > {
        return this.httpClient.get<Array<NotificationDeliveryMethod>>(
            environment.apiUrl +
                '/vsc/notifications/notification-delivery-methods'
        );
    }

    getUserNotificationEventReminders(
        id: string
    ): Observable<Array<NotificationEventReminder>> {
        return this.httpClient.get<Array<NotificationEventReminder>>(
            environment.apiUrl +
                `/vsc/notifications/${id}/notification-event-reminders`
        );
    }

    getNotificationCaseCategories(): Observable<
        Array<NotificationCaseCategory>
    > {
        return this.httpClient.get<Array<NotificationCaseCategory>>(
            environment.apiUrl +
                '/vsc/notifications/notification-case-categories'
        );
    }

    deleteNotification(id: string): Observable<any> {
        return this.httpService.delete(`${this.NOTIFICATIONS_URL}/me/${id}`, {
            interceptHttpError: false,
        });
    }

    checkIfUserHasPushNotificationsEnabled(
        userId: string
    ): Observable<boolean> {
        return this.httpService.get<boolean>(
            `${this.NOTIFICATIONS_URL}/${userId}/is-push-enabled`
        );
    }

    updatePlayerLanguage(playerId: string, language: string): Observable<any> {
        return this.httpService.put<any>(
            `${this.NOTIFICATIONS_URL}/push/devices`,
            {
                playerId,
                language,
            }
        );
    }
}
