import { Injectable } from '@angular/core';

import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

import { NotificationEventReminder } from './notification-event-reminder';

export type NotificationEventRemindersState =
    EntityState<NotificationEventReminder>;

export const initialState: NotificationEventRemindersState = {
    loading: false,
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'notificationEventReminders' })
export class NotificationEventRemindersStore extends EntityStore<
    NotificationEventRemindersState,
    NotificationEventReminder
> {
    constructor() {
        super(initialState);
    }

    setUserNotificationEventReminders(
        notifEventReminders: Array<NotificationEventReminder>,
        userId: string
    ): void {
        const state = {};
        state[userId] = notifEventReminders;
        this.update(state);
    }
}
