// Akita store
import { Injectable } from '@angular/core';

import { Store, StoreConfig } from '@datorama/akita';

export interface SurveysState {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'surveys' })
export class SurveysStore extends Store<SurveysState> {
    private static INITIAL_STATE: SurveysState = {};

    constructor() {
        super(SurveysStore.INITIAL_STATE);
    }
}
