import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';

import { TranslocoModule } from '@ngneat/transloco';

import { IconModule } from '@shared/icon/icon.module';
import { MaterialModule } from '@shared/material.module';

import { CookieSettingsComponent } from './cookie-settings/cookie-settings.component';
import { CookiesManageComponent } from './cookies-manage/cookies-manage.component';
import { CookiesComponent } from './cookies.component';

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        FlexLayoutModule,
        TranslocoModule,
        IconModule,
    ],
    declarations: [
        CookiesComponent,
        CookiesManageComponent,
        CookieSettingsComponent,
    ],
    exports: [CookiesComponent],
})
export class CookiesModule {}
