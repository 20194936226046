import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { IconModule } from '@shared/icon/icon.module';

import { TranslocoRootModule } from '../../transloco-root.module';
import { VscBreadcrumbsComponent } from './component/vsc-breadcrumbs.component';
import { VscBreadcrumbsConfig } from './service/vsc-breadcrumbs.config';
import { VscBreadcrumbsService } from './service/vsc-breadcrumbs.service';

@NgModule({
    imports: [CommonModule, RouterModule, TranslocoRootModule, IconModule],
    declarations: [VscBreadcrumbsComponent],
    exports: [VscBreadcrumbsComponent],
})
export class VscBreadcrumbsModule {
    static forRoot(): ModuleWithProviders<VscBreadcrumbsModule> {
        return {
            ngModule: VscBreadcrumbsModule,
            providers: [VscBreadcrumbsService, VscBreadcrumbsConfig],
        };
    }
}
