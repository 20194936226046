import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { environment } from '@environment/environment';

import { Alert } from '../../state/alert-state/alert';

@Injectable({ providedIn: 'root' })
export class AlertDataService {
    constructor(private httpClient: HttpClient) {}

    getActiveAlert(): Observable<Alert> {
        return this.httpClient.get<Alert>(
            environment.apiUrl + '/vsc/alerts/active'
        );
    }
}
