import { Injectable, Injector, Type } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class DecoratorService {
    private static injector: Injector;

    constructor(injector: Injector) {
        DecoratorService.injector = injector;
    }

    public static getServiceInstance<T>(type: Type<T>) {
        return DecoratorService.injector.get(type);
    }
}
