export enum GroupStatus {
    Active,
    Inactive,
    All,
}

export enum GroupType {
    Internal,
    Customer,
}
