<footer
    fxLayout="row"
    fxLayout.lt-md="column"
    fxLayoutAlign="space-between center"
>
    <div fxLayout="row" fxFlex="50" fxLayoutAlign="start center">
        <div class="powered-by">
            <p>
                Powered by
                <img src="../../../assets/images/enliveo-trademark.svg" />
                enliveo
            </p>
        </div>
    </div>
    <div
        fxLayoutGap="10px"
        fxLayout="row"
        class="links"
        fxFlex="50"
        fxLayoutAlign="end center"
    >
        <a> {{ '© enliveo' | transloco }} {{ year }} </a>
        <a (click)="navigateToPrivacy()">{{ 'Privacy' | transloco }} </a>
        <a (click)="navigateToTOS()">{{ 'TOS' | transloco }}</a>
        <a (click)="navigateToCookiesPage()">{{
            'Cookie Policy' | transloco
        }}</a>
    </div>
</footer>
