import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { setLoading } from '@datorama/akita';

import { EMPTY, Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { SnackBarService } from '@shared/snack-bar/snack-bar.service';

import { ClassAssessmentSurvey } from '../models/class-assessment-survey.model';
import { SurveyDialogData } from '../models/survey-dialog-data.model';
import { SurveyQuestionOptionType } from '../models/survey-question-option-type.enum';
import { Survey } from '../models/survey.model';
import { UserSurvey } from '../models/user-survey.model';
import { SurveysStore } from '../state/surveys.store';
import { SurveyComponent } from '../survey.component';
import { SurveyDataService } from './survey.dataservice';

@Injectable({ providedIn: 'root' })
export class SurveyService {
    constructor(
        private surveyDataService: SurveyDataService,
        private dialog: MatDialog,
        private snackBar: SnackBarService,
        private surveysStore: SurveysStore
    ) {}

    showSelfAssessmentSurveyIfAvailable(): Observable<Survey> {
        return this.surveyDataService.getSelfAssessmentSurvey().pipe(
            tap((anxietyAssesmentSurvey: Survey) => {
                if (anxietyAssesmentSurvey) {
                    this.showSurveyDialog(
                        {
                            isClassSurvey: false,
                            survey: anxietyAssesmentSurvey,
                            showCommentField: false,
                            surveyQuestionOptionType:
                                SurveyQuestionOptionType.Radio,
                            title: anxietyAssesmentSurvey.name,
                            subtitle: `We would like to ask you a few questions to enable us to measure how helpful our services are. You can choose to answer all or none of the questions. Choosing not to answer will not affect your access to any of our services in any way. There are no right or wrong answers to these questions. Just think about your life now, and please, be honest. We very much appreciate your help.`,
                        },
                        '650px'
                    );
                }
            })
        );
    }

    showClassAssessmentSurveyIfAvailable(): Observable<ClassAssessmentSurvey> {
        return this.surveyDataService.getClassAssessmentSurvey().pipe(
            tap((classAssesmentSurvey: ClassAssessmentSurvey) => {
                if (classAssesmentSurvey) {
                    this.showSurveyDialog(
                        {
                            isClassSurvey: true,
                            survey: classAssesmentSurvey,
                            showCommentField: true,
                            surveyQuestionOptionType:
                                SurveyQuestionOptionType.Star,
                            title: classAssesmentSurvey.name,
                            subtitle:
                                'Please take a moment to fill out the form below:',
                            classSessionId: classAssesmentSurvey.classSessionId,
                        },
                        'auto'
                    );
                }
            })
        );
    }
    getIsolationSurvey(): Observable<Survey> {
        return this.surveyDataService.getIsolationSurvey().pipe(
            setLoading(this.surveysStore),
            catchError(() => {
                this.snackBar.open('Failed to load Survey.');
                return EMPTY;
            })
        );
    }

    saveUserSurvey(userSurvey: UserSurvey): Observable<UserSurvey> {
        return this.surveyDataService.saveUserSurvey(userSurvey).pipe(
            setLoading(this.surveysStore),
            catchError(() => {
                this.snackBar.open('Failed to save User Survey.');
                return EMPTY;
            })
        );
    }

    private showSurveyDialog(data: SurveyDialogData, width: string): void {
        this.dialog.open(SurveyComponent, {
            width,
            disableClose: true,
            data,
        });
    }
}
