import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { NgxPermissionsGuard } from 'ngx-permissions';

import { AuthGuard } from '@core/auth/auth-guard.service';
import { Permissions } from '@core/auth/permissions';

import { AdminComponent } from '@admin/admin.component';
import { routes as publicRoutes } from '@public/public-routing.module';
import { PublicComponent } from '@public/public.component';

import { ConsumerInformationCanActivateGuard } from './consumer-information/consumer-information-can-activate.guard';
import { ConsumerInformationComponent } from './consumer-information/consumer-information.component';
import { ConsumerInformationGuard } from './consumer-information/consumer-information.guard';

const routes: Routes = [
    {
        path: '',
        canActivate: [AuthGuard, ConsumerInformationGuard],
        component: PublicComponent,
        children: [...publicRoutes],
    },
    {
        path: 'admin',
        canActivate: [AuthGuard, NgxPermissionsGuard, ConsumerInformationGuard],
        component: AdminComponent,
        loadChildren: () =>
            import('./admin/admin/admin.module').then((m) => m.AdminModule),
        data: {
            permissions: {
                only: [Permissions.Administration.View],
                redirectTo: 'unauthorized',
            },
        },
    },
    {
        path: 'consumer-information',
        canActivate: [AuthGuard, ConsumerInformationCanActivateGuard],
        component: ConsumerInformationComponent,

        loadChildren: () =>
            import('./consumer-information/consumer-information.module').then(
                (m) => m.ConsumerInformationModule
            ),
    },
    { path: '**', redirectTo: 'not-found' },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            paramsInheritanceStrategy: 'always',
            relativeLinkResolution: 'legacy',
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
