import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    OnInit,
    Output,
} from '@angular/core';

import { TranslocoService } from '@ngneat/transloco';

import { combineLatest, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { Permissions } from '@core/auth/permissions';

import { Constants } from '@shared/constants';
import { PagePaths } from '@shared/constants/page-paths';

import { ChatsQuery } from '@public/chats/chat-state/chats.query';

@Component({
    selector: 'vsc-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuComponent implements OnInit {
    Permissions: any = Permissions;
    PagePaths: typeof PagePaths = PagePaths;

    isChatsScheduleUrl$: Observable<boolean>;
    chatInvitesCountNote$: Observable<string>;

    @Output() menuItemClick: EventEmitter<any> = new EventEmitter<any>();

    constructor(
        private chatsQuery: ChatsQuery,
        private translocoService: TranslocoService
    ) {}

    ngOnInit(): void {
        this.chatInvitesCountNote$ = combineLatest([
            this.chatsQuery.selectPendingChatsCountConditionally$,
            this.translocoService.langChanges$.pipe(startWith('')),
        ]).pipe(
            map(([count]: [number, string]) =>
                this.formatChatInvitesCountNote(count)
            )
        );
    }

    onMenuItemClick(): void {
        this.menuItemClick.emit();
    }

    openMail(): void {
        window.open(Constants.GMAIL_URL);
    }

    private formatChatInvitesCountNote(count: number): string {
        return !count
            ? ''
            : `${count} ${this.translocoService.translate(
                  count === 1 ? 'invite' : 'invites'
              )}`;
    }
}
