import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    HostBinding,
    Input,
    Output,
} from '@angular/core';

import { RouterQuery } from '@datorama/akita-ng-router-store';

import { Observable, tap } from 'rxjs';

@Component({
    selector: 'vsc-menu-item',
    templateUrl: './menu-item.component.html',
    styleUrls: ['./menu-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuItemComponent {
    @Input() path: string;
    @Input() basePath: string;
    @Input() title: string;
    @Input() icon: string;
    @Input() note: string;
    @Output() menuItemClick: EventEmitter<void> = new EventEmitter<void>();

    @HostBinding('class.active') isActive: boolean;

    isActive$: Observable<boolean> = this.routerQuery
        .select((routerState) =>
            routerState.state.urlAfterRedirects.startsWith(this.basePath)
        )
        .pipe(tap((isActive: boolean) => (this.isActive = isActive)));

    constructor(private routerQuery: RouterQuery) {}

    onMenuItemClick(): void {
        this.menuItemClick.emit();
    }
}
