import { Validators } from '@angular/forms';

import { Observable } from 'rxjs';

import { Lookup } from '@shared/state/lookup/lookup';

export enum FieldType {
    CHECKBOX,
    DATEPICKER,
    RADIO,
    SELECTDROPDOWN,
    SELECTLIST,
    SLIDETOGGLE,
    TEXTAREA,
    TEXTFIELD,
    SUBHEADER,
    DIVIDER,
    DATERANGEPICKER,
}

export interface Field {
    name: string;
    type: FieldType;
    children?: Field[];
    defaultValue?: any;
    disabled?: boolean;
    options?: Array<Lookup> | Observable<Array<Lookup>> | any;
    config: any;
    parent?: string;
    validation?: Validators[];
    visible?: boolean;
    labelVisible: boolean;
    label: string;
    isTranslatable?: boolean;
}

export interface KeyValuePair {
    key: string;
    value: any;
}

export interface Error {
    name: string;
    text: string;
    rules: Validators[];
}
