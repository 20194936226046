import { AfterViewInit, Component, NgZone, OnInit } from '@angular/core';

import { akitaDevtools } from '@datorama/akita';

import { Observable, take } from 'rxjs';

import { AuthService } from '@core/auth/auth.service';
import {
    NotificationsSettings,
    NotificationsSettingsService,
} from '@core/services/notifications-settings.service';
import { NotificationsHub } from '@core/services/notifications.hub';
import { environment } from '@environment/environment';

import { Constants } from '@shared/constants';
import { OneSignalHelperService } from '@shared/notifications/services/onesignal-helper.service';
import { ToastService } from '@shared/toast/services/toast.service';
import { CommonUtilities } from '@shared/utils/common-utilities';

import { OrganizationService } from '@admin/organizations/services/organization.service';

import { VersionCheckService } from '../version-check.service';

@Component({
    selector: 'vsc-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [NotificationsHub],
})
export class AppComponent implements OnInit, AfterViewInit {
    organizationLogoUrl: string;
    hasAnyToast$: Observable<boolean>;

    constructor(
        private ngZone: NgZone,
        private notificationsHub: NotificationsHub,
        private notificationsSettingsService: NotificationsSettingsService,
        private organizationService: OrganizationService,
        private toastService: ToastService,
        private oneSignalHelper: OneSignalHelperService,
        public authService: AuthService,
        public versionCheckService: VersionCheckService
    ) {
        this.hasAnyToast$ = this.toastService.hasAnyToast$;
    }

    ngOnInit(): void {
        this.initAppTheme('primary-theme');
        this.loadScripts();

        this.versionCheckService.initVersionCheck(environment.versionCheckURL);

        if (!environment.production || environment.namespace === 'dev') {
            akitaDevtools(this.ngZone);
        }
        const organizationId: string = this.authService.tenantId;
        organizationId &&
            this.organizationService
                .getOrganizationLogoUrl(organizationId)
                .pipe(take(1))
                .subscribe((organizationLogoUrl: string) => {
                    if (organizationLogoUrl) {
                        this.organizationService.addOrganizationLogoUrl(
                            organizationLogoUrl
                        );
                        this.organizationLogoUrl = organizationLogoUrl;
                    }
                });
    }

    ngAfterViewInit(): void {
        this.notificationsSettingsService
            .fetchNotificationsSettings()
            .pipe(take(1))
            .subscribe((settings: NotificationsSettings) => {
                this.notificationsSettingsService.loadNotificationsSettings(
                    settings
                );
                this.oneSignalHelper.initOneSignal(
                    settings.isPushNotificationsEnabled
                );
            });

        this.notificationsHub.startConnection();
    }

    private initAppTheme(newThemeClass: string): void {
        const body: HTMLBodyElement = document.getElementsByTagName('body')[0];
        body.classList.add(newThemeClass);
    }

    private loadScripts(): void {
        CommonUtilities.loadScript({
            src: environment.fontAwesomeKitScriptUrl,
            appendTo: 'head',
            crossOrigin: 'anonymous',
        });

        CommonUtilities.loadScript({
            src: `https://${environment.jitsiUrl}/${Constants.JITSI_SCRIPT_NAME}`,
            appendTo: 'head',
        });

        if (environment.production) {
            CommonUtilities.loadScript({
                src: 'assets/scripts/stonly.js',
                appendTo: 'head',
            });
        }
    }
}
