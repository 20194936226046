<div
    fxLayout="row"
    fxLayout.lt-lg="column"
    fxLayoutGap="16px"
    fxLayoutAlign="stretch stretch"
    class="home-container"
>
    <div
        fxFlex="100"
        fxFlex.xl="60"
        fxFlex.md="100"
        fxFlex.lg="60"
        fxFlex.xs="100"
        fxFlex.sm="100"
    >
        <div
            *ngIf="this.hasAnyAlert"
            fxLayout="column"
            fxLayout.xl="row"
            fxLayoutGap="16px"
        >
            <vsc-alerts-widget
                fxFlex="100"
                (alertVisibilityChangeEvent)="onAlertVisibilityChange($event)"
            >
            </vsc-alerts-widget>
        </div>
        <vsc-todays-schedule
            [ngClass]="{ 'set-height': isAlertVisible }"
        ></vsc-todays-schedule>
    </div>

    <div
        fxFlex="100"
        fxFlex.xl="40"
        fxFlex.md="100"
        fxFlex.lg="40"
        fxFlex.xs="100"
        fxFlex.sm="100"
        fxLayout="column"
        fxLayoutGap="16px"
    >
        <vsc-announcements-widget></vsc-announcements-widget>
        <div
            *ngIf="this.hasAnyPromotion"
            fxLayout="column"
            fxLayout.xl="row"
            fxLayoutGap="16px"
        >
            <vsc-promotional-widget
                *ngxPermissionsOnly="Permissions.Promotions.View"
                fxFlex="100"
            >
            </vsc-promotional-widget>
        </div>
    </div>
</div>
