import { Injectable, InjectionToken, Inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { Observable } from 'rxjs';

import {
    ConfirmDialogModel,
    ConfirmDialogComponent,
} from './confirm-dialog.component';

export interface ConfrimDialogDefaultOptions {
    message: string | Observable<string>;
    title: string | Observable<string>;
    noText: string | Observable<string>;
    yesText: string | Observable<string>;
}

export const CONFIRM_DIALOG_DEFAULT_OPTIONS =
    new InjectionToken<ConfrimDialogDefaultOptions>(
        'Confirm Dialog Default Options'
    );

@Injectable({ providedIn: 'root' })
export class ConfirmDialogService {
    constructor(
        private dialog: MatDialog,
        @Inject(CONFIRM_DIALOG_DEFAULT_OPTIONS)
        private defaultOptions: ConfrimDialogDefaultOptions
    ) {
        if (defaultOptions === undefined) {
            defaultOptions = {
                title: 'Confrim action',
                message: 'Are you sure you want perform this action?',
                noText: 'No',
                yesText: 'Yes',
            };
        }
    }

    public confirm(
        message?: string,
        title?: string,
        isNoButtonVisible: boolean = false
    ): Observable<boolean> {
        const dialogData = new ConfirmDialogModel(
            message ?? this.defaultOptions.message,
            title ?? this.defaultOptions.title,
            this.defaultOptions.yesText,
            this.defaultOptions.noText,
            isNoButtonVisible
        );

        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            maxWidth: '480px',
            data: dialogData,
            autoFocus: false,
        });

        return dialogRef.afterClosed();
    }
}
