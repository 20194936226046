<div fxLayoutAlign="center center" fxLayout="column">
    <h5 class="error-message">
        {{ 'Whoops, looks like something went wrong!' | transloco }}
    </h5>
    <img
        src="./../../../assets/images/backgrounds/return-to-homepage.jpg"
        alt="Return to home page Image"
        class="return-to-homepage"
    />
    <div class="description-message">
        <p class="message">
            {{
                'Please click on the button below to return to the Home Page.'
                    | transloco
            }}
        </p>
        <p class="message">
            {{ 'Enjoy your experience and have a nice day!' | transloco }}
        </p>
        <button
            (click)="navigateToHome()"
            mat-flat-button
            color="primary"
            class="btn-unauthorized"
        >
            {{ 'Return To Homepage' | transloco }}
        </button>
    </div>
</div>
