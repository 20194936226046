import {
    AbstractControl,
    FormControl,
    ValidationErrors,
    Validator,
} from '@angular/forms';

import * as moment from 'moment';

import { DynamicValidator, Group, Rule } from './dynamic';

export class ValidDateValidator extends DynamicValidator implements Validator {
    constructor(private message: string, rules?: Array<Rule | Group>) {
        super(rules);
    }

    validate(control: AbstractControl): ValidationErrors | null {
        return super.validateControl(control, () => {
            if (control instanceof FormControl) {
                if (!control?.value || moment(control.value).isValid()) {
                    return null;
                }

                return {
                    validDate: {
                        message: this.message,
                    },
                };
            }

            return null;
        });
    }
}
